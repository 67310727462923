<template>
  <!-- Muss be only one in Page ??? -->
  <div class="scroll-to-heading">
    <div
      class="video-player position-relative embed-responsive embed-responsive-16by9"
      :style="{height: calcHeight}"
    >
      <div
        v-if="!playerStatus"
        class="video-player-image embed-responsive-item"
        :style="{'backgroundImage':'url('+ require('src/assets/ais-logo/ais_play.png') +')'}"
        style="
        background-size: contain;
        background-color: black;
        "
      >
        <loader style="position: unset; height: 100%"></loader>
      </div>

      <iframe
        v-if="videoUrl !== null"
        id="video_player"
        :style="{'opacity':!playerStatus ? '1' : '1'}"
        class="video-player-component embed-responsive-item"
        width="100%"
        height="100%"
        :src="videoUrl"
        :name="eventIdentifier+'_video_player'"
        allowfullscreen
        webkitallowfullscreen
        mozallowfullscreen
        allow="autoplay; encrypted-media"
        lazyload="off"
        @click="showRestrictionMessage()"
      />
    </div>
  </div>
</template>

<script>
  import { normalizeImg } from 'src/filters';
  import { floorTo, formatMediaUrl, truncateSymbolsToNumber } from 'src/utils';
  import Auth from 'src/service/authService';
  import { localStore } from 'vimmi-web-utils/esm/localStore';
  import { secondLocation } from 'vimmi-web-utils/cjs/secondLocation';
  import { thirdLocation } from 'vimmi-web-utils/cjs/thirdLocation';
  import Config from 'src/service/config';
  import { PLAYER_EVENTS, PLAYER_METHODS, PM } from 'vimmi-web-utils/cjs/postMessage';
  import ProPlayBtn from '../playbtn/playbtn';
  import getLanguageKey from 'src/utils/getLanguageKey.js';
  import { AuthVariableMixin } from 'src/plugins/mixin';
  import { amsClient } from '@/service/ams';
  import Loader from '../../loader/loader';

  export default {
    name: 'MediaPlayerVideoMP',
    components: {
      Loader,
      ProPlayBtn,
    },
    mixins: [AuthVariableMixin],
    props: ['eventIdentifier', 'autoplay', 'vHeight'],
    data() {
      return {
        // Auth user and restriction
        isRestricted: null,
        clickOnMedia: Date.now(),
        // item values
        prevOpt: {},
        currentItem: {},
        //        itemId: null,
        videoUrl: null,
        tempPoster: null,
        // Analitiks
        iid: 0,
        prevId: 0,
        isLive: null,
        percentage: null,
        nextPercentage: 0,
        duration: null,
        position: 0,

        stream_time: 0,
        previewStop: false,

        //        playingOptions: {},
        isAdsPlaying: false,
        isVideoPlaying: false,
        isVideoChangedDuringADS: false,

        // Player
        calcHeight: '100%',
        playerStatus: null, // 1 -init else not defined 0 - not init
        // context: {},
        TagByIsWatchedVideoList: 'watchlist',
        TIMEOUT_FOR_NEW_VIDEO_AFTER_ERROR: 5 * 1000,
        idTimeoutNewVideoAtrerError: 0,
      };
    },
    //    computed: {
    //      isAuth() {
    //        return Auth.isAuth(); //&& !Auth.isSilent();
    //      },
    //    },
    created() {
      this.handlePMEvents();
      this.$bus.$on(this.eventIdentifier + '_pauseVideo', this.event_pauseVideo);
      this.$bus.$on(this.eventIdentifier + '_playVideo', this.event_playVideo);
      this.$bus.$on(
        this.eventIdentifier + '_playNowVideo',
        this.event_playNowVideo,
      );
      this.$bus.$on(
        this.eventIdentifier + '_loadPrevNextVideo',
        this.event_loadPrevNextVideo,
      );
      this.$bus.$on(
        this.eventIdentifier + '_enableControls',
        this.event_enableControls,
      );
      this.$bus.$on(
        this.eventIdentifier + '_disableControls',
        this.event_disableControls,
      );
      this.$bus.$on(
        this.eventIdentifier + '_setComponentPoster',
        this.event_setComponentPoster,
      );
      this.$bus.$on(
        this.eventIdentifier + '_actionOnButton',
        this.event_actionOnButton,
      );
      //      this.$bus.$on('playerCM', this.playerCM);
      this.$bus.$on('scrollVideoToView', this.scrollToView);
      //      this.$bus.$on('userSuccessAuth', this.onUserSuccessLogin);
      //      this.$bus.$on('userSuccessLogOut', this.onUserSuccessLogOut);
      // this.$bus.$on('changedUserState', this.onChangedUserState);
      this.$bus.$on('sync', this.onSync);
      this.$bus.$on('amsBeforeLogout', this.amsBeforeLogout);
      this.$bus.$on('amsBeforeLogged', this.amsBeforeLogged);
      this.$bus.$on('beforeTerminated', this.beforeTerminated);
      this.$bus.$on('thirdLocationRetry', () => {
        this.send(PLAYER_METHODS.CONFIGURATION, {
          data: {
            thirdLocation: {
              retryRequest: true,
            },
          },
        });
      });
      this.$bus.$on('thirdLocationCancel', () => {
        this.send(PLAYER_METHODS.CONFIGURATION, {
          data: {
            thirdLocation: {
              cancelRequest: true,
            },
          },
        });
      });
    },
    mounted() {
      Config.loaded(() => {
        if (!_isPreviewMode()) {
          this.calcHeight =
            this.vHeight || (this.$el.clientWidth * 9) / 16 + 'px' || '100%';
          let initPlayerOption = {
            initEmpty: true,
            debug: true,
            font_size: '14px',
            env: Config.get('env'),
            lang: this.$i18n.locale,
          };


          if (Config.get('debug') || location.href.indexOf('debug=true') !== -1) {
            initPlayerOption.debug = true;
          }

          if (Config.get('secondLocation')) {
            initPlayerOption.sl = true;
          }
          if (thirdLocation.isEnabled()) {
            initPlayerOption.tl = true;
          }
          let playerUrl = Config.get('player.url');
          let options = Object.assign(
            Config.get('player.options'),
            initPlayerOption,
          );
          this.videoUrl = this.formatMediaUrl(playerUrl, options);
          if (!this.vHeight) {
            this.$bus.$on('resizeWindow', this.calculateHeight);
          }
          this.$bus.$emit(this.eventIdentifier + '_videoComponentReady', {});
          this.TIMEOUT_FOR_NEW_VIDEO_AFTER_ERROR = Config.get(
            'player.timeoutChangingItems',
          );
        } else {
          this.$bus.$emit(this.eventIdentifier + '_videoComponentReady', {});
        }
      });
      if (thirdLocation.isEnabled()) {
        thirdLocation.events.on('updatedDomain', () => {
          this.send(PLAYER_METHODS.CONFIGURATION, {
            data: {
              thirdLocation: {
                activeDomain: thirdLocation.get('activeDomain'),
                domains: thirdLocation.get('domains'),
                nRetries: thirdLocation.get('nRetries'),
                nNonPriorityRetries: thirdLocation.get('nNonPriorityRetries'),
                nDistroRetries: thirdLocation.get('nDistroRetries'),
                confRetryDelay: thirdLocation.get('confRetryDelay'),
                confAttempts: thirdLocation.get('confAttempts'),
                maxReq: thirdLocation.get('maxReq'),
              },
            },
          });
        });
      }
    },

    beforeDestroy() {
      if (thirdLocation.isEnabled()) {
        thirdLocation.events.off('updatedDomain');
      }
      if (!this.currentOptions?.preview) {
        if (this.currentItem.id) {
          this.setWatchedVideo();
        }
        this.setResumeWatch();
      }
      /** analytics */
      this.skipNextAfterError();
      /** analytics end */
      this.$bus.$off('sync', this.onSync);
      this.$bus.$off('resizeWindow', this.calculateHeight);
      //      this.$bus.$off('playerCM', this.playerCM);
      this.$bus.$off(
        this.eventIdentifier + '_playNowVideo',
        this.event_playNowVideo,
      );
      this.$bus.$off(
        this.eventIdentifier + '_actionOnButton',
        this.event_actionOnButton,
      );
      this.$bus.$off(this.eventIdentifier + '_playVideo', this.event_playVideo);
      this.$bus.$off(this.eventIdentifier + '_pauseVideo', this.event_pauseVideo);
      this.$bus.$off(
        this.eventIdentifier + '_loadPrevNextVideo',
        this.event_loadPrevNextVideo,
      );
      this.$bus.$off(
        this.eventIdentifier + '_disableControls',
        this.event_disableControls,
      );
      this.$bus.$off(
        this.eventIdentifier + '_enableControls',
        this.event_enableControls,
      );
      this.$bus.$off(
        this.eventIdentifier + '_setComponentPoster',
        this.event_setComponentPoster,
      );
      this.$bus.$off('scrollVideoToView', this.scrollToView);
      //      this.$bus.$off('userSuccessAuth', this.onUserSuccessLogin);
      //      this.$bus.$off('userSuccessLogOut', this.onUserSuccessLogOut);
      // this.$bus.$off('changedUserState', this.onChangedUserState);

      this.$bus.$off('amsBeforeLogout', this.amsBeforeLogout);
      this.$bus.$off('amsBeforeLogged', this.amsBeforeLogged);
      this.$bus.$off('beforeTerminated', this.beforeTerminated);
    },

    methods: {
      // NOTE: imported functions
      truncateSymbolsToNumber,
      floorTo,
      formatMediaUrl,

      amsBeforeLogout() {
        if (this.isFullAuth) {
          this.previewStop = false;
          // Вызывается только в случае, если делаем логаут для обычного юзера
          console.log('[DEBUG][USER]');
          this.setDailyPreview();
        }
      },

      amsBeforeLogged() {
        if (this.isGuest) {
          this.previewStop = false;
          // Вызывается только в случае логина обычного пользователя, если до этого был гость
          console.log('[DEBUG][GUEST]');
          this.setDailyPreview();
        }
      },

      beforeTerminated() {
        this.setDailyPreview(true);
      },

      setDailyPreview(terminated = false) {
        if (this.previewStop && this.currentOptions?.preview && this.stream_time) {
          this.previewStop = true;
          amsClient.stock.setDailyPreviewTime(
            parseInt(this.stream_time + (this.currentOptions.preview_info.consumed_time || 0)),
            this.currentItem.id,
            parseInt(this.stream_time),
            'item',
            terminated,
          );
        }

        this.stream_time = 0;
      },

      onChangedUserState(data = {}) {
        // if (data.type === 'logged') {
        //   this.playerCM('setUser');
        //
        // } else if (data.type === 'logout') {
        //   this.playerCM('unsetUser');
        // }
        if (data.type === 'logout' && !this.isGuest) {
          this.setRestrictionState({ restricted: 'RestrictedForUnknow' });
        }
        this.updatePlayerConf();
        let isWasRestricted = this.isRestricted;
        this.isVideoRestricted(this.currentItem)
          .then(restriction => {
            this.$logger.info('MPV', 'onChangedUserState', {
              isWasRestricted, restriction,
            });
            this.isRestricted = restriction;
            if (data.type === 'logged') {
              this.prevOpt.autoplay = this.stream_time > 1 && this.isVideoPlaying && !this.isRestricted;
              if (this.position) {
                this.prevOpt.startPosition = isWasRestricted && !this.isRestricted ? 0 : this.position;
              }
              if (this.isRestricted) {
                this.showRestrictionMessage();
                this.event_PlayNowPart2(this.prevOpt);
              } else if (isWasRestricted) {
                this.event_PlayNowPart2(this.prevOpt);
                this.$bus.$emit(this.eventIdentifier + '_togglePlayerLayout', {
                  value: this.prevOpt.autoplay,
                  arrows: this.prevOpt.autoplay,
                });
                this.hideRestrictionMessage();
                this.skipNextAfterError();
              }
            } else if (data.type === 'logout' && !this.isActiveFeature('guest-users')) {
              if (this.isRestricted) {
                this.prevOpt.autoplay = (this.stream_time > 1 || this.prevOpt.isLive) && this.isVideoPlaying;
                this.prevOpt.startPosition = 0;
                this.event_PlayNowPart2(this.prevOpt);
                this.showRestrictionMessage();
                this.$bus.$emit(this.eventIdentifier + '_togglePlayerLayout', {
                  value: true,
                  arrows: false,
                });
              } else {
                this.hideRestrictionMessage();
                this.skipNextAfterError();
              }
            } else if (data.type === 'logout') {
              this.position = 0;
            }
          });
      },
      async onSync(data) {
        if (data && data.afterLogin) {
          console.log('skip sync after login', data);
          return;
        }
        this.updatePlayerConf();
        let restriction = await this.isVideoRestricted(this.currentItem);
        let isWasRestricted = this.isRestricted;
        this.isRestricted = restriction;

        if (restriction) {
          this.setRestrictionState({ restricted: this.isRestricted });
          this.showRestrictionMessage();
        } else if (isWasRestricted) {
          if (this.position) {
            this.prevOpt.startPosition = !this.isRestricted ? 0 : this.position;
          }
          this.prevOpt.autoplay = this.stream_time > 1 || this.isVideoPlaying || isWasRestricted;
          this.event_PlayNowPart2(this.prevOpt);
          this.$bus.$emit(this.eventIdentifier + '_togglePlayerLayout', {
            value: this.prevOpt.autoplay,
            arrows: this.prevOpt.autoplay,
          });
          this.hideRestrictionMessage();
          this.skipNextAfterError();
        }
      },
      updatePlayerConf() {
        let analytics = {};
        if (this.isActiveFeature('buh-analytics')) {
          analytics.buh = amsClient.get('buh');
        }
        console.log('[deb] isEnabled', thirdLocation.isEnabled(), thirdLocation);
        this.send(PLAYER_METHODS.CONFIGURATION, {
          data: {
            portal: {
              /** for player module, start*/
              public_app_key: Config.get('auth.ams.public_app_key'),
              private_id: Auth.get('user.privateId'),
              dat: Auth.get('user.dat'),
              concurrency: Auth.get('concurrency'),
              'Device-Info': amsClient.getAMSDeviceInfo(),
              // browser: device.info.browser.name, // temporary
              // os_version: device.info.os.version, // temporary
              /** for player module, end*/

              app_id: Config.get('app_id'),
              app_info: Config.get('app_info'),
              app_version: Config.get('version'),
              user_lang: getLanguageKey(localStore.get('lang') || 'tha', 'iso-2'),
              language: localStore.get('lang'),
              user_id: Auth.get('user.id'),
              temp_user_id: Auth.get('extraData.temp_id'),
              classification: (amsClient.get('user.classes') || []).join(',') || 'non-ais',
              device_id: Auth.get('authInfo.device_id'),
              device_key: Config.get('device_key'),
              buhDeviceId: this.$stats.buhDeviceIdPart,
              customer: process.env.VUE_APP_CUSTOMER,
              sid: Auth.get('user.sid'),
              restrictedDevices: Auth.getDeviceRestriction(),
              preferred_audio_lang: localStore.get('preferred_audio_lang') || this.$i18n.locale,
              server: secondLocation.isEnabled() ? secondLocation.getBackDomain() : thirdLocation.isEnabled() ? thirdLocation.get('activeDomain') : location.host,
              metadata: Config.get('player.metadata'),
            },
            thirdLocation: {
              activeDomain: thirdLocation.get('activeDomain'),
              domains: thirdLocation.get('domains'),
              nRetries: thirdLocation.get('nRetries'),
              nNonPriorityRetries: thirdLocation.get('nNonPriorityRetries'),
              nDistroRetries: thirdLocation.get('nDistroRetries'),
              confRetryDelay: thirdLocation.get('confRetryDelay'),
              confAttempts: thirdLocation.get('confAttempts'),
              maxReq: thirdLocation.get('maxReq'),
            },

            /// player params
            player_conf: {
              vimmi_copyright: false,
              // quality_selector: { 'reverse': true },
            },
            analytics: analytics,
          },
        });
      },
      handlePMEvents() {
        let pId = '_P';

        PM.inst().off(PLAYER_EVENTS.READY);
        PM.inst().on(PLAYER_EVENTS.READY, (event) => {
          Config.loaded(() => {
            // if we use cm that player will be ready only after cm init
            this.updatePlayerConf();
            // if (this.$cm && this.$cm.initializedConfig) {
            //   this.playerCM('setUser');
            // }
            /** todo: for ais */
            this.playerStatus = 1;
            /** end */
            this.$bus.$emit(this.eventIdentifier + '_playerReady', {});
          });
        });

        // loadedMetadata Player Start
        PM.inst().off(PLAYER_EVENTS.LOADEDMETADATA);
        PM.inst().on(PLAYER_EVENTS.LOADEDMETADATA, event => {
          if (!this.isRestricted) {
            this.$logger.info('MPV', 'loadedMetadata', event);
            this.$bus.$emit(this.eventIdentifier + '_loadedMetadata', {});
            this.skipNextAfterError();
          }
          this.playerStatus = 1;
        });

        // // loadedMetadata Player Start
        // PM.inst().off(PLAYER_EVENTS.AREA_CLICK);
        // PM.inst().on('buttonClick' + pId, event => {
        //   this.$logger.info('MPV', 'buttonClick', event);
        //   if (event && event.button) {
        //     // if (event.button === 'subscribe') {
        //     //   this.$stats.send('try_play_premium', {
        //     //     extra_data: {
        //     //       itype: this.currentItem.itype,
        //     //       item_id: this.currentItem.id,
        //     //       product_offer_result: 'purchase',
        //     //     },
        //     //   });
        //     //   subscribeService.subscribeVDO(this.currentItem.id);
        //     // }
        //   }
        // });

        // // restrictedFromServer event
        // PM.inst().off('restricted' + pId);
        // PM.inst().on('restricted' + pId, event => {
        //   this.$logger.info('MPV', 'restrictedFromServer', event);
        //   this.showRestrictionMessage();
        //   this.$bus.$emit(this.eventIdentifier + '_isVideoRestricted');
        //   this.showNextVideoAfterError();
        //   this.playerStatus = 1;
        // });
        // restrictedFromServer event
        PM.inst().off(PLAYER_METHODS.FORCE_LOGIN);
        PM.inst().on(PLAYER_METHODS.FORCE_LOGIN, event => {
          this.$logger.info('MPV', 'force_login', event);
          this.$bus.$emit('toggleAuthPopup', {
            force: true,
            login: true,
            center: false,
            trigger: false,
            formId: 'fromPlayer',
          });
          //          this.showRestrictionMessage();
          //          this.$bus.$emit(this.eventIdentifier + '_isVideoRestricted');
          //          this.showNextVideoAfterError();
          //          this.playerStatus = 1;
        });

        // // Autoplay video
        // PM.inst().off('autoplay' + pId);
        // PM.inst().on('autoplay' + pId, event => {
        //   this.$logger.info('MPV', 'start play', event);
        //   this.sendProductOfferPlayedOnEvent();
        //   this.setWatchedVideo();
        //   this.skipNextAfterError();
        //   this.playerStatus = 1;
        //   this.isVideoPlaying = true;
        // });
        // Play
        PM.inst().off(PLAYER_EVENTS.USER_PLAY);
        PM.inst().on(PLAYER_EVENTS.USER_PLAY, event => {
          this.isVideoPlaying = true;
          this.$logger.info('MPV', 'play', event);
          this.sendProductOfferPlayedOnEvent();
          this.$bus.$emit(this.eventIdentifier + '_playerPlay', event);
          this.skipNextAfterError();
          this.setWatchedVideo();
          this.playerStatus = 1;
        });

        // Resume
        PM.inst().off(PLAYER_EVENTS.USER_RESUME);
        PM.inst().on(PLAYER_EVENTS.USER_RESUME, event => {
          this.isVideoPlaying = true;
          this.$logger.info('MPV', 'resume', event);
          this.$bus.$emit(this.eventIdentifier + '_playerResume', event);
          this.skipNextAfterError();
          this.setWatchedVideo();
          this.playerStatus = 1;
          this.duration = event.duration;
          this.position = event.position;
        });
        // Resume
        PM.inst().off(PLAYER_EVENTS.TIME_UPDATE);
        PM.inst().on(PLAYER_EVENTS.TIME_UPDATE, event => {
          // this.$logger.info('MPV', 'timeupdate', event);
          this.duration = event.duration;
          this.position = event.position;
          this.stream_time = event.stream_time;
        });

        // Preview finished
        // PM.inst().off(PLAYER_EVENTS.PREVIEW_FINISHED);
        // PM.inst().on(PLAYER_EVENTS.PREVIEW_FINISHED, event => {
        //   this.setDailyPreview();
        //
        //   if (event.triggerMessage !== undefined && !event.triggerMessage) {
        //     return;
        //   }
        //   if (!this.isFullAuth) {
        //     this.$bus.$emit('toggleAuthPopup', {
        //       component: 'login',
        //       force: true,
        //       formId: 'fromPlayer',
        //     });
        //   } else {
        //     // TODO need some logic
        //   }
        // });

        // Preview finished (when the user stops to watching the preview on some position)
        // PM.inst().off(PLAYER_METHODS.STOP_PREVIEW);
        // PM.inst().on(PLAYER_METHODS.STOP_PREVIEW, (event) => {
        //   this.$logger.info('MPV', 'previewStop', event);
        //   // this.previewStop = true;
        //   /** todo: need to send only for quest users or non_free items*/
        //   this.setDailyPreview();
        //
        //   // if (!this.isAuth) {
        //   //   this.$bus.$emit('toggleAuthPopup', {
        //   //     login: true,
        //   //     center: true,
        //   //     trigger: false,
        //   //     formId: 'fromPlayer'
        //   //   });
        //   // } else {
        //   //   this.$bus.$emit('showCouponPurchase', {
        //   //     itemId: this.currentItem.id
        //   //   });
        //   // }
        // });

        // Pause
        PM.inst().off(PLAYER_EVENTS.USER_PAUSE);
        PM.inst().on(PLAYER_EVENTS.USER_PAUSE, event => {
          this.isVideoPlaying = false;
          this.$logger.info('MPV', 'pause', event);
          this.$bus.$emit(this.eventIdentifier + '_playerPause', event);
          this.skipNextAfterError();
          this.duration = event.duration;
          this.position = event.position;
          //          this.pausePlayerAnalytiks(event);
        });
        // Playing
        PM.inst().off(PLAYER_EVENTS.PLAYING);
        PM.inst().on(PLAYER_EVENTS.PLAYING, event => {
          this.isVideoPlaying = true;
          this.$logger.info('MPV', 'playing', event);
          this.$bus.$emit(this.eventIdentifier + '_playerPlaying', event);
          this.playerStatus = 1;
          this.duration = event.duration;
          this.position = event.position;
        });

        PM.inst().off(PLAYER_EVENTS.AD_EVENTS);
        PM.inst().on(PLAYER_EVENTS.AD_EVENTS, event => {
          this.$logger.info('MPV', 'adEvents', event);

          this.playerStatus = 1;
          switch (event.name) {
            case 'playing':
              this.skipNextAfterError();
              this.$bus.$emit(this.eventIdentifier + '_playerPlay', event);
              this.$bus.$emit(this.eventIdentifier + '_togglePlayerLayout', {
                value: true,
                arrows: true,
              });
              this.isAdsPlaying = true;
              break;
            case 'ended':
              this.isAdsPlaying = false;
              break;
          }
        });

        // Play Next
        PM.inst().off(PLAYER_EVENTS.PLAYING_NEXT);
        PM.inst().on(PLAYER_EVENTS.PLAYING_NEXT, options => {
          this.$logger.info('MPV', 'playingNext', options);
          this.isRestricted = options.restricted;
          this.currentOptions = options;
          // this.iid = this.getMediaId(asset.extraData.iid, asset.media_id);

          //          this.prevOpt = options;
          this.skipNextAfterError();
          if (this.isRestricted) {
            this.showRestrictionMessage();
          }

          if (!this.currentOptions?.preview) {
            this.setWatchedVideo();
            this.setResumeWatch();
          }

          this.$bus.$emit(this.eventIdentifier + '_playerNextPlaying', options);
          this.currentItem = options.item;
        });

        // Play Prev
        PM.inst().off(PLAYER_EVENTS.PLAYING_PREV);
        PM.inst().on(PLAYER_EVENTS.PLAYING_PREV, options => {
          //          this.startPlayerAnalytics(event);
          this.currentOptions = options;
          this.isRestricted = options.restricted;
          // this.iid = this.getMediaId(options.iid, options.id);

          //          this.prevOpt = options;
          this.skipNextAfterError();
          if (this.isRestricted) {
            this.showRestrictionMessage();
          }
          this.setWatchedVideo();
          this.$logger.info('MPV', 'playingPrev', options);
          this.$bus.$emit(this.eventIdentifier + '_playerPrevPlaying', options);
          this.currentItem = options.item;
        });

        // EndStop
        PM.inst().off(PLAYER_EVENTS.ENDED);
        PM.inst().on(PLAYER_EVENTS.ENDED, event => {
          this.$logger.info('MPV', 'ended', event);
          //          this.endPlayAnalytiks(event);
          this.$bus.$emit(this.eventIdentifier + '_playerEnded', {});
        });

        // Error
        PM.inst().off(PLAYER_EVENTS.ERROR);
        PM.inst().on(PLAYER_EVENTS.ERROR, event => {
          this.$logger.info('MPV', 'error', event);
          this.showNextVideoAfterError();
          this.$bus.$emit(this.eventIdentifier + '_playerError', {});
          this.errorPlayerAnalytics(event);
          this.playerStatus = 1;
        });

        PM.inst().off(PLAYER_EVENTS.AREA_CLICK);
        PM.inst().on(PLAYER_EVENTS.AREA_CLICK, event => {
          this.$logger.info('MPV', 'area_click', event);

          this.duration = event.duration;
          this.position = event.position;
          this.hideRestrictionMessage();
        });
        PM.inst().on(PLAYER_METHODS.CONFIGURATION, event => {
          console.info('MPV', 'CONFIGURATION', event);
          if (event?.thirdLocation) {
            thirdLocation.set(event.thirdLocation);
            if (event.thirdLocation.triggerRetry) {
              this.$bus.$emit('openRetryPopup', {
                retryFromPlayer: true,
              });
            }
          }
        });
      },

      isVideoRestricted(item) {
        return new Promise(resolve => {
          let age =
            item && item.age_restriction
              ? item.age_restriction !== 'AP' && item.age_restriction !== 'None'
                ? parseInt(item.age_restriction)
                : item.age_restriction !== 'None'
                  ? 0
                  : 0
              : 0;
          if (!this.isAuth || (this.isGuest && item.noguest) || (this.isGuest && item.drm)) {
            resolve('RestrictedForUnknow');
          } else if ((amsClient.get('user.in_sync') === false) && !(amsClient.get('user.classes')[0] === 'ais'
            && amsClient.isContentFreemium(item)) && !this.isGuest && (item.not_free || amsClient.isContentFreemium(item))) {
            resolve('RestrictedForNotFreeInSyncMode');
          } else if (amsClient.isContentPremium(item) && !amsClient.canPlayPremium(item)) {
            resolve(this.isGuest ? 'RestrictedForUnknow' : 'RestrictedForNotFree');
          } else if (amsClient.isContentFreemium(item) && !amsClient.canPlayFreemium(item)) {
            // resolve('RestrictedForFreemium');
            resolve(this.isGuest ? 'RestrictedForUnknow' : 'RestrictedForNotFree');
          } else {

            this.isShowRestrictedMessage = false;
            resolve(null);
          }
        });
      },
      // NOTE: events functions
      event_enableControls() {
        this.send(PLAYER_METHODS.CONTROLS_STATE, {
          data: {},
          name: 'enableControls',
        });
      },
      event_actionOnButton(options = {}) {
        if (options.name) {
          this.send(PLAYER_METHODS.CONTROLS_STATE, {
            data: options.data || {},
            name: options.name,
          });
        }
      },
      event_disableControls() {
        this.send(PLAYER_METHODS.CONTROLS_STATE, {
          data: {},
          name: 'disableControls',
        });
      },
      event_pauseVideo() {
        this.send(PLAYER_METHODS.PAUSE);
      },
      event_playVideo() {
        this.event_enableControls();
        this.send(PLAYER_METHODS.PLAY);
      },
      event_setComponentPoster(options) {
        this.tempPoster = normalizeImg(
          options.item.backdrop || options.item.poster,
          'image',
        );
      },
      event_loadPrevNextVideo(opt) {
        let item = opt.item;
        let isNext = opt.isNext;
        let options = {
          playerOptions: {},
          extraData: {},
        };

        options.playerOptions.bigButton = opt.bigButton === true;
        options.playerOptions.mainPage = opt.mainPage === true;
        options.playerOptions.autoplay = opt.autoplay === true;

        options.playerOptions.loadMode = opt.loadMode || 'default';
        item.click_to_media_start = Date.now();

        this.loadItemPlayOptions(item)
          .then(newOpt => {
            options = Object.assign(options, newOpt);
            this.send(PLAYER_METHODS.CONTROLS_STATE, {
              data: options,
              name: isNext ? 'loadNext' : 'loadPrev',
            });
          });
      },

      // Play Video Now
      event_playNowVideo(opt) {
        if (!opt.item) {
          return;
        }
        this.clickOnMedia = opt.clickTime || Date.now();
        this.prevOpt = opt;
        this.$logger.log('MPV', 'playNowVideo', opt);
        let item = opt.item;
        if (this.currentItem && this.position && this.duration && !this.currentOptions?.preview) {
          this.setResumeWatch();
        }
        this.currentItem = item;
        this.stream_time = 0;
        this.previewStop = false;

        this.tempPoster = normalizeImg(item.backdrop || item.poster, 'image');
        // this.iid = this.getMediaId(item.iid, item.id);
        this.event_PlayNowPart2(opt);
      },
      event_PlayNowPart2(opt) {
        if (!opt.item) {
          return;
        }
        let item = opt.item;
        let options = {
          playerOptions: {},
          extraData: {},
        };
        if (this.previewStop) {
          this.previewStop = false;
        }

        // options.isLive = this.isLive = ['item_live_virtual', 'item_live'].indexOf(item.itype) !== -1;
        //        options.itype = item.itype;
        // options.poster = normalizeImg(item.backdrop || item.poster, 'image');

        this.loadItemPlayOptions(item)
          .then(newOpt => {
            options = Object.assign(options, newOpt);
            options.playerOptions.autoplay = opt.autoplay === true;
            options.playerOptions.bigButton = opt.bigButton === true;
            options.playerOptions.mainPage = opt.mainPage === true;

            this.$logger.info('loaded video playOptions', options);
            this.currentOptions = options;
            this.send(PLAYER_METHODS.PLAY_NEW_VIDEO, options);
            this.isRestricted = options ? options.restricted : null;
            if (options && options.restricted) {
              let restricted = options.restricted;
              let dataCm = {
                media_id: options.iid,
                content_title: this.truncateSymbolsToNumber(item.title),
                itype: item.itype,
              };
              if (restricted === 'RestrictedForAge') {
                if (this.$cm) {
                  this.$cm.trackAppEvent(
                    this.$cm.APP_EVENTS.PLAY_ERROR,
                    Object.assign(dataCm, {
                      error_name: 'This video is age restricted',
                    }),
                  );
                }
              } else if (
                restricted === 'RestrictedForLive' ||
                restricted === 'RestrictedForUnknow'
              ) {
                if (this.$cm) {
                  this.$cm.trackAppEvent(
                    this.$cm.APP_EVENTS.PLAY_ERROR,
                    Object.assign(dataCm, {
                      error_name: 'This video is restricted for not autorised user',
                    }),
                  );
                }

              } else if (restricted === 'RestrictedForDevice') {
                this.$cm.trackAppEvent(
                  this.$cm.APP_EVENTS.PLAY_ERROR,
                  Object.assign(dataCm, {
                    error_name: 'This video is restricted for device',
                  }),
                );
              }
              this.showRestrictionMessage();
              this.$bus.$emit(this.eventIdentifier + '_isVideoRestricted', item);
              this.showNextVideoAfterError();
            } else {
              this.skipNextAfterError();
            }
          });
      },


      async loadItemPlayOptions(item) {
        console.log('[loadItemPlayOptions], item', item);
        if (!item || !item.id) {
          if (item.media) {
            item.id = item.media.split('/')[2];
          } else {
            console.log(`can't find media`);
            return null;
          }
        }

        if (!item.media) {
          console.warn(`can't find media field`, item);
          item.media = `/play/${item.id}/`;
        }

        let options = {
          playerOptions: {},
        };

        options.isLive = ['item_live_virtual', 'item_live'].indexOf(item.itype) !== -1;
        options.media_id = item.id;
        options.alphaId = item.mid;
        options.itype = item.itype;
        options.drm = !!item.drm || item.mplayer === 'hooq';
        options.item = item;

        if (!!item.drm) {
          options.drm_types = item.drm_types;
        } else if (item.mplayer === 'hooq') {
          options.drm_types = ['HLS/FAIRPLAY', 'DASH/WIDEVINE'];
        }

        let screen = this.$stats.get('current.screen') || {};
        options.extraData = {
          media_aspect_ratio: item.media_aspect_ratio,
          poster: normalizeImg(item.backdrop || item.poster, 'image'),
          title: item.title,
          iid: this.getMediaId(item.iid, item.id),
          // item_id: item.id,
          // itype: item.itype,
          screen_id: screen.id || null,
          screen_type: this.$stats.utils.normaliseScreentype(screen.link),
          click_to_media_start: item.click_to_media_start || this.clickOnMedia,
          context: Config.get('temp.context') || 'user_click', //todo
          subscription_status: amsClient.getSubscriptionStatus(item),
        };

        options.restricted = await this.isVideoRestricted(item);
        // options.preview_info = item.preview_info
        //   ? {...item.preview_info}
        //   : null;
        // options.preview = !!options.preview_info && ((this.isGuest && !item.not_free) || (item.not_free && !!options.restricted));
        // if (options.preview) {
        //   options.playerOptions.autoplay = false;
        // }

        /** todo: handle case when next or prev episode and live */
        // let maxSeconds = Auth.get('user.preview_info.daily_seconds');
        // if (!!options.preview && maxSeconds) {
        //   let usrPreviewInf = await amsClient.stock.getDailyPreviewTime(
        //     item.id,
        //     true,
        //   );
        //   console.log('[usrPreviewInf]', {usrPreviewInf, maxSeconds});
        //   let fullLeftSeconds = maxSeconds - usrPreviewInf.full;
        //   /** item mode */
        //
        //   if (
        //     fullLeftSeconds <
        //     options.preview_info.preview_end - options.preview_info.preview_start
        //   ) {
        //     options.preview_info.preview_end =
        //       fullLeftSeconds + options.preview_info.preview_start;
        //   }
        //   if (usrPreviewInf.item) {
        //     if (
        //       usrPreviewInf.item <
        //       options.preview_info.preview_end -
        //       options.preview_info.preview_start
        //     ) {
        //       options.preview_info.preview_end =
        //         options.preview_info.preview_end - usrPreviewInf.item;
        //       options.preview_info.consumed_time = usrPreviewInf.item;
        //     } else {
        //       options.preview_info.preview_end =
        //         options.preview_info.preview_start;
        //     }
        //   }
        //   /** daily mode */
        // } else

        if (this.isActiveFeature('resume-watch') && this.isAuth && !options.restricted && (item.itype === 'item_mov_vod' || item.itype === 'item_mov_episode')) {
          let positionData = null;
          try {

            if (item.itype === 'item_mov_episode') {
              positionData = await amsClient.stock.getResumeWatch(item.id, item.series_pk);
            } else {
              positionData = await amsClient.stock.getResumeWatch(item.id);
            }
          } catch (e) {
            console.log(e);
          }


          console.log('[positionData]', { positionData });

          if (positionData && positionData.time) {
            options.playerOptions.startPosition = +positionData.time;
          }
        }

        if (item.not_free && !options.restricted && this.$cm) {
          this.$cm.trackAppEvent(this.$cm.APP_EVENTS.TRY_PLAY_PREMIUM, {
            media_id: item.iid,
            itype: item.itype,
            result: 'ok',
          });
        } else if (this.isAuth && item.not_free && options.restricted && this.$cm) {
          this.$cm.trackAppEvent(this.$cm.APP_EVENTS.TRY_PLAY_PREMIUM, {
            media_id: item.iid,
            itype: item.itype,
            result: 'not_allowed',
          });
        }
        if ((item.not_free && !options.restricted) || (this.isAuth && item.not_free && options.restricted)) {
          this.$stats.send('try_play_premium', {
            itype: item.itype,
            item_id: item.id,
            product_offer_result: (item.not_free && !options.restricted) ? 'ok' : 'not_allowed',
          });
        }

        return options;
      },

      send(type, data) {
        data = data || {};
        PM.inst().send({
          target: this.getCurrentFrame(this.eventIdentifier + '_video_player'),
          type: type,
          data: data,
        });
      },
      showNextVideoAfterError() {
        this.skipNextAfterError();
        this.idTimeoutNewVideoAtrerError = setTimeout(() => {
          this.skipNextAfterError();
          this.$bus.$emit(this.eventIdentifier + '_showNextAfterError', {
            isAlreadyPlaying: false,
          });
        }, this.TIMEOUT_FOR_NEW_VIDEO_AFTER_ERROR);
      },
      skipNextAfterError() {
        clearTimeout(this.idTimeoutNewVideoAtrerError);
      },

      scrollToView() {
        this.$el.scrollIntoView();
      },
      calculateHeight() {
        this.calcHeight = (this.$el.clientWidth * 9) / 16 + 'px';
      },

      getMediaId(base1, base2) {
        return base1 || parseInt(base2.substr(0, 8), 16);
      },
      getCurrentFrame(nameOrId) {
        let el = document.getElementById(nameOrId);
        if (!el) {
          el = document.querySelector('[name="' + nameOrId + '"]');
        }
        return el || null;
      },
      setRestrictionState(options = {}) {
        this.send(PLAYER_METHODS.SET_RESTRICTION, {
          restricted: options.restricted,
        });
      },
      setWatchedVideo() {

        if (
          !this.isRestricted &&
          this.isActiveFeature('history-list') &&
          this.isFullAuth &&
          this.prevId !== this.currentItem.id
        ) {
          this.prevId = this.currentItem.id;
          amsClient.stock.setRecent(this.currentItem.id);
        }
      },

      setResumeWatch() {
        if (this.currentItem.itype === 'item_mov_vod' || this.currentItem.itype === 'item_mov_episode') {
          if (this.isActiveFeature('resume-watch') && this.isFullAuth) {
            let position = this.position;
            if (this.duration && this.position > 5) {
              if ((this.position + 300) > this.duration) {
                position = 0;
              }
              try {
                if (this.currentItem.itype === 'item_mov_vod') {
                  amsClient.stock.setMovieResumeWatch(this.currentItem.id, parseInt(position));
                } else if (this.currentItem.itype === 'item_mov_episode') {
                  amsClient.stock.setEpisodeResumeWatch(this.currentItem.series_pk, this.currentItem.season_pk, this.currentItem.id, parseInt(position));
                }
              } catch (e) {
                console.log(e);
              }
            }
          }
        }
      },

      errorPlayerAnalytics(event) {
        if (this.$cm) {
          this.$cm.trackAppEvent(this.$cm.APP_EVENTS.PLAY_ERROR, {
            content_title: this.truncateSymbolsToNumber(this.currentItem.title, 250),
            itype: this.currentItem.itype,
            media_id: event.options.iid,
            error_name: this.truncateSymbolsToNumber(event.error.message, 250),
          });
        }
      },
      hideRestrictionMessage() {
        if (this.isShowRestrictedMessage) {
          this.isShowRestrictedMessage = false;
        }
        this.$bus.$emit('toggleAuthPopup', {
          login: true,
          center: true,
          trigger: false,
          formId: 'fromPlayer',
        });
      },
      showRestrictionMessage() {
        this.$nextTick(() => {
          this.isShowRestrictedMessage = true;
          if (this.isRestricted === 'RestrictedForLive') {
            this.$bus.$emit('toggleAuthPopup', {
              login: true,
              trigger: true,
              center: true,
              message: this.$i18n.t('VIDEO.CONTENT_IS_RESTRICTED'),
              formId: 'fromPlayer',
            });
          } else if (
            /*this.isRestricted === 'RestrictedForUnknow' || */
            this.isRestricted === 'RestrictedForNotFree'
          ) {
            // this.$bus.$emit('toggleAuthPopup', {
            //   login: true,
            //   trigger: true,
            //   center: true,
            //   message: this.$i18n.t('VIDEO.CONTENT_IS_RESTRICTED'),
            //   formId: 'fromPlayer',
            // });
          }
        });
      },

      sendProductOfferPlayedOnEvent() {
        if (this.currentItem && this.$stats) {
          let itemSubscription = amsClient.getSubscriptionStatus(this.currentItem);
          if (itemSubscription !== 'free') {

            // let { products, offers } = itemSubscription;
            let { products, offers } = amsClient.getActiveProductsOffers(this.currentItem);
            let extraData = {
              itype: this.currentItem.itype,
              item_id: this.currentItem.id,
            };
            products.forEach(product => {
              this.$stats.send('product_offer_played_on', Object.assign({
                product_id: product,
                offer_id: null,
              }, extraData));
            });
            offers.forEach(offer => {
              this.$stats.send('product_offer_played_on', Object.assign({
                product_id: null,
                offer_id: offer,
              }, extraData));
            });
          }
        }
      }
    },
  };
</script>

<style lang="scss">
  @import './_video';
</style>
