

export default (function () {
  return {
    removeScript(idOrLink){
      let tag = document.getElementById(idOrLink) || document.querySelector(`[src=${idOrLink}`);
      if(tag) {
        tag.parentNode.removeChild(tag);
      }
    },
    addScript: function (id, link) {
      return (function (d, s, id) {
        let js, fjs = d.getElementsByTagName(s)[0];
  
        if (d.getElementById(id)) return
        js = d.createElement(s)
        js.id = id
        js.src = link
        fjs.parentNode.insertBefore(js, fjs)
        return js;
      }(document, 'script', id))
    },
  }
})();
