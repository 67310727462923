<template>
  <div
    class="ugc-virtual-scroll"
  >
    <slot/>
  </div>
</template>
<script>
  import { debounce } from 'lodash';
  export default {
    name: 'UgcVirtualScroll',
    props: {
      pageOffset: {
        type: Number,
        default: 50,
      },
      delayMs: {
        type: Number,
        default: 300,
      },
      isLoaded: {
        type: Boolean,
        default: true,
      },
      isElement: {
        type: null,
        default: null,
      },
    },
    data() {
      return {};
    },

    computed: {
      scrollDebounce() {
        return debounce(this.scroll, this.delayMs, {leading: true});
      },
      element() {
        // this.logger.log(`Search element ${this.isElement}`);
        let el = document.getElementById(this.isElement);

        return this.isElement && el ? el : null;
      },
    },

    mounted() {
      if (this.element) {
        // this.logger.log(`Add scroll event to element`);
        // this.logger.dir(this.element);
        this.element.addEventListener('scroll', this.scrollDebounce);
      } else {
        // this.logger.log(`Add scroll event to window`);
        window.addEventListener('scroll', this.scrollDebounce);
      }
    },
    beforeDestroy() {
      if (this.element) {
        // this.logger.log(`Remove scroll event from element`);
        this.element.removeEventListener('scroll', this.scrollDebounce);
      } else {
        // this.logger.log(`Remove scroll event from window`);
        window.removeEventListener('scroll', this.scrollDebounce);
      }
    },
    methods: {
      scroll(evt) {
        if (!this.isLoaded || !this.isInViewport()) {
          // this.logger.log(`Reject scroll emit`);
          return;
        }
        // this.logger.log(`Accept scroll emit`);
        this.eventBottom();
      },

      isInViewport() {
        // current scroll position
        let position = this.element
          ? this.element.scrollHeight - this.element.scrollTop
          : document.documentElement.getBoundingClientRect().bottom;
        // height of viewport
        let fullHeight = this.element
          ? this.element.clientHeight
          : document.documentElement.clientHeight;

        // this.logger.log(`Position: ${position}, Full height: ${fullHeight}`);

        return position <= fullHeight + fullHeight * this.pageOffset;
      },

      eventBottom() {
        // this.logger.log(`Emit bootom event`);
        this.$emit('bottom');
      },
    },
  };
</script>

<style lang="scss">
.ugc-virtual-scroll {
  min-height: calc(100vh + 100px);
  // overflow: hidden;
  // overflow-y: auto;
  // height: calc(100%);
  // margin: 0;
}
</style>

