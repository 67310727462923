<template>
  <!--v-click-outside="close"-->
  <div
    v-if="isActive"
    class="auth-block animated fadeIn p-4"
    @click.stop
  >
    <h3 class="auth-title text-center">
      <span v-t="{ path: 'LOGIN.LOGIN_TITLE' }" />
      <!-- <button
        type="button"
        class="auth-title-close"
        aria-label="Close"
        @click="close()"
      >
        <span
          aria-hidden="true"
          class="fas fa-times"
        />
      </button>-->
    </h3>
    <h5
      v-for="(mess, index) in messages"
      :key="index"
      class="auth-message text-center mt-4 mb-4"
      :class="'message-' + mess.status"
    >
      {{ mess.text }}
    </h5>

    <form
      class="container-fluid auth-form need-validated mt-4"
      autocomplete="on"
      @submit.prevent.stop="login()"
    >
      <template v-if="isDevMode">
        <div class="row w-100 form-group auth-form-group auth-form-email">
          <label for="email">
            <span v-t="{ path: 'LOGIN.LOGIN' }" />
          </label>
          <input
            id="email"
            v-model="accessData.login"
            :disabled="waiting"
            type="text"
            name="login"
            autocomplete="username"
            class="form-control"
            :class="{ 'is-invalid': validator.login }"
            :placeholder="$t('LOGIN.LOGIN')"
            required="required"
          />
          <span v-if="validator.login" class="invalid-feedback">{{
            validator.login[0]
          }}</span>
        </div>

        <div class="row w-100 form-group auth-form-group auth-form-pass">
          <label for="password">
            <span v-t="{ path: 'LOGIN.PASSWORD' }" />
          </label>
          <input
            id="password"
            v-model="accessData.password"
            :disabled="waiting"
            type="password"
            name="password"
            autocomplete="current-password"
            class="form-control"
            :class="{ 'is-invalid': validator.password }"
            :placeholder="$t('LOGIN.PASSWORD')"
            required="required"
            minlength="6"
          />
          <span v-if="validator.password" class="invalid-feedback">{{
            validator.password[0]
          }}</span>
          <!-- <small class="form-text text-muted">
           Your password must be 8-20 characters long, contain letters and numbers, and must not contain spaces, special characters, or emoji.
          </small>-->
        </div>
        <div
          class="row w-100 form-group custom-control custom-checkbox auth-form-remember"
        >
          <input
            id="rememberMe"
            v-model="accessData.remember"
            :disabled="waiting"
            type="checkbox"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="rememberMe">
            <span v-t="{ path: 'LOGIN.REMEMBER_ME' }" />
          </label>
        </div>
        <div
          class="row w-100 form-group auth-form-group justify-content-center"
        >
          <button
            :disabled="waiting"
            type="submit"
            class="btn btn-sm auth-login rounded"
          >
            <span
              class="d-inline-block text-truncate align-middle w-100 text-uppercase"
            >
              <span v-t="{ path: 'LOGIN.SING_IN' }" />
            </span>
          </button>
        </div>
      </template>

      <!--<div class="row">
        <div class="auth-separator">
          <span class="auth-separator-or">
            <span v-t="{path: 'LOGIN.OR'}" />
          </span>
        </div>
      </div>
      <div class="row auth-social rounded">
        <a
          href="javascript:;"
          class="auth-social-facebook"
          @click.stop="facebook()"
        >
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAcCAYAAABoMT8aAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpDMzIzNDVDRUIyQjMxMUU3OEJDRUM2QjQ3MzIwOUFBRiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpDMzIzNDVDRkIyQjMxMUU3OEJDRUM2QjQ3MzIwOUFBRiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkMzMjM0NUNDQjJCMzExRTc4QkNFQzZCNDczMjA5QUFGIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkMzMjM0NUNEQjJCMzExRTc4QkNFQzZCNDczMjA5QUFGIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+TK3o3gAAAL9JREFUeNpi/P//PwMewAPEaUDsBcQ6QCwCxMxI8k8ZQAbgwHZA/Ow/AYBLsz4Qf/1PBMBlwIH/RAJGLGGgAcTXGYgELFjE7PCoXw/Eh4H4K5T/HpvzG3C49jI27zJhsYUdh+2PsAkyMRAP/lNqAFYAioXLQFoaSYwTiDmwqP0NxF/QxPYy/ieQlgmAy8PEgN1AhiCSIChAJbAo/gjEd9DETmJLie04UuIWYlMiSWDUgFEDaGbAaxxqX2ATBAgwACc4Slue8nCJAAAAAElFTkSuQmCC"
          >
          <span v-t="{path: 'LOGIN.SIGN_UP_WITH_FACEBOOK'}" />
        </a>
      </div>-->
    </form>
    <div class="d-flex justify-content-between mt-4">
      <a
        href="javascript:;"
        class="auth-fungus btn rounded"
        :disabled="waiting"
        @click.stop="fungusLogin()"
      >
        <span
          class="d-inline-block text-truncate align-middle w-100 text-uppercase"
        >
          <span v-t="{ path: isDevMode ? 'LOGIN.FUNGUS' : 'LOGIN.SING_IN' }" />
        </span>
      </a>
      <button
        type="button"
        class="btn auth-cancel rounded"
        :disabled="waiting"
        @click="close"
      >
        <span
          class="d-inline-block text-truncate align-middle w-100 text-uppercase"
        >
          <span v-t="{ path: 'LOGIN.CANCEL' }" />
        </span>
      </button>
      <!-- <button
          type="button"
          class="col-6 btn auth-register rounded"
          @click.stop="showRegister()"
        >
          <span
            class="d-inline-block text-truncate align-middle w-100"
          >
            <span v-t="{path: 'LOGIN.REGISTER'}" />
          </span>
      </button>-->
    </div>
  </div>
</template>

<script>
  import Auth from 'src/service/authService';
  import Config from 'src/service/config';
  import vClickOutside from 'v-click-outside';
  import { vapp } from '@/main';
  import { localStore } from 'vimmi-web-utils/esm/localStore';
  import { amsClient } from '../../../service/ams';


  export default {
    name: 'LoginBlock',
    directives: {
      clickOutside: vClickOutside.directive,
    },

    props: {
      isActive: {
        type: [Boolean, String],
        default: false,
      },
      defaultMessages: {
        type: String,
      },
      isShowRegister: {
        type: Boolean,
      },

      isShowForgot: {
        type: Boolean,
      },
      isForce: {
        type: Boolean,
      },
    },
    data() {
      return {
        isDevMode: false,
        timeToShowMessage: 4 * 1000,
        accessData: {
          login: '',
          password: '',
          remember: false,
          //          rule: false,
          //          subscribe: false,
        },
        fix18n: {},
        messages: [],
        timeOutID: 0,
        validator: {},
        waiting: false,
      };
    },
    watch: {
      defaultMessages: function (newVal) {
        if (this.defaultMessages) {
          this.messages.splice(0, 1, {
            status: 'info',
            text: this.defaultMessages,
          });
        } else {
          this.messages = [];
        }
      },
      isForce: function (newVal) {
        if (newVal) {
          this.fungusLogin();
        }
      },
    },
    mounted() {
      Config.loaded(() => {
        if (Config.get('mode') === 'dev') {
          this.isDevMode = true;
        } else {
          this.isDevMode = false;
        }
      });

      this.validator = {};
      this.fix18n = this.$i18n;
    },
    methods: {
      translateVal(val) {
        return this.fix18n.t(val);
      },
      close() {
         let isConsent = !Auth.isGuest() ? amsClient.get('user.consent') === false : !localStore.get(`consent_${Auth.get('authInfo.device_id')}`);
        if(isConsent) {
          vapp.$bus.$emit('toggleAuthPopup', {
            login_type: true,
            trigger: true,
          });
        }
        if (this.waiting) {
          return;
        }
        if (!this.isShowRegister && !this.isShowForgot && this.isActive) {
          this.$emit('update:isActive', false);
          this.$body.closeModal();
        }
      },
      //      facebook() {
      //        Auth.authFacebook()
      //          .then(() => {
      //            this.validator = {};
      //            this.showMessage(
      //              {
      //                status: 'success',
      //                text: this.translateVal('LOGIN.SUCCESSFULLY_AUTHORIZED'),
      //              },
      //              3000
      //            );
      //            setTimeout(() => {
      //              this.close();
      //              // this.$router.push(this.$router.currentRoute.fullPath);
      //            }, 3000);
      //          })
      //          .catch(error => {
      //            this.showMessage(
      //              {
      //                status: 'error',
      //                text: error.response
      //                  ? error.response.data.message
      //                  : error.message || error,
      //              },
      //              3000
      //            );
      //          });
      //      },
      //      showRegister() {
      //        this.$emit('update:isShowRegister', true);
      //      },
      //      showForgot() {
      //        this.$emit('update:isShowForgot', true);
      //      },
      fungusLogin() {
        if (this.waiting) {
          return;
        }
        this.showMessage({
          status: 'success',
          text: this.translateVal('LOGIN.WAIT'),
        });
        Auth.openFungusLoginPage();
        // Auth.login('fungus')
        //   .then(res => {
        //     this.showMessage({
        //       status: res.status ? 'success' : 'warning',
        //       text: res.message,
        //     });
        //   });
      },
      login: function () {
        if (!this.accessData.login) {
          this.showMessage({
            status: 'warning',
            text: this.translateVal('REGISTER_FORM.CORRECT_LOGIN'),
          });
          return;
        } else if (
          !this.accessData.password ||
          this.accessData.password.length < 6
        ) {
          this.showMessage({
            status: 'warning',
            text: this.translateVal('LOGIN.YOUR_PASSWORD_MUST'),
          });
          return;
        }
        this.waiting = true;

        // authStore.set('ams.privateId', this.accessData.login);
        Auth.loginByAMS(
          {
            user: this.accessData.login,
            pass: this.accessData.password,
          },
          this.accessData.remember,
        )
          .then((response) => {
            this.waiting = false;
            this.validator = {};
            // this.showMessage(
            //   {
            //     status: 'success',
            //     text: this.translateVal('LOGIN.SUCCESSFULLY_AUTHORIZED'),
            //   },
            //   3000,
            // );
            setTimeout(() => {
              let consent = !Auth.isGuest() ? amsClient.get('user.consent') !== false : !localStore.get(`consent_${Auth.get('authInfo.device_id')}`)

              if(consent) {
                this.close();
              }
            }, 2000);
          })
          .catch((error) => {
            this.waiting = false;
            this.validator = {
              login: [this.translateVal('LOGIN.PLEASE_FILL_IN_LOGIN')],
              password: [this.translateVal('LOGIN.PLEASE_FILL_IN_PASSWORD')],
            };
            setTimeout(() => {
              this.validator = {};
            }, 8000);
            //   error.hasOwnProperty('response') &&
            //   error.response &&
            //   error.response.hasOwnProperty('data') &&
            //   error.response.data &&
            //   error.response.data.hasOwnProperty('validator')
            //     ? error.response.data.validator
            //     : {};
            if (error.name.indexOf('not active') !== -1) {
              this.showMessage(
                {
                  status: 'error',
                  text: this.translateVal('LOGIN.ACCOUNT_NOT_ACTIVE'),
                },
                8000,
              );
            }
          });
        //
        // Auth.login(
        //   'ams',
        //   {
        //     user: this.accessData.login,
        //     pass: this.accessData.password
        //   },
        //   null,
        //   null,
        //   this.accessData.remember,
        //   'default'
        // )
        //   .then(() => {
        //     this.waiting = false;
        //     this.validator = {};
        //     this.showMessage(
        //       {
        //         status: 'success',
        //         text: this.translateVal('LOGIN.SUCCESSFULLY_AUTHORIZED')
        //       },
        //       3000
        //     );
        //     setTimeout(() => {
        //       this.close();
        //     }, 3000);
        //   })
        //   .catch(error => {
        //     Auth.logOut();
        //     console.log(error);
        //     this.waiting = false;
        //     this.validator =
        //       error.hasOwnProperty('response') &&
        //       error.response &&
        //       error.response.hasOwnProperty('data') &&
        //       error.response.data &&
        //       error.response.data.hasOwnProperty('validator')
        //         ? error.response.data.validator
        //         : {};
        //     this.showMessage(
        //       {
        //         status: 'error',
        //         text:
        //           error.response && error.response.data
        //             ? error.response.data.message
        //             : error.message || error.toString()
        //       },
        //       3000
        //     );
        //   });
      },
      showMessage(messageData, time) {
        this.messages.push(messageData);
        setTimeout(() => {
          let pos = this.messages.indexOf(messageData);
          this.messages.splice(pos, 1);
        }, time || this.timeToShowMessage);
      },
    },
  };
</script>