<template>
  <section class="video-container">
    <vue-headful
      :title="metadata.title"
      :description="metadata.description"
      :image="metadata.image"
      :url="metadata.url"
      :keywords="metadata.keyword"
      :head="metadata.head"
    />
    <loader v-if="loading" />
    <div v-else class="container-fluid">
      <div class="row">
        <areas
          class="col-12 px-0 mb-3 mb-lg-0"
          :class="relatedLink ? 'col-lg-9' : null"
          :areas="areas"
          :page="'video'"
          :branding-ads="pageAds"
        />
        <related-list
          v-if="relatedLink"
          class="col-12 col-lg-3 mb-3 mb-lg-0"
          :link="relatedLink"
        />
      </div>
    </div>
  </section>
</template>

<script>
  import Areas from 'src/components/areas/areas';
  import Loader from 'src/components/loader/loader';
  import Config from 'src/service/config';

  import filtersImage from 'src/filters/normalizeImg';
  import JsonLD from 'src/service/jsonld';
  import truncateToNumber from 'src/utils/truncateSymbolsToNumber.js';
  import { escapeStringJS } from 'src/utils/escape';
  import normaliseMetaTags from 'src/utils/normaliseMetaTags';

  import RelatedList from 'src/components/related-list/related-list.vue';
  import { ScreenReadyMixin } from 'src/plugins/mixin';
  import { getMetaIos } from 'src/utils/generateDeepLink';
  import { amsClient } from 'src/service/ams';
  import { memoryStore } from 'vimmi-web-utils/cjs/memoryStore';

  export default {
    name: 'PageWithRelatedTemplate',
    components: {
      Areas,
      Loader,
      RelatedList,
    },
    mixins: [ScreenReadyMixin],
    data() {
      return {
        areas: [],
        loading: true,
        reload: true,
        head: {},
        currentPage: '',
        pageAds: {},
        reloadComponent: true,
        timeoutID: 0,
        metadata: {},
      };
    },
    computed: {
      relatedLink() {
        // https://ais-qa-app.demo-vidnt.com/recommend/59a83976aae7311ac477815b/default/
        if (
          this.head &&
          this.head.genre_code &&
          this.head.genre_code.length > 0
        ) {
          // return `/recommend/${this.head.genre_code[0]}/default/`;
          return `/related/${this.head.id}/`;
        } else {
          return null;
        }
        // } else if (this.areas && this.areas.length && this.areas[0].length && this.areas[0][0].genre_code && this.areas[0][0].genre_code.length) {
        // return `/recommend/${this.areas[0][0].genre_code[0]}/default/`;

        // } else {
        //   return `/recommend/default/default/`;

        // }
      },
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        // vm.$logger.log('[beforeRouteEnter] pr', to);
        vm.loading = true;
        vm.getData(to);
      });
    },
    beforeRouteUpdate(to, from, next) {
      JsonLD.pop();
      JsonLD.pop();
      // this.$logger.log('[beforeRouteUpdate] pr', this.reloadComponent, to);
      if (this.reloadComponent) {
        this.loading = true;
        this.getData(to);
      }
      // this.reloadComponent = true;
      next();
    },
    watch: {
      'head.id': function() {
        this.setScreenReady(this.head, this.screenRequestTime);
      },
    },
    created() {
    },
    mounted() {
      this.$bus.$on('setCurrentPage', this.event_setCurrentPage);
    },
    beforeDestroy() {
      JsonLD.pop();
      JsonLD.pop();
      this.$bus.$off('setCurrentPage', this.event_setCurrentPage);
    },
    methods: {
      event_setCurrentPage(el) {
        let page = el && el.link ? el.link : null;
        // this.$logger.log('[setCurrentPage] pr ph1', {
        //   page,
        //   $route: this.$route,
        //   currentPage: this.currentPage
        // });

        this.currentPage = page;
        this.$bus.$emit('refreshDfpSlots');
        this.changeMeta(el);
        if (page && page.indexOf(this.$route.path) === -1) {
          this.$router
            .push({
              path: page,
            })
            .then((resp) => {
              this.fillHeader();
              this.addSchemaOrg(el);
            });
        } else {
          this.fillHeader();
          this.addSchemaOrg(el);
        }
      },
      changeMeta(el) {
        // Keywords - the same
        if (el) {
          this.head = Object.assign({}, el);

          // this.head.metadata = this.head.metadata || {};
          // this.head.metadata.title = el.title ? el.title : null;
          // this.head.metadata.description = el.description ? el.description : null;
          // this.head.metadata.poster = el.poster ? el.poster : null;
        }
      },

      addSchemaOrg(item) {
        if (
          item &&
          item.age_restriction &&
          item.age_restriction !== 'AP' &&
          item.age_restriction !== 'None'
        ) {
          JsonLD.update();
          // NOTE: need for correct schema org
          JsonLD.push({});
          JsonLD.push({});
          console.warn(`Item is restricted, no markup available.`);
          return;
        }

        let data = {
          '@context': 'http://schema.org',
          '@type': 'VideoObject',
          name: escapeStringJS(item.title),
          description: escapeStringJS(truncateToNumber(item.description, 300)),
          thumbnailUrl: [
            item.poster,
            filtersImage(item.poster, 'image', 'ED', 'ED'),
            filtersImage(item.poster, 'image', 'SD', 'SD'),
            filtersImage(item.poster, 'image', 'HD', 'HD'),
          ],
          uploadDate: this.formatToISODate(item.published_at),
          duration: this.formatToISODuartion(item.duration),
          interactionCount: item.views,
          expires: this.formatToISODate(item.expired_at),
          regionsAllowed:
            item.geoblock && item.geoblock === 'yes'
              ? item.distribution_country
              : null,
          genre:
            item.genre && item.genre.length > 0 ? item.genre.join(',') : null,
        };

        JsonLD.push(data);

        JsonLD.update();
      },

      formatToISODate(date) {
        return date ? date.replace(' ', 'T') + 'Z' : null;
      },

      formatToISODuartion(date) {
        let hours = Math.floor(date / 3600);
        let mins = Math.floor((date - hours * 3600) / 60);
        let secs = Math.floor(date - hours * 3600 - mins * 60);
        return `T${hours}H${mins}M${secs}S`;
      },

      fillHeader() {
        this.metadata = normaliseMetaTags(this.head);
        this.metadata.head = Object.assign(this.metadata.head, {
          'meta[name="prerender-status-code"]': { content: 200 },
          'meta[name="apple-itunes-app"]': { content: getMetaIos(this.head) },

          // 'meta[name="cXenseParse:url"]': { content: this.metadata.url }
        });
      },

      checkByRedirect(err) {
        if (typeof err === 'string') {
          let prs = err.split(':');
          if (prs[0] === '301') {
            let url = prs[1] || '/';
            let err = prs[2] || 'Content not found';
            memoryStore.set('redirect', {
              code: prs[0],
              url: url,
              err: err,
            });
            this.$router.push({ path: url });
            return true;
          } else {
            return false;
          }
        }
        return false;
      },

      getData(route) {
        // this.$logger.log('[getData] pr ph1', route);
        Config.loaded(() => {

          let isGW2W =
            route.query.gwtw !== undefined || route.query.gw2w !== undefined;

          let url = route.path;
          if (_isPreviewMode()) {
            url += (url.indexOf('?') === -1 ? '?' : '&') + 'preview=1';
          }
          this.screenRequestTime = this.setScreenRequest({
            link: url,
          });
          amsClient
            .callAms(url)
            .then((data) => {
              // NOTE: need in case where we have data from cache
              this.$nextTick(async () => {
                if (!data.error) {
                  this.setCurrentScreen(data.head);
                  data.head.cached = !!data.cached;
                  this.head = data.head;
                  this.setScreenResponse(data.head);

                  if (
                    this.head.itype === 'section' ||
                    this.head.itype === 'section_season' ||
                    this.head.itype === 'item_mov_vod'
                  ) {
                    this.setAreas(Object.assign([], [this.head]));
                    if (this.head.itype === 'section_season' && isGW2W) {
                      const series_pk = data.items.length && data.items[0].series_pk;
                      let res = await this.handleGW2W(series_pk);
                      if (res) {
                        return;
                      }
                    }
                  } else if (this.head.itype === 'item_app') {
                    this.setAreas(Object.assign([], [this.head]));

                    let url = this.head.dl_web || this.head.schema_web || null;
                    if (url) {
                      this.$router.back();
                      Object.assign(document.createElement('a'), {
                        target: '_blank',
                        href: url,
                      }).click();
                    } else {
                      this.$plError.setError({
                        text: 'No set link to redirect',
                        title: 'Not found',
                        code: 404,
                      });
                      this.$router.replace({
                        path: '/error',
                      });
                    }
                  } else if (
                    this.head.itype === 'category' ||
                    this.head.itype === 'category_series'
                  ) {
                    let norm = Object.assign({}, this.head);
                    norm.itype = `${this.head.itype}_head`;
                    norm.seasons = norm.seasons || data.items.length;
                    let normItems = Object.assign([], data.items || []);
                    normItems =
                      this.head.itype === 'category_series'
                        ? normItems.reverse()
                        : normItems;

                    this.setAreas(Object.assign([], [norm, ...normItems]));

                    if (this.head.itype === 'category_series' && isGW2W) {
                      let res = await this.handleGW2W();
                      if (res) {
                        return;
                      }
                    }
                  } else {
                    // this.$logger.log(data);
                    // this.head = data.items[0][0];
                    this.setAreas(Object.assign([], [...data.items]));
                  }

                  this.fillHeader();
                  this.loading = false;

                  // this.$logger.log('[getData] pr ph2', this.head, this.areas);
                } else {
                  this.checkByRedirect(data.error);
                }

                this.changeMeta(this.head);
                this.addSchemaOrg(this.head);
              });
            })
            .catch((err) => {
              console.log(err);
              this.$plError.setError(err);
              this.$router.replace({
                path: '/error',
              });
            });
        });
      },
      handleGW2W(series_pk = null) {
        /** try to get info by series id*/
        return amsClient.stock
          .getResumeWatch(null, series_pk || this.head.id)
          .then((response) => {
            if (response) {
              return this.$router.push({
                name: 'SeriesPage',
                params: {
                  type: 'series',
                  id: response.episode,
                },
                query: {},
              });
            } else {
              /** try to find first season in the series*/
              let firstSeason = this.areas.find((season) => {
                return (season.itype =
                  'section_season' && season.ord !== undefined);
              });
              if (firstSeason) {
                return amsClient
                  .callAms(firstSeason.link, { cache: false })
                  .then((seasonResp) => {
                    let t = seasonResp.items.sort((a, b) => {
                      if (a.ord < b.ord) {
                        return -1;
                      }
                      if (a.ord > b.ord) {
                        return 1;
                      }
                      return 0;
                    });
                    let firstEpisode = t[0];
                    if (firstEpisode.itype === 'item_mov_episode') {
                      return this.$router.push({
                        name: 'SeriesPage',
                        params: {
                          type: 'series',
                          id: firstEpisode.id,
                        },
                        query: {},
                      });
                    }
                  });
              }
            }
            return false;
          });
      },
      setAreas(areas) {
        console.log('[PageWith][areas]', areas);
        this.areas = areas;
      },
    },
  };
</script>
