var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"carusel-component",class:{ 'carousel-items-loaded': _vm.isRenderedItems },attrs:{"component-id":_vm.carouselId}},[(
      _vm.arrowsPlacement === 'left' ||
      _vm.arrowsPlacement === 'side' ||
      _vm.arrowsPlacement === 'top'
    )?_c('div',{staticClass:"d-none d-md-flex",class:_vm.arrowsPlacement === 'side'
        ? ' left-arrows ' + _vm.arrowsPlacement + '-arrows'
        : _vm.arrowsPlacement + '-arrows',style:({ top: _vm.topArrowPx })},[_c('left-arrow',{staticClass:"btn-arrow btn-arrow-left",attrs:{"color":_vm.arrowsColor.color,"hover":_vm.arrowsColor.hoverColor,"disabled":_vm.arrowsLeftDisabled},nativeOn:{"click":function($event){return _vm.clickPrevDebounced($event)}}}),_c('right-arrow',{staticClass:"btn-arrow btn-arrow-right",attrs:{"color":_vm.arrowsColor.color,"hover":_vm.arrowsColor.hoverColor,"disabled":_vm.arrowsRightDisabled},nativeOn:{"click":function($event){return _vm.clickNextDebounced($event)}}})],1):_vm._e(),_c('transition',{attrs:{"name":"fadeIn","enter-active-class":"animated fadeIn","appear":true,"appear-enter-class":"animated fadeIn"},on:{"after-enter":_vm.isEnteredList}},[_c('carousel-event-hadler',{staticClass:"carusel-data-list",on:{"move":_vm.onMouseMove,"start":_vm.onMouseDown,"stop":_vm.onMouseUp}},[_c('transition-group',{staticClass:"carusel-transition",style:({ transform: ("translateX(" + _vm.transformSwipe + "px)") }),attrs:{"name":_vm.transitionType,"tag":"div","type":"animation"}},_vm._l((_vm.itemsRendered),function(coli){return _c('div',{key:_vm.getKeyValue(coli, _vm.offset),staticClass:"carusel-data-column",style:(_vm.styleSlide),attrs:{"component-key":_vm.getKeyValue(coli, _vm.offset)}},[_vm._l((_vm.row),function(rowi){return [(_vm.getItemPosition(rowi, coli, _vm.offsetItem) >= 0)?_vm._t("default",null,{"item":_vm.getItemsByPosition(_vm.getItemPosition(rowi, coli, _vm.offsetItem)),"position":_vm.getItemPosition(rowi, coli, _vm.offsetItem)}):_vm._e()]})],2)}),0)],1)],1),(
      _vm.arrowsPlacement === 'right' ||
      _vm.arrowsPlacement === 'side' ||
      _vm.arrowsPlacement === 'bottom'
    )?_c('div',{staticClass:"d-none d-md-flex",class:_vm.arrowsPlacement === 'side'
        ? ' right-arrows ' + _vm.arrowsPlacement + '-arrows'
        : _vm.arrowsPlacement + '-arrows',style:({ top: _vm.topArrowPx })},[_c('left-arrow',{staticClass:"btn-arrow btn-arrow-left",attrs:{"disabled":_vm.arrowsLeftDisabled},nativeOn:{"click":function($event){return _vm.clickPrevDebounced($event)}}}),_c('right-arrow',{staticClass:"btn-arrow btn-arrow-right",attrs:{"disabled":_vm.arrowsRightDisabled},nativeOn:{"click":function($event){return _vm.clickNextDebounced($event)}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }