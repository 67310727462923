<template>
  <div ref="termsContainer" class="container-fluid terms-container">
    <h3 class="terms-title">
      <span v-t="{ path: 'BLOCK.TERMS_SERVICE' }" />
    </h3>
    <br>
    <div>
      <p class="terms-content" v-html="tacContent"></p>
    </div>
    <div class="terms-button">
      <div
        v-if="tacContent"
        class="terms-cancel"
        @click="closeTACPage()"
      >
        <span
          class=""
        >
          <span v-t="{ path: 'BLOCK.BACK' }"></span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import { amsClient } from 'src/service/ams';
  import { localStore } from 'vimmi-web-utils/esm/localStore';
  import contentLanguage from 'src/filters/contentLanguage';
  import Config from '@/service/config';

  export default {
    name: 'TACPage',

    data() {
      return {
        tacContent: '',
        query: '',
      };
    },
    mounted() {
      this.query = this.$router.currentRoute.query.q;
      Config.loaded(() => {
        amsClient.callAms(`/get_doc/terms/`, { cache: false }).then((data) => {
          // let langFromLocalStore = localStore.get('lang');
          // let langUI = langFromLocalStore ? langFromLocalStore : 'tha';
          this.tacContent = contentLanguage(data.head, 'content');
          this.$refs.termsContainer.scrollTop = 0;
          // let popup = document.querySelector('.terms-content');
          // if (data.head && data.head.content) {
          //   if (data.head.alt_lang && data.head.alt_lang[langUI]) {
          //     this.tacContent = data.head.alt_lang[langUI].content;
          //     // popup.innerHTML = this.tacContent;
          //   } else {
          //     this.tacContent = data.head.content;
          //     // popup.innerHTML = this.tacContent;
          //   }
          // }
        });
        this.$nextTick(() => {
          this.checkAcceptMode();
        });
        // document.querySelector('.terms-container').scrollTop = 0;
      });
    },
    beforeDestroy() {
      if (this.query === 'accept') {
        try {
          let headerMenu = document.querySelector('.header-container');
          headerMenu.style.pointerEvents = 'auto';
        } catch (e) {

        }
        this.$bus.$emit('toggleAuthPopup', {
          pdpa_type: 'close',
          trigger: false,
          accept: true,
        });
      }
    },

    methods: {
      checkAcceptMode() {
        // setTimeout(() => {
          if (this.query === 'accept') {
            try {
              let headerMenu = document.querySelector('.header-container');
              headerMenu.style.pointerEvents = 'none';
            } catch (e) {

            }
            this.$bus.$emit('toggleAuthPopup', {
              login_type: true,
              accept: false,
            });
          }
        // }, 1000);
      },
      closeTACPage() {
        if (this.query === 'accept') {
          this.$router.go(-1);
        } else {
          this.$router.push({ path: '/set' });
        }
      },
    },
  };

</script>

<style>
  .terms-container {
    background-color: #1c1718;
    color: white;
  }

  .terms-content {
    max-width: 800px;
    margin: auto;
  }

  .terms-title {
    max-width: 800px;
    margin: auto;
    padding-top: 70px;
  }

  .terms-cancel {
    background: #BCD043;
    width: 70px;
    margin: auto;
    padding: 5px;
    border-radius: 5px;
    color: #000;
    cursor: pointer;

  }

  .terms-button {
    text-align: center;
    padding-bottom: 50px;
  }

  .terms-button :hover {
    opacity: .8;
  }

</style>