import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import App from './App';
import 'intersection-observer';
import SmartBanner from 'smart-app-banner';
import vueHeadful from 'vue-headful';
import vueScrollBehavior from 'vue-scroll-behavior';

import { AlertPlugin, CollapsePlugin, DropdownPlugin, ModalPlugin, NavbarPlugin } from 'bootstrap-vue';
import ObserveVisibility from 'vue-observe-visibility';

import { LoggerVuePlugin } from 'logger-js';
import router from './router';
import store from './store';
import './directives';
import './filters';
import i18n from './lang/index';
import Config from './service/config';
// Plugins
import VueErrorHandler from './service/vue-error-handler';
import ErrorPlugin from './plugins/errorPlugin';
import BusPlugin from './plugins/event-bus';
import BodyPlugin from './plugins/body-style';
import './registerServiceWorker';
import { default as FeatureTogglePlugin, features } from 'src/plugins/feature-toggle';
import VueAnalyticsPlugin from 'src/plugins/analytics/vue-analytics-plugin';
import AisTemplatesPlugin from 'src/plugins/ais-templates';
import { localStore } from 'vimmi-web-utils/esm/localStore';
import lifecycle from 'page-lifecycle';


console.info(
  `PORTAL version: ${process.env.VUE_APP_PROJECT_VERSION}, build date: ${process.env.VUE_APP_PROJECT_DATE}`);


let ios = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;


new SmartBanner({
  title: 'AIS PLAY',
  author: ios ? 'Simple Download from App Store' : 'Simple Download from Google Play',
  button: 'VIEW',
  store: {
    ios: 'Download now',
    android: 'Download now',
  },
  // TODO: use local image
  icon: require('src/../public/img/AISPlayApp.png'),
  // force: 'ios',
});

Vue.use(LoggerVuePlugin, {
  pluginName: '$logger',
  project: {
    name: 'Portal',
    style: 'background: #1f55ff; color: #ffffff',
  },
  debug: {
    enabled: true,
    level: 0,
  },
});

Vue.use(VueAnalyticsPlugin);


Vue.use(BusPlugin);
Vue.use(FeatureTogglePlugin);
Vue.use(AisTemplatesPlugin);

Vue.use(vueScrollBehavior, { router: router });


Vue.use(ErrorPlugin);
Vue.use(BodyPlugin);

// Botstrap
Vue.use(DropdownPlugin);
Vue.use(NavbarPlugin);
Vue.use(AlertPlugin);
Vue.use(ModalPlugin);
Vue.use(CollapsePlugin);

Vue.use(ObserveVisibility);

Vue.component('vue-headful', vueHeadful);

Vue.config.errorHandler = VueErrorHandler;
Vue.config.productionTip = process.env.NODE_ENV !== 'production';

// For production  process.env.NODE_ENV === 'production'
Vue.config.devtools = true;
Vue.config.performance = true;

Vue.prototype.$isPreviewMode = _isPreviewMode();
Vue.prototype.$isPdpaPopup = false;
Vue.prototype.$fungusLogin = false;

/* eslint-disable no-new */
export const vapp = new Vue({
  i18n,
  el: '#app',
  router,
  store,
  components: { App },
  created() {

    let oldVersion = localStore.get('version');
    if (!oldVersion) {
      this.$stats.send('first_run', {});
      localStore.set('version', Config.get('version'));
    }
    if (oldVersion && oldVersion !== Config.get('version')) {
      localStore.set('version', Config.get('version'));
      this.$stats.send('upgrade', {
          old_version: `${Config.get('app_info')} ${oldVersion}`,
        },
      );
    }

  },
  mounted() {
    lifecycle.addEventListener('statechange', (event) => {
      // console.log('[statechange]', event.oldState, event.newState)
      if (event.newState === 'terminated') {
        this.$bus.$emit('beforeTerminated');
        vapp.$stats.send('app_closed', {}, {
          beacon: true,
          fetch: false,
        });
      }
    });
  },
  template: '<App/>',
});


