import { localStore } from 'vimmi-web-utils/esm/localStore';
import { vapp } from '@/main';
import { localCache } from 'vimmi-web-utils/esm/localCache';

import { logger } from 'src/service/logger';
import { amsClient } from '../ams';
import router from '../../router';
import { activateFeature, deactivateFeature, isActiveFeature } from '@/plugins/feature-toggle';
import Auth from 'src/service/authService';
import Vue from 'vue';
import Config from '../config';
import fpTest from '@/service/fpTest';

class AuthService {

  constructor() {
    this.$logger = logger();
    this.fungusLogin = false;
    this.fungusLogin = false;
    this.consent = null;

    amsClient.on('auth_state_change', async (event, eventData) => {

      let user = amsClient.get('user');
      this.$logger.log('[amsClient][auth_state_change]', eventData, user);

      switch (eventData.type) {
        case 'logged':
          if (this.isGuest() /*|| !!this.isAuth()*/) {
            await this.checkDocsVersion();
          } else {
            this.consent = amsClient.get('user.consent') === false;
          }

          this.showPdpaPopup();

          if (amsClient.get('user.guest')) {
            vapp.$bus.$emit('changedUserState', eventData);
          }
          if (amsClient.get('buh.olsy') === false) {
            deactivateFeature('cm-analytics');
          } else {
            activateFeature('cm-analytics');
          }
          // the application should displays user as logged
          break;
        case 'inactive':
          // that means that user comes from AMS as inactive, so need to logout
          break;
        case 'no-user':
          await this.checkDocsVersion();
          this.showPdpaPopup();

        case 'logout':

          // the user log out
          this.afterLogOut();
          break;
      }
    });

    amsClient.on('before_auth_state_change', (event, eventData) => {
      this.$logger.log('[amsClient][before_auth_state_change]', eventData);
      switch (eventData.type) {
        case 'logged':
          vapp.$bus.$emit('amsBeforeLogged', eventData);
          break;
        case 'logout':
          this.fungusLogin = false;
          vapp.$bus.$emit('toggleAuthPopup', {
            trigger: false,
            accept: false,
            login_type: true,
          });
          vapp.$bus.$emit('amsBeforeLogout', eventData);
          break;
      }

    });

    /** when AMS sync complete */
    amsClient.on('sync', (event, eventData) => {
      this.$logger.log('[amsClient][sync]', eventData);
      vapp.$bus.$emit('sync', eventData);

    });
  }

  showPdpaPopup() {
    if (vapp.$route.path.includes('terms_and_conditions') || vapp.$route.path.includes('privacy_notice')) {
      return;
    }
    if (this.consent && !vapp.$isPdpaPopup && !(amsClient.get('user.guest') && this.fungusLogin)) {
      setTimeout(() => {
        vapp.$bus.$emit('toggleAuthPopup', {
          login_type: this.consent,
          trigger: this.consent,
          accept: (this.isAuth() && !this.isGuest()) || this.fungusLogin,
        });
      }, 1000);
    }
  }


  async checkDocsVersion() {
    return amsClient.callAms(`/docs_version/`, { cache: false }).then((data) => {
      let isCorrectPrivacyVersion = true;
      let isCorrectTermsVersion = true;

      this.consentDtypes = data.info.required_dtypes || [];
      this.isPrivacy = data.info.active.privacy;
      this.isTerms = data.info.active.terms;
      if (this.isPrivacy) {
        isCorrectPrivacyVersion = this.isPrivacy === localStore.get(`privacy_version_${Auth.get('authInfo.device_id')}`);
      } else {
        isCorrectPrivacyVersion = true;
        localStore.delete(`privacy_version_${Auth.get('authInfo.device_id')}`);
      }
      if (this.isTerms) {
        isCorrectTermsVersion = this.isTerms === localStore.get(`terms_version_${Auth.get('authInfo.device_id')}`);
      } else {
        isCorrectTermsVersion = true;
        localStore.delete(`terms_version_${Auth.get('authInfo.device_id')}`);
      }
      this.consent = !(isCorrectPrivacyVersion && isCorrectTermsVersion);

      if (this.consent) {
        localStore.delete(`consent_${Auth.get('authInfo.device_id')}`);
      }

      // vapp.$bus.$emit('toggleAuthPopup', {
      //   login_type: true,
      //   trigger: true,
      //   accept: false,
      // });
      return this.consent;
    });

  }

  isGuest() {
    return !!amsClient.get('user.guest');
  }

  isAuth() {
    return amsClient.isAuth();
  }

  getDeviceRestriction() {
    return amsClient.get('deviceRestrictions');
  }


  static getFungusRedirectUrl() {
    let props = router.resolve({
      name: 'Callbacks',
      params: {
        type: 'fungus',
        status: 'any',
      },
    });
    return (
      location.origin +
      (location.href.indexOf('/portal') === -1
        ? props.href.replace('/portal', '')
        : props.href)
    );
  }


  saveCurrentPath() {
    let path = vapp.$router.currentRoute.fullPath;
    localStore.set('auth-redirect-url', path);
  }

  getPortalPrevPath() {
    return localStore.get('auth-redirect-url');
  }

  openFungusLoginPage() {
    console.log('openFungusLoginPageopenFungusLoginPage');
    this.saveCurrentPath();
    localCache.clean(true);

    return amsClient.account.openFungusLoginPage(AuthService.getFungusRedirectUrl());
  }

  loginByFungusToken(ol5) {
    let scope = this;
    console.log('[debug][loginByFungusToken] enter');
    this.fungusLogin = true;
    return new Promise((resolve, reject) => {

      amsClient.account.loginByFungusOL5Token(ol5, AuthService.getFungusRedirectUrl()).then(() => {
        let handler = () => {
          console.log('[debug][loginByFungusToken] sync');
          vapp.$nextTick(() => {
            if (scope.get('user.second_screen')) {

              vapp.$logger.log('2nd id type, clearing all caches');
              vapp.$bus.$emit('re-load-ams-entry');
            }
            vapp.$bus.$emit('changedUserState', { type: 'logged' });

            amsClient.off('sync', handler);
            resolve(true);
          });
        };
        amsClient.on('sync', handler);
      }).catch(reject);
    });
  }


  loginByAMS(credentials, rememberMe = false) {
    let scope = this;

    return amsClient.account.login(credentials.user, credentials.pass, { amsLoginType: 'device' }).then((response) => {
      if (Config.get('fp_test')) {
        fpTest.send('login', {
          userId: response.user.id,
        });
      }
      if (response.user.consent === false) {
        vapp.$bus.$emit('toggleAuthPopup', {
          login_type: true,
          trigger: false,
          accept: true,
        });
      }

      let handler = function() {
        vapp.$nextTick(() => {
          if (scope.get('user.second_screen')) {
            vapp.$bus.$emit('re-load-ams-entry');
          }
          vapp.$bus.$emit('changedUserState', { type: 'logged' });

          amsClient.off('sync', handler);
        });
      };
      amsClient.on('sync', handler);
      if (vapp.$cm) {
        vapp.$cm.setUser({
          session_id: this.get('user.sid'),
          signup_type: this.get('user.login_type') === 'fungus' ? 'fungus' : 'ams',
          username: this.get('user.login_type') === 'fungus' ? this.get('user.privateId') : this.get('user.id'),
        });
      }
    });
  }

  logOut(beforeLogin = false) {
    if (!this.isLogoutCalled) {
      this.isLogoutCalled = true;
      return amsClient.account.logout().then((response) => {
        if (isActiveFeature('guest-users') && !beforeLogin) {
          let handler = function() {
            vapp.$nextTick(() => {

              vapp.$bus.$emit('changedUserState', { type: 'logged', afterLogout: true });

              amsClient.off('sync', handler);

            });
          };
          amsClient.on('sync', handler);

          if (isActiveFeature('guest-users') && Config.get('guest_mode')) {
            amsClient.account.guestLogin({ amsLoginType: 'device' }).then((amsData) => {
              // vapp.$bus.$emit('changedUserState', {type: 'logged', afterLogout: true});
              if (Config.get('fp_test')) {
                fpTest.send('guest_login', {
                  userId: amsData.user.id,
                });
              }
            });
          }
        }
        this.isLogoutCalled = false;
      });
    }
  }

  afterLogOut() {


    if (vapp.$cm) {
      vapp.$cm.unsetUser();
    }
    if (amsClient.get('buh.olsy') === false) {
      deactivateFeature('cm-analytics');
    } else {
      activateFeature('cm-analytics');
    }

    if (this.get('user.second_screen')) {

      vapp.$bus.$emit('re-load-ams-entry', true);
      vapp.$router.push({
        path: '/',
      });
    }

    vapp.$nextTick(() => {
      vapp.$bus.$emit('changedUserState', { type: 'logout' });
    });
  }

  async getContactNumber() {
    if (this.get('user.second_screen')) {
      return await amsClient.account.apiV2.getContactNumber();
    }
    return null;
  }

  get(key) {
    return amsClient.get(key);
  }
}

export default new AuthService();
