<template>
  <div class="auth-page-container">
    <loader v-if="loading" />
  </div>
</template>

<script>
  import Loader from 'src/components/loader/loader';
  import Auth from 'src/service/authService';
  import { vapp } from '@/main';
  import { amsClient } from '../../service/ams';
  import Config from 'src/service/config';


  export default {
    name: 'CallbacksContainer',
    components: {
      Loader,
    },
    data() {
      return {
        loading: true,
        type: null,
        status: 'fungus',
      };
    },
    computed: {},
    beforeRouteEnter(_to, _from, next) {
      next(vm => {
        if (_to.params.type) {
          vm.type = _to.params.type;
        } else if (_to.matched.length && _to.matched[0].props.default && _to.matched[0].props.default.type) { // for supports old auth way
          vm.type = _to.matched[0].props.default.type;
        }
        if (_to.params.status) {
          vm.status = _to.params.status;
        } else if (_to.matched.length && _to.matched[0].props.default && _to.matched[0].props.default.status) { // for supports old auth way
          vm.status = _to.matched[0].props.default.status;
        }
        if (vm.type === 'fungus') {
          if (_to.query && _to.query.ol5) {
            vm.loginByFungusToken(_to.query.ol5);
          } else {
            if (Auth.isAuth() && !Auth.isGuest()) {
              vm.$router.push({
                path: '/',
              });
            }
          }
        } else if (vm.type === 'vdo') {
          //          this.handleVDOResponse();
        }

      });
    },
    beforeRouteUpdate(_to, _from, next) {
      next();
    },
    created() {

    },
    mounted() {

    },
    beforeDestroy() {

    },
    methods: {
      handleVDOResponse(response) {
      },
      redirectBack() {
        let redirectPath = Auth.getPortalPrevPath();
        if (redirectPath) {
          this.$router.push({
            path: redirectPath || '/',
          });
        } else if (Auth.get('user.second_screen')) {
          this.$router.push({
            path: '/',
          });
        }
      },
      loginByFungusToken(ol5) {
        Config.loaded(() => {

          Auth.loginByFungusToken(ol5).then((response) => {
            this.$nextTick(() => {
              vapp.$redirectFromFungus = true;
              this.redirectBack();
            });

          }).catch((error) => {
            console.error(error);
            this.$logger.warn('fungus error:', error);
            this.$nextTick(() => {
              this.$bus.$emit('toggleAuthPopup', {
                login: true,
                center: false,
                trigger: true,
                message: error.message,
              });
              this.$router.push({
                path: '/',
              });
            });
          });
        });
      },
    },
  };
</script>
