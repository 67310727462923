<template>
  <div
    v-if="isAvailable"
    class="pro-button-block"
  >
    <button
      class="pro-button"
      :style="{ color: textColor,
                backgroundColor: backgroundColor, //'rgba(58, 77, 181, 0.65)',
                border: (border === false) ? '' : '1px solid ' + textColor }"
      @click="goToShow()"
    >
      <span>{{ text }}</span> <span
        v-if="arrows"
        style="color: #C3C3C3;"
      >
        ≫
      </span>
    </button>
    <!-- <router-link v-else class="pro-button" :to="target" :style="{  color: textColor,
                                        backgroundColor: backgroundColor, //'rgba(58, 77, 181, 0.65)',
                                        border: (border === false) ? '' : '1px solid ' + textColor  }"
                                        >
            <span>{{ text }}</span> <span style="color: #c3c3c3;" v-if="arrows">≫</span>
        </router-link> -->
  </div>
</template>

<script>
  import { isExternalLink } from 'src/utils';
  import { amsClient } from 'src/service/ams';
  import Config from 'src/service/config';

  export default {
    name: 'ProLinkButton',
    props: {
      'border': {
        type: [String, Boolean],
        default: true,
      },
      target: {
        type: String,
        required: true,
      },
      background: {
        type: String,
        default: 'rgba(31, 85, 255, 0.5)',
      },
      opacity: {
        default: 1,
        type: [String, Number],
      },
      color: {
        type: String,
        default: 'white',
      },
      text: {
        type: String,
        default: 'Go to the shows',
      },
      arrows: {
        type: Boolean,
        default: false,
      },
      videoid: {
        type: String,
      },
    },

    data() {
      return {
        context: {},
        textColor: '',
        backgroundColor: '',
      };
    },
    computed: {
      isAvailable() {
        return this.textColor;
      },
    },
    created() {
      Config.loaded(() => {
        // this.context = response.context;
        this.applyColors();
      });
    },
    mounted() {
    },
    methods: {
      isExternalLink,
      applyColors() {
        let colors;
        if (Config.get('context.conf.colors')) {
          colors = Config.get('context.conf.colors');
        }
        this.textColor = colors.hasOwnProperty(this.color) ? colors[this.color] : this.color;

        this.backgroundColor = colors.hasOwnProperty(this.background) ? colors[this.background] : this.background;
        // console.log(this.backgroundColor, this.opacity);
        if (this.backgroundColor.indexOf('#') !== -1) {
          if (this.backgroundColor.length === 9) {
            this.backgroundColor = this.rgbToString(this.hexToRgb(this.backgroundColor));
          } else if (this.opacity) {
            this.backgroundColor = this.setHexOpacity(this.backgroundColor, this.opacity);
          }
        }
      },
      hexToRgb(hex) {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function(m, r, g, b) {
          return r + r + g + g + b + b;
        });

        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        if (!result) {
          result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        }
        return result ? [
          parseInt(result[1], 16),
          parseInt(result[2], 16),
          parseInt(result[3], 16),
          result.length >= 5 ? +(parseInt(result[4], 16) / 255).toFixed(2) : 1,
        ] : [];
      },
      rgbToString(rgb) {
        let rgbString = rgb.map(val => val)
          .join(',');
        if (rgb.length === 3 && !opacity) {
          rgbString = 'rgb(' + rgbString + ')';
        } else if (rgb.length === 4) {
          rgbString = 'rgba(' + rgbString + ')';
        }
        return rgbString;
      },
      setHexOpacity(hex, opacity) {
        let rgbString = this.hexToRgb(hex)
          .map(val => val);
        rgbString[3] = opacity;
        // console.log(rgbString);
        return 'rgba(' + rgbString.join(',') + ')';
        // return rgba.replace(//, opacity);
      },
      goToShow() {
        let url = `/get_shows/${this.videoid}/`;
        amsClient.callAms(url, { cache: false })
          .then(data => {
            // console.log(res.data);
            let target = data.head.target || this.target;
            if (target === this.target) {
              console.warn('Router to Video Page', target);
            }
            this.$router.push(target);
          })
          .catch((err) => {
            console.warn('Cannot get show. Redirect to video page:', this.target);
            this.$router.push(this.target);
          });
      },
    },
  };
</script>

<style lang="scss">
  @import './_prolinbtn';
</style>
