import { vapp } from '../main';
import formatDate from '../filters/formatDate';
import { amsClient } from 'src/service/ams';

class EpgService {
  constructor() {
    this.AIS_HARDCODED_TIME_ZONE_OFFSET = 25200000; //7h//  18000000; //5h//
    this.epgFormattedItems = {};
  }

  getCurrentEvent(epgData) {
    if (epgData && epgData.length) {
      let currentTime = Date.now();
      for (let index in epgData) {
        if (epgData[index].startTime < currentTime && epgData[index].endTime > currentTime) {
          return epgData[index];
        }
      }
      return {};
    }
  }

  getTimestamp(date, time, timeZoneOffset = 0) {
    let _d = date.split('-');
    let _t = time.split(':');
    return Date.UTC(+_d[0], (+_d[1]) - 1, +_d[2], +_t[0], +_t[1], +_t[2]) - timeZoneOffset;
  }

  prepareEpg(epgData) {
    if (epgData && epgData.length) {
      epgData.map((epgItem) => {
        epgItem.startTime = this.getTimestamp(epgItem.date, epgItem.start, this.AIS_HARDCODED_TIME_ZONE_OFFSET);
        epgItem.endTime = this.getTimestamp(epgItem.date_end, epgItem.end, this.AIS_HARDCODED_TIME_ZONE_OFFSET);
        this.epgFormattedItems[epgItem.parent] = this.epgFormattedItems[epgItem.parent] || [];
        this.epgFormattedItems[epgItem.parent].push(epgItem);
      });
    }
    return this.epgFormattedItems;
  }

  loadEpgByIds(id, dateStart = null, dateEnd = null) {
    let epgUrl = '/epg/';
    if (Array.isArray(id)) {
      id = id.join(',');
    }
    let url = epgUrl + '?items=' + id;

    if (dateStart && dateEnd) {

      dateStart = dateStart + this.AIS_HARDCODED_TIME_ZONE_OFFSET + ((new Date()).getTimezoneOffset() * 60 * 1000);
      dateEnd = dateEnd + this.AIS_HARDCODED_TIME_ZONE_OFFSET + ((new Date()).getTimezoneOffset() * 60 * 1000);
      url += `&start_date=${formatDate(dateStart, 'yyyy-MM-dd')}&end_date=${formatDate(dateEnd,
        'yyyy-MM-dd')}&start_time=${formatDate(dateStart, 'HH:mm:ss')}&end_time=${formatDate(dateEnd, 'HH:mm:ss')}`;
    }

    return amsClient.callAms(url, { cache: false })
      .then((data) => {
        if (data && data.items) {
          return data.items;
        }
        return [];
      });
  }
}

export default (new EpgService());
