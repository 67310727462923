import {normalizeImg} from 'src/filters';

export default (el, bindings) => {
  let imgName = bindings.value.name;
  let imgType = bindings.value.type;
  let desktop = bindings.value.desktop || null;
  let mobile = bindings.value.mobile || null;
  let img = normalizeImg(imgName, imgType, desktop, mobile);
  // console.log(bindings);
  el.style.backgroundImage = (img) ? `url(${img})` : 'none';
}
