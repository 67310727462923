<template>
  <div class="related-list pl-lg-0">
    <list-wrapper
      :items="items"
      :limit="-1"
    >
      <template v-slot:title>
        <span v-t="{path: 'VIDEO_COMPONENT.RELATED_LIST'}"/>
      </template>
      <template v-slot:no-items>
        <span
          v-if="isLoaded"
          v-t="{path: 'VIDEO_COMPONENT.NO_RELATED_LIST'}"
        />
        <span v-else>
          <!-- TODO: in another component -->
           <loader/>
        </span>
      </template>
      <template v-slot="{item,index}">
        <related-item
          :key="item.id"
          :item="item"
          :related-method="relatedMethod"
          :index="index"
          class="col-12 col-md-6 col-lg-12 mx-0 pr-lg-0"
        />
      </template>
    </list-wrapper>
  </div>
</template>

<script>
  import RelatedItem from './related-item.vue';
  import ListWrapper from 'src/components/list-wrapper.vue';
  import Config from 'src/service/config';
  import { amsClient } from 'src/service/ams';
  import { AuthVariableMixin } from '../../plugins/mixin';
  import Loader from 'src/components/loader/loader';

  export default {
    name: 'RelatedList',
    components: {
      RelatedItem,
      ListWrapper,
      Loader,
    },
    mixins: [AuthVariableMixin],
    props: {
      link: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        items: [],
        relatedMethod: Config.get('analytics.defaultRelatedMethod'),
        isLoaded: false,
      };
    },

    created() {
      this.isLoaded = false;
      if (!this.$stats.get(`current.${encodeURIComponent(this.link)}.relatedMethod`)) {
        this.$stats.set(`current.${encodeURIComponent(this.link)}.relatedMethod`, this.relatedMethod);
      }
      this.onSync();
    },
    methods: {
      onSync() {
        amsClient.callAms(this.link, { cache: false })
          .then(data => {
            this.items = Object.assign([], data.items);
            if (data.head.method) {
              this.relatedMethod = data.head.method;
              this.$stats.set(`current.${encodeURIComponent(this.link)}.relatedMethod`, this.relatedMethod);
            }

            this.isLoaded = true;
            // this.checkElementHeight();
          });
      },
    },
  };
</script>
