<template>
  <section
    v-if="isAvailable"
    class="social-section text-center"
  >
    <div class="container">
      <h2 class="social-section-title">
        {{ title }}:
      </h2>
      <div class="social-section-icons d-block d-lg-inline-block">
        <a
          v-for="(item, key) in positionSocial"
          :key="key"
          :href="items[item]"
          class="social-section-link"
          :class="{ 'social-section-link-disabled' :!items[item]}"
          target="_blank"
        >
          <span class="fa-stack">
            <i class="far fa-circle fa-stack-2x"/>
            <i
              class="fab fa-stack-1x"
              :class="'fa-' + IconsID[item]"
              aria-hidden="true"
            />
          </span>
        </a>
      </div>
    </div>
  </section>
</template>

<script>

  export default {
    name: 'SocialBlock',
    props: [
      'area',
    ],

    data() {
      return {
        IconsID: {
          'fb': 'facebook-f',
          'instagram': 'instagram',
          'twitter': 'twitter',
        },
      }
    },
    computed: {
      isAvailable() {
        return this.items && Object.keys(this.items).length > 0;
      },
      title() {
        return this.area.title;
      },
      positionSocial() {
        return ['fb', 'instagram', 'twitter'];
      },
      items() {
        let items = this.area.items;
        for (let key in items) {
          let prop = key.toLowerCase();
          items[prop] = items[key];
        }
        return items;
      },
    },
  }
</script>

<style lang="scss">
  @import './_social-block';
</style>
