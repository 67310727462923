import setFeatures from 'feature-toggle';
import FeatureList from './features';

export const features = setFeatures(FeatureList);

export function isActiveFeature(name) {
  return features.active(name);
}

export function deactivateFeature(name) {
  return features.deactivate([name]);
}

export function activateFeature(name) {
  return features.activate([name]);
}

export const FeatureToggleMixin = {
  methods: {
    isActiveFeature,
    deactivateFeature,
    activateFeature,
  },
};


const install = function (Vue) {
  Vue.mixin(FeatureToggleMixin);
}
// Export module
export default {install}