<template>
  <div
    class="auth-wrap animated fadeIn p-4 login-type"
  >
    <img
      :src="require('src/assets/ais-logo/ais_play.png')"
      alt="AIS PLAY"
      height="100%"
      width="100%"
      class="ais-logo"
    >
    <h3 class="login-type-title text-center">
      <span v-t="{ path: 'BLOCK.WELCOME' }" />
    </h3>
    <div class="text-center">
      <button
        type="button"
        :disabled="loading"
        class="login-type-btn login"
        @click.stop="showAuthPopup()"
      >
        <span
          class="d-block text-truncate align-middle w-100"
        >
          <span v-t="{ path: 'BLOCK.LOGIN' }" />
        </span>
      </button>
      <button
        type="button"
        :disabled="loading"
        class="login-type-btn guest"
        @click="closeLoginTypePopup"
      >
        <span
          class="d-block text-truncate align-middle w-100"
        >
          <span v-t="{ path: 'BLOCK.IMGUEST' }" />
        </span>
      </button>
    </div>
  </div>
</template>

<script>
  import Config from '../../../service/config';
  import Auth from '../../../service/authService';
  import { amsClient } from '../../../service/ams';
  import { vapp } from '@/main';
  import { localStore } from 'vimmi-web-utils/esm/localStore';

  export default {
    name: 'LoginType',
    data: function() {
      return {
        loading: false,
        isPrivacy: '',
        isTerms: '',
      };
    },
    created() {
    },
    mounted() {
      document.body.style.overflowY = 'hidden';
      document.documentElement.style.overflowY = 'hidden';
      document.body.style.position = 'fixed';
      document.documentElement.style.position = 'fixed';
      vapp.$isPdpaPopup = true;
    },
    destroyed() {
      document.body.style.overflowY = 'auto';
      document.documentElement.style.overflowY = 'auto';
      document.body.style.position = 'static';
      document.documentElement.style.position = 'static';
      vapp.$isPdpaPopup = false;
    },
    methods: {
      showAuthPopup() {
        if (Config.get('mode') === 'dev') {
          this.$bus.$emit('toggleAuthPopup', {
            login: true,
            login_type: false,
            trigger: true,
          });
        } else {
          Auth.openFungusLoginPage();
        }
      },
      async closeLoginTypePopup() {
        this.loading = true;
        await this.getDocsVersions();
        let isConsent = !Auth.isGuest() && Auth.isAuth() ? amsClient.get('user.consent') === false
          : !localStore.get(`consent_${Auth.get('authInfo.device_id')}`) && (this.isPrivacy || this.isTerms);
        this.$bus.$emit('toggleAuthPopup', {
          login_type: true,
          trigger: false,
          accept: isConsent,
        });
        this.loading = false;
      },
      getDocsVersions() {
        return amsClient.callAms(`/docs_version/`, { cache: false }).then((data) => {
          this.isPrivacy = data.info.active.privacy;
          this.isTerms = data.info.active.terms;
        });
      },
    },
  };
</script>

<style scoped>
  .auth-wrap {
    background-color: #151516;
    border-radius: 15px;
    color: #fff;
    /*box-shadow: 0px 0px 10px 1px #a5a5a5;*/
    max-width: 450px;
    width: 90%;
    min-width: 300px;
    max-height: 565px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1000;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: -50px auto auto;
  }

  .ais-logo {
    width: 180px;
    height: 38px;
    margin: auto;
    display: block;
  }

  .login-type-title {
    padding: 24px 0 42px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    display: block;
    margin: auto;
    color: #FFFFFF;
  }

  .login-type-btn {
    display: block;
    flex-grow: 1;
    font-weight: bold;
    min-width: 70px;
    max-width: 370px;
    width: 90%;
    height: 55px;
    border-radius: 10px;
    background: #8BB31D;
    color: #343434;
    border: none;
    margin: auto;
  }

  .login:hover {
    opacity: .8;
  }

  .guest {
    background-color: #151516;
    color: #fff;
    margin-top: 10px;
    font-weight: 400;
    font-size: 16px;
    border: none;
  }

  .guest span:hover {
    color: #BCD043;
    cursor: pointer;
  }

  button:focus {
    outline: none;
  }
</style>