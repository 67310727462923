<template>
  <div
    class="carusel-component"
    :class="{ 'carousel-items-loaded': isRenderedItems }"
    :component-id="carouselId"
  >
    <!-- Arrows top and left-->
    <div
      v-if="
        arrowsPlacement === 'left' ||
        arrowsPlacement === 'side' ||
        arrowsPlacement === 'top'
      "
      class="d-none d-md-flex"
      :class="
        arrowsPlacement === 'side'
          ? ' left-arrows ' + arrowsPlacement + '-arrows'
          : arrowsPlacement + '-arrows'
      "
      :style="{ top: topArrowPx }"
    >
      <left-arrow
        class="btn-arrow btn-arrow-left"
        :color="arrowsColor.color"
        :hover="arrowsColor.hoverColor"
        :disabled="arrowsLeftDisabled"
        @click.native="clickPrevDebounced"
      />
      <right-arrow
        class="btn-arrow btn-arrow-right"
        :color="arrowsColor.color"
        :hover="arrowsColor.hoverColor"
        :disabled="arrowsRightDisabled"
        @click.native="clickNextDebounced"
      />
    </div>
    <transition
      name="fadeIn"
      enter-active-class="animated fadeIn"
      :appear="true"
      appear-enter-class="animated fadeIn"
      @after-enter="isEnteredList"
    >
      <carousel-event-hadler
        class="carusel-data-list"
        @move="onMouseMove"
        @start="onMouseDown"
        @stop="onMouseUp"
      >
        <transition-group
          class="carusel-transition"
          :name="transitionType"
          tag="div"
          :style="{ transform: `translateX(${transformSwipe}px)` }"
          type="animation"
        >
          <div
            v-for="coli in itemsRendered"
            :key="getKeyValue(coli, offset)"
            class="carusel-data-column"
            :component-key="getKeyValue(coli, offset)"
            :style="styleSlide"
          >
            <!-- itemsRendered: {{ items }} row: {{ row }} -->
            <template v-for="rowi in row">
              <slot
                v-if="getItemPosition(rowi, coli, offsetItem) >= 0"
                :item="
                  getItemsByPosition(getItemPosition(rowi, coli, offsetItem))
                "
                :position="getItemPosition(rowi, coli, offsetItem)"
              />
            </template>
          </div>
        </transition-group>
      </carousel-event-hadler>
    </transition>

    <!-- Arrows bottom and right-->
    <div
      v-if="
        arrowsPlacement === 'right' ||
        arrowsPlacement === 'side' ||
        arrowsPlacement === 'bottom'
      "
      class="d-none d-md-flex"
      :class="
        arrowsPlacement === 'side'
          ? ' right-arrows ' + arrowsPlacement + '-arrows'
          : arrowsPlacement + '-arrows'
      "
      :style="{ top: topArrowPx }"
    >
      <left-arrow
        class="btn-arrow btn-arrow-left"
        :disabled="arrowsLeftDisabled"
        @click.native="clickPrevDebounced"
      />
      <right-arrow
        class="btn-arrow btn-arrow-right"
        :disabled="arrowsRightDisabled"
        @click.native="clickNextDebounced"
      />
    </div>
  </div>
</template>

<script>
import LeftArrow from './carousel-arrow-left';
import RightArrow from './carousel-arrow-right';
import CarouselEventHadler from './carousel-event';
import device from '../../service/device-service.js';
import debounce from 'lodash/debounce'; //https://lodash.com/docs/4.17.10#debounce

export default {
  name: 'CaruselSlider',
  components: {
    LeftArrow,
    RightArrow,
    CarouselEventHadler,
  },
  props: {
    componentId: {
      type: [Number, String, null],
      default: null,
    },
    row: {
      type: Number,
      default: 1,
    },
    column: {
      type: Number,
      default: 1,
    },
    step: {
      type: Number,
      default: 1,
    },

    buffer: {
      type: Number,
      default: 0,
    },
    infinite: {
      type: [String, Number, Boolean],
      default: false,
    },
    items: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    autoplay: {
      type: [String, Boolean],
      default: false,
    },
    breakpoints: {
      type: [Array],
      default: () => {
        return [
          {
            width: 966,
            column: 6,
          },
          {
            width: 560,
            column: 5,
          },
          {
            width: 380,
            column: 4,
          },
        ];
      },
    },
    ratio: {
      type: Number,
      default: 16 / 9,
    },
    marginColumn: {
      type: Number,
      default: 8,
    },

    marginBottomRow: {
      type: Number,
      default: 0,
    },

    arrowsPlacement: {
      type: [String],
      default: 'side',
      validator: function (value) {
        let vals = ['bottom', 'top', 'side', 'left', 'right', 'none'];
        return vals.indexOf(value) !== -1;
      },
    },
    arrowTopCenter: {
      type: [String],
      default: 'image',
      validator: function (value) {
        let vals = ['image', 'item'];
        return vals.indexOf(value) !== -1;
      },
    },
    arrowColor: {
      type: [String],
      default: '#ffffff',
    },
    arrowHoverColor: {
      type: [String],
      default: '#1f55ff',
    },

    halfMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      offset: 0,
      translateX: -150,
      translateCalcX: -150,
      transition: 'transform 600ms ease',
      direction: 'next', //left,
      drag: false,
      diff: {
        x: 0,
        y: 0,
        dx: 0,
        dy: 0,
      },
      start: {
        x: 0,
        y: 0,
      },
      transformSwipe: 0,
      animatioId: 0,

      offsetItem: 0,
      visibleColumn: 0,
      hasVisiblePart: false,
      isRenderedItems: false,
      fixMobileVisibility: 1,
      currentStep: 0,
      autoplayId: 0,
      carouselId: Math.ceil(Math.random() * 10000000),
      AUTO_PLAY_TIME: 5 * 1000, // ms,
      componentBaseWidth: 1e10,
      slideWidth: 200,
      minSlideWidth: 75,
      halfModeLocal: this.halfMode,
    };
  },
  computed: {
    arrowsColor() {
      return {
        hoverColor: this.arrowHoverColor ? this.arrowHoverColor : '',
        color: this.arrowColor ? this.arrowColor : '',
      };
    },
    itemsLen() {
      return this.items.length;
    },
    maxVisiblesItems() {
      let norm = this.hasVisiblePart ? 1 : 0;
      return (this.visibleColumn - norm) * this.row;
    },
    isLessAsMaxVisibles() {
      return this.itemsLen <= this.maxVisiblesItems;
    },
    arrowsLeftDisabled() {
      // let isLessAsMaxVisibles = ;
      let isFirstItemAsFirstVisible =
        !this.infinite &&
        this.offsetItem >= 0 &&
        this.offsetItem < this.maxVisiblesItems;
      return isFirstItemAsFirstVisible || this.isLessAsMaxVisibles;
    },
    arrowsRightDisabled() {
      let isLastItemVisible =
        !this.infinite &&
        this.offsetItem >= this.itemsLen - this.maxVisiblesItems &&
        this.offsetItem < this.itemsLen;
      return isLastItemVisible || this.isLessAsMaxVisibles;
    },

    autoplayTime() {
      return this.currentStep * this.AUTO_PLAY_TIME;
    },

    styleSlide() {
      return {
        width: this.slideWidth + 'px',
        'margin-left': this.marginColumn + 'px',
        'margin-right': this.marginColumn + 'px',
      };
    },

    topArrowPx() {
      let res =
        this.arrowTopCenter === 'image' && this.row === 1
          ? this.slideWidth / this.ratio / 2 + 'px'
          : '46%';
      return res;
    },

    defaultTransformSwipe() {
      if (this.isLessAsMaxVisibles) {
        return 0;
      }
      let result = -(
        this.slideWidth * this.bufferVal +
        this.bufferVal * this.marginColumn * 2
      );
      if (this.halfModeLocal) {
        return result + this.slideWidth / 2;
      }
      return result;
    },

    itemsRendered() {
      return this.visibleColumn + 2 * this.bufferVal;
    },

    bufferVal() {
      return this.buffer || this.currentStep || this.visibleColumn;
    },

    transitionType() {
      return 'virtual-slider-' + this.direction;
    },

    visiblePosition() {
      return this.correctArrayIndex(this.offsetItem) - 1;
    },

    clickNextDebounced() {
      return debounce(this.userClickNext, 500, {
        leading: true,
        trailing: false,
      });
    },
    clickPrevDebounced() {
      return debounce(this.userClickPrev, 500, {
        leading: true,
        trailing: false,
      });
    },
  },
  mounted() {
    this.handlerSize();
    this.$bus.$on('resizeWindow', this.handlerSizeDebounce);
    document.addEventListener('visibilitychange', this.setAutoPlay);
    this.$bus.$on(this.carouselId + '_click', this.clickArrow);
  },
  beforeDestroy() {
    clearTimeout(this.autoplayId);
    document.removeEventListener('visibilitychange', this.setAutoPlay);
    this.$bus.$off('resizeWindow', this.handlerSizeDebounce);
    this.$bus.$off(this.carouselId + '_click', this.clickArrow);
  },
  methods: {
    isElementInviewport() {
      let rect = this.$el.getBoundingClientRect();

      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight ||
            document.documentElement.clientHeight) /*or $(window).height() */ &&
        rect.right <=
          (window.innerWidth ||
            document.documentElement.clientWidth) /*or $(window).width() */
      );
    },

    calcSlideWidth() {
      let baseWhW = window.innerWidth
        ? window.innerWidth >= this.componentBaseWidth
          ? this.componentBaseWidth
          : window.innerWidth
        : null;
      let baseWhC = this.$el.clientWidth
        ? this.$el.clientWidth >= this.componentBaseWidth
          ? this.componentBaseWidth
          : this.$el.clientWidth
        : null;
      let padding = parseInt(window.getComputedStyle(this.$el).paddingLeft);
      let wh = (baseWhC || baseWhW || this.componentBaseWidth) - 2 * padding;

      // this.$logger.log(baseWhC, baseWhW, this.componentBaseWidth, wh, padding);
      let vsbcol = this.halfModeLocal
        ? this.visibleColumn + 1
        : this.visibleColumn;
      let wdt = Math.floor(
        (wh - (this.visibleColumn - 1) * 2 * this.marginColumn) / vsbcol,
      );
      wdt = wdt > this.minSlideWidth ? wdt : this.minSlideWidth;
      this.hasVisiblePart = !(wdt > this.minSlideWidth);
      // this.$logger.log(wdt, this.visibleColumn, this.fixMobileVisibility, this.marginColumn);

      this.$nextTick(() => {
        this.transformSwipe = this.defaultTransformSwipe;
      });
      this.slideWidth = wdt;
      return wdt;
    },
    userClickNext() {
      this.showItems(this.currentStep);
      this.$nextTick(() => {
        this.$bus.$emit(this.carouselId + '_isNextClicked', {
          position: this.visiblePosition,
          direction: 'none',
        });
      });
    },
    clickArrow(options = {}) {
      let position = options.pos || options.pos === 0 ? options.pos : -1;
      let direction =
        this.isLessAsMaxVisibles ||
        (options.direction && options.direction === 'none')
          ? 0
          : options.direction === 'prev'
          ? -1
          : 1;
      if (direction !== 0) {
        let correctoffset = this.correctArrayIndex(this.offsetItem);
        let checking =
          (correctoffset >= position && direction < 0) ||
          (correctoffset <= position && direction > 0);
        let diff = checking
          ? position - correctoffset
          : direction * this.itemsLen + position - correctoffset;
        let step =
          position > -1
            ? Math.ceil(diff / this.row)
            : direction * this.currentStep;
        this.showItems(step);
      } else {
      }
    },
    userClickPrev() {
      this.showItems(-this.currentStep);

      this.$nextTick(() => {
        this.$bus.$emit(this.carouselId + '_isPrevClicked', {
          position: this.visiblePosition,
          direction: 'none',
        });
      });
    },
    isMobile() {
      return device.isAnyMobile() || device.isMobileSize();
    },
    isEnteredList() {
      this.isRenderedItems = true;
    },
    handlerSize() {
      let col = this.column;
      let step = this.step;
      let baseWhW = window.innerWidth
        ? window.innerWidth >= this.componentBaseWidth
          ? this.componentBaseWidth
          : window.innerWidth
        : null;
      let baseWhC = this.$el.clientWidth
        ? this.$el.clientWidth >= this.componentBaseWidth
          ? this.componentBaseWidth
          : this.$el.clientWidth
        : null;
      let wh = baseWhC || baseWhW || this.componentBaseWidth;
      let halfMode = this.halfMode;
      this.breakpoints.forEach((el) => {
        // console.log(wh, el.width, col, el.column);
        if (wh < el.width) {
          col = el.hasOwnProperty('column') ? el.column : col;
          halfMode = el.hasOwnProperty('halfMode') ? el.halfMode : halfMode;
          step = el.hasOwnProperty('step') ? el.step : step;
        }
      });
      this.halfModeLocal = !!halfMode;
      this.visibleColumn = col ? col : this.column;
      this.fixMobileVisibility =
        this.visibleColumn === 1 || this.isMobile() ? 0 : 1;
      this.currentStep = step > this.visibleColumn ? this.visibleColumn : step;
      this.transformSwipe = this.defaultTransformSwipe;

      if (this.isLessAsMaxVisibles) {
        this.offsetItem = 0;
      }
      this.setAutoPlay();
      this.calcSlideWidth();
    },

    handlerSizeDebounce() {
      this.handlerSize();
    },

    setAutoPlay() {
      clearTimeout(this.autoplayId);
      if (this.autoplay && !this.isLessAsMaxVisibles && !document.hidden) {
        this.autoplayId = setTimeout(
          this.userClickNext,
          this.autoplayTime,
          this.currentStep,
          true,
        );
      }
    },

    correctArrayIndex(index) {
      let norm = index % this.itemsLen;
      let res =
        norm >= this.itemsLen
          ? norm - this.itemsLen
          : norm < 0
          ? this.itemsLen + norm
          : norm;
      return res;
    },
    getItemPosition(row, col, offset) {
      let correctOffset = this.isLessAsMaxVisibles
        ? this.correctArrayIndex(offset)
        : this.correctArrayIndex(offset - this.row * this.bufferVal);
      let pos = correctOffset + (this.row * col - (this.row - row) - 1); //correctOffset + this.row * col - (this.row - row) ;
      let outRange = pos >= this.itemsLen || pos < 0;
      let res =
        this.isLessAsMaxVisibles && outRange ? -1 : this.correctArrayIndex(pos); //(pos > this.itemsLen)? pos - this.itemsLen : (pos < 0)? this.itemsLen + pos: pos;
      return res;
    },
    getItemsByPosition(pos) {
      return this.items[pos];
    },
    getKeyValue(pos, offset) {
      let res = offset + pos; //  Math.round(Math.random() * 10) % 6 ;// + offset;
      return res;
    },

    showItems(step = this.currentStep, autoplay = false) {
      this.offsetItem = this.offsetItem + step * this.row;
      this.offset = this.offset + step;
      this.direction = step < 0 ? 'prev' : step > 0 ? 'next' : 'none';
      this.transformSwipe = this.defaultTransformSwipe;
      if (!autoplay) {
        this.setAutoPlay();
      }
    },
    checkPositionByTransformWidth(width) {
      if (!this.isLessAsMaxVisibles) {
        // no drag is no all items
        if (width > 0) {
          this.userClickNext();
        } else if (width < 0) {
          this.userClickPrev();
        } else {
          console.log('none');
        }
      }
    },
    onMouseDown(event) {
      this.drag = !this.drag;
      if (this.drag) {
        let elm = this.$el.getElementsByClassName('carusel-transition')[0];
        elm.classList.add('no-select');
        // event.preventDefault();
        event.stopPropagation();
        this.diff = {
          x: 0,
          y: 0,
          dx: 0,
          dy: 0,
        };
        this.start.x =
          event.type === 'touchstart'
            ? event.touches[0].clientX
            : event.clientX;
        this.start.y =
          event.type === 'touchstart' ? event.touches[0].pageY : event.pageY;
      }
    },

    onMouseUp(event) {
      if (this.drag) {
        let half = 20;
        if (Math.abs(this.diff.x) > half) {
          event.preventDefault();
          event.stopPropagation();
          this.endSwipe();
        }
      }
    },
    onMouseMove(event) {
      if (this.drag) {
        let cPosX =
          event.type === 'touchmove' ? event.touches[0].clientX : event.clientX;
        let cPosY =
          event.type === 'touchmove' ? event.touches[0].pageY : event.pageY;

        this.diff.dx = this.diff.x;
        this.diff.dy = this.diff.y;

        this.diff.x = this.start.x - cPosX;
        this.diff.y = this.start.y - cPosY;

        // NOTE: slide duration
        let half = 20; // this.slideWidth >> 3;
        if (Math.abs(this.diff.x) > half) {
          event.preventDefault();
          event.stopPropagation();
          this.endSwipe();
        } else {
          // this.transformSwipe = this.defaultTransformSwipe - this.diff.x;
        }
      }
    },

    endSwipe() {
      let elm = this.$el.getElementsByClassName('carusel-transition')[0];
      elm.classList.remove('no-select');
      // event.preventDefault();
      // event.stopPropagation();

      this.transformSwipe = this.defaultTransformSwipe;
      this.drag = false;
      this.checkPositionByTransformWidth(this.diff.x);
    },
  },
};
</script>

<style lang="scss">
@import './_carousel-slider';
</style>
