var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pv-scroll',{class:{ 'no-scroll': _vm.noScroll },attrs:{"is-loading":_vm.isLoaded,"is-element":false},on:{"bottom":_vm.bottom}},[_c('transition-group',{attrs:{"name":"stagged-area","appear":true,"appear-active-class":"animated fadeInUp","enter-active-class":"animated fadeInUp","leave-active-class":"animated fadeOutDown","tag":"div"}},_vm._l((_vm._areas),function(area,index){return _c('div',{key:("key-" + index),staticClass:"area-block"},[(area.aclass === 'section_ads' || _vm.checkBanner(area))?_c('dfp',{staticClass:"dfp-block",attrs:{"area":area,"placement":'section'}}):_vm._e(),(_vm.checkBanner(area, 'top'))?_c('dfp',{staticClass:"dfp-block",attrs:{"area":area,"placement":"top"}}):_vm._e(),(area.aclass === 'section_social')?_c('social-block',{attrs:{"area":area}}):(area.aclass === 'section_internal' && _vm.page !== 'article')?_c('internal-carousel',{attrs:{"area":area,"ordered":true}}):(
          area.aclass === 'section' && !area.box && _vm.page !== 'article'
        )?_c('pro-carousel',{attrs:{"area":area}}):(area.aclass === 'section_promo')?_c('promo',{attrs:{"area":area}}):(area.aclass === 'epg')?_c('live-epg',{attrs:{"area":area}}):(area.aclass === 'site_header' && _vm.page === 'show')?_c('show-profile',{attrs:{"area":area}}):(area.aclass === 'live_player' && _vm.page === 'live')?_c('live-player',{attrs:{"area":area}}):(
          area.aclass === 'video_flow' ||
          (area.aclass === 'video' && _vm.page === 'video')
        )?_c('media-player',{attrs:{"area":area,"extended":_vm.page === 'video'}}):(area.aclass === 'video' && _vm.page === 'home')?_c('vod',{attrs:{"area":area}}):_vm._e(),(_vm.checkBanner(area, 'bottom'))?_c('dfp',{staticClass:"dfp-block",attrs:{"area":area,"placement":"bottom"}}):_vm._e(),(
          area.itype === 'item_mov_vod' || area.itype === 'item_mov_episode'
        )?_c('media-player',{attrs:{"area":area,"is-one":true}}):(
          ['category_head', 'category_series_head'].includes(area.itype)
        )?_c('category-title',{attrs:{"area":area}}):(
          [
            'area_grid',
            'section',
            'category',
            'section_channels',
            'category_series',
            'section_link' ].indexOf(area.itype) > -1
        )?_c('internal-carousel',{attrs:{"area":area,"ordered":true,"reversed":false}}):(['section_season'].indexOf(area.itype) > -1)?_c('internal-carousel',{attrs:{"area":area,"ordered":true,"reversed":true}}):(['section_link_directives'].indexOf(area.itype) > -1)?_c('directive-carousel',{attrs:{"area":area}}):(area.itype === 'item_live')?_c('live-epg',{attrs:{"area":area}}):(area.itype === 'banner_group')?_c('banner',{attrs:{"area":area}}):(area.itype === 'item_app')?_c('app-area',{attrs:{"area":area}}):_vm._e()],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }