import { vapp } from 'src/main';


import Config from 'src/service/config';
import { amsClient } from '../../service/ams';
import { clone } from 'lodash';

let state = () => {
  return {
    favorites: {}
  };
};

let getters = {
  isFavoriteItem(state) {
    return ({ item_id, itype }) => {
      return state.favorites && state.favorites[itype] && state.favorites[itype].indexOf(item_id) !== -1;
    };
  }
};

let mutations = {
  setFavorites(state, values) {
    state.favorites = values;
  }

};

let actions = {
  onSync({ state, dispatch, getters, commit }, data) {
    let favorites = clone(amsClient.get('stock.favorites'));
    commit('setFavorites', favorites);
  },
  clean({ state, dispatch, getters, commit }, data) {
    commit('setFavorites', {});
  },
  toggleFavorite({ state, dispatch, getters, commit }, { operation, item_id, itype }) {
    let promise;
    if (operation === 'stop') {
      promise = amsClient.stock.stopFavorite(itype, item_id);
    } else {
      promise = amsClient.stock.startFavorite(itype, item_id);
    }
    promise.then((response) => {
      let favorites = clone(amsClient.get('stock.favorites'));
      commit('setFavorites', favorites);
      if (vapp.$stats) {
        vapp.$stats.send(operation === 'stop' ? 'unfavorite_item' : 'favorite_item', {
            item_id: item_id,
            itype: itype
          }
        );
      }
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
