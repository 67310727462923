<script>
  import isExternalLink from 'src/utils/isExternalLink.js';

  export default {
    name: 'LinkWrapper',
    functional: true,
    props: {
      link: {
        default: null,
        required: true,
      },
    },
    render(createElement, context) {
      let link = context.props.link;
      if (!link) {
        return createElement(
          'div',
          Object.assign(context.data, {
            nativeOn: context.listeners,
          }),
          context.children,
        );
      } else if (isExternalLink(context.props.link)) {
        let attrs = Object.assign(
          {
            href: context.props.link,
            target: 'blank',
          },
          context.data.attrs || {},
        );
        return createElement(
          'a',
          Object.assign(context.data, {
            attrs: attrs,
            on: context.listeners,
          }),
          context.children,
        );
      } else if (link === 'exclusive'){
        return createElement(
          'div',
          context.data,
          context.children,
        );
      } else {
        let attrs = Object.assign(
          {
            to: context.props.link,
          },
          context.data.attrs || {},
        );
        return createElement(
          'router-link',
          Object.assign(context.data, {
            attrs: attrs,
            nativeOn: context.listeners,
          }),
          context.children,
        );
      }
    },
  };
</script>
