<template>
  <div
    class="accept-wrap animated fadeIn p-4 "
    @click.stop
  >
    <img
      :src="require('src/assets/ais-logo/ais_play.png')"
      alt="AIS PLAY"
      height="100%"
      width="100%"
      class="ais-logo"
    >
    <h3 class="accept-title">
      <span v-t="{ path: 'BLOCK.ACCEPT_TITLE' }" />
    </h3>
    <div>
      <div class="accept-description">
        <div v-t="{ path: 'BLOCK.ACCEPT_PARAGRAPH1' }" />
        <br>
        <div>
          <span v-t="{ path: 'BLOCK.ACCEPT_PARAGRAPH2_1' }" />
          <a
            v-if="isTerms"
            class=""
            @click="showPdpaPopup('terms_and_conditions')"
          >
            <span
              v-t="{ path: 'BLOCK.TERMS_SERVICE' }"
              class="accept-description-link"
            />
          </a>
          <span
            v-if="isTerms && isPrivacy"
            v-t="{ path: 'BLOCK.ACCEPT_AND' }"
          />
          <a
            v-if="isPrivacy"
            @click="showPdpaPopup('privacy_notice')"
          >
            <span
              v-t="{ path: 'BLOCK.PRIVACY_POLICY' }"
              class="accept-description-link"
            />
          </a>
          <span v-t="{ path: 'BLOCK.ACCEPT_PARAGRAPH2_2' }" />
        </div>
      </div>
    </div>
    <button
      type="button"
      class="accept-btn"
      @click.stop="accept()"
    >
      <span
        class="d-block text-truncate align-middle w-100"
      >
        <span v-t="{ path: 'BLOCK.ACCEPT' }" />
      </span>
    </button>
  </div>
</template>

<script>
  import { vapp } from '@/main';
  import { amsClient } from '../../../service/ams';
  import { localStore } from 'vimmi-web-utils/esm/localStore';
  import Auth from '../../../service/authService';
  import Config from '@/service/config';

  export default {
    name: 'AcceptPopup',
    data: function() {
      return {
        consentDtypes: [],
        versions: [],
        docVersion: '',
        isPrivacy: false,
        isTerms: false,
      };
    },
    mounted() {
      document.querySelector('.auth-container ').style.display = 'block';
      document.body.style.overflowY = 'hidden';
      document.documentElement.style.overflowY = 'hidden';
      document.body.style.position = 'fixed';
      document.documentElement.style.position = 'fixed';
      vapp.$isPdpaPopup = true;
      Config.loaded(() => {
        // to do: optimization code below
        if (Auth.isAuth() && !Auth.isGuest()) {
          this.consentDtypes = amsClient.get('user.consent_dtypes') || [];
          this.isPrivacy = this.consentDtypes.includes('privacy');
          this.isTerms = this.consentDtypes.includes('terms');
          // this.getDocVersion();
          this.getVersions();
        } else {
          amsClient.callAms(`/docs_version/`, { cache: false }).then((data) => {
            this.isPrivacy = data.info.active.privacy;
            this.isTerms = data.info.active.terms;
            // this.getDocVersion();
            this.getVersions();
          });
        }
      });
    },
    destroyed() {
      document.body.style.overflowY = 'auto';
      document.documentElement.style.overflowY = 'auto';
      document.body.style.position = 'static';
      document.documentElement.style.position = 'static';
      vapp.$isPdpaPopup = false;
    },
    methods: {
      getVersions() {
        if (this.isPrivacy) {
          amsClient.callAms(`/get_doc/privacy/`, { cache: false }).then((data) => {
            this.versions['privacy'] = data.head.full_version;
            this.docVersion = data.head.full_version;
          });
        }
        if (this.isTerms) {
          amsClient.callAms(`/get_doc/terms/`, { cache: false }).then((data) => {
            this.versions['terms'] = data.head.full_version;
            this.docVersion = data.head.full_version;
          });
        }
        if (this.isPrivacy && this.isTerms) {
          this.docVersion = '';
        }
      },

      accept() {
        if (Auth.isGuest() || !Auth.isAuth()) {
          let consentDtypes = [];
          if (this.isPrivacy) {
            localStore.set(`privacy_version_${Auth.get('authInfo.device_id')}`, this.isPrivacy);
            consentDtypes.push('privacy')
          }
          if (this.isTerms) {
            localStore.set(`terms_version_${Auth.get('authInfo.device_id')}`, this.isTerms);
            consentDtypes.push('terms')
          }
          localStore.set(`consent_${Auth.get('authInfo.device_id')}`, amsClient.get('user.id'));
          for (let i = 0; i < consentDtypes.length; i++) {
            this.$stats.send('legal_terms_accepted', {
              doc_version: this.versions[consentDtypes[i]],
              content_title: consentDtypes[i],
              acceptance_time: Date.now() / 1000,
              doc_language: localStore.get('lang') || 'tha',
            });
          }
        } else {
          amsClient.account.acceptTerms(this.docVersion).then((data) => {
            for (let i = 0; i < this.consentDtypes.length; i++) {
              this.$stats.send('legal_terms_accepted', {
                doc_version: this.versions[this.consentDtypes[i]],
                content_title: this.consentDtypes[i],
                acceptance_time: Date.now() / 1000,
                doc_language: localStore.get('lang') || 'tha',
              });
            }
          });
        }
        vapp.$bus.$emit('toggleAuthPopup', {
          login_type: true,
          accept: false,
        });
        vapp.$fungusLogin = false;
      },
      showPdpaPopup(docType) {
        document.querySelector('.auth-container ').style.display = 'none';
        this.$router.push({
          path: `/${docType}`,
          query: { q: 'accept' },
        });
      },
    },
  };
</script>

<style lang="scss">
  @import 'accept-popup';
</style>
