import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { localStore } from "vimmi-web-utils/esm/localStore";
import Config from 'src/service/config';

import lang_en from './eng';
import lang_th from './tha';

Vue.use(VueI18n);

export const messages = {
  eng: lang_en,
  tha: lang_th,
};

//?lang=en
let langUrl = location.href.match('[\\?&]lang=([^&#s]*)');
let lang =
  langUrl && langUrl.length && langUrl[1]
    ? langUrl[1]
    : localStore.get('lang')
    ? localStore.get('lang')
    : Config.get('languages.default');

let i18n = new VueI18n({
  locale: lang, // set locale
  fallbackLocale: 'eng',
  messages, // set locale messages
});

export default i18n;
