<!---
<template>
  <section class="video-container">
    <vue-headful
      :title="metadata.title"
      :description="metadata.description"
      :image="metadata.image"
      :url="metadata.url"
      :keywords="metadata.keyword"
      :head="metadata.head"
    />
    <loader v-if="loading"/>
    <div
      v-else
      class="container"
    >
      <div class="row">
        <areas
          class="col-xs-12 col-lg-9 px-0"
          :areas="areas"
          :page="'video'"
          :branding-ads="pageAds"
        />
        <related-list
          class="col-xs-12 col-lg-3 px-lg-0"
          :link="relatedLink"
        />
      </div>
    </div>
  </section>
</template>

<script>
  import Areas from 'src/components/areas/areas';
  import Loader from 'src/components/loader/loader';

  import filtersImage from 'src/filters/normalizeImg';
  import JsonLD from 'src/service/jsonld';
  import truncateToNumber from 'src/utils/truncateSymbolsToNumber.js';
  import { escapeStringJS } from 'src/utils/escape';
  import normaliseMetaTags from 'src/utils/normaliseMetaTags';

  import RelatedList from 'src/components/related-list/related-list.vue';

  export default {
    name: 'VideoContainer',
    components: {
      Areas,
      Loader,
      RelatedList
    },
    data() {
      return {
        areas: [],
        loading: true,
        reload: true,
        head: {},
        currentPage: '',
        pageAds: {},
        reloadComponent: false,
        timeoutID: 0,
        metadata: {}
      };
    },
    computed: {
      relatedLink() {
        // https://ais-qa-app.demo-vidnt.com/recommend/59a83976aae7311ac477815b/default/
        if (
          this.head &&
          this.head.genre_code &&
          this.head.genre_code.length > 0
        ) {
          return `/recommend/${this.head.genre_code[0]}/default/`;
        } else if (
          this.areas &&
          this.areas.length &&
          this.areas[0].length &&
          this.areas[0][0].genre_code &&
          this.areas[0][0].genre_code.length
        ) {
          return `/recommend/${this.areas[0][0].genre_code[0]}/default/`;
        } else {
          return `/recommend/default/default/`;
        }
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.loading = true;
        vm.getData(to);
      });
    },
    beforeRouteUpdate(to, from, next) {
      JsonLD.pop();
      JsonLD.pop();

      this.loading = true;
      this.$logger.log(`Change path from`, from, ' to ', to);
      if (to.path === from.path) {
        this.getItem(to.query.vid);
      } else {
      this.getData(to);
      }
      // if (this.reloadComponent) {

      // }
      // this.reloadComponent = true;
      next();
    },
    created() {},
    mounted() {
      this.$bus.$off('setCurrentPage', this.event_setCurrentPage);
      this.$bus.$on('setCurrentPage', this.event_setCurrentPage);
    },
    beforeDestroy() {
      JsonLD.pop();
      JsonLD.pop();
      this.$bus.$off('setCurrentPage', this.event_setCurrentPage);
    },
    methods: {
      getItem(id) {
        this.$cache({url: `/get_item/${id}/`}).then (response => {
          this.$nextTick( () => {
            this.head = response.data.head;
            this.$set(this.areas, 0, this.head);
            this.loading = false;
          });
        });
      },

      event_setCurrentPage(el) {
        // el -path to page
        let page = el && el.link ? el.link : null;
        if (page && this.currentPage !== link) {
          this.currentPage = page;
          this.reloadComponent = false;
          this.$bus.$emit('refreshDfpSlots');
          this.$router.push({
            path: page,
            query: Object.assign({}, this.$router.currentRoute.query || {})
          });
          this.changeMeta(el);
          setTimeout(() => {
            this.fillHeader();
            this.addSchemaOrg(el);
            this.reloadComponent = true;
          }, 0);
        }
      },
      changeMeta(el) {
        // Keywords - the same
        if (el) {
          this.head.metadata.title = el.title ? el.title : null;
          this.head.metadata.description = el.description ? el.description : null;
          this.head.metadata.poster = el.poster ? el.poster : null;
        }
      },

      addSchemaOrg(item) {
        if (
          item &&
          item.age_restriction &&
          item.age_restriction !== 'AP' &&
          item.age_restriction !== 'None'
        ) {
          JsonLD.update();
          // NOTE: need for correct schema org
          JsonLD.push({});
          JsonLD.push({});
          console.warn(`Item is restricted, no markup aviable.`);
          return;
        }
        // let breadcrump = {
        //   '@context': 'http://schema.org',
        //   '@type': 'BreadcrumbList',
        //   itemListElement: [
        //     {
        //       '@type': 'ListItem',
        //       position: 1,
        //       item: {
        //         '@id': window.location.origin + '/portal/',
        //         name: 'PROTV PLUS'
        //       }
        //     },
        //     {
        //       '@type': 'ListItem',
        //       position: 1,
        //       item: {
        //         '@id': window.location.domain + '/portal' + item.link,
        //         name: escapeStringJS(item.title)
        //       }
        //     }
        //   ]
        // };

        // JsonLD.push(breadcrump);

        let data = {
          '@context': 'http://schema.org',
          '@type': 'VideoObject',
          name: escapeStringJS(item.title),
          description: escapeStringJS(truncateToNumber(item.description, 300)),
          thumbnailUrl: [
            item.poster,
            filtersImage(item.poster, 'image', 'ED', 'ED'),
            filtersImage(item.poster, 'image', 'SD', 'SD'),
            filtersImage(item.poster, 'image', 'HD', 'HD')
          ],
          uploadDate: this.formatToISODate(item.published_at),
          duration: this.formatToISODuartion(item.duration),
          interactionCount: item.views,
          expires: this.formatToISODate(item.expired_at),
          regionsAllowed:
            item.geoblock && item.geoblock === 'yes'
              ? item.distribution_country
              : null,
          genre: item.genre && item.genre.length > 0 ? item.genre.join(',') : null
        };

        JsonLD.push(data);

        JsonLD.update();
      },

      formatToISODate(date) {
        return date ? date.replace(' ', 'T') + 'Z' : null;
      },

      formatToISODuartion(date) {
        let hours = Math.floor(date / 3600);
        let mins = Math.floor((date - hours * 3600) / 60);
        let secs = Math.floor(date - hours * 3600 - mins * 60);
        return `T${hours}H${mins}M${secs}S`;
      },

      fillHeader() {
        this.metadata = normaliseMetaTags(this.head.metadata);
      },

      getData(route) {
        let slug = null;
        let url = route.path;
        if (_isPreviewMode()) {
          url += (url.indexOf('?') === -1 ? '?' : '&') + 'preview=1';
        }

        this.$cache({
          url: url,
          config: {
            cm: {
              screen_id: url,
              screen_type: 'page',
              itype: 'item',
              content_title: route.name
            }
          }
        })
          .then(response => {
            // NOTE: need in case where we have data from cache
            this.$nextTick(() => {
              let err = response.data.error;
              if (!err) {
                this.head = response.data.head;
                if (
                  this.head.itype === 'item_mov_vod' ||
                  this.head.itype === 'item_mov_episode' ||
                  this.head.itype === 'item_live'
                ) {
                  this.areas = Object.assign([], [this.head]);
                } else {
                  this.$logger.log(response.data);
                  // this.head = response.data.items[0][0];
                  this.areas = Object.assign(
                    [],
                    [this.head, ...response.data.items]
                  );
                }
                this.fillHeader();
                // dfpService.setCurrentPageDfpContext(this.head.ads && this.head.ads.context ? this.head.ads.context : null);
                // if (["page"].indexOf(this.head.itype) !== -1) {
                //   dfpService.setCurrentPageDfpContext("video");
                // } else {
                // dfpService.setCurrentPageDfpContext(
                //   this.head.ads && this.head.ads.context
                //     ? this.head.ads.context
                //     : 'video'
                // );
                // this.pageAds = this.head.ads.placements[0];
                // this.$set(this.areas, '0', response.data.content.areas[0]);
                this.loading = false;
                if (this.$cm) {
                  this.$cm.trackAppEvent(this.$cm.APP_EVENTS.SCREEN_READY, {
                    screen_id: url,
                    screen_type: response.config.cm.screen_type,
                    content_title:
                      response.data.head.title ||
                      response.config.cm.content_title,
                    itype: response.config.cm.itype
                  });
                }
              } else {
                if (typeof err === 'string') {
                  let prs = err.split(':');
                  if (prs[0] === '301') {
                    let url = prs[1] || '/';
                    let err = prs[2] || 'Content not found';
                    if (this.$global) {
                      this.$global.set('redirect', {
                        code: prs[0],
                        url: url,
                        err: err
                      });
                    }
                    this.$router.push({ path: url });
                  }
                }
              }
            });
          })
          .catch(err => {
            if (
              err &&
              err.response &&
              err.response.data &&
              err.response.data.error
            ) {
              let errTxt = err.response.data.error;
              if (typeof errTxt === 'string') {
                let prs = errTxt.split(':');
                if (prs[0] === '301') {
                  let url = prs[1] || '/';
                  let err = prs[2] || 'Content not found';
                  if (this.$global) {
                    this.$global.set('redirect', {
                      code: prs[0],
                      url: url,
                      err: errTxt
                    });
                  }
                  this.$router.push({ path: url });
                  return;
                }
              }
            }

            this.$plError.setError(err);
            this.$router.replace({
              path: '/error'
            });
          });
      }
    }
  };
</script>

<style lang="scss">
</style>
-->

<template>
  <section class="video-container">
    <vue-headful
      :title="metadata.title"
      :description="metadata.description"
      :image="metadata.image"
      :url="metadata.url"
      :keywords="metadata.keyword"
      :head="metadata.head"
    />
    <loader v-if="loading" />

    <div v-else class="container-fluid">
      <div class="row">
        <!-- {{ areas }} -->
        <areas
          class="col-12 px-0 mb-3 mb-lg-0"
          :areas="areas"
          :page="'video'"
          :branding-ads="pageAds"
        />
        <!-- <related-list
          v-if="relatedLink"
          class="col-12 col-lg-3 mb-3 mb-lg-0"
          :link="relatedLink"
        /> -->
      </div>
    </div>
  </section>
</template>

<script>
  import PageWithRelatedTemplate from 'src/views/PageWithRelated';
  import { amsClient } from '../../service/ams';

  export default {
    name: 'CategoryPage',
    extends: PageWithRelatedTemplate,
    methods: {
      getItem(id) {
        amsClient.callAms(`/get_item/${id}/`, { cache: false }).then((data) => {
          this.$nextTick(() => {
            this.head = data.head;
            this.$set(this.areas, 0, this.head);
            this.loading = false;
          });
        });
      },
    },
  };
</script>
