<template>
  <!-- <div class="page-container"> -->
  <section class="page-container">
    <vue-headful
      :title="metadata.title"
      :description="metadata.description"
      :image="metadata.image"
      :url="metadata.url"
      :keywords="metadata.keyword"
      :head="metadata.head"
    />
    <loader v-if="loading" />
    <div
      v-else
      class="areas-container"
    >
      <areas
        :areas="areas"
        :page="headerPageType"
        :branding-ads="pageAds"
      />
    </div>
    <b-modal
      ref="error_modal"
      :title="modalTitle"
      hide-footer
    >
      <span v-t="'MODAL.PAGE.CONTENT'" />
    </b-modal>
  </section>
</template>

<script>
  import Config from 'src/service/config.js';
  import Areas from 'src/components/areas/areas';
  import Loader from 'src/components/loader/loader';
  import dfpService from 'src/service/dfpService';
  import JsonLD from 'src/service/jsonld';
  import { escapeStringJS } from 'src/utils/escape';
  import normaliseMetaTags from 'src/utils/normaliseMetaTags';
  import { ScreenReadyMixin, AuthVariableMixin } from 'src/plugins/mixin';
  import { getMetaIos } from 'src/utils/generateDeepLink';
  import { amsClient } from 'src/service/ams';
  import { memoryStore } from 'vimmi-web-utils/cjs/memoryStore';

  export default {
    name: 'PageContainer',
    components: {
      Areas,
      Loader,
    },
    mixins: [AuthVariableMixin, ScreenReadyMixin],

    data() {
      return {
        loading: true,
        areas: [],
        context: {},
        content: null,
        head: {},
        localContext: {},
        pageAds: {},
        currentPage: null,
        reloadComponent: true,
        metadata: {},
      };
    },
    computed: {
      headerPageType() {
        // if (this.head.template.includes('channel')) {
        //   return 'channel';
        // } else {
        //   return 'show';
        // }
        return 'section';
      },
      modalTitle() {
        return this.$t('MODAL.PAGE.TITLE');
      },
    },
    watch: {
      loading: function(val) {
        if (val === false) {

          this.setScreenReady(this.head, this.screenRequestTime);
        }
      },
    },
    mounted() {
      this.$bus.$on('loader', this.setLoading);
      this.$bus.$on('setCurrentPage', this.event_setCurrentPage);
    },
    beforeDestroy() {
      JsonLD.pop();
      this.$bus.$off('loader', this.setLoading);
      this.$bus.$off('setCurrentPage', this.event_setCurrentPage);
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.loading = true;
        vm.getData(to);
      });
    },
    beforeRouteUpdate(to, from, next) {
      // let prev = to && to.params && to.params.name ? to.params.name : null;
      // let curr =
      //   from && from.params && from.params.name ? from.params.name : null;
      // if (curr && prev && prev !== curr) {
      this.currentPage = null;
      JsonLD.pop();
      this.loading = true;
      this.getData(to);
      // }
      next();
    },
    // watch: {
    //   $route(to, from) {
    //     this.loading = true;
    //     this.getData(to);
    //   }
    // },
    methods: {
      loadBanners: dfpService.loadBanners,
      hasBanner: dfpService.hasBanner,
      getBanner: dfpService.getBanner,
      onChangedUserState(event) {
        this.areas = [];
        this.loading = true;
        this.getData(this.$route);
      },
      fillHeader() {
        // let data = this.head && this.head.seo_metadata?  this.head.seo_metadata : this.head;
        this.metadata = normaliseMetaTags(this.head);

        // this.head.title =
        //   this.head && this.head.metadata && this.head.metadata.title
        //     ? this.head.metadata.title
        //     : 'Show/Channel';
        // this.head.description =
        //   this.head && this.head.metadata && this.head.metadata.description
        //     ? this.head.metadata.description
        //     : `Show/Channel Page`;
        // this.head.keyword =
        //   this.head && this.head.metadata && this.head.metadata.keywords
        //     ? this.head.metadata.keywords.join(',')
        //     : `Show/Channel`;
        // this.head.image =
        //   this.head && this.head.metadata && this.head.metadata.poster
        //     ? this.head.metadata.poster
        //     : location.origin + '/portal/static/favicon/favicon.png';
        // this.head.url =
        //   this.head && this.head.metadata && this.head.metadata.url
        //     ? this.head.metadata.url
        //     : window.location.href;
        // this.head.type =
        //   this.head && this.head.metadata && this.head.metadata.type
        //     ? this.head.metadata.type
        //     : 'website';

        this.metadata.head = Object.assign(this.metadata.head, {
          'meta[name="prerender-status-code"]': { content: 200 },
          'meta[name="apple-itunes-app"]': { content: getMetaIos(this.head) },

          // 'meta[name="cXenseParse:url"]': { content: this.metadata.url }
        });
        // this.metadata.head = {
        //   // 'meta[property="og:type"]': { content: this.metadata.type },
        //   // 'meta[name="cXenseParse:pageclass"]': { content: 'frontpage' },
        //   // 'meta[name="cXenseParse:url"]': { content: this.metadata.url },
        //   'meta[name="prerender-status-code"]': { content: 200 }
        // };
      },

      addSchemaOrg(item) {
        let breadcrump = {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              item: {
                '@id': window.location.origin + '/portal/',
                name: 'PROTV PLUS',
              },
            },
            {
              '@type': 'ListItem',
              position: 2,
              item: {
                '@id': window.location.origin + '/portal' + item.link,
                name: escapeStringJS(item.title),
              },
            },
          ],
        };
        JsonLD.push(breadcrump);
        JsonLD.update();
      },
      getData(route) {
        Config.loaded(() => {
          const url = `/screen/${route.params.id}/`;
          this.screenRequestTime = this.setScreenRequest({
            id: route.params.id,
            itype: 'screen',
            link: `/screen/${route.params.id}/`,
          });
          amsClient.callAms(url)
            .then(data => {
              this.$nextTick(() => {

                this.setCurrentScreen(data.head);
                this.content = data.content;
                this.areas = data.items;
                data.head.cached = !!data.cached;
                this.head = data.head;
                this.setScreenResponse(this.head);
                this.fillHeader();
                this.addSchemaOrg(this.head);

                if (memoryStore.get('redirect')) {
                  let err = memoryStore.get('redirect');
                  this.showErrorModal(err.err, err.code);
                  memoryStore.set('redirect', null);
                } else {
                  this.showErrorModal(data.error);
                }

                this.loading = false;
              });
            })
            .catch(err => {
              this.$plError.setError(err, 'network');
              this.$router.replace({ path: '/error' });
            });
        });
      },

      showErrorModal(error_modal = null, code = null, redirect = null) {
        if (error_modal) {
          this.$refs.error_modal.show();
          code = 301;
          memoryStore.set('autoplay', false);
          memoryStore.set('close_backdrop', true);

        } else {
          memoryStore.set('autoplay', true);

        }
        let res = code ? { content: `${code}` } : { content: '200' };
        this.metadata.head = Object.assign(this.metadata.head, {
          'meta[name="prerender-status-code"]': res,
        });
        // ['meta[name="prerender-status-code"]'] = res;
        // this.$set(this.head, 'head', res);
      },

      event_setCurrentPage(el) {
        let page = el && el.link ? el.link : null;
        if (page && this.currentPage !== link) {
          let fn = this.currentPage ? 'push' : 'replace';
          this.$router[fn]({
            path: this.$router.currentRoute.path,
            query: Object.assign({}, this.$router.currentRoute.query || {}, {
              video_id: page,
            }),
          });
          this.currentPage = page;
        }
      },
    },
  };
</script>

<style lang="scss">
  @import './_Page';
</style>
