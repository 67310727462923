import { makeHash } from 'vimmi-web-utils/cjs/makeHash';
import { localStore } from 'vimmi-web-utils/esm/localStore';
import { doFetch } from 'vimmi-web-utils/cjs/doFetch';

export class FpTest {
  fpResult;
  env;
  udid;
  origUdid;

  async formatFpPayload() {
    const components = this.fpResult.components;
    let result = {};
    for (const componentsKey in components) {
      let value = undefined;
      let origVal = components[componentsKey].value;
      if (origVal !== undefined) {
        if (componentsKey === 'fontPreferences') {
          value = await this.makeSha(JSON.stringify(origVal));
        } else if (componentsKey === 'plugins') {
          value = (origVal.map((plugin) => {
            return plugin.name;
          })).join(', ');
        } else if (componentsKey === 'videoCard') {
          value = (origVal.vendor || '') + ', ' + (origVal.renderer || '');
        } else if (componentsKey === 'canvas') {
          result[`fp_canvas_text`] = await this.makeSha(origVal.text);
          result[`fp_canvas_geometry`] = await this.makeSha(origVal.geometry);
        } else if (Array.isArray(origVal)) {
          value = origVal.join(', ');
        } else if (origVal && typeof origVal === 'object') {
          value = await this.makeSha(JSON.stringify(origVal));
        } else {
          value = origVal;
        }
      }

      if (value !== undefined) {
        result[`fp_${componentsKey}`] = value;
      }
    }
    return result;
  }

  storeFpResult(fpResult) {
    this.fpResult = fpResult;
  }

  setEnv(env) {
    this.env = env;
  }

  setUdid(udid, origUdid) {
    this.udid = udid;
    this.origUdid = origUdid;
  }

  async makeSha(text) {
    const encoder = new TextEncoder();
    const data = encoder.encode(text);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  }

  async send(event, extraData = {}) {
    let identId = localStore.get('userIdentId');
    if (!identId) {
      identId = makeHash(32);
      localStore.set('userIdentId', identId);
    }

    const formattedPayload = await this.formatFpPayload();
    const data = {
      event,
      recordId: await this.makeSha(JSON.stringify(this.fpResult.components)), // <string>
      env: this.env, //<dev|prod>
      // userId: null, // <guest or auth user id>
      deviceId: this.udid, //<browser fingerprint>
      origDeviceId: this.origUdid, //<browser fingerprint>
      identId, //<random key>
      ts: Date.now(), //<timestamp>
      userAgent: navigator.userAgent, //<raw user agent>,
      // fp{name}: null,//<fingerprint payload value>
      ...formattedPayload,
      ...extraData,
    };
    // console.log('[data]', data);
    // console.log('[formattedPayload]', formattedPayload);
    console.log('[fpTest][send]', data);
    doFetch('https://th-bk-sila-amsbl01.ais-vidnt.com:5601', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).catch((e) => {
      console.warn(e);
    });
  }
}

const fpTest = new FpTest();
export default fpTest;