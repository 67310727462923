<template>
  <section class="home-container">
    <vue-headful
      :title="metadata.title"
      :description="metadata.description"
      :image="metadata.image"
      :url="metadata.url"
      :keywords="metadata.keyword"
      :head="metadata.head"
    />
    <loader v-if="!isAviable" />
    <areas v-else :areas="areas" :page="'home'" :branding-ads="pageAds" />
    <b-modal ref="error_modal" :title="modalTitle" hide-footer>
      <span v-t="'MODAL.PAGE.CONTENT'" />
    </b-modal>
  </section>
</template>

<script>
  import Areas from 'src/components/areas/areas';
  import Loader from 'src/components/loader/loader';
  import JsonLD from 'src/service/jsonld';
  import Config from 'src/service/config';
  import normaliseMetaTags from 'src/utils/normaliseMetaTags';
  import { ScreenReadyMixin, AuthVariableMixin } from 'src/plugins/mixin';
  import { getMetaIos } from 'src/utils/generateDeepLink';
  import { amsClient } from 'src/service/ams';
  import { memoryStore } from 'vimmi-web-utils/cjs/memoryStore';

  export default {
    name: 'HomeContainer',
    components: {
      Areas,
      Loader,
    },
    mixins: [AuthVariableMixin, ScreenReadyMixin],
    props: [],
    data() {
      return {
        // context: {},
        areas: [],
        head: {},
        ads: {},
        loading: true,
        pageAds: [],
        currentPage: null,
        reloadComponent: true,
        metadata: {},
      };
    },
    computed: {
      isAviable() {
        return !this.loading; // && this.context && this.context.conf;
      },
      modalTitle() {
        return this.$t('MODAL.PAGE.TITLE');
      },
    },
    watch: {
      isAviable: function(val) {
        if (val) {
          this.setScreenReady(this.head, this.screenRequestTime);
        }
      },
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.loading = true;
        vm.get2ndHome();
      });
    },
    beforeRouteUpdate(to, from, next) {
      let prev = to && to.params && to.params.name ? to.params.name : null;
      let curr =
        from && from.params && from.params.name ? from.params.name : null;
      if (curr && prev && prev !== curr) {
        this.currentPage = null;
        JSON.pop();
        this.loading = true;
        this.get2ndHome();
      }
      next();
    },
    mounted() {
      this.$bus.$on('loader', this.setLoading);
      this.$bus.$on('setCurrentPage', this.event_setCurrentPage);
      this.$bus.$on('re-load-ams-entry', this.get2ndHome);
    },
    beforeDestroy() {
      JsonLD.pop();
      this.$bus.$off('loader', this.setLoading);
      this.$bus.$off('setCurrentPage', this.event_setCurrentPage);
      this.$bus.$off('re-load-ams-entry', this.get2ndHome);
    },
    methods: {
      onChangedUserState(event) {
        this.areas = [];
        this.loading = true;
        this.getHomeData();
      },
      get2ndHome() {
        this.areas = [];
        this.loading = true;
        Config.loaded(() => {
          amsClient.callAms(Config.get('amsEntryPoint')).then((data) => {
            if (data.items.length) {
              this.getHomeData(data.items[0].link);
            } else {
              this.$router.replace({
                path: '/error',
              });
            }
          });
        });
      },
      getHomeData(url = `/screen/home_web/`) {
        //        let query =
        //              this.$route && this.$route.query && this.$route.query.video_id
        //                ? this.$route.query.video_id.split('/')
        //                : null;
        //        let id = query && query.length > 0 && query[4] ? query[4] : null;
        //        let part_url = id ? `?video_id=${id}` : '';

        if (_isPreviewMode()) {
          url += (url.indexOf('?') === -1 ? '?' : '&') + 'preview=1';
        }
        this.screenRequestTime = this.setScreenRequest({
          link: url,
          itype: 'screen',
        });
        amsClient
          .callAms(url)
          .then((data) => {
            this.areas = data.items;
            this.ads = {};

            data.head.cached = !!data.cached;
            this.head = data.head;
            //            this.$stats.set('current.screen', this.head);
            this.setCurrentScreen(this.head);
            // if(!this.head.cached) {
            this.setScreenResponse(this.head);
            // }
            // dfpService.setCurrentPageDfpContext(
            //   this.ads && this.ads.context ? this.ads.context : null
            // );
            this.loading = false;
            this.fillHeader();
            this.addSchemaOrg();
            this.pageAds =
              this.head && this.head.ads && this.head.ads.placements
                ? this.head.ads.placements[0]
                : {};
          })
          .catch((err) => {
            this.$plError.setError(err, 'network');
            this.$router.replace({ path: '/error' });
          });
      },
      fillHeader() {
        // let data = this.head && this.head.seo_metadata?  this.head.seo_metadata : this.head;

        this.metadata = normaliseMetaTags(this.head);
        this.metadata.head = Object.assign(this.metadata.head, {
          'meta[name="prerender-status-code"]': { content: 200 },
          'meta[name="apple-itunes-app"]': { content: getMetaIos(this.head) },
        });
      },

      showErrorModal(err, code) {
        if (err) {
          this.$refs.error_modal.show();
          code = 301;

          memoryStore.set('close_backdrop', true);
        }
        let res = code ? { content: `${code}` } : { content: '200' };
        this.metadata.head = Object.assign({}, this.metadata.head, {
          'meta[name="prerender-status-code"]': res,
        });
      },

      event_setCurrentPage(el) {
        let page = el && el.page ? el.page : null;
        if (page && this.currentPage !== page) {
          let fn = this.currentPage ? 'push' : 'replace';
          this.$router[fn]({
            path: this.$router.currentRoute.path,
            query: Object.assign({}, this.$router.currentRoute.query || {}, {
              video_id: page,
            }),
          });
          this.currentPage = page;
        }
      },
      addSchemaOrg() {
        let data = {
          '@context': 'http://schema.org',
          '@type': 'WebSite',
          url: location.origin,
          potentialAction: {
            '@type': 'SearchAction',
            target: location.origin + '/portal/search?q={search_term_string}',
            'query-input': 'required name=search_term_string',
          },
        };
        JsonLD.push(data);
        JsonLD.update();
      },
      setLoading(value = true) {
        this.loading = loading;
      },
    },
  };
</script>

<style lang="scss">
  @import './_Home';
</style>
