let DefaultConfig = {
    version: process.env.VUE_APP_PROJECT_VERSION.toString(),
    app_info: (process.env.VUE_APP_CUSTOMER + '-web').toUpperCase(),
    app_id: 'vimmi.web',
    api_version: '2.8.2',
    device_key: 'com.vimmi.ais.portal',
    secondLocation: false,
    thirdLocation: true,
    //  host: 'http://protv.vidnt.com',
    //  debugPlayer: false,
    env: 'prod', // env which come from AMS for player and analytics
    //  useGuestMode: false,
    amsEntryPoint: '/screen/main/',
    player: {
      // it's default player url, it will be changed when come conf from AMS
      url: location.protocol + '//dev-player.vidnt.com/',
      //    free_view_seconds: process.env.NODE_ENV === 'production' ? 20 : 60 * 5, //20 sec : 5min
      options: {
        width: '100%25',
        height: '100%25',
        showEmbedded: false,
        qualityChange: true,
        style_schema: 'ais',
        account: 'ais',
        fullScreen: true,
        lang: 'en',
      },
      timeoutChangingItems: 10 * 1000, // ms
    },
    dfp: {
      //    useContext: true,
      //    pid: '21663181702',
      // cmsid: '2472872',
      // video_ad:
      //   'https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&impl=s&gdfp_req=1&env=vp&output=vmap&unviewed_position_start=1',
      //
      // // &iu=/36564804/protvplus/Live/LiveVideoVast
      // video_live_ad:
      //   'https://pubads.g.doubleclick.net/gampad/live/ads?sz=640x480&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1'
      // old live ad link
      // video_live_ad: 'https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1'
    },
    analytics: {
      // catchMedia: {
      //   env: 'default',
      //   link: `${location.origin}${
      //     !process.env.BASE_URL ? '/' : process.env.BASE_URL
      //     }CMSDK-aisg-latest.min.js`,
      //   // TODO: for test cm
      //   // link: location.protocol + '//cmsdk-js.catchmedia.com/latest/atl/CMSDK.js',
      //   partner_id: 3073,
      //   app_code: 'AIS-WEB',
      //   allowGeoLocation: false,
      //   enableInbox: false, // TODO: currently false
      //   // sendAnalyticData: true
      // },
      defaultRelatedMethod: 'default',
    },
    languages: {
      default: 'tha',
      list: {
        eng: 'English',
      },
    },

    sdk: {
      fb: 'https://connect.facebook.net/en_US/sdk.js',
      tw: 'https://platform.twitter.com/widgets.js',
    },
    user_profile_settings: {
      forsed_logout_date: new Date(2018, 4, 22, 0, 0, 0), // 22.05.2018
      session_time: 30 * 24 * 60 * 60 * 1000, // 30 days in ms
      EPG_CACHE_TIME: 5 * 60, // 5 min in sec
    },
    auth: {
      // user: {
      //   forsed_logout_date: new Date(2018, 4, 22, 0, 0, 0), // 22.05.2018
      //   session_time: 30 * 24 * 60 * 60 * 1000 // 30 days in ms
      // },
      ams: {
        public_app_key: 'vimmiCB1D0336',
        auth_url: '/accounts/login/',
        account_sync: '/accounts/sync/',
        delete_url: '/accounts/delete/',
        logout_url: '/accounts/logout/',
        play_template: '/playtemplate/',
      },
      fungus: {
        //.... comes from AMS
      },
      sso: {
        auth_url: 'https://sso.protv.ro/api/login',
        register_url: 'https://sso.protv.ro/api/register',
        forgot_pass_url: 'https://sso.protv.ro/api/password/reset',
        delete_url: 'https://sso.protv.ro/api/user/delete',
        update_url: 'https://sso.protv.ro/api/user/edit',
        subscribe_url: 'https://sso.protv.ro/api/newsletter/subscribe',
        unsubscribe_url: 'https://sso.protv.ro/api/newsletter/unsubscribe',
        app_id: '5a29443d349df',
      },
      facebook: {
        url: 'https://sso.protv.ro/api/login/facebook',
        app_id: '2141706452741846', // '1147926948637494',
        // dev_app_id: '169743826985976'
      },
    },

    deep_link: {
      android: 'intent://vimmiapp.ais-vidnt.com/open/{{type}}/?id={{id}}#Intent;scheme=https;package=com.ais.mimo.AISPlay;end',
      link: `${window.location.origin}/sharepoint/open/{{type}}/{{id}}/`, // NOTE: / - have link at seft
      ios: 'vimmiapp://open/{{type}}/?id={{id}}',
    },
    components: {
      MP: {
        color: 'lightgreen',
        debug: true,
        // debug: process.env.NODE_ENV !== 'production' && debug
      },
      MPV: {
        color: 'green',
        debug: true,
        // debug: process.env.NODE_ENV !== 'production' && debug
      },
      AS: {
        color: 'pink',
        debug: true,
      },
    },
    fingerprintOpt: {
      fonts: { extendedJsFonts: true },
      excludes: {
        userAgent: true,
        pixelRatio: true,
        screenResolution: true,
        timezoneOffset: true,
        timezone: true,
        indexedDb: true,
        localStorage: true,
        sessionStorage: true,
        addBehavior: true,
        openDatabase: true,
        cpuClass: true,
        colorDepth: true,
        doNotTrack: true,
        availableScreenResolution: true,
        plugins: true,
        webgl: true,
        canvas: true,
        adBlock: true,
        hasLiedLanguages: true,
        hasLiedResolution: true,
        hasLiedOs: true,
        hasLiedBrowser: true,
        fonts: true,
        fontsFlash: true,
        audio: true,
        enumerateDevices: true,
      },
    },
  }
;

export default DefaultConfig;
