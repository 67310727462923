import AMSClient from 'ams-client-js/dist/ais-ams-client.cjs';
import Config from './config';
import { localStore } from 'vimmi-web-utils/esm/localStore';
import { deepMerge } from 'vimmi-web-utils/cjs/deepMerge';
import { isActiveFeature } from '@/plugins/feature-toggle';
import { visitorId } from 'vimmi-web-utils/cjs/visitorId';
import { makeHash } from 'vimmi-web-utils/cjs/makeHash';
import fpTest from '@/service/fpTest';
import { visitorIdTest } from 'vimmi-web-utils/cjs/visitorIdTest';
import { cookieSrv } from 'vimmi-web-utils/cjs/cookie';

let env = 'prod';
if (location.origin.indexOf('qa-app') !== -1) {
  env = 'qa';
} else if (location.origin.indexOf('stg-app') !== -1) {
  env = 'stg';
} else if (location.origin.indexOf('dev-trk') !== -1 || location.origin.indexOf('localhost') !== -1) {
  env = 'dev';
}
if (Config.get('dev_mode')) {
  env = 'dev';
}
fpTest.setEnv(env);

export const amsClient = new AMSClient({
  checkPageRefreshBeforeSync: 20,
  backend: location.host,
  platform: 'web',
  screenType: 'ss', // for 2ndLocation
  env: env,
  secondLocation: Config.get('secondLocation'),
  thirdLocation: Config.get('thirdLocation'),
  appInfo: {
    customer: process.env.VUE_APP_CUSTOMER,
    app_id: Config.get('app_id'),
    app_info: Config.get('app_info'),
    app_version: Config.get('version'),
    language: localStore.get('lang') || 'tha',
    device_key: Config.get('device_key'),
  },
  authInfo: {
    public_app_key: Config.get('auth.ams.public_app_key'),
    device_id: localStore.get('udid') || null,
  },
});


/** whe AMS conf loaded */
amsClient.on('conf', (amsConf) => {
  let conf = deepMerge(
    amsConf.portal || {},
    {
      languages: { list: amsConf.languages || {} },
    },
    { buh: amsClient.get('buh') },
    { guest_mode: amsConf?.app_conf?.WEB?.guest_mode },
  );
  Config.load(conf, amsClient);

  if (isActiveFeature('guest-users') && amsConf?.app_conf?.WEB?.guest_mode) {
    /** to login as a guest user */
    if (!amsClient.isAuth()) {
      amsClient.account.guestLogin({ amsLoginType: 'device' }).then((amsData) => {
        console.log('Login as a guest user');
        if (Config.get('fp_test')) {
          fpTest.send('guest_login', {
            userId: amsData.user.id,
          });
        }
      });
    }
  } else if (amsClient.isAuth() && !!amsClient.get('user.guest')) {
    amsClient.account.logout();
  }
});
const lastTestOrigUdid = localStore.get('fp_orig_udid') || cookieSrv.get('fp_orig_udid');
Promise.all([visitorIdTest(), visitorId(), amsClient.init()]).then((res) => {
  setTimeout(() => {
    if (Config.get('fp_test')) {
      /** for debug fingerprint generation */
      const fpTestResult = res[0];
      const fpTestUdid = fpTestResult.iosVisitorId || fpTestResult.visitorId;
      const fpTestOrigUdid = fpTestResult.visitorId;
      fpTest.storeFpResult(fpTestResult);
      fpTest.setUdid(fpTestUdid, fpTestOrigUdid);

      if (amsClient.get('user.id') && lastTestOrigUdid && lastTestOrigUdid !== fpTestOrigUdid) {
        fpTest.send('fp_regenerate', {
          userId: amsClient.get('user.id'),
        });
      }
    }
  });
  const udid = res[1];
  amsClient.conf({
    authInfo: {
      device_id: udid,
    },
  });
  if (!amsClient.get('extraData.temp_id')) {
    let randomString = makeHash(16);
    /** Temporal user ID. Mainly to use on events before user created. Should be sent with all events.  */
    amsClient.setExtraData('temp_id', `temp_${udid}_${randomString}`);
  }
});