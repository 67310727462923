<template>
  <section
    v-if="isAvailable"
    :id="getId"
    class="promo-container"
  >
    <div class="container">
      <h2 class="default-title text-uppercase">
        {{ title }}
      </h2>
    </div>
    <carusel-slider
      class="px-0 px-lg-3"
      :items="items"
      :row="row"
      :column="column"
      :infinite="true"
      :autoplay="auto"
      :step="step"
      :ratio="1"
      arrow-top-center="item"
    >
      <!--<div class="slick-item" :key="item.id" v-for="item in items">-->
      <template slot-scope="{item}">
        <promo-card :item="item"/>
      </template>
    </carusel-slider>
    <!-- </div> -->
  </section>
</template>

<script>
  import CaruselSlider from 'src/components/carousel/carousel-slider';
  import PromoCard from './promo-card';
  import { amsClient } from 'src/service/ams';

  export default {
    name: 'Promo',
    components: {
      CaruselSlider,
      PromoCard,
    },
    props: {
      area: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        loading: {},
        arrowColor: '#1f55ff',
        items: [],
        breakpoints: [
          {
            width: 967, // by less as lg -1024px, we use container with witdh 970px
            column: 3,
          },
          {
            width: 640,
            column: 2,
          },
        ],
      };
    },

    computed: {
      title() {
        return this.area.title;
      },
      // items() {
      //   return this.area.items || [];
      // },
      isAvailable() {
        return !this.loading && this.items.length > 0;
      },
      grid() {
        return ['1', '4', '4', 'manual'];
      },
      column() {
        return +this.grid[1];
      },
      row() {
        return +this.grid[0];
      },
      step() {
        return +this.grid[2];
      },
      auto() {
        return !!(this.grid[3] === 'auto');
      },
      getId() {
        return this.area.title.toLowerCase().replace(/\s/g, '');
      },
    },

    created() {
      this.loading = true;

      amsClient.callAms(this.area.link, { cache: false })
        .then(response => {
          this.loading = false;
          let data = response.data.items;
          let area = this.area.items || [];
          this.items = [...area, ...data];
        });
    },
  };
</script>

<style lang="scss">
  @import './_promo';
</style>
