<template>
  <div style="height: calc(100vh - 120px); margin-top: -10px">
    <iframe
      :src="weburl"
      width="100%"
      height="100%"
      allowfullscreen
      frameborder="0"
    />
  </div>
</template>

<script>
  import { amsClient } from '@/service/ams';

  export default {
    name: 'Webview',
    data() {
      return {
        dataHead: {},
        weburl: '',
      };
    },
    mounted() {
      let itemId = this.$route.params.id;
      this.getItem(itemId);
    },
    methods: {
      getItem(id) {
        amsClient.callAms(`/get_item/${id}/`, { cache: false }).then((data) => {
          console.log('FROM AMS', data);
          this.$nextTick(() => {
            this.dataHead = data.head;
            this.weburl = data.head.weburl;
          });
        });
      },
    },
  };

</script>

<style scoped>

</style>