import generateUrl from '@/utils/generateUrl';

export default (item, link = null) => {
  if (!item) {
    return '/';
  } else if(item.exclusive === true) {
    return 'exclusive'
  } else if(item.itype === 'screen_tv_ex') {
    return `/exclusive/${item.slug}/`
  } else if (item.itype === 'screen_settings') {
    return `/set`;
  } else if (item.itype === 'screen_tv') {
    return `/live/`;
  } else if (['item_live_virtual', 'item_live'].indexOf(item.itype) !== -1) {
    return `/live/?vid=${item.id}`;
  } else if (item.itype === 'item_app') {
    return (
      item.dl_web || item.schema_web || item.link
    );
  } else if (['item_mov_episode', 'section_season'].indexOf(item.itype) > -1) {
    let type = item.itype === 'item_mov_episode' ? 'series' : 'season';
    return `/serial/${type}/${item.id}`;
  } else if (item.itype === 'item_webview'){
    return `/webview/${item.id}`
  }
  else {
    return item.link;
  }
};


/**
 * @function _hasPageLink
 * @private
 * @param {Object} obj - Object of the video item.
 * @property {Object} obj.seo_metadata.page_link - Universal link for the item.
 * @return {String | Boolean} Item Route or false value.
 * */
function _hasPageLink({ seo_metadata: { page_link } = {} }) {
  return  page_link || false
}