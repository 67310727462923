/**
* @function _generateLink
* @private
* @param {Object} obj - Object of the video item.
* @property {String} obj.title - Eng title.
* @property {String} obj.alt_lang.tha.title - Thai title.
* @property {String} obj.id - Video item id. Mandatory property.
* @return {String} For examole: 'John-Wick-จอห์น-วิค-แรงกว่านรก-5ccc3c09aae73105b35549c4'
* */
export default function ({ title: engTitle, alt_lang: { tha: { title: thaTitle } = {} } = {}, id }) {
  if (!id) throw new Error('Id is required')
  const linkConstituents = [];
  const regexp = /[:/?#[\]@!$&'()*+,;=\s]/g
  const _replace = string => string.replace(regexp, '-')
  if (engTitle && thaTitle.length) {
    linkConstituents.push(_replace(engTitle))
  }
  if (thaTitle && thaTitle.length) {
    linkConstituents.push(_replace(thaTitle))
  }
  linkConstituents.push(id)

  return linkConstituents.join('-')
}