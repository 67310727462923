<template>
  <pro-carousel
    v-if="!loading"
    class="internal-section-component"
    :area="area"
    :items-prop="items"
  />
</template>

<script>
import ProCarousel from './pro-carousel';
import Auth from 'src/service/authService';

import { sessionStore } from 'vimmi-web-utils/cjs/sessionStore';
import { amsClient } from 'src/service/ams';

export default {
  name: 'InternalSection',
  components: {
    ProCarousel,
  },
  props: {
    area: {
      type: Object,
      required: true,
    },
    changeLink: {
      type: Boolean,
      required: false,
      default: true,
    },
    ordered: {
      type: Boolean,
      required: false,
      default: false,
    },
    reversed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      profile: 'none',
      genre: 'none',
      genre_name_storage: 'genre',
      link: this.area.link,
      template: this.area.link,
      loading: true,
      items: [],
    };
  },
  mounted() {
    this.$bus.$on('changedUserState', this.userChange);
    //      this.$bus.$on('userSuccessAuth', this.userChange);
    //      this.$bus.$on('userSuccessLogOut', this.userChange);
    this.getData();
  },
  beforeDestroy() {
    this.$bus.$off('changedUserState', this.userChange);
    //      this.$bus.$off('userSuccessAuth', this.userChange);
    //      this.$bus.$off('userSuccessLogOut', this.userChange);
  },
  methods: {
    getData() {
      this.profile = Auth.isAuth() ? Auth.get('user.id') : 'null';
      this.genre = sessionStore.get(this.genre_name_storage) || 'null';
      if (this.link && this.template && this.changeLink) {
        this.link = this.template.replace(`{UID}`, this.profile);
        this.link = this.link.replace(`{GENREID}`, this.genre);
        // this.link = this.template.replace(`{profile}`, this.profile);
        // this.link = this.link.replace(`{genre}`, this.genre);
        // let lk = this.link.split('/');
        // if (lk.length < 6) {
        //   lk[2] = this.genre;
        //   lk[3] = this.profile;
        //   this.link = lk.join('/') ;// this.link.replace('{genre}', this.genre).replace('{profile}', this.profile); //`/recomended/${this.genre}/${this.profile}/`//
        // } else {
        //   this.link = `/recommend/${this.genre}/${this.profile}/`;
        // }
      } //else {
      //this.link = `/recommend/${this.genre}/${this.profile}/`;
      //}

      amsClient
        .callAms(this.link, { cache: false })
        .then((data) => {
          this.loading = false;
          let ordered = this.ordered
            ? Object.assign([], data.items || [])
            : Object.assign(
                [],
                data.items.sort((a, b) => 0.5 - Math.random()) || [],
              );
          let reserveData = this.reversed ? ordered.reverse() : ordered;
          // console.log(data, reserveData, this.reversed);
          let area = this.area.items || [];
          this.items = [...area, ...reserveData];
        })
        .catch((error) => {
          console.error(error);
        });
    },

    userChange() {
      setTimeout(() => {
        this.getData();
      }, 300);
    },
  },
};
</script>

<style lang="scss">
</style>
