<template>
  <!--<div class="block" :class="{'active-block': item.next, sponsored: item.sponsored}">-->

  <div
    class="media-related-block"
    :class="{'media-related-block-active':item.active, 'next':item.next }"
    @click="setCurrentItem()"
  >
    <div
      class="media-related-block-thumbs "
      :class="{'live-item': ['item_live_virtual', 'item_live'].indexOf(item.itype) !== -1}"
    >
      <img
        class="media-related-block-thumbs-img"
        :alt="item.title "
        :src=" item.backdrop || item.poster | normalizeImg('thumb', 'ED')"
      >
      <!-- <v-lazy-image :intersection-options="{'rootMargin': '100px' }" class="media-related-block-thumbs-img" :alt="item.title " :src=" item.poster | normalizeImg('thumb', 'ED')" /> -->

      <!-- <div class="media-related-block-container"> -->
      <span
        v-if="item.active && ['item_live_virtual', 'item_live'].indexOf(item.itype) === -1"
        class="media-related-block-label-active"
        title="Now playing"
      >
        <span v-t="{path: 'VIDEO_COMPONENT.NOW_PLAYING'}"/>
      </span>
      <!--<span-->
      <!--v-if="item.next"-->
      <!--class="media-related-block-label-next "-->
      <!--title="Up Next"-->
      <!--&gt;-->
      <!--<span v-t="{path: 'VIDEO_COMPONENT.UP_NEXT'}" />-->
      <!--</span>-->
      <duration
        v-if="!item.active && ['item_live_virtual', 'item_live'].indexOf(item.itype) === -1"
        class="media-related-block-duration"
        :duration="item.duration"
      />
    </div>
    <!-- <span class="media-related-block-title" :title="item.title" v-ellipsisText="{content: item.title, resize: true}"></span> -->
    <trim-el
      class="media-related-block-title"
      :text="item.title"
      tag="span"
    />
  </div>

  <!--<span @click="setCurrentItem()" v-if="notReloadPage" class="playlist-item">-->
  <!--<div class="thumb" v-bg-image-js="{name: item.poster, type: 'thumb'}">-->
  <!--<Duration v-if="!(item.active || item.next)" class="mr-duration"-->
  <!--:duration="item.duration"></Duration>-->
  <!--<div v-else>-->
  <!--<div class="active-mprt" v-if="item.active">-->
  <!--<span>Now Playing</span>-->
  <!--</div>-->
  <!--<div class="active-mprt" v-else-if="item.next">-->
  <!--<span>Next Play</span>-->
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->
  <!--<h3 class="media-player-related-title" :class="{'active-title': item.next}"-->
  <!--v-ellipsisText="{content: item.title, resize: true}"></h3>-->
  <!--</span>-->
  <!--</div>-->
</template>

<script>
  import Duration from '../duration/duration';
  import TrimEl from 'src/components/common/trim-el';

  export default {
    name: 'MediaPlayerRelatedBlock',
    components: {
      Duration,
      TrimEl,
    },
    props: ['item', 'eventIdentifier', 'notReloadPage', 'index'],
    methods: {
      setCurrentItem() {
        this.$bus.$emit(this.eventIdentifier + '_changeCurrentVideo', {
          item: this.item,
          isPlayedItem: true,
          scrollToView: true,
        });
      },
    },
  };
</script>

<style lang="scss">
@import './_media-related-block';
</style>
