export default {
  // Home Page
  HEADER: {
    LOGIN: 'Login',
    PROFILE: 'Profile',
    ACCOUNT: 'Account',
    LOGOUT: 'Logout',
    SEARCH: 'Search',
  },

  // Show Page/ Video Page
  VIDEO_COMPONENT: {
    RELATED_LIST: 'Related items',
    NO_RELATED_LIST: 'No related items',
  },

  // Live Page
  EPG: {
    TV_SCHEDULE: 'TV Program Guides',
    TODAY: 'Today',
    TOMORROW: 'Tomorrow',
  },
  // Error Page
  ERROR: {
    BACK_HOME: 'Go to homepage',
    DISTRO_MSG_1: 'We are sorry that the operation could not be completed.',
    DISTRO_MSG_2: 'Please try again!',
    DISTRO_WAIT_MSG: 'Please wait a moment! <br> You are connecting to the server.',
    DISTRO_RETRY: 'Retry',
  },

  // Search Page
  SEARCH_PAGE: {
    RESULTS_FOR: 'Results for:',
    NOT_SELECTED: 'Not selected categories to view.',
    NOT_FOUND: 'Not found {name} with keywords:',
    LOAD_MORE: 'Load More',
  },

  // Dashboard
  BLOCK: {
    SETTINGS: 'Settings',
    BLOCK: {
      MY_PROFILE: "My Profile",
      MY_PREFERENCE: "My Preferences",
    },
    LANGUAGE: 'Language',
    AUDIO_LANGUAGE: 'Preferred audio language',
    TERMS_SERVICE: ' Terms and Conditions ',
    PRIVACY_POLICY: ' Privacy Notice ',
    WELCOME: 'Welcome',
    BACK: 'Back ',
    ACCEPT: 'Accept ',
    ACCEPT_TITLE: 'Terms and Conditions',
    ACCEPT_AND: 'and',
    ACCEPT_PARAGRAPH1: 'Welcome to the AIS PLAY.',
    ACCEPT_PARAGRAPH2_1: 'The application gathers all kinds of entertainment for everyone. The User should read and understand ',
    ACCEPT_PARAGRAPH2_2: ' before deciding to "Accept" our terms and continue using our AIS PLAY Service. If the User refuses to accept, it will lead to AIS PLAY Service denial.',
    LOGIN: 'Login',
    IMGUEST: 'I’m a Guest',
    SAVE: 'Save',
    LENGTH: 'Length:',
    YEAR: 'Year:',
    VIEWS: 'Views:',
    SEASONS: 'Seasons:',
    USER: {
      MY_ACCOUNT: {
        LABEL: 'My Account',
        OPEN: 'Open',
      },
      INTERNET_NUMBER: 'AIS Intenet number',
      CONTACT_NUMBER: 'Contact number',
    },
  },

  // Autorization form
  LOGIN: {
    LOGIN_TITLE: 'Login',
    LOGIN: 'Login',
    CANCEL: 'Cancel',
    WAIT: 'Please wait!',
    FUNGUS: 'Fungus',
    PASSWORD: 'Password',
    SING_IN: 'Login ',
    REMEMBER_ME: 'Remember me',
    PLEASE_FILL_IN_LOGIN: 'Please fill in the correct username!',
    PLEASE_FILL_IN_PASSWORD: 'Please fill in the correct password!',
    ACCOUNT_NOT_ACTIVE: 'This account has been disabled.',
    YOUR_PASSWORD_MUST:
      'Your password must be 6-20 characters long, contain letters and numbers, and must not contain spaces, special characters, or emajl.',
    SUCCESSFULLY_AUTHORIZED: 'You are successfully authorized!',
    AUTHENTICATION_DATA:
      'If you already have an account, you can use the same authentication data.',
  },

  GUEST_LOGIN: {
    TITLE: 'Login required. Do you want to login?',
    YES: 'Yes',
    NO: 'No',
  },

  VIDEO: {
    CONTENT_IS_RESTRICTED:
      'Please login to enjoy the best entertainment for all.',
  },
  MODAL: {
    PAGE: {
      TITLE: 'Content not found',
      CONTENT:
        'Selected content was removed from portal. Try to find some interesting content on another page.',
    },
  },

  SUBSRIPTION: {
    LABEL: 'Subscribe',
  },
};
