<template>
  <div
    v-if="ad"
    class="dfp-ad"
    @click.native="clickByBaner()"
  />
</template>

<script>
  import device from 'src/service/device-service';
  import dfpService from 'src/service/dfpService';

  export default {
    name: 'Dfp',
    props: {
      ads: {
        type: Object
      },
      area: {
        type: Object
      },
      placement: {
        type: String,
        default: 'bottom',
        validator: function(value) {
          // section - top or bottom
          let vals = [
            'section',
            'bottom',
            'top',
            'inline',
            'middle',
            'background'
          ];
          let pos = value.split('|');
          return vals.indexOf(pos[0]) !== -1;
        }
      }
    }, // ['ad', 'tempId', 'addSize', 'contextUrl'],
    data() {
      return {
        context: {},
        _divId: '',
        el: null,
        ad: {},
        slot: {}
      };
    },
    computed: {
      places() {
        if (this.placement === 'section') {
          return ['bottom', 'top'];
        } else {
          return [this.placement];
        }
      }
    },

    created() {
      this.deleteGroovskin();
      this.$bus.$on('refreshDfpSlots', this.refreshDfp);
      this.$bus.$on('resizeWindow', this.refreshDfp);
    },
    destroyed() {
      this.deleteGroovskin();

      this.$bus.$off('refreshDfpSlots', this.refreshDfp);
      this.$bus.$off('resizeWindow', this.refreshDfp);

      if (window.googletag) {
        window.googletag.destroySlots([this.slot]);
      }
    },
    mounted() {
      if (!this.$isPreviewMode) {
        if (this.area && !this.ads) {
          this.loadBanners(this.area);
          for (let i = 0; i < this.places.length; i++) {
            if ((this.hasBanner(this.places[i]), this.area)) {
              this.ad = this.getBanner(this.places[i], this.area);
              this.loadAd();
              break;
            }
          }
        } else {
          this.ad = this.ads;
          this.loadAd();
        }
      }

      // this.el = this.$el;
      //      contextService.get(this, this.contextUrl || null)
      //        .then((response) => {
      //          this.context = response.context;
      //        });

      //      this.$bus.$on('resizeWindow',  () => {
      //        let size = this.ad.size.split('x');
      //        let wh = Math.min(window.screen.width, window.innerWidth);
      //        this.el.style.overflowX = (size[0] < wh) ? 'hidden' : 'auto';
      //        this.el.style.overflowY = 'hidden';
      //        this.el.style.width = (size[0] < wh) ? size[0] + 'px' : (wh - 60) + 'px';
      //      });

      // window.addEventListener('resize',);
    },

    methods: {
      loadAd() {
        if (this.ad) {
          let sizes = [];

          if ((device.isAnyMobile() || device.isMobileSize()) && this.ad.mob) {
            sizes = this.ad.mob.split(',').map(el => {
              return el.split('x').map(el => +el);
            });
          } else if (this.ad.size && !device.isMobileSize()) {
            sizes = this.ad.size.split(',').map(el => {
              return el.split('x').map(el => +el);
            });
          }
          let id = device.isAnyMobile() || device.isMobileSize() ? this.ad.mob_id : this.ad.id;
          this._divId = 'div-gpt-ad-' + (Math.random() * 100000).toFixed();
          let dfpPath = dfpService.getAndFormatDfpPath(id);
          this.$el.id = this._divId;
          if (sizes && sizes.length > 0) {
            setTimeout(() => {
              let acceptedSizes = sizes;
              window.googletag.cmd.push(() => {
                this.slot = window.googletag
                  .defineSlot(dfpPath, acceptedSizes, this._divId)
                  .addService(window.googletag.pubads())
                  .setCollapseEmptyDiv(true);
                window.googletag.enableServices();
                window.googletag.display(this._divId);
              });
            }, 1000);
          }
        }
      },
      // TODO: check if works
      clickByBaner() {
        // if (this.$cm) {
        //   this.$cm.trackAppEvent(this.$cm.APP_EVENTS.BANNER_CLICK, {
        //     banner_id: this._divId,
        //     content_title: this.$route.name,
        //   });
        // }
      },
      refreshDfp() {
        if (window.googletag) {
          window.googletag.pubads()
            .refresh([this.slot]);
        }
      },
      deleteGroovskin() {
        let els = document.querySelectorAll('[id^=\'grsk\']');
        Array.prototype.forEach.call(els, function(el) {
          el.parentNode.removeChild(el);
        });
      },
      loadBanners: dfpService.loadBanners,
      hasBanner: dfpService.hasBanner,
      getBanner: dfpService.getBanner
    }
  };
</script>

<style lang="scss">
  @import './_dfp';
</style>
