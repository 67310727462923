import { localStore } from "vimmi-web-utils/esm/localStore";

export default class ErrorPlugin {
  constructor() {
    this.lastError = localStore.get('lastError');
  }
  
  getLastError() {
    return this.lastError;
  }
  
  setError(err = null, type = 'app') {
    if(err instanceof String) {
      this.lastError = {
        text: err,
        type: type,
        title: 'Error script',
        code: '999',
        stack: (new Error()).stack,
      };
    } else if(err.response && err.response.data && err.response.data.error) {
      this.lastError = {
        text: err.response.data.error,
        title: err.response.statusText,
        page: err.response.responseURL,
        type: type,
        code: err.response.status,
      };
    } else if(err.hasOwnProperty('request')) {
      this.lastError = {
        text: err.message,
        title: err.request.statusText,
        page: err.request.responseURL,
        type: type,
        code: err.request.status,
      };
    } else if(err instanceof Error) {
      this.lastError = {
        text: err.message,
        title: 'Exeption Error',
        code: '666',
        type: type,
        stack: err.stack,
      };
    } else {
      this.lastError = err;
      if(!this.lastError.stack) {
        this.lastError.stack = (new Error()).stack;
      }
      if(!this.lastError.type) {
        this.lastError.type = type;
      }
      
    }
    console.log(this.lastError);
    localStore.set('lastError', this.lastError);
  }
}
