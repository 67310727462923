<script>
  import Config from 'src/service/config.js';
  import PageWithRelatedTemplate from 'src/views/PageWithRelated';
  import { amsClient } from 'src/service/ams';
  import { memoryStore } from 'vimmi-web-utils/cjs/memoryStore';

  export default {
    name: 'SeriesPage',
    extends: PageWithRelatedTemplate,

    data() {
      return {
        areas: [],
        loading: true,
        reload: true,
        head: {},
        pageAds: {},
        reloadComponent: false,
        timeoutID: 0,
        metadata: {},
        currentVideo: null,
        isFirst: true,
      };
    },
    computed: {},

    mounted() {
      // this.$logger.log(this.$route);
    },

    beforeRouteUpdate(to, from, next) {
      this.loading = false;
      this.changeVideo(to.params.id);

      next();
    },

    methods: {
      event_setCurrentPage(newVideo) {

        Config.loaded(() => {
          let type = newVideo.itype === 'item_mov_episode' ? 'series' : 'season';

          let isReloadSeasons = false;
          if (
            this.currentVideo &&
            newVideo.series_pk !== this.currentVideo.series_pk
          ) {
            isReloadSeasons = true;
          }

          this.currentVideo = Object.assign({}, newVideo);
          this.$bus.$emit('refreshDfpSlots');
          this.isFirst = false;
          this.changeMeta(newVideo);
          if (
            this.$route.params.id !== newVideo.id ||
            this.$route.params.type !== type
          ) {
            let routerMet = this.isFirst ? 'replace' : 'push';
            this.$router[routerMet]({
              name: 'SeriesPage',
              params: {
                id: newVideo.id,
                type: type,
              },
            }).then(() => {
              if (isReloadSeasons) {
                this.loading = true;
                this.getData(this.$route);
              }
              this.fillHeader();
              this.addSchemaOrg(newVideo);
            });
          } else {
            if (isReloadSeasons) {
              this.loading = true;
              this.getData(this.$route);
            }
            this.fillHeader();
            this.addSchemaOrg(newVideo);
          }
        });
      },

      changeVideo(id) {
        Config.loaded(() => {
          let eventIdentifier = memoryStore.get('main_player');

          amsClient.callAms(`/get_item/${id}/`, { cache: false }).then((data) => {
            this.$bus.$emit(eventIdentifier + '_changeCurrentVideo', {
              item: data.head,
              isPlayedItem: true,
              scrollToView: false,
            });
          });
        });
      },

      getData(route) {

        Config.loaded(() => {
          let url =
            route.params.type === 'series'
              ? `/get_item/${route.params.id}/`
              : `/get_section/${route.params.id}/`;

          let promises = [];
          if (route.params.type === 'series') {
            promises.push(amsClient.callAms(url, { cache: false }));
            promises.push(
              amsClient.callAms(`/get_seasons/${route.params.id}/`, {
                cache: false,
              }),
            );
            this.isFirst = false;
          } else {
            promises.push(amsClient.callAms(url, { cache: false }));
          }
          Promise.all(promises)
            .then((response) => {

              this.$nextTick(() => {
                let err = response.some((data) => data.error);
                if (!err) {
                  this.head = response[0].head;

                  if (this.head.itype === 'item_mov_episode') {
                    let seasons =
                      response[1].head && response[1].head.seasons
                        ? response[1].head.seasons
                        : [];
                    this.setAreas(Object.assign([], [this.head, ...seasons]));
                    this.$set(this.head, 'id', this.head.id);

                    this.fillHeader();
                    this.loading = false;
                  } else {
                    let lastIndex = response[0].items.length - 1;
                    let lastItem = response[0].items[lastIndex];

                    if (lastItem) {
                      amsClient
                        .callAms(lastItem.seasons, { cache: false })
                        .then((data) => {
                          this.head = Object.assign({}, lastItem);
                          this.head.itype = `head-category`;

                          this.setAreas(
                            Object.assign([], [lastItem, ...data.head.seasons]),
                          );
                          this.$set(this.head, 'id', this.head.id);
                          this.fillHeader();
                          this.loading = false;
                        });
                    } else {
                      this.$plError.setError({
                        text: 'No series in season',
                        title: 'Not found',
                        code: 404,
                      });
                      this.$router.replace({
                        path: '/error',
                      });
                    }
                  }
                } else {
                  this.checkByRedirect(err);
                }
              });
            })
            .catch((err) => {
              if (
                err &&
                err.response &&
                err.response.data &&
                err.response.data.error
              ) {
                let errTxt = err.response.data.error;
                if (typeof errTxt === 'string') {
                  this.checkByRedirect(errTxt);
                  return;
                }
              }

              this.$plError.setError(err);
              this.$router.replace({
                path: '/error',
              });
            });
        });
      },
    },
  };
</script>
