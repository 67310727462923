export default class Configurable {
  constructor() {
    this._confData = {};
  }
  
  get data() {
    return this._confData;
  }
  
  get(key = null) {
    if(!key) {
      return null;
    }
    let chunks = key.split('.');
    if(chunks.length > 1) {
      let lastVal = this._confData;
      while(chunks.length) {
        lastVal = this.getRecursive(lastVal, chunks.shift());
      }
      return lastVal;
    } else {
      return this._confData[key];
    }
  }
  
  getRecursive(value, name) {
    return value && (value[name] !== null && value[name] !== undefined)
      ? value[name]
      : null;
  }
  
  set(key = null, value = null) {
    
    if(key) {
      let chunks = key.split('.');
      let confData = this._confData;
      let lastKey = key;
      if(chunks.length > 1) {
        while(chunks.length) {
          lastKey = chunks.shift();
          confData = this.setRecursive(confData, lastKey, chunks.length === 0);
        }
      }
      if(value && !Array.isArray(value) && typeof value === 'object' && Object.keys(value).length) {
        confData[lastKey] = confData[lastKey] || {};
        for(let subKey in value) {
          confData[lastKey][subKey] = value[subKey];
        }
      } else {
        confData[lastKey] = value;
      }
      return true;
    } else {
      console.warn('Config.set, not correct params: ', key, value);
      return false;
    }
  }
  
  setRecursive(data, name, isLast) {
    if(isLast) {
      //      data[name] = value;
      return data;
    } else if(!data[name]) {
      data[name] = {};
    }
    return data[name];
  }
}