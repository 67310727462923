<template>
  <component
    :is="tag"
    :key="`trim-${text}`"
    v-resize-element="debouncedTrim"
    v-observe-visibility="{callback: visibilityChange, intersection: intersection, throttle: 300}"
    :title="text | stripHTML"
  >
    {{ trimedText | stripHTML }}
  </component>
</template>

<script>
  import { stripHTML } from '../../filters';
  import { resize } from '../../directives/resize';
  import debounce from 'lodash/debounce';
  import { memoryStore } from "vimmi-web-utils/cjs/memoryStore";
  // &hellip; https://www.w3schools.com/charsets/tryit.asp?deci=8230&ent=hellip

  export default {
    props: {
      text: {
        type: String,
        required: true,
      },
      tag: {
        type: String,
        default: 'span',
      },
      nativeRow: {
        default: false,
        type: [Boolean],
      },
    },
    data() {
      return {
        trimedText: this.text,
        canvas: {},
        ctx: {},
        DEFAULT_WIDTH_ELEMENT: 200,
        cashe: {},
        isVisible: false,
        textMistake: 1,
        DEBOUNCE_TIME: 25,
        intersection: {
          threshold: 0,
        },
      };
    },
    computed: {
      debouncedTrim() {
        return debounce(this.trimTextBinded, this.DEBOUNCE_TIME, {
          leading: false,
          trailing: true,
        });
      },
    },

    watch: {
      text: {
        handler: function() {
          this.debouncedTrim();
        },
      },
    },

    created() {
      this.canvas = document.createElement('canvas');
      this.canvas.hidden = true;
      this.canvas.height = '1px';
      this.canvas.width = '1px';
      this.ctx = this.canvas.getContext('2d');
    },

    mounted() {
      // this.trimedText = this.trimText(this.$el, this.text);
      // this.$bus.$on('resizeWindow',  this.trimTextBinded);
    },

    methods: {
      trimTextBinded() {
        this.$nextTick(() => {
          this.trimedText = this.trimText(this.$el, this.text);
        });
      },
      visibilityChange(isVisible, entry) {
        this.isVisible = isVisible;
        if (isVisible) {
          this.debouncedTrim();
        }
      },
      getFont(styles) {
        let font = styles.font;
        if (!font) {
          font =
            styles.fontWeight +
            ' ' +
            styles.fontStyle +
            ' ' +
            styles.fontVariant +
            ' ' +
            styles.fontSize +
            '/' +
            styles.lineHeight +
            ' ' +
            styles.fontFamily;
        }
        return font;
      },

      getIntValue(value, defaultValue = null) {
        let val = parseInt(value);
        return !isNaN(val) ? val : defaultValue;
      },

      checkPositive(value, defaultReturn = 0) {
        return value > 0 ? value : defaultReturn;
      },

      calculateHeight(styles, defaultHeight = 0) {
        let maxHeight = this.getIntValue(styles.maxHeight);
        let height = this.getIntValue(styles.height);
        let ht = maxHeight > 0 ? maxHeight : height > 0 ? height : defaultHeight;

        let paddingTop =
          this.getIntValue(styles.paddingTop, 0) +
          this.getIntValue(styles.paddingBottom, 0);
        return this.checkPositive(ht - paddingTop, 0);
      },

      calculateWidth(styles, width) {
        let paddingLn =
          this.getIntValue(styles.paddingLeft, 0) +
          this.getIntValue(styles.paddingRight, 0);
        let wdth = Math.round(this.checkPositive(width - paddingLn));
        return wdth;
      },

      getNormalizeText(text) {
        let replaceRow = this.nativeRow ? ' <br/> ' : '';
        let txtRows = text.replace('\n', replaceRow);
        return txtRows;
      },

      getCasheDate(name) {
        // if (memoryStore.get(name)) {
        return memoryStore.get(name) || null;
        // } else if (this.cashe.hasOwnProperty(name)) {
        //   return this.cashe[name];
        // } else {
        //   return null;
        // }
      },

      setCasheDate(nama, data) {
        // if (this.$global) {
        //   this.cashe[name] = data;
          memoryStore.set(name, data);
        // } else {
        //   this.cashe[name] = data;
        // }
      },

      spaceIndexOf(text = '', start = 0, end = 0, last = false) {
        if (text && text.length > 0) {
          let endText = Math.min(text.length - 1, end);
          let txt = text.slice(start, end);
          // console.log(txt, start, end, text.length);
          let regexp = last ? /[\s|-]+(?!.*[\s|-])/gi : /[\s|-]+/i;
          regexp.lastIndex = start;
          let res = txt.search(regexp);
          return res;
        }
        return -1;
      },

      // transformText(text, rows, width, symbolPix) {
      //   // console.log(`TransformText, $w=${width}, $_text=${text}, $rows=${rows}, $symbolPix=${symbolPix}`);
      //   let name = `W${width}_R${rows}_T${text}_S${symbolPix}`;
      //   if (this.getCasheDate(name)) {
      //     return this.getCasheDate(name);
      //   }
      //   let txtRows = this.getNormalizeText(text);
      //   let words = txtRows.split(/\s+/g);
      //   let _strings = [];
      //   let _currentIndex = 0;
      //   let endSymbolsWidth = this.getWordWidth('...');

      //   // console.log(words.length);
      //   for ( let index = 0; index < words.length && _currentIndex < rows; index += 1) {
      //     let word = words[index] + ' ';

      //     if (!_strings[_currentIndex]) {
      //       _strings[_currentIndex] = word;
      //       // index += 1;
      //       continue;
      //     }
      //     let isLastRow = _currentIndex + 1 >= rows;
      //     let isLastWord = !(_currentIndex + 1 < rows);
      //     let currWidth =
      //       (_strings[_currentIndex].length + word.length) * symbolPix;
      //     let wdt =
      //       !isLastRow || (isLastRow && isLastWord)
      //         ? width
      //         : width - endSymbolsWidth - spaceWidth;

      //     if (word === '<br/> ') {
      //       if (isLastRow) {
      //         _strings[_currentIndex] += '...' + word + ' ';
      //       } else {
      //         _strings[_currentIndex] += word + ' ';
      //       }
      //       _currentIndex += 1;
      //       // index += 1;
      //       continue;
      //     }
      //     if (currWidth < wdt) {
      //       _strings[_currentIndex] += word;
      //     } else {
      //       if (isLastRow) {
      //         _strings[_currentIndex] += '...';
      //         break;
      //       } else {
      //         _currentIndex += 1;
      //         _strings[_currentIndex] = word;
      //       }
      //     }
      //   }
      //   let res = _strings.join(' ');
      //   this.setCasheDate(name, res);
      //   return res;
      // },

      // transformByWidth(text, rows, width) {
      //   let name = `W${width}_R${rows}_T${text}`;
      //   if (this.getCasheDate(name)) {
      //     return this.getCasheDate(name);
      //   }
      //   let txtRows = this.getNormalizeText(text);
      //   let words = txtRows.split(/\s+/g);
      //   let strings = [];
      //   let currentStrings = 0;
      //   let stringsWidth = 0;
      //   let spaceWidth = this.getWordWidth(' ') + this.textMistake;
      //   let endSymbolsWidth = this.getWordWidth('...');
      //   for (
      //     let index = 0;
      //     index < words.length && currentStrings < rows;
      //     index++
      //   ) {
      //     let isLastRow = !(currentStrings + 1 < rows);
      //     let isLastWord = index === words.length - 1;
      //     let checkWidth =
      //       !isLastRow || (isLastRow && isLastWord)
      //         ? width
      //         : width - endSymbolsWidth - spaceWidth;

      //     let word = words[index];
      //     let currentWidth = this.getWordWidth(word);

      //     if (word === '<br/>' && stringsWidth > 0) {
      //       if (isLastRow) {
      //         strings.push('...');
      //       }
      //       strings.push(word);
      //       currentStrings += 1;
      //       stringsWidth = 0;
      //       continue;
      //     }

      //     if (stringsWidth + currentWidth < checkWidth) {
      //       strings.push(word);
      //       stringsWidth += currentWidth + spaceWidth;
      //     } else if (!isLastRow) {
      //       currentStrings += 1;
      //       strings.push(word);
      //       stringsWidth = currentWidth + spaceWidth;
      //     } else {
      //       currentStrings += 1;
      //       if (stringsWidth >= checkWidth) {
      //         strings.pop();
      //       }
      //       strings.push('...');
      //     }
      //   }
      //   let res = strings.join(' ');
      //   this.setCasheDate(name, res);

      //   return res;
      // },

      findStringByWidth(text, width) {
        if (text && text.length > 0) {
          let textWidth = this.getWordWidth(text);
          let index = Math.min(
            Math.floor((width / textWidth) * text.length),
            text.length,
          );
          let lastSpace =
            index !== text.length
              ? this.spaceIndexOf(text, 0, index + 1, true)
              : -1;
          if (lastSpace > 0) {
            let norm = Math.min(lastSpace + 1, text.length - 1);
            let res = text.slice(0, norm);
            if (this.getWordWidth(res) > width) {
              return this.findStringByWidth(res, width);
            }
            return res;
          }
          return text;
        } else {
          return text;
        }
      },

      transformByTextLen(text, rows, width) {
        let name = `W${width}_R${rows}_T${text}`;
        if (this.getCasheDate(name)) {
          return this.getCasheDate(name);
        }
        let txtRows = this.getNormalizeText(text);
        let endSymbolsWidth = this.getWordWidth(stripHTML('...'));
        let strings = [];
        let currentStrings = 0;
        let textLenght = txtRows.length;
        let rowsIndex = (width * rows) / this.getWordWidth(txtRows);
        if (rowsIndex < 1) {
          txtRows = txtRows.slice(0, Math.floor((rowsIndex + 0.1) * textLenght));
          textLenght = txtRows.length;
        }
        // console.group(name);
        for (
          let currentStrings = 0;
          currentStrings < rows && textLenght > 0;
          currentStrings++
        ) {
          let isLastRow = !(currentStrings + 1 < rows);
          let str = this.findStringByWidth(txtRows, width);
          let sliceIndex = Math.min(str.length, txtRows.length);

          // NOTE: if '\n' is first item ignore it
          if (this.nativeRow && str.indexOf('<br/>') > 0) {
            sliceIndex = str.indexOf('<br/>');
            str = str.slice(0, sliceIndex);
          }

          txtRows = txtRows.slice(sliceIndex);
          strings.push(str);

          if (isLastRow && txtRows.length > 0) {
            str = this.findStringByWidth(str, width - endSymbolsWidth);
            str += '&hellip;';
            strings.splice(currentStrings, 1, str);
          }
          textLenght = txtRows.length;
        }
        let res = strings.join('');
        this.setCasheDate(name, res);
        return res;
      },

      getWordWidth(val) {
        // this.ctx.font = this.getFont(styles);
        let metrics = this.ctx.measureText(val);
        return Math.round(metrics.width);
      },

      trimText(el, txt) {
        let text = stripHTML(txt); //.replace('\n', ' ').replace('\r', '');
        if (text) {
          if (this.isVisible) {
            let textMeasure = text.trim().replace(/\r?\n/gi, '');
            let styles = getComputedStyle(el);

            // get Symbol width
            this.ctx.font = this.getFont(styles);
            // let endMetrics = this.ctx.measureText('...');
            // let metrics = this.ctx.measureText(textMeasure);
            let currentWidth = this.getWordWidth(textMeasure); //metrics.width;
            let symbolPix = currentWidth / textMeasure.length;

            // Get Rows
            let htElem = this.calculateHeight(styles, el.clientHeight);
            let lineHeight = this.getIntValue(styles.lineHeight, 1);
            let rows = Math.max(Math.floor(htElem / lineHeight), 1);

            // Get target Width
            let width = this.checkPositive(
              this.calculateWidth(styles, el.clientWidth),
              this.DEFAULT_WIDTH_ELEMENT,
            );
            let targetWidth = width * rows;
            return this.transformByTextLen(text, rows, width);
          } else {
            return this.nativeRow ? text.replace('\n', '<br/>') : text;
          }
        } else {
          return '';
        }
      },
    },
    // render: function(createElement) {
    //   // console.log(this.tag,  this.text);
    //   return createElement(this.tag, {
    //     attrs: {
    //       title: stripHTML(this.text),
    //     },
    //     name: 'trimmed-element',
    //     domProps: {
    //       innerHTML: this.trimedText,
    //     },
    //     directives: [
    //       {
    //         name: 'resize-element',
    //         value: this.debouncedTrim,
    //       },
    //       {
    //         name: 'observe-visibility',
    //         value: {
    //           callback: this.visibilityChange,
    //           // throttle: 300,
    //         },
    //       },
    //     ],
    //   });
    // },
  };
</script>
