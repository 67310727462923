<template>
  <div class="container-fluid row mx-0">
    <vue-headful
      :title="metadata.title"
      :description="metadata.description"
      :image="metadata.image"
      :url="metadata.url"
      :keywords="metadata.keyword"
      :head="metadata.head"
    />
    <loader v-if="loading" />
    <template v-else>
      <div class="col-12 col-lg-8 mb-3 mb-lg-0 px-0">
        <media-video
          class="video-live-player"
          :event-identifier="eventIdentifier"
          :autoplay="false"
        />
        <live-text-block
          v-if="activeChannel"
          class="mt-3"
          :channel="activeChannel"
          :event="currentEvent"
        />
      </div>
      <channels-list
        v-if="channels.length"
        :event-identifier="eventIdentifier"
        :active-channel="activeChannelId"
        class="col-12 col-lg-4 px-0 pl-lg-4 pr-lg-0 mb-3 mb-lg-0 live-channellist"
        :items="channels"
        :exclusive-screen="screenName"
      />
    </template>
  </div>
</template>

<script>
  import MediaVideo from 'src/components/player/video/video';
  import ChannelsList from 'src/components/channels-list/channels-list';
  import contentLanguage from 'src/filters/contentLanguage.js';
  import normaliseMetaTags from 'src/utils/normaliseMetaTags';
  import LiveTextBlock from 'src/components/live/live-text-block.vue';
  import Loader from 'src/components/loader/loader.vue';
  import { ScreenReadyMixin } from 'src/plugins/mixin';
  import { amsClient } from 'src/service/ams';
  import isString from 'lodash/isString';
  import Config from 'src/service/config.js';
  import generateUrl from '@/utils/generateUrl';
  import EpgService from '@/store/epg';
  import { localStore } from 'vimmi-web-utils/esm/localStore';

  export default {
    name: 'ExclusiveTV',
    components: {
      ChannelsList,
      MediaVideo,
      LiveTextBlock,
      Loader,
    },
    mixins: [ScreenReadyMixin],
    data() {
      return {
        eventIdentifier: Math.ceil(Math.random() * 10000000),
        channels: [],
        activeChannelId: null,
        activeChannel: null,
        metadata: {},
        playerReady: false,
        loading: true,
        screenName: null,
        playmode: null,
        defaultChannel: null,
      };
    },
    computed: {
      currentEvent() {
        let it = this.activeChannelId
          ? EpgService.getCurrentEpg(this.activeChannelId)
          : null;
        return it;
      },
    },
    watch: {
      'head.id': function(val) {
        this.setScreenReady(this.head, this.screenRequestTime);
      },
      screenName: function(newVal, oldVal) {
        if (newVal === oldVal) return;

        this.getChannels().then(() => this.setActiveChannel(), null);
      },
      activeChannelId: function(newVal, oldVal) {
        if (newVal === oldVal) return;

        if (this.channels && this.channels.length) {
          this.setActiveChannel();
        } else if (!this.loading)
          this.getChannels().then(() => this.setActiveChannel(), null);
      },
      activeChannel: function(newVal, oldVal) {
        if (newVal && !this.$route.params.id) {
          localStore.set(`${this.screenName}_channel_id`, newVal.id);
          const url = `/exclusive/${this.screenName}/${generateUrl(newVal)}`;
          this.$router.push({
            path: url,
          });
        }
      },
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        const { id, name } = to.params;
        console.log('[ExclusiveTV][beforeRouteEnter]', id, name);
        vm.screenName = name;
        vm.activeChannelId = id ? id : null;
      });
    },
    beforeRouteUpdate(to, from, next) {
      const { id, name } = to.params;
      console.log('[ExclusiveTV][beforeRouteUpdate]', id, name);
      if (this.screenName !== name) {
        this.stateRegression().then(() => {
          this.screenName = name;
          this.activeChannelId = id ? id : null;
          this.subscribeToEvents();
          this.loadScreen();
        });
      } else if (id && this.activeChannelId !== id) {
        this.activeChannelId = id;
         localStore.set(`${this.screenName}_channel_id`, this.activeChannelId)
      } else {
        const url = `/exclusive/${this.screenName}/${generateUrl(
          this.activeChannel,
        )}`;
        this.$router.push({
          path: url,
        });
      }
      next();
    },
    mounted() {
      this.subscribeToEvents();
      this.loadScreen();
    },
    destroyed() {
      this.unsubscribeFromEvents();
    },
    methods: {
      getChannels() {
        if (!this.screenName) return Promise.reject();
        this.loading = true;
        return new Promise((resolve, reject) => {
          Config.loaded(() => {
            return amsClient
              .callAms(`/screen/${this.screenName}/items`, { cache: false })
              .then((data) => {
                let items = data.items;
                this.playmode = data.head?.playmode;
                this.defaultChannel = data.head.media ? data.head.media[0] : null;
                items.map((item) => {
                  item.pss = {
                    canPlayPremium: amsClient.canPlayPremium(item),
                    canPlayFreemium: amsClient.canPlayFreemium(item),
                    isPremium: amsClient.isContentPremium(item),
                    isFreemium: amsClient.isContentFreemium(item),
                    canPlay:
                      !item.not_free ||
                      (amsClient.isContentFreemium(item) &&
                        amsClient.canPlayFreemium(item)) ||
                      (amsClient.isContentPremium(item) &&
                        amsClient.canPlayPremium(item)),
                  };
                });
                this.channels = items;
                window.datadata = data;

                EpgService.setChannels(this.channels);
                EpgService.dispatchgetEpgList();

                resolve(data);
              })
              .catch(reject)
              .finally(() => {
                this.loading = false;
              });
          });
        });

      },
      setActiveChannel() {
        if (this.activeChannel?.id === this.activeChannelId) return;

        let key = null;
        if (!localStore.get(`${this.screenName}_channel_id`) && this.playmode === 'recent') {
          key = this.defaultChannel;
        } else {
          key =this.$route.params.id || (this.playmode !== 'enforce' && localStore.get(`${this.screenName}_channel_id`)) || this.defaultChannel || 0;
        }
        localStore.set(`${this.screenName}_channel_id`,key)
        let index = this.channels.findIndex((el) => el.id === key || key === 0);

        index = index < 0 ? 0 : index;
        this.activeChannel =
          index > -1 ? Object.assign({}, this.channels[index]) : null;
        if (!this.activeChannel) return;

        this.event_setActiveChannel(true);
      },
      setActiveChannelId(id) {
        this.activeChannelId = id ? id : this.activeChannelId;
      },
      event_playerReady() {
        this.$nextTick(() => {
          this.playerReady = true;
          if (!this.activeChannel) return;
          this.event_setActiveChannel(true);
        });
      },
      event_setActiveChannel(forcePlay) {
        let channel = this.activeChannel;
        this.$nextTick(() => {
          if (
            this.playerReady &&
            (forcePlay || this.activeChannelId !== channel.id)
          ) {
            this.$bus.$emit(this.eventIdentifier + '_playNowVideo', {
              item: channel,
              autoplay: true,
              bigButton: true,
              clickTime: Date.now(),
            });
          }

          if (this.activeChannelId === channel.id) {
            return;
          }

          this.activeChannelId = channel.id;
          if (localStore.get('last_play_channel') !== channel.id && this.playmode !== 'enforce') {
            localStore.set('last_play_channel', channel.id);
          }
          // this.activeChannel = Object.assign({}, channel);
          let dt =
            channel && channel.seo_metadata ? channel.seo_metadata : channel;
          let meta = Object.assign({}, dt, {
            title: `${channel.chid}. ${channel.title}`,
          });
          this.metadata = normaliseMetaTags(meta);
        });
      },
      subscribeToEvents() {
        this.$bus.$on('setActiveChannel', this.setActiveChannelId);
        this.$bus.$on(
          this.eventIdentifier + '_playerReady',
          this.event_playerReady,
        );
        this.$bus.$on('changedUserState', this.onChangedUserState);

        this.getChannels();
      },
      unsubscribeFromEvents() {
        this.$bus.$off('setActiveChannel', this.setActiveChannelId);
        this.$bus.$off(
          this.eventIdentifier + '_playerReady',
          this.event_playerReady,
        );
        this.$bus.$off('changedUserState', this.onChangedUserState);
      },
      loadScreen() {
        Config.loaded(() => {
          this.screenRequestTime = this.setScreenRequest({
            link: `/screen/${this.screenName}/`,
          });
          amsClient
            .callAms(`/screen/${this.screenName}/`)
            .then((data) => {
              this.head = Object.assign({ cached: data.cached }, data.head);
              //          this.$stats.set('current.screen', this.head);
              this.setCurrentScreen(this.head);
              // if(!this.head.cached) {
              this.setScreenResponse(this.head);
              // }
            })
            .catch((err) => {
              console.error(err);
              this.head = Object.assign(
                {},
                {
                  itype: `screen_tv_ex`,
                  id: 'error',
                  link: `/screen/${this.screenName}/`,
                },
              );
            });
        });

      },
      stateRegression() {
        this.eventIdentifier = Math.ceil(Math.random() * 10000000);
        this.channels = [];
        this.activeChannelId = null;
        this.activeChannel = null;
        this.metadata = {};
        this.playerReady = false;
        this.loading = true;
        this.screenName = null;
        this.unsubscribeFromEvents();
        return this.$nextTick();
      },
      onChangedUserState() {
        this.getChannels().then(() => this.setActiveChannel());
      },
    },
  };
</script>

<style scoped lang="scss">
  .live-channellist {
    .list-wrapper {
      height: 30vh;
      min-height: 300px;

      &-items {
        height: calc(100% - 70px);
      }
    }
  }

  @include media-breakpoint-up('lg') {
    .live-channellist {
      .list-wrapper {
        height: 90vh;
      }
    }
  }
</style>