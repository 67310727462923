import IsExernalLink from './isExternalLink';
import GetScrollbarWidth from './getScrollBarWidth';
// import DecryptURL from './decryptUrl';
// import ChunkArr from './chunkArray'
// import XML2JSON from './xml2json'
// import JSON2XML from './json2xml'
import FmDFpID from './formatDfpId'
// import ParseXml from './parseXML'
import TruncateSymbolsToNumber from './truncateSymbolsToNumber';
import FloorTo from './floorTo';
import FormatMediaURL from './formatMediaUrl';

export const isExternalLink = IsExernalLink;
export const getScrollbarWidth = GetScrollbarWidth;
// export const decryptUrl = DecryptURL;
// export const chunkArray = ChunkArr;
// export const xml2json = XML2JSON;
// export const json2xml = JSON2XML;
export const formatDfpId = FmDFpID;
// export const parseXml = ParseXml;
export const truncateSymbolsToNumber = TruncateSymbolsToNumber;
export const formatMediaUrl = FormatMediaURL;
export const floorTo = FloorTo;

export default {
  isExternalLink: IsExernalLink,
  getScrollbarWidth: GetScrollbarWidth,
  // decryptUrl: DecryptURL,
  // chunkArray: ChunkArr,
  // xml2json: XML2JSON,
  // json2xml: JSON2XML,
  formatDfpId: FmDFpID,
  // parseXml: ParseXml,
  truncateSymbolsToNumber: TruncateSymbolsToNumber,
  floorTo: FloorTo,
  formatMediaUrl: FormatMediaURL,
}
