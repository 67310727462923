<template>
  <!-- <backdrop :area="brandingAds"> -->
  <pv-scroll
    :is-loading="isLoaded"
    :is-element="false"
    :class="{ 'no-scroll': noScroll }"
    @bottom="bottom"
  >
    <transition-group
      name="stagged-area"
      :appear="true"
      appear-active-class="animated fadeInUp"
      enter-active-class="animated fadeInUp"
      leave-active-class="animated fadeOutDown"
      tag="div"
    >
      <div
        v-for="(area, index) in _areas"
        :key="`key-${index}`"
        class="area-block"
      >
        <dfp
          v-if="area.aclass === 'section_ads' || checkBanner(area)"
          class="dfp-block"
          :area="area"
          :placement="'section'"
        />
        <dfp
          v-if="checkBanner(area, 'top')"
          class="dfp-block"
          :area="area"
          placement="top"
        />
        <social-block v-if="area.aclass === 'section_social'" :area="area" />
        <internal-carousel
          v-else-if="area.aclass === 'section_internal' && page !== 'article'"
          :area="area"
          :ordered="true"
        />
        <pro-carousel
          v-else-if="
            area.aclass === 'section' && !area.box && page !== 'article'
          "
          :area="area"
        />

        <promo v-else-if="area.aclass === 'section_promo'" :area="area" />
        <live-epg v-else-if="area.aclass === 'epg'" :area="area" />
        <show-profile
          v-else-if="area.aclass === 'site_header' && page === 'show'"
          :area="area"
        />
        <!-- <channel-profile v-else-if="area.aclass === 'site_header' && page==='channel'" :area="area" /> -->

        <live-player
          v-else-if="area.aclass === 'live_player' && page === 'live'"
          :area="area"
        />

        <media-player
          v-else-if="
            area.aclass === 'video_flow' ||
            (area.aclass === 'video' && page === 'video')
          "
          :area="area"
          :extended="page === 'video'"
        />
        <vod
          v-else-if="area.aclass === 'video' && page === 'home'"
          :area="area"
        />
        <dfp
          v-if="checkBanner(area, 'bottom')"
          class="dfp-block"
          :area="area"
          placement="bottom"
        />
        <!--  AIS -->
        <!-- <pro-carousel
        v-if="area.itype === 'area_grid' || area.itype === 'section'"
        :area="area"
        />-->
        <!-- <vod
        v-else-if="area.itype === 'item_mov_vod' || area.itype === 'item_mov_episode'"
        :area="area"
        />-->
        <media-player
          v-if="
            area.itype === 'item_mov_vod' || area.itype === 'item_mov_episode'
          "
          :area="area"
          :is-one="true"
        />
        <!-- <media-player
          v-else-if="area.itype === 'section_channels'"
          :area="area"
          :link="area.link"
        />-->
        <!-- <video-player
          v-else-if="area.itype === 'category_series'"
          :area="area"
        />-->
        <category-title
          v-else-if="
            ['category_head', 'category_series_head'].includes(area.itype)
          "
          :area="area"
        />
        <internal-carousel
          v-else-if="
            [
              'area_grid',
              'section',
              'category',
              'section_channels',
              'category_series',
              'section_link',
            ].indexOf(area.itype) > -1
          "
          :area="area"
          :ordered="true"
          :reversed="false"
        />
        <internal-carousel
          v-else-if="['section_season'].indexOf(area.itype) > -1"
          :area="area"
          :ordered="true"
          :reversed="true"
        />
        <directive-carousel
          v-else-if="['section_link_directives'].indexOf(area.itype) > -1"
          :area="area"
        />
        <live-epg v-else-if="area.itype === 'item_live'" :area="area" />
        <banner v-else-if="area.itype === 'banner_group'" :area="area" />
        <app-area v-else-if="area.itype === 'item_app'" :area="area" />
      </div>
    </transition-group>
    <!-- </backdrop> -->
  </pv-scroll>
</template>

<script>
import SocialBlock from 'src/components/social-block/social-block';
import InternalCarousel from 'src/components/pro-carousel/internal-procarousel';
import DirectiveCarousel from 'src/components/pro-carousel/directive-carousel';
import ProCarousel from 'src/components/pro-carousel/pro-carousel';
import Promo from 'src/components/promo/promo';
import Vod from 'src/components/player/main/vod';
// import LivePlayer from 'src/components/live/live-player';
import Dfp from 'src/components/dfp/dfp';
import MediaPlayer from 'src/components/player/media/media-player';
// import MediaPlayer1 from 'src/components/player/media/media-player.1';
import LiveEpg from 'src/components/live-epg/live-epg';
import ShowProfile from 'src/components/show-profile/show-profile';
import ChannelProfile from 'src/components/channel-profile/channel-profile';
import dfpService from 'src/service/dfpService';
import BlockChanels from '../block-chanels/block-chanels';
import Backdrop from '../backdrop/backdrop';
import Banner from 'src/components/banner.vue';
import VideoPlayer from 'src/components/video-player.vue';
import AppArea from 'src/components/area-app.vue';
import CategoryTitle from 'src/components/category-title/category-title';
import PvScroll from 'src/components/pv-scroll/scroll';

export default {
  name: 'Areas',

  components: {
    BlockChanels,
    MediaPlayer,
    // MediaPlayer1,
    SocialBlock,
    Promo,
    InternalCarousel,
    ProCarousel,
    Vod,
    Dfp,
    // LivePlayer,
    LiveEpg,
    ShowProfile,
    ChannelProfile,
    Backdrop,
    Banner,
    VideoPlayer,
    AppArea,
    CategoryTitle,
    DirectiveCarousel,
    PvScroll,
  },
  props: {
    page: {
      type: String,
      default: null,
      validator: function (value) {
        let vals = [
          'video',
          'home',
          'article',
          'show',
          'channel',
          'live',
          'search',
          'shows-az',
          'dashboard',
          'section',
          'category',
        ];
        return vals.indexOf(value) !== -1;
      },
    },
    areas: {
      type: Array,
      default() {
        return [];
      },
    },
    brandingAds: {
      type: [Object, null],
    },
  },
  data() {
    return {
      visibleAreas: [],
      index: 0,
      isLoaded: false,
      COUNT_ITEMS: 9,
    };
  },
  computed: {
    _areas() {
      if (_isPreviewMode()) {
        //          return [];
        return this.visibleAreas.slice(0, 1);
      }
      return this.visibleAreas;
    },
    isActiveScroll() {
      return this.isActiveFeature('use-scroll-area');
    },
    noScroll() {
      return this.areas.length < 2;
    },
  },
  created() {
    this.index = 0;
    if (this.isActiveScroll) {
      this.addItemsByScroll();
    } else {
      this.pushNextItem();
    }
  },
  methods: {
    loadBanners: dfpService.loadBanners,
    hasBanner: dfpService.hasBanner,
    getBanner: dfpService.getBanner,
    checkBanner(area, placement) {
      let res =
        area.aclass !== 'section_ads' &&
        this.loadBanners(area) &&
        this.hasBanner(placement, area);
      return res;
    },
    bottom() {
      if (this.isActiveScroll) {
        this.addItemsByScroll();
      }
      // console.log(`scroll bottom`);
    },

    addItemsByScroll() {
      // console.log(`scroll bottom push`, this.index);
      if (this.areas.length > 0 && !this.isLoaded) {
        let counter = Math.floor(this.index / this.COUNT_ITEMS) + 1;
        this.index = this.COUNT_ITEMS * counter;
        let pushed = this.areas.slice(
          this.index - this.COUNT_ITEMS,
          this.index,
        );

        pushed.forEach((element) => {
          this.visibleAreas.push(element);
        });

        if (this.index >= this.areas.length) {
          this.isLoaded = true;
        }
      }
    },

    pushNextItem() {
      if (this.areas.length > 0) {
        this.visibleAreas.push(this.areas[this.index]);
        this.index++;
        if (this.index < this.areas.length) {
          if (this.index < 4) {
            setTimeout(this.pushNextItem, 500);
          } else {
            this.pushNextItem();
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import './_areas';

.no-scroll {
  min-height: auto !important;
}
</style>

