import Vue from 'vue';
import Router from 'vue-router';

import CallbacksContainer from '../views/CallbacksPage/Callbacks';
import HomeContainer from '../views/MainHome/Home';
import PageContainer from '../views/Page/Page';
import ErrorContainer from '../views/ErrorPage/ErrorPage';
import VideoContainer from '../views/VideoPage/Video';
import SearchContainer from '../views/SearchPage/Search';
import LiveContainer from '../views/LivePage/Live';
import CategoryPage from 'src/views/CategoryPage/Category.vue';
import SettingsPage from 'src/views/SettingsPage/Settings.vue';
import SeriesPage from 'src/views/SeriesPage/Series.vue';
import TACPage from 'src/views/TACPage/TACPage.vue';
import PrivacyPage from 'src/views/PrivacyPage/PrivacyPage';
import Auth from 'src/service/authService';
import AccountContainer from '../views/AccountPage/Account.vue';
import HyperlinksPage from '@/views/HyperlinksPage/HyperlinksPage';
import ExclusiveTVPage from '@/views/ExclusiveTVPage/ExclusiveTV';
import Webview from '@/views/WebviewPage/Webview';
import getLanguageKey from '../utils/getLanguageKey';
import { localStore } from 'vimmi-web-utils/esm/localStore';
import Config from '../service/config';
import { doFetch } from 'vimmi-web-utils/esm/doFetch';
import { amsClient } from '../service/ams';

Vue.use(Router);
let router = new Router({
  mode: process.env.NODE_ENV === 'development' ? 'hash' : 'history', // to testing v-account need to use only 'history' mode
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      name: 'Error',
      component: ErrorContainer,
    },
    {
      path: '/callback/:type/:status',
      name: 'Callbacks',
      component: CallbacksContainer,
    },
    {
      path: '/webview/:id',
      name: 'Webview',
      component: Webview,
    },
    {
      path: '/auth',
      name: 'Auth',
      component: CallbacksContainer,
      props: {
        status: 'any',
        type: 'fungus',
      },
    },
    {
      path: '/',
      name: 'Home',
      component: HomeContainer,
    },

    {
      path: '/screen/:id',
      name: 'Page',
      component: PageContainer,
    },
    {
      path: '/set',
      name: 'Settings',
      component: SettingsPage,
    },
    {
      path: '/get_item/:id',
      name: 'VideoPageVideo',
      component: VideoContainer,
    },
    {
      path: '/get_section/:id',
      name: 'VideoPageSection',
      component: CategoryPage,
    },

    {
      path: '/serial/:type/:id',
      name: 'SeriesPage',
      component: SeriesPage,
    },

    {
      path: '/search',
      name: 'Search',
      component: SearchContainer,
    },
    {
      path: '/terms_and_conditions',
      name: 'TACPage',
      component: TACPage,
    },
    {
      path: '/privacy_notice',
      name: 'PrivacyPage',
      component: PrivacyPage,
    },
    {
      path: '/live',
      name: 'Live',
      component: LiveContainer,
    },
    {
      path: '/hyperlinks',
      name: 'Hyperlinks',
      component: HyperlinksPage,
    },
    {
      path: '/account',
      name: 'Account',
      component: AccountContainer,
    },
    {
      path: '/exclusive/:name/(.*-)*:id?',
      name: 'ExclusiveTV',
      component: ExclusiveTVPage,
    },
  ],

  // scrollBehavior(to, from, savedPosition) {
  //   if (to.hash) {
  //     let anchor = to.hash.slice(1, to.hash.length);
  //     let el = document.getElementById(anchor);
  //     if (el) {
  //       let fix = el.getBoundingClientRect();
  //       smoothScroll(fix.top, undefined, { y: -120 });
  //     }
  //   } else if (savedPosition) {
  //     return savedPosition;
  //   } else {
  //     smoothScroll(0);
  //     // window.scroll({ top: 0, left: 0, behavior: 'smooth'});
  //   }
  // },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !Auth.isAuth()) {
    next({
      path: '/',
    });
  }/* else if (amsClient.get('user.consent') === false && (!Auth.getPortalPrevPath() || to.fullPath !== Auth.getPortalPrevPath()) && to.fullPath !== '/') {
    next(false);
  } */else {
    if (to.path === '/account') {
      Config.loaded(() => {

        if (!Auth.isAuth() || Auth.isGuest()) {
          let path = to.fullPath;
          localStore.set('auth-redirect-url', path);
          amsClient.account.openFungusLoginPage();
          return;
        }

        let domain = 'http://110.49.202.163';
        const privateId = encodeURIComponent(Auth.get('user.privateId'));
        const lang = getLanguageKey(localStore.get('lang') || 'tha', 'iso-2');
        const platform = 'WEB';
        let hash = to.hash;
        if (hash.indexOf('#') === 0) {
          hash = hash.slice(1);
        }
        if (amsClient.get(`customer.abs.${btoa('vaccount')}`)) {
          let t = new URL(atob(amsClient.get(`customer.abs.${btoa('vaccount')}`)));
          domain = t.origin;
        }
        const fungusUrl = `${domain}/${Config.get('env') !== 'prod' ? 'V-Account/' : ''}${privateId}/${hash}?lang=${lang}&platform=${platform}`;
        /*      Object.assign(document.createElement('a'), {
                target: '_blank',
                href: fungusUrl,
              }).click();*/
        doFetch(`${domain}/V-Account`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: JSON.stringify({
            privateId,
            lang,
            platform,
          }),
        })
          .finally(() => {
            let link = document.createElement('a');
            link.href = fungusUrl;
            link.target = '_blank';
            link.classList.add('d-none');
            document.body.appendChild(link);
            link.click();
            link.remove();
          });

        next({ path: '/set' });
      });
    }
    next();
  }
});
//
router.afterEach((to, from) => {
  // window.scrollTo(0, 0);
});

export default router;
