<template>
  <div class="container-fluid px-0 px-lg-3">
    <carousel-slider
      class="px-0 px-lg-3"
      :items="area.items"
      :row="1"
      :column="1"
      :infinite="true"
      :autoplay="true"
      :step="1"
    >
      <template slot-scope="{item}">
        <router-link
          :to="item.link || '/'"
          class="carousel-card"
          draggable="false"
          ondragstart="return false;"
        >
          <div
            class="carousel-card-thumb embed-responsive embed-responsive-16by9"
          >
            <img
              class="carousel-card-thumb-img embed-responsive-item"
              :src="item.banner | normalizeImg('image', null)"
              draggable="false"
              :alt="item.title"
              :title="item.title"
            >
            <duration
              class="align-bottom-left carousel-card-duration"
              :duration="item.duration"
              icon-play="none"
            />
          </div>
          <div class="carousel-card-text-block">
            <trim-el
              class="carousel-card-title"
              :text="item.title"
              tag="h5"
            />
          </div>
        </router-link>
      </template>
    </carousel-slider>
    <!-- <div
      v-bg-image-js="{name: poster, type: 'image'}"
      class="show-profile-img"
    >-->
    <!--  TODO: work as image -->
    <!-- <div class="show-profile-overlay text-right">
        <button
          v-if="!isSubscribed"
          class="btn show-profile-btn  not-focusable"
          tabindex="0"
          type="button"
          @click.stop="subscribe()"
        >
          <span v-t="{path: 'SHOW_PROFILE.SUBSCRIBE'}" />
        </button>
        <button
          v-if="isSubscribed"
          class="btn show-profile-btn  not-focusable"
          tabindex="0"
          type="button"
          @click.stop="unsubscribe()"
        >
          <span v-t="{path: 'SHOW_PROFILE.UNSUBSCRIBE'}" />
        </button>

        <div class="show-profile-sponsor">
          <a
            v-if="sponsorPoster"
            class="show-profile-sponsor-poster"
            :href="sponsorLink"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <img
              :src="sponsorPoster | normalizeImg('thumb')"
              class="show-profile-sponsor-poster-img"
            >
          </a>
        </div>
      </div>
    </div>-->
    <!-- <div class="subscibe-thumb"> -->
    <!-- <img class="show-profile-img" :src="poster | normalizeImg('image')" /> -->
    <!-- </div> -->
  </div>
</template>

<script>
  import subscribeService from 'src/service/subscribe-service';
  import parseUrl from 'src/utils/parseUrl';
  import Auth from 'src/service/authService';
  import CarouselSlider from 'src/components/carousel/carousel-slider';
  import TrimEl from 'src/components/common/trim-el.vue';
  import duration from 'src/components/player/duration/duration.vue';

  export default {
    name: 'ShowProfile',
    components: {
      CarouselSlider,
      TrimEl,
      duration,
    },
    props: {
      /** Area with all data as one object */
      area: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        /** Params for sunscription */
        params: {},
        /**
         * State for subbsription.
         * @default false.
         */
        isSubscribed: false,
      };
    },
    computed: {
      /**
       * Flag to show button
       * @deprecated
       */
      button() {
        return !!this.area.subscribe;
      },
      /**
       * Media
       * @deprecated
       */

      media() {
        return this.area.media;
      },
      /**
       * Link to show poster, if is unaviale component don`t show.
       */
      poster() {
        return this.area.backdrop;
      },
      /**
       * Link to show sponsor poster, if is unaviale link don`t show.
       */
      sponsorPoster() {
        return this.area.sponsor_poster;
      },
      /**
       * Link to show sponsor.
       */
      sponsorLink() {
        return this.area.sponsor_link;
      },
    },
    mounted() {
      this.params = parseUrl(this.area.subscribe.split('?')[1]);
      if(Auth.isAuth()) {
        this.checkPrograms();
      }

      this.$bus.$on('changedUserState', this.onChangedUserState);
      //      this.$bus.$on('userSuccessAuth', this.onUserSuccessLogin);
      //      this.$bus.$on('userSuccessLogOut', this.onUserSuccessLogin);
    },
    beforeDestroy() {
      this.$bus.$off('changedUserState', this.onChangedUserState);
      //      this.$bus.$off('userSuccessAuth', this.onUserSuccessLogin);
      //      this.$bus.$off('userSuccessLogOut', this.onUserSuccessLogin);
    },
    methods: {
      onChangedUserState(data = {}) {

        if(data.type === 'logged') {
          this.checkPrograms();

        } else if(data.type === 'logout') {
          this.isSubscribed = false;
        }
      },
      //      onUserSuccessLogin() {
      //        this.checkPrograms();
      //      },
      //      onUserSuccessLogOut() {
      //        this.isSubscribed = false;
      //      },
      checkPrograms() {
        subscribeService.getListSubscribed(Auth.get('user.id'))
          .then(result => {
            if(result.programs && Array.isArray(result.programs)) {
              this.isSubscribed =
                result.programs.indexOf(this.params.program) !== -1;
            }
          });
      },
      unsubscribe() {
        if(!Auth.isAuth()) {
          this.$bus.$emit('toggleAuthPopup', {
            login: true,
          });
          return;
        }
        if(!this.params.program) {
          return;
        }
        subscribeService.unsubscribe(this.params.program)
          .then(() => {
            this.isSubscribed = false;
          });
      },
      subscribe() {
        if(!Auth.isAuth()) {
          this.$bus.$emit('toggleAuthPopup', {
            login: true,
          });
          return;
        }
        if(!this.params.program) {
          return;
        }
        subscribeService.subscribe(this.params.program)
          .then(() => {
            this.isSubscribed = true;
          });
      },
    },
  };
</script>

<style lang="scss">
  @import './_show-profile';
</style>
