<template>
  <div class="settings-container-fluid">
    <vue-headful
      :title="metadata.title"
      :description="metadata.description"
      :image="metadata.image"
      :url="metadata.url"
      :keywords="metadata.keyword"
      :head="metadata.head"
    />
    <!-- <loader v-if="loading"/> -->
    <div class="container-fluid">
      <div class="container-fluid bg-block py-3 px-0">
        <div class="settings-container">
          <h2 class="default-title mb-5">
            <i
              class="far fa-cog mr-2 d-none d-md-inline"
              style="font-size: 1.8em;     vertical-align: middle;"
            />
            <span v-t="{path: 'BLOCK.SETTINGS'}" />
          </h2>
          <div class="container-fluid px-0">
            <template v-if="isUserAccount">
              <div class="block-title-container">
                <span
                  v-t="{path: 'BLOCK.BLOCK.MY_PROFILE'}"
                  class="block-title-container-span"
                />
              </div>
              <div class="row my-4">
                <label
                  for="name"
                  class="col-12 col-md-6 text-left font-weight-bold"
                >
                  <div
                    class="btn-my-account"
                    @click="showFungusAccount()"
                  >
                    <i class="far fa-user mr-2 settings-icon" />
                    <span v-t="{path: 'BLOCK.USER.MY_ACCOUNT.LABEL'}" />
                  </div>
                </label>
              </div>
              <template v-if="isUserAccountData">
                <div
                  v-if="ffbid"
                  class="row my-4"
                >
                  <label
                    for="name"
                    class="col-12 col-md-6 text-left font-weight-bold"
                  >
                    <i class="far fa-globe mr-2 settings-icon" />
                    <span v-t="{path: 'BLOCK.USER.INTERNET_NUMBER'}" />
                  </label>
                  <p
                    class="col-12 col-md-6 text-left my-0 ml-5 ml-md-0"
                  >
                    {{ ffbid || '-' }}
                  </p>
                </div>
                <div class="row my-4">
                  <label
                    for="name"
                    class="col-12 col-md-6 text-left font-weight-bold"
                  >
                    <i class="fal fa-address-card mr-2 settings-icon" />
                    <span v-t="{path: 'BLOCK.USER.CONTACT_NUMBER'}" />
                  </label>
                  <p
                    class="col-12 col-md-6 text-left my-0 ml-5 ml-md-0"
                  >
                    {{ contactnumber || '-' }}
                  </p>
                </div>
              </template>
            </template>
            <div class="block-title-container">
              <span
                v-t="{path: 'BLOCK.BLOCK.MY_PREFERENCE'}"
                class="block-title-container-span"
              />
            </div>
            <div class="row my-4">
              <label
                for="name"
                class="col-12 col-md-6 text-left font-weight-bold"
              >
                <span class="fa-stack mr-2">
                  <i class="far fa-bookmark fa-stack-2x fa-rotate-270" />
                  <i class="fa-stack-1x settings-icon-text">{{ iso2lang }}</i>
                </span>
                <span v-t="{path: 'BLOCK.LANGUAGE'}" />
              </label>
              <div class="col-12 col-md-6 text-left ml-5 ml-md-0">
                <button
                  v-for="(item, key) in languages"
                  :key="key"
                  class="language-select"
                  :class="{'language-select-active': selected === key}"
                  @click="setSelected(key)"
                >
                  {{ item }}
                </button>
              </div>
            </div>
            <div class="row my-4">
              <label
                for="name"
                class="col-12 col-md-6 text-left font-weight-bold"
              >
                <span class="fa-stack mr-2">
                  <i class="far fa-circle fa-stack-2x" />
                  <i class="fas fa-headphones fa-stack-1x" />
                </span>
                <span v-t="{path: 'BLOCK.AUDIO_LANGUAGE'}" />
              </label>
              <div class="col-12 col-md-6 text-left ml-5 ml-md-0">
                <button
                  v-for="(item, key) in languages"
                  :key="key"
                  class="language-select"
                  :class="{'language-select-active': selectedAudio === key}"
                  @click="setAudioSelected(key)"
                >
                  {{ item }}
                </button>
              </div>
            </div>
            <div class="row my-4" v-if="isTerms">
              <div
                class="col-12 col-md-6 text-left font-weight-bold"
              >
                <div
                  class="btn-my-account"
                  @click="$router.push(`terms_and_conditions` )"
                >
                   <span class="fa-stack mr-2">
                <i class="far fa-circle fa-stack-2x" />
                <i class="fas fa-info fa-stack-1x" />
                </span>
                  <span v-t="{path: 'BLOCK.TERMS_SERVICE'}" />
                </div>
              </div>
            </div>
          </div>
          <div class="row my-4" v-if="isPrivacy">
            <div
              class="col-12 col-md-6 text-left font-weight-bold"
            >
              <div
                class="btn-my-account btn-policy"
                @click="$router.push(`privacy_notice` )"
              >
                  <span class="fa-stack mr-2">
                  <i class="policy-icon" />
                </span>
                <span v-t="{path: 'BLOCK.PRIVACY_POLICY'}" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Loader from 'src/components/loader/loader';
  import Config from 'src/service/config.js';
  import { localStore } from 'vimmi-web-utils/esm/localStore';
  import { AuthVariableMixin, ScreenReadyMixin } from 'src/plugins/mixin';
  import Auth from 'src/service/authService';
  import getLanguageKey from 'src/utils/getLanguageKey.js';
  import normaliseMetaTags from 'src/utils/normaliseMetaTags';
  import { amsClient } from 'src/service/ams';
  import { doFetch } from 'vimmi-web-utils/esm/doFetch';

  const localStoreKeys = {
    isNeedOpenVAccount: 'isNeedOpenVAccount',
  };

  export default {
    name: 'SettingsPage',
    components: {
      Loader,
    },
    mixins: [ScreenReadyMixin, AuthVariableMixin],
    data() {
      return {
        screenRequestTime: null,
        loading: false,
        selected: this.$i18n.locale,
        selectedAudio: null,
        template: null,
        languages: Config.get('languages.list'),
        metadata: {},
        consentDtypes: [],
        isPrivacy: false,
        isTerms: false,

        linkNumber: null,
        contactNumberLocal: null,

        // V-accounts
        currentVPage: 'home',
        domain: 'http://110.49.202.163',
      };
    },

    computed: {
      iso2lang() {
        return getLanguageKey(this.selected, 'iso-2');
      },

      ffbid() {
        return this.isFullAuth
          ? Auth.get('user.second_screen')
            ? Auth.get('customer.idValue')
            : null
          : null;
      },
      contactnumber() {
        return this.isFullAuth
          ? Auth.get('user.second_screen')
            ? Auth.get('customer.idValue')
            : this.contactNumberLocal
          : null;
      },
      // FIXME: Feature Toggle fungus-my-account
      isUserAccount() {
        return this.isActiveFeature('fungus-my-account');
      },
      isUserAccountData() {
        return this.isActiveFeature('fungus-my-account-data');
      },
    },
    created() {
      let prevAudio = localStore.get('preferred_audio_lang');
      if (!prevAudio) {
        this.selectedAudio = this.$i18n.locale;
        this.setAudioSelected(this.$i18n.locale);
      } else {
        this.selectedAudio = prevAudio;
      }
      Config.loaded(() => {
        this.template = Config.get('auth.fungus.vaccount');
        this.linkNumber = Config.get('auth.fungus.contact_number');
        this.languages = Config.get('languages.list');
        this.getUserData();
      });

      if (amsClient.get(`customer.abs.${btoa('vaccount')}`)) {
        let t = new URL(atob(amsClient.get(`customer.abs.${btoa('vaccount')}`)));
        this.domain = t.origin;
      }

      this.$bus.$on('changedUserState', this.getUserData);
    },
    beforeDestroy() {
      this.$bus.$off('changedUserState', this.getUserData);
    },

    async mounted() {
      Config.loaded(async () => {
        this.getConsentDtypes();
        let title = this.$i18n.t('BLOCK.SETTINGS');
        let head = await this.getData();
        let data = head && head.seo_metadata ? head.seo_metadata : head;
        this.metadata = normaliseMetaTags(data);
        this.setScreenReady(head, this.screenRequestTime);
        if (Auth.isAuth() && !Auth.isGuest() && localStore.get(localStoreKeys.isNeedOpenVAccount)) {
          localStore.delete(localStoreKeys.isNeedOpenVAccount);
          this.openFungusWindow();
        }
      });

      amsClient.on('auth_state_change', (event, data) => {
        setTimeout(() => {
          if (data.type === 'logged') {
            this.getConsentDtypes();
          }
        });
      });
    },

    methods: {
      getData() {
        this.screenRequestTime = this.setScreenRequest({
          id: 'set',
          itype: 'screen',
          link: `/screen/set/`,
        });

        return amsClient.callAms(`/screen/set/`, { cache: false })
          .then(data => {
            //            this.$stats.set('current.screen', response.data.head);
            this.setCurrentScreen(data.head);
            data.head.cached = !!data.cached;
            // if (!response.cached) {
            this.setScreenResponse(data.head);
            // }
            return data.head;
          })
          .catch(err => {
            return {
              id: 'error',
              itype: `screen_settings`,
              link: `/screen/set/`,
            };
          });
      },
      // getConsentDtypes() {
      //   let user = amsClient.get('user');
      //   if (user) {
      //     this.getConsentDtypes();
      //   } else {
      //     setTimeout(() => {
      //       this.getConsentDtypes();
      //     }, 1000);
      //   }
      // },
      getConsentDtypes() {
        if (!Auth.isAuth() || Auth.isGuest()) {
          this.isPrivacy = localStore.get(`privacy_version_${Auth.get('authInfo.device_id')}`);
          this.isTerms = localStore.get(`terms_version_${Auth.get('authInfo.device_id')}`);
          // this.getDocVersion();
          // this.getVersions();
        } else {
          this.consentDtypes = amsClient.get('user.consent_dtypes') || [];
          this.isPrivacy = this.consentDtypes.includes('privacy');
          this.isTerms = this.consentDtypes.includes('terms');
        }
      },

      setSelected(langIso3) {
        this.selected = langIso3;
        this.$i18n.locale = langIso3;
        localStore.set('lang', langIso3);


        amsClient.conf({
          appInfo: {
            language: langIso3,
          },
        });

        // this.$stats.updateConfig({
        //   language: item,
        // });
      },

      setAudioSelected(item) {
        this.selectedAudio = item;
        localStore.set('preferred_audio_lang', item);
      },

      async getUserData() {
        if (this.isFullAuth) {
          this.contactNumberLocal = await Auth.getContactNumber();
        }
      },
      openFungusWindow() {
        const privateId = encodeURIComponent(Auth.get('user.privateId'));
        const lang = getLanguageKey(localStore.get('lang') || 'tha', 'iso-2');
        const platform = 'WEB';
        const fungusUrl = `${this.domain}/${Config.get('env') !== 'prod' ? 'V-Account/' : ''}${privateId}/${this.currentVPage}?lang=${lang}&platform=${platform}`;
        doFetch(`${this.domain}/V-Account`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `privateId=${encodeURIComponent(privateId)}&lang=${encodeURIComponent(lang)}&platform=${encodeURIComponent(platform)}`,
          // body: JSON.stringify({
          //   privateId,
          //   lang,
          //   platform,
          //   freemiumPeriodEndDate: '',
          // }),
        })
          .finally(() => {
            let link = document.createElement('a');
            link.href = fungusUrl;
            link.target = '_blank';
            link.classList.add('d-none');
            document.body.appendChild(link);
            link.click();
            link.remove();
          });

      },
      showFungusAccount() {
        if (Auth.isAuth() && !Auth.isGuest()) {
          this.openFungusWindow();
        } else {
          localStore.set(localStoreKeys.isNeedOpenVAccount, true);
          // Auth.openFungusLoginPage();
          this.showAuthPopup();
        }
      },

      showAuthPopup() {
        this.$bus.$emit('toggleAuthPopup', {
          guestLogin: true,
        });
      },
      showPdpaPopup(type) {
        this.$bus.$emit('toggleAuthPopup', {
          pdpa_type: type,
          trigger: true,
          accept: false,
          isAcceptPdpa: false,
        });
      },
    },
  };
</script>


<style lang="scss">
  .language-select {
    font-size: 1.2em;
    background: transparent;
    outline: none;
    padding: 0;

    margin: 0 8px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    border: none;
    color: $text-color;

    &:focus,
    &:hover {
      color: $main-color;
      outline: none;
    }

    &-active,
    &:active {
      text-decoration: none;
      color: $main-color;
      font-weight: bold;
    }
  }

  .btn-my-account {
    display: inline-block;
    background: transparent;
    outline: none;
    border-radius: 4px;
    color: $text-color;
    cursor: pointer;

    &:focus,
    &:hover {
      color: $main-color;
      outline: none;
    }

    &-active,
    &:active {
      color: $main-color;
      font-weight: bold;
      outline: none;
    }
  }

  .settings-icon {
    font-size: 1.8em;
    vertical-align: middle;

    &-text {
      margin-left: -2px;
      font-size: 0.6em;
    }
  }

  .settings-container {
    max-width: 800px;
    width: 100%;
    margin: auto;
    padding: 0 16px;
  }

  .block-title-container {
    margin-bottom: 30px;

    &-span {
      font-size: x-large;
    }
  }

  .policy-icon {
    background-image: url("../../assets/icons/privacy_policy.png");
    height: 100%;
    display: block;
    margin-left: 3px;
    background-repeat: no-repeat;
  }

  .btn-policy {
    &:hover {
      .policy-icon {
        background-image: url("../../assets/icons/privacy_policy_hover.png");
      }
    }
  }
</style>
