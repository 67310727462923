export default {
  // Home Page
  HEADER: {
    LOGIN: 'เข้าสู่ระบบ',
    PROFILE: 'ข้อมูลส่วนตัว',
    ACCOUNT: 'บัญชีส่วนตัว',
    LOGOUT: 'ออกจากระบบ',
    SEARCH: 'ค้นหา',
  },

  // Show Page/ Video Page
  VIDEO_COMPONENT: {
    RELATED_LIST: 'เกี่ยวข้อง',
    NO_RELATED_LIST: 'ไม่มีรายการที่เกี่ยวข้อง',

  },

  // Live Page
  EPG: {
    TV_SCHEDULE: 'ผังรายการทีวี',
    TODAY: 'ในวันนี้',
    TOMORROW: 'วันพรุ่งนี้',
  },
  // Error Page
  ERROR: {
    BACK_HOME: 'ไปที่หน้าแรก',
    DISTRO_MSG_1: 'ขออภัยเป็นอย่างสูง',
    DISTRO_MSG_2: 'ระบบไม่สามารถดำเนินการได้ กรุณาลองอีกครั้ง',
    DISTRO_WAIT_MSG: 'กรุณารอสักครู่!<br>ท่านกำลังเชื่อมต่อกับระบบ',
    DISTRO_RETRY: 'ลองอีกครั้ง',
  },


  // Search Page
  SEARCH_PAGE: {
    RESULTS_FOR: 'ผลลัพธ์:',
    NOT_SELECTED: 'ไม่ได้เลือกหมวด',
    NOT_FOUND: 'ไม่พบ "{name}" ไม่ได้เลือกหมวด:',
    LOAD_MORE: 'แสดงเพิ่มเติม',
  },

  // Dashboard
  BLOCK: {
    SETTINGS: 'การตั้งค่า',
    BLOCK: {
      MY_PROFILE: 'ข้อมูลผู้ใช้งาน',
      MY_PREFERENCE: 'การตั้งค่า',
    },
    LANGUAGE: 'ภาษา',
    AUDIO_LANGUAGE: 'เสียงที่ต้องการ',
    TERMS_SERVICE: ' ข้อตกลงและเงื่อนไขการใช้บริการ ',
    PRIVACY_POLICY: ' ประกาศการคุ้มครองข้อมูลส่วนบุคคล ',
    WELCOME: 'ยินดีต้อนรับ',
    BACK: 'กลับ ',
    ACCEPT: 'ตกลง ',
    ACCEPT_AND: 'และ',
    ACCEPT_TITLE: 'ข้อตกลงและเงื่อนไขการใช้บริการ',
    ACCEPT_PARAGRAPH1: 'ขอต้อนรับเข้าสู่การใช้บริการ AIS PLAY',
    ACCEPT_PARAGRAPH2_1: 'แอปพลิเคชันที่รวมความบันเทิงเพื่อทุกคน ผู้ใช้บริการโปรดศึกษาและทำความเข้าใจ ',
    ACCEPT_PARAGRAPH2_2: 'นี้ก่อนการกด "ตกลง" เพื่อเข้าใช้บริการ AIS PLAY ต่อไป หากท่านไม่ตกลงยอมรับตามที่กำหนดไว้นี้ จะทำให้ไม่สามารถเข้าใช้บริการ AIS PLAY ได้',
    LOGIN: 'เข้าสู่ระบบ',
    IMGUEST: 'เข้าใช้งานแบบไม่ลงทะเบียน',
    SAVE: 'บันทึก',
    LENGTH: 'ระยะเวลา:',
    YEAR: 'ปี:',
    VIEWS: 'เข้าชม:',
    SEASONS: 'ฤดูกาล:',
    USER: {
      MY_ACCOUNT: {
        LABEL: 'บัญชีของฉัน',
        OPEN: 'Open',
      },
      INTERNET_NUMBER: 'AIS Intenet number',
      CONTACT_NUMBER: 'Contact number',
    },
  },

  // Autorization form
  LOGIN: {
    LOGIN_TITLE: 'เข้าสู่ระบบ',
    LOGIN: 'เข้าสู่ระบบ',
    CANCEL: 'ยกเลิก',
    WAIT: 'โปรดรอ!',
    FUNGUS: 'Fungus',
    PASSWORD: 'รหัสผ่าน',
    SING_IN: 'เข้าสู่ระบบ',
    REMEMBER_ME: 'จดจำฉัน',
    PLEASE_FILL_IN_LOGIN: 'กรุณากรอกหมายเลขเข้าใช้งานที่ถูกต้อง!',
    PLEASE_FILL_IN_PASSWORD: 'กรุณากรอกรหัสผ่านที่ถูกต้อง!',
    YOUR_PASSWORD_MUST:
      'Your password must be 6-20 characters long, contain letters and numbers, and must not contain spaces, special characters, or email.',
    SUCCESSFULLY_AUTHORIZED: 'คุณได้เข้าสู่ระบบเรียบร้อยแล้ว',

    AUTHENTICATION_DATA:
      'หากคุณมีบัญชีการใช้งานอยู่แล้ว คุณสามารถใช้หมายเลขดังกล่าว เข้าใช้งานได้เลย',
  },

  GUEST_LOGIN: {
    TITLE: 'เข้าสู่ระบบ เพื่อใช้ฟังก์ชั่นนี้',
    YES: 'เข้าสู่ระบบ',
    NO: 'ไม่',
  },

  VIDEO: {

    CONTENT_IS_RESTRICTED:
      'กรุณาเข้าสู่ระบบเพื่อรับชมความบันเทิงสำหรับทุกคน',
  },
  MODAL: {
    PAGE: {
      TITLE: 'ไม่พบเนื้อหา',
      CONTENT:
        'คอนเทนต์ที่เลือกถูกนำออกจากแพลตฟอร์ม ลองค้นหาคอนเทนต์ที่น่าสนใจในหน้าอื่น',
    },
  },

  SUBSRIPTION: {
    LABEL: 'สมัครสมาชิก',
  },
};
