import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
// import productsStore from './products-list.store';

Vue.use(Vuex);

const debug = (process.env.NODE_ENV !== 'production');

export default new Vuex.Store({
  strict: debug,
  // modules: {
  //   productsStore,
  // },
  plugins: (debug) ? [createLogger] : [],
})
