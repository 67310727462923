<!-- TODO: Copy components/search.vue-->
<template>
  <div class="search-container container-fluid">
    <vue-headful
      :title="metadata.title"
      :description="metadata.description"
      :image="metadata.image"
      :url="metadata.url"
      :keywords="metadata.keyword"
      :head="metadata.head"
    />
    <loader v-if="loading" />
    <div v-else>
      <!--<backdrop :area="pageAds">-->
      <!-- <dfp v-if="pageAds" class="dfp-block" placement="top" :ads="pageAds"> </dfp> -->
      <div class="container-fluid bg-block py-3 px-3 px-md-5 text-wrap">
        <h1 class="default-title mb-3">
          <span v-t="{path: 'SEARCH_PAGE.RESULTS_FOR'}" />
          <span class="search-result-not-query-overflowed">{{ searchQuery }}</span>
        </h1>
        <form class="form-check form-check-inline search-result-form flex-wrap">
          <div
            v-for="(res, index) in listOfResultsLabels"
            :key="index"
            class="custom-control custom-checkbox"
          >
            <input
              :id="res.id"
              v-model="res.value"
              type="checkbox"
              class="custom-control-input"
            >
            <label
              class="custom-control-label text-capitalize"
              :for="res.id"
            >{{ res.text }}</label>
          </div>
        </form>
      </div>

      <div
        v-if="!isListOfResultsLabelsSomeValueTrue"
        class="search-result-not bg-block area-block my-3"
      >
        <span
          class="search-result-not-text-message py-3 px-3 px-md-5 text-wrap"
        >
          <span v-t="{path: 'SEARCH_PAGE.NOT_SELECTED'}" />
        </span>
      </div>
      <div
        v-for="(area, index) in searchArea"
        v-else
        :key="index"
        class="area-block bg-block mb-3"
      >
        <!-- <dfp class="dfp-block" v-if="index !== 0 " :area="area" placement="top" /> -->
        <div v-show="listOfResultsLabels[index].value">
          <!-- <transition name="d" :appear="true" appear-active-class="fadeIn" enter-active-class="fadeIn" leave-active-class="fadeOut"> -->
          <pro-carousel
            v-if="area.items.length > 0"
            :area="area"
            class="animated fadeIn px-0"
          />
          <!-- <load-more

            class="row animated fadeIn"
            :area="area"
          >
            <template slot-scope="val">
              <search-card :item="val"/>
            </template>
          </load-more>-->
          <div
            v-else
            class="search-result-not"
          >
            <span class="search-result-not-text-message py-3 px-3 px-md-5">
              <h2
                class="default-title mb-3"
              >{{ listOfResultsLabels[index].text }}</h2>
              <span
                v-t="{path: 'SEARCH_PAGE.NOT_FOUND', args: {name : listOfResultsLabels[index].text}}"
              />
              <!-- {{ $t("SEARCH_PAGE.NOT_FOUND") }} {{}} {{ $t("SEARCH_PAGE.WITH_kEYWORDS") }} -->
              <span class="search-result-not-query-overflowed">{{ searchQuery }}</span>
            </span>
          </div>
          <!-- </transition> -->
          <!-- <div class="row">
            <dfp
              class="dfp-block"
              :area="area"
              placement="bottom"
            />
          </div>-->
        </div>
      </div>
      <!-- <social-section
        v-if="socialArea"
        :area="socialArea"
      />-->
      <!--</backdrop>-->
    </div>
  </div>
</template>

<script>
  import dfpService from 'src/service/dfpService';
  import Loader from 'src/components/loader/loader';
  import JsonLD from 'src/service/jsonld.js';
  import normaliseMetaTags from 'src/utils/normaliseMetaTags';
  import ProCarousel from 'src/components/pro-carousel/pro-carousel.vue';
  import { ScreenReadyMixin } from 'src/plugins/mixin';
  import Config from 'src/service/config';
  import { amsClient } from 'src/service/ams';

  export default {
    name: 'Search',
    components: {
      // Dfp,
      // LoadMore,
      // SocialSection,
      // SearchCard,
      Loader,
      // Backdrop,
      ProCarousel,
    },
    mixins: [ScreenReadyMixin],
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.loading = true;
        vm.getData(to);
      });
    },
    beforeRouteUpdate(to, from, next) {
      this.loading = true;
      this.getData(to);
      next();
      // this.limitSearch = this.visibleCounterSearch;
    },
    data() {
      // let query = this.$router.currentRoute.query.q || '';
      // query = query.substr(0, 256);
      return {
        areas: [],
        head: {},
        metadata: {},
        // ads: {},
        searchQuery: null,
        pageAds: {},
        loading: true,
        searchArea: [],
        socialArea: {},

        listOfResultsLabels: [

          {
            id: 'movie',
            text: 'movies',
            value: true,
          },
          {
            id: 'series',
            text: 'series',
            value: true,
          },

          {
            id: 'episode',
            text: 'episodes',
            value: true,
          },
          {
            id: 'live',
            text: 'live',
            value: true,
          },
        ],
        VOVA_DONT_MAKE_DFP_CONTEXT_CONF: true,
      };
    },
    computed: {
      isListOfResultsLabelsSomeValueTrue() {
        return this.listOfResultsLabels.some(element => element.value);
      },
      titles() {
        return this.area.title;
      },
    },
    watch: {
      'head.title': function(val) {
        this.setScreenReady(this.head, this.screenRequestTime);
      },
    },
    methods: {
      // loadBanners: dfpService.loadBanners,
      hasBanner: dfpService.hasBanner,
      getBanner: dfpService.getBanner,

      fillHeader() {
        JsonLD.update();

        this.head.link = this.head.link && this.head.link[0] ? this.head.link[0] : this.head.link;
        this.head.description = this.head.description
          ? this.head.description
          : `Search for  query '${this.searchQuery}' has ${
            this.areas[0].items.length
          } ${this.areas[0].title} and ${this.areas[1].items.length} ${
            this.areas[1].title
          } .`;
        this.head.search_keyword = this.searchQuery;
        this.head.title = this.head.title
          ? this.head.title
          : 'Search result for ' + this.searchQuery;
        // this.head.keyword = this.head.keyword ?
        //   this.head.keyword :
        //   `Search ${this.searchQuery}`;
        // this.head.image =
        // this.head && this.head.metadata && this.head.metadata.poster
        //     ? this.head.metadata.poster
        //     : location.origin + '/portal/static/favicon/favicon.png';
        // this.head.url = this.head && this.head.metadata && this.head.metadata.url ? this.head.metadata.url : window.location.pathname;
        // this.head.type = this.head && this.head.metadata && this.head.metadata.type ? this.head.metadata.type : "website";

        this.metadata = normaliseMetaTags(this.head);

        // this.metadata.head = {
        //   'meta[property="og:type"]': { content: this.metadata.type },
        //   'meta[name="cXenseParse:pageclass"]': { content : 'frontpage' },
        //   'meta[name="cXenseParse:url"]': { content : this.metadata.url}
        // }
      },

      showError(err) {
        this.$router.push({
          path: '/error/' + encodeURI(err),
        });
      },

      getAreas(areas) {
        Config.set('temp.context', 'search');
        let len = areas.length;
        let res1 = [];
        let res2 = {};
        for (let i = 0; i < len; i++) {
          if (areas[i].itype === 'area_grid') {
            let index = this.listOfResultsLabels.findIndex(function(el) {
              return (
                areas[i].title.toLowerCase()
                  .indexOf(el.text.toLowerCase()) !== -1
              );
            });
            if (index !== -1) {
              // let item = this.listOfResultsLabels[index];
              res1[index] = areas[i];
              this.listOfResultsLabels[index].text = areas[i].title;
              // TODO: for correct aspect ratio
              if (index === 3) {
                res1[index].aspect_ratio = '1x1';
              }
            }
          } else if (areas[i].aclass === 'section_social') {
            res2 = areas[i];
          }
        }

        //        for (let i = 0; i < len; i++) {
        //          if (areas[i].itype === "area_grid") {
        //            if(this.listOfResultsLabels[i]){
        //              res1.push(areas[i]);
        //              this.listOfResultsLabels[i].text = areas[i].title;
        //            }
        //          } else if (areas[i].aclass === "section_social") {
        //            res2 = areas[i];
        //          }
        //        }

        this.searchArea = Object.assign([], res1);

        this.socialArea = res2.length > 0 ? Object.assign({}, res2) : null;
      },

      getData(route) {
        Config.loaded(() => {
          let queryBase =
            route && route.query && route.query.q
              ? route.query.q.substr(0, 256)
              : '';
          let query = encodeURIComponent(
            queryBase.replace(/[\@]/g, '&#x40;')
              .replace(/[\|]/g, '&#x7c;'),
          ); // encodeURI(

          const searchUrl = `/search/?term=${query}`;
          this.screenRequestTime = this.setScreenRequest({
            id: 'search',
            link: searchUrl,
          });
          amsClient.callAms(searchUrl, { cache: false })

            .then(data => {

              //            this.$stats.set('current.screen', response.data.head);
              this.setCurrentScreen(data.head);
              this.$stats.send('search', {
                result_of_click: 'direct_search',
                search_keyword: decodeURIComponent(queryBase),
              });
              this.$nextTick(() => {
                this.areas = data.items;
                data.head.cached = !!data.cached;
                this.head = Object.assign({}, data.head);
                // if (!this.head.cached) {
                this.head.id = this.head.id || 'search';
                this.setScreenResponse(this.head);
                // }
                this.getAreas(this.areas);
                // dfpService.setCurrentPageDfpContext(this.head.ads && this.head.ads.context ? this.head.ads.context : null);

                // if(['page'].indexOf(this.head.itype) !== -1 || this.VOVA_DONT_MAKE_DFP_CONTEXT_CONF) {
                //   dfpService.setCurrentPageDfpContext("search");
                // } else {
                //   dfpService.setCurrentPageDfpContext(
                //     this.head.ads && this.head.ads.context ? this.head.ads.context : 'homepage');
                // }
                this.pageAds = {}; // this.head.ads.placements[0];

                this.searchQuery = queryBase;
                this.fillHeader();
                // this.getContext();

                // if(this.$cm) {
                //   this.$cm.trackAppEvent(this.$cm.APP_EVENTS.SCREEN_READY, {
                //     screen_id: response.config.cm.screen_id,
                //     screen_type: response.config.cm.screen_type,
                //     itype: response.config.cm.itype,
                //     content_title: response.data.head.title || response.config.cm.content_title,
                //   });
                // }

                this.loading = false;
              });
            })
            .catch(err => {
              this.loading = false;
              console.log(err);
              let errStr = `${err.response.status}:  ${err.response.statusText} `;
              this.$plError.setError(err, 'network');

              this.showError(errStr);
            });
        });
      },
    },
  };
</script>

<style lang="scss">
  @import './_Search';
</style>
