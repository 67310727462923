<template>
  <div
    class="pdpa-block animated fadeIn p-4"
    @click.stop
  >
    <h3 class="pdpa-title">
      <span v-t="{ path: titlePDPA }"/>
    </h3>
    <br>
    <div>
      <p class="pdpa-content"/>
    </div>
    <div class="pdpa-button">
      <button
        type="button"
        class="btn auth-cancel rounded"
        @click="close"
      >
        <span
          class="d-inline-block text-truncate align-middle w-100"
        >
          <span v-t="{ path: 'BLOCK.BACK' }" class='back-btn'></span>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
  import Auth from 'src/service/authService';
  import vClickOutside from 'v-click-outside';
  import Config from '../../../service/config';
  import { localStore } from 'vimmi-web-utils/esm/localStore';
  import { vapp } from '@/main';

  export default {
    name: 'PdpaPopup',

    props: {
      isActive: {
        type: [Boolean, String],
        default: true,
      },
      pdpaType: {
        type: String,
      },
      isAcceptPdpa: {
        type: [Boolean, String],
        default: false,
      },
    },
    data() {
      return {
        pdpa_text: '',
        titlePDPA: '',
      };
    },
    created() {
      this.titlePDPA = this.pdpaType === 'terms' ? 'BLOCK.TERMS_SERVICE' : 'BLOCK.PRIVACY_POLICY';
      amsClient.callAms(`/get_doc/${this.pdpaType}/`, { cache: false }).then(function(data) {
        let langFromLocalStore = localStore.get('lang');
        let langUI = langFromLocalStore ? langFromLocalStore : 'tha'
        let popup = document.querySelector('.pdpa-content');
        if (data.head && data.head.content) {
          if (data.head.alt_lang && data.head.alt_lang[langUI]) {
            popup.innerHTML = data.head.alt_lang[langUI].content;
          } else {
            popup.innerHTML = data.head.content;
          }
        }
      });
    },
    mounted() {
      document.querySelector('.auth-container ').scrollTop = 0;
      document.body.style.overflowY = 'hidden';
      document.documentElement .style.overflowY = 'hidden';
      document.body.style.position = 'fixed';
      document.documentElement .style.position = 'fixed';
      vapp.$isPdpaPopup = true;
    },
    methods: {
      close() {
        this.$bus.$emit('toggleAuthPopup', {
          pdpa_type: 'close',
          trigger: false,
          accept: this.isAcceptPdpa,
        });
      },
    },
    destroyed() {
      document.body.style.overflowY = 'auto';
      document.documentElement .style.overflowY = 'auto';
      document.body.style.position = 'static';
      document.documentElement .style.position = 'static';
      vapp.$isPdpaPopup = false;
    },
  };
</script>

<style lang='scss'>
@import './_pdpa-popup';
</style>
