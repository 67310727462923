import UserConfig from '../config/config_user';
import AppConfig from '../config/config_template';
import {deepMerge} from 'vimmi-web-utils/cjs/deepMerge';
import normaliseLanguageCodes from '../utils/normaliseLanguageCodes';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import normalizeUrlProtocol from 'src/utils/normalizeUrlProtocol';
import { deactivateFeature } from "@/plugins/feature-toggle";
import { ConfigService } from 'vimmi-web-utils/cjs/configService';

let DefaultConfig = AppConfig;

if (process.env.NODE_ENV !== 'production') {
  DefaultConfig = deepMerge(AppConfig, UserConfig);
}

class ConfigClass extends ConfigService {
  isLoaded = false;
  callbacks = [];

  constructor() {
    super();
    this.set(DefaultConfig);
  }

  load(_conf, _amsClient) {
    if (_conf.dev_mode) {
      this._confData.mode = 'dev';
    }
    if (this._confData.dev_mode) {
      this._confData.mode = 'dev';
    }

    this._confData.fp_test = _conf.fp_test;
    this._confData.env = _conf.env || 'prod';
    this._confData.guest_mode = _conf.guest_mode;

    /** Player config
     * option: url
     * option: metadata
     * */
    if (_conf.player) {
      if (_conf.player.url) {
        if (process.env.NODE_ENV === 'production' || this._confData.player.replaceLink) {
          this._confData.player.url = normalizeUrlProtocol(_conf.player.url);
        }
      }
      if (_conf.player.metadata) {
        this._confData.player.metadata = _conf.player.metadata;
      }
    }
    if (_conf && _conf.user_profile_settings) {
      this._confData.user_profile_settings = Object.assign(this._confData.user_profile_settings,
        _conf.user_profile_settings);
    }

    /** Languages config
     * option: languages
     * */
    if (_conf.languages) {
      this._confData.languages = deepMerge({}, this._confData.languages,
        { list: normaliseLanguageCodes(_conf.languages.list) });
    }

    /** Fungus config
     * */
    if (_conf.abs) {
      let fungus = {};
      try {
        for (let key in _conf.abs) {
          let fname = Utf8.stringify(Base64.parse(key));
          fungus[fname] = Utf8.stringify(Base64.parse(_conf.abs[key]));
          if (location.protocol === 'https:') {
            // NOTE: 'loginHostUrl', should be as `http`
            if (['registerUrl', 'secondScreenloginHostUrl'].indexOf(fname) !== -1) {
              fungus[fname] = normalizeUrlProtocol(fungus[fname]);
            }
          }
        }
      } catch (e) {
        console.warn('Error while parsing data [abs]', e);
      }
      this._confData.auth.fungus = fungus;
    }

    this.isLoaded = true;
    while (this.callbacks.length) {
      (this.callbacks.shift())();
    }
  }

  loaded(callback) {
    if (this.isLoaded) {
      callback();
    } else {
      this.callbacks.push(callback);
    }
  }
}

export default new ConfigClass();
