import UserConfig from "@/config/config_user";

let configs = {
  'fungus-my-account': true,
  'fungus-my-account-data': false,
  'fungus-login': true,
  'guest-users': true,
  'rtl': false,
  'cm-analytics': true,
  'buh-analytics': true,
  'fbq-analytics': false,
  'favorite-btn': true,
  'history-list': true,
  'favorite-btn-series': true,
  'resume-watch': true,
  'share-btn': true,
  'share-btn-category': false,
  'share-btn-open-app': false,
  'new-live-design': true,
  'block-subscribe-btn': false,
  // 'quest-mode': false,
  'use-scroll-area': true,
  'live-item-circle': false,
  'live-item-background': false,
  'live-item-class': false,
  'cache-for-request': false,
  'ais-header-footer': true,
}

if (process.env.NODE_ENV !== 'production') {

  configs = Object.assign(configs, UserConfig.features || {});

}


export default Object.keys(configs).filter(key => configs[key]);
