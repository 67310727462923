<template>
  <div v-if="items.length" class="container-fluid media-player">
    <div class="row flex-wrap">
      <div
        class="col-12 media-player-container"
        :class="{ 'media-player-extended': extended, 'col-lg-8': !isOne }"
      >
        <media-video
          class="media-player-video"
          :event-identifier="eventIdentifier"
          :autoplay="autoplay"
        />

        <div class="px-3 py-3 mt-3 bg-block media-player-text">
          <h3 class="default-title">
            <span>{{ title }}</span>
          </h3>
          <div class="no-wrap user-action-block">
            <subscription-label v-if="isSubscribeBtn" is-btn />
            <button
              v-if="isShowFavoriteBtn"
              class="btn btn-circle-icon btn-favorite"
              :class="{ active: isFavorite }"
              @click="toggleFavorite"
            >
              <i
                class="fa-heart"
                :class="{ fas: isFavorite, far: !isFavorite }"
              />
            </button>
            <share-btn :item="currentdata" />
          </div>
          <div v-if="description" class="media-player-lead mt-2">
            {{ description }}
          </div>
          <div v-if="currentdata" class="mt-3 text-muted">
            <div v-if="currentdata.year" class="video-year">
              <!-- Year: -->
              <span v-t="{ path: 'BLOCK.YEAR' }" />
              {{ currentdata.year }}
            </div>
            <div v-if="currentdata.duration" class="video-duration">
              <!-- Length: -->
              <span v-t="{ path: 'BLOCK.LENGTH' }" />
              {{ currentdata.duration | duration }}
            </div>
          </div>
        </div>
        <!-- <div
           class="d-flex flex-wrap justify-content-between mx-3 ml-lg-0 mr-lg-0"
         >
           <media-tags :item="currentdata"/>
           &lt;!&ndash; <media-views :item="currentdata" /> &ndash;&gt;
        </div>-->

        <!-- <div class="mx-3 ml-lg-0 mr-lg-3 line-hr"/>
         <div
           v-if="currentdata && currentdata.itype && !isLoading"
           class="d-flex justify-content-start flex-wrap mx-3 ml-lg-0 mr-lg-3 media-player-social"
         >
           <share
             class="my-1 my-md-0"
             :socials="['facebook', 'twitter']"
             :type="'player'"
             :item="currentdata"
           />
           <button
             class="btn btn-add-2-playlist my-1 my-md-0 ml-auto ml-md-0 mr-0 mr-md-3"
             @click.stop="toggleWatchLater()"
           >
             <i
               class="fas fa-margin"
               :class="{'fa-plus': !isWatchLater, 'fa-minus' : isWatchLater}"
             />
             {{ isWatchLater ? this.$i18n.t('VIDEO_COMPONENT.DELETE_FROM_PLAYLIST') :
             this.$i18n.t('VIDEO_COMPONENT.ADD_TO_PLAYLIST') }}
             &lt;!&ndash;{{ $t("VIDEO_COMPONENT.PLAYLIST") }}&ndash;&gt;
           </button>
           <pro-link-button
             v-if="extended"
             class="btn-pro-link ml-md-0 order-5 order-md-3 my-1 my-md-0"
             :videoid="currentdata.id"
             :target="currentdata.link || '/'"
             :text="$t('VIDEO_COMPONENT.GO_TO_SHOW_WEBSITE')"
           />
           &ndash;&gt;
           <media-rating
             class="mr-auto mr-md-0 ml-0 ml-md-auto my-1 my-md-0 media-player-social-rating order-4"
             :media-id="mediaId"
             :rating-external="ratingFromCM"
           />
           <div class="break-line-flex order-3 order-md-5"/>
        </div>-->
      </div>
      <media-related
        v-if="!isOne && currentItem && currentItem.itype && !isLoading"
        class="col-lg-4 col-12 pl-3 pl-lg-0 media-player-related align-self-stretch"
        :video-items="items"
        :event-identifier="eventIdentifier"
        :current="currentItem"
        :not-reload-page="true"
      />
    </div>
    <!--  TODO: Comment comments-module -->
    <!-- <div v-if="extended" class="row">
      <comments-block class="col-lg-8 col-12" v-if="currentItem && currentItem.itype && !isLoading" />
      <div class="col-lg-4 col-12 px-0 pt-2">
        <dfp :area="area" placement="inline" />
      </div>
    </div>-->
  </div>
</template>

<script>
  import MediaVideo from 'src/components/player/video/video';
  import MediaRelated from 'src/components/player/media/media-related';
  // import MediaRating from 'src/components/player/rating/rating';
  // import Share from 'src/components/share-block/share';
  import ProLinkButton from 'src/components/player/prolinkbtn/_prolinkbtn';
  import Dfp from 'src/components/dfp/dfp';
  // import CommentsBlock from 'src/components/comment/comment';
  import contentLanguage from 'src/filters/contentLanguage.js';
  import stripHTML from 'src/filters/stripHtml.js';
  import { AuthVariableMixin, FavoriteMixin } from 'src/plugins/mixin';
  import ShareBtn from 'src/components/share-btn/share-btn';
  import SubscriptionLabel from 'src/components/subscription-label';
  import Device from 'src/service/device-service';
  import { amsClient } from 'src/service/ams';
  import { memoryStore } from 'vimmi-web-utils/cjs/memoryStore';
  import { sessionStore } from 'vimmi-web-utils/cjs/sessionStore';

  export default {
    name: 'MediaPlayer',
    components: {
      MediaVideo,
      // MediaRating,
      // MediaTags,
      // MediaViews,
      MediaRelated,
      // Share,
      ProLinkButton,
      // CommentsBlock,
      Dfp,
      ShareBtn,
      SubscriptionLabel,
    },
    mixins: [FavoriteMixin, AuthVariableMixin],
    props: {
      area: {
        type: Object,
      },
      extended: {
        type: [String, Boolean],
        default: false,
      },
      notReloadPage: {
        type: [String, Boolean],
        default: true,
      },
      link: {
        type: String,
        default: null,
      },
      isOne: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isLoading: true,
        eventIdentifier: Math.ceil(Math.random() * 10000000),
        currentItem: {},
        currentdata: {},
        items: Object.assign([], [this.area]),
        autoplay: true,
      };
    },
    computed: {
      /** nend for mixin */
      mediaId() {
        return this.currentItem.id;
      },
      itype() {
        return this.currentItem.itype;
      },
      title() {
        return stripHTML(contentLanguage(this.currentItem, 'title'));
      },
      description() {
        return stripHTML(
          contentLanguage(this.currentItem, 'description') ||
          contentLanguage(this.currentItem, 'synopsis'),
        );
      },
      // TODO: ft block-subscribe-btn
      isSubscribeBtn() {
        return this.isActiveFeature('block-subscribe-btn');
      },
    },
    created() {
      if (!this.isOne) {
        this.getRelatedList();
      } else {
        this.getCurrentItem();
      }
    },

    mounted() {
      this.isLoading = true;

      window.addEventListener('popstate', this.eventPopstateChange);
      memoryStore.set('main_player', this.eventIdentifier);

      if (
        this.area.hasOwnProperty('genre_code') &&
        this.area.genre_code.length > 0
      ) {
        sessionStore.set('genre', this.area.genre_code[0]);
      } else {
        sessionStore.set('genre', 'none');
      }

      this.autoplay = memoryStore.get('autoplay') || true;
      this.$bus.$on(
        this.eventIdentifier + '_playerReady',
        this.event_playerReady,
      );

      //      this.$bus.$off(this.eventIdentifier + '_changeCurrentVideo', this.event_changeCurrentVideo);
      this.$bus.$on(
        this.eventIdentifier + '_changeCurrentVideo',
        this.event_changeCurrentVideo,
      );

      //      this.$bus.$off(this.eventIdentifier + '_playerNextPlaying', this.event_playerClickPrevNext);
      this.$bus.$on(
        this.eventIdentifier + '_playerNextPlaying',
        this.event_playerClickPrevNext,
      );

      //      this.$bus.$off(this.eventIdentifier + '_playerPrevPlaying', this.event_playerClickPrevNext);
      this.$bus.$on(
        this.eventIdentifier + '_playerPrevPlaying',
        this.event_playerClickPrevNext,
      );

      //      this.$bus.$off(this.eventIdentifier + '_loadedMetadata', this.event_loadedMetadata);
      this.$bus.$on(
        this.eventIdentifier + '_loadedMetadata',
        this.event_loadedMetadata,
      );

      //      this.$bus.$off(this.eventIdentifier + '_playerEnded', this.event_playerEnded);
      // this.$bus.$on(
      //   this.eventIdentifier + '_playerEnded',
      //   this.event_playerEnded
      // );
      // this.$bus.$on(
      //   this.eventIdentifier + '_showNextAfterError',
      //   this.setNextAsCurrent
      // );
    },
    destroyed() {
      window.removeEventListener('popstate', this.eventPopstateChange);

      // this.$bus.$off(
      //   this.eventIdentifier + '_playerEnded',
      //   this.event_playerEnded
      // );
      this.$bus.$off(
        this.eventIdentifier + '_loadedMetadata',
        this.event_loadedMetadata,
      );
      this.$bus.$off(
        this.eventIdentifier + '_playerNextPlaying',
        this.event_playerClickPrevNext,
      );
      this.$bus.$off(
        this.eventIdentifier + '_playerPrevPlaying',
        this.event_playerClickPrevNext,
      );
      this.$bus.$off(
        this.eventIdentifier + '_changeCurrentVideo',
        this.event_changeCurrentVideo,
      );
      this.$bus.$off(
        this.eventIdentifier + '_playerReady',
        this.event_playerReady,
      );
      // this.$bus.$off(
      //   this.eventIdentifier + '_showNextAfterError',
      //   this.setNextAsCurrent
      // );
    },
    methods: {
      event_playerReady() {
        this.$logger.log('MP', 'event_playerReady');
        this.$bus.$emit(this.eventIdentifier + '_playNowVideo', {
          item: this.currentItem,
          autoplay: this.autoplay,
          bigButton: true,
          clickTime: Date.now(),
        });
      },

      event_changeCurrentVideo(options) {
        options = options || {};
        if (options.item.id === this.currentItem.id) {
          return;
        }
        this.$logger.log('on event_changeCurrentVideo', options.item.id);

        this.isLoading = true;
        // if (options.isEndedVideo) {
        //        let pos = this.items.findIndex(el => {
        //          return el.id === options.item.id;
        //        });
        //        this.changeItemsList(pos);

        /** instead this.getCurrentItem() */
        this.currentItem = options.item;
        this.$set(this.currentItem, 'id', options.item.id);
        this.currentdata = Object.assign({}, this.currentItem);
        this.$set(this.currentdata, 'id', this.currentItem.id);
        // this.getAllTagsForVideo();
        this.isLoading = false;
        this.$bus.$emit('setCurrentPage', this.currentItem);
        /** end */

        //        this.getCurrentItem();

        this.$bus.$emit(this.eventIdentifier + '_playNowVideo', {
          item: this.currentItem,
          autoplay: true,
          bigButton: true,
          clickTime: Date.now(),
          ...options,
        });
        // } else {
        // this.getRelatedList(options.item.id);
        // }
        if (options.scrollToView) {
          this.$bus.$emit('scrollVideoToView', true);
        }
      },
      // event_playerEnded() {
      /** only for episodes*/
      // if (this.currentItem.itype === 'item_mov_episode') {

      /** todo: playnext (player) */
      // this.getNextItem()
      //   .then(opt => {
      //     if (opt) {
      //       this.event_changeCurrentVideo({
      //         item: opt.item,
      //         play_type: opt.play_type,
      //         [opt.play_type]: opt[opt.play_type],
      //         isPlayedItem: true,
      //         isEndedVideo: true
      //       });
      //     } else {
      //       this.$bus.$emit(this.eventIdentifier + '_actionOnButton', {
      //         name: 'hideNext'
      //       });
      //     }
      //   })
      //   .catch(error => {
      //     this.$bus.$emit(this.eventIdentifier + '_actionOnButton', {
      //       name: 'hideNext'
      //     });
      //   });
      // }

      //        let nextItem = this.getNextItem();
      //        // this.$bus.$emit('setCurrentPage', nextItem.page);
      //        this.event_changeCurrentVideo({
      //          item: nextItem,
      //          isPlayedItem: true,
      //          isEndedVideo: true
      //        });
      // },
      event_loadedMetadata() {
        this.loadNextPrevItems();
      },
      event_playerClickPrevNext(asset) {
        this.nextLoaded = true;
        this.isLoading = true;
        let itemId =
          asset.media_id ||
          asset.item
            .id; /* && options.extraData && options.extraData.item_id ? options.extraData.item_id :
          (options.id || null);*/
        this.$logger.info('MP', 'event_playerClickPrevNext', asset, itemId);
        // let data = response.data;
        // if (!data.error) {
        this.currentItem = asset.item;
        this.$set(this.currentItem, 'id', this.currentItem.id);
        this.currentdata = Object.assign({}, this.currentItem);
        this.$set(this.currentdata, 'id', this.currentItem.id);
        // this.getAllTagsForVideo();
        this.isLoading = false;
        this.$bus.$emit('setCurrentPage', this.currentItem);
        // }
        //        this.changeItemsList(pos);
        //        this.getCurrentItem();
        // this.$cache({
        //   url: '/get_item/' + itemId + '/'
        // })
        //   .then(response => {
        //     let data = response.data;
        //     if (!data.error) {
        //       this.currentItem = data.head;
        //       this.$set(this.currentItem, 'id', data.head.id);
        //       this.currentdata = Object.assign({}, this.currentItem);
        //       this.$set(this.currentdata, 'id', this.currentItem.id);
        //       this.getAllTagsForVideo();
        //       this.isLoading = false;
        //       this.$bus.$emit('setCurrentPage', this.currentItem);
        //     }
        //   });
      },

      getCurrentItem() {
        if (this.items.length && !this.isOne) {
          this.currentItem = Object.assign({}, this.items[0]);
          this.$set(this.currentItem, 'id', this.items[0].id);
          this.currentdata = Object.assign({}, this.currentItem);
          this.$set(this.currentdata, 'id', this.currentItem.id);
          // this.getAllTagsForVideo();
          this.isLoading = false;

          this.$bus.$emit('setCurrentPage', this.currentItem);
          // this.$cache({
          //   url: this.currentItem.link
          // }).then(response => {
          //   this.currentdata = Object.assign({}, response.data.head) ;
          //   this.$set(this.currentdata, 'id', response.data.head.id);
          //   this.getAllTagsForVideo();
          //   this.isLoading = false;
          //   this.$bus.$emit('setCurrentPage', this.currentItem.page);
          // });
        } else {
          this.currentItem = Object.assign({}, this.area);
          this.$set(this.currentItem, 'id', this.area.id);
          this.currentdata = Object.assign({}, this.currentItem);
          this.$set(this.currentdata, 'id', this.currentItem.id);
          // this.getAllTagsForVideo();
          this.isLoading = false;

          this.$bus.$emit('setCurrentPage', this.currentItem);
        }
      },

      loadNextPrevItems() {
        /**
         * only for episodes
         * */
        if (this.currentItem.itype === 'item_mov_episode') {
          // this.$bus.$emit(this.eventIdentifier + '_loadPrevNextVideo', {
          //   //                  item: opt.item,
          //   //                  play_type: opt.play_type,
          //   //                  [opt.play_type]: opt[opt.play_type],
          //   isNext: true,
          //   bigButton: true
          //   // ...opt,
          // });
          this.getNextItem()
            .then((opt) => {
              if (opt) {
                this.$bus.$emit(this.eventIdentifier + '_loadPrevNextVideo', {
                  //                  item: opt.item,
                  //                  play_type: opt.play_type,
                  //                  [opt.play_type]: opt[opt.play_type],
                  isNext: true,
                  bigButton: true,
                  ...opt,
                });
              } else {
                this.$bus.$emit(this.eventIdentifier + '_actionOnButton', {
                  name: 'hideNext',
                });
              }
            })
            .catch((error) => {
              this.$bus.$emit(this.eventIdentifier + '_actionOnButton', {
                name: 'hideNext',
              });
            });
        }
      },
      getNextItem() {
        let url = `/playnext/${this.currentItem.id}/?nomedia`;
        return amsClient.callAms(url, { cache: false }).then((data) => {
          if (data) {
            return {
              item: data.head,
            };
          }
          return null;
        });
      },

      getPrevItem() {
        return this.items[this.items.length - 1];
      },

      changeItemsList(posCurrent) {
        let previousItems = this.items.splice(0, posCurrent);
        this.items = this.items.concat(previousItems);
      },
      // setNextAsCurrent() {
      //   this.event_playerEnded();
      // },

      getRelatedList(id) {
        if (!id && this.link) {
          amsClient.callAms(this.link, { cache: false }).then((data) => {
            this.items = Object.assign([], [...data.items]);
            this.getCurrentItem();
          });
        } else {
          amsClient.callAms(`/related/${id}/`, { cache: false }).then((data) => {
            this.items = Object.assign([], data.items);
            this.getCurrentItem();
            // Play Now
            this.$bus.$emit(this.eventIdentifier + '_playNowVideo', {
              item: this.currentItem,
              autoplay: true,
              bigButton: true,
              clickTime: Date.now(),
            });
          });
        }
      },
      eventPopstateChange() {
        this.$nextTick(() => {
          // console.log('Router', this.$router.currentRoute);
          let query = this.$router.currentRoute.query.video_id;
          if (query) {
            let item = this.items.find((el) => {
              return el.link === query;
            });
            if (item) {
              this.event_changeCurrentVideo({
                item: item,
                isPlayedItem: true,
                isEndedVideo: true,
              });
            }
          }
        });
      },
    },
  };
</script>

<style lang="scss">
  @import './_media-player';
</style>
