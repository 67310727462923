<template>
  <div class="container-fluid banner-component">
    <carousel-slider
      class="px-0"
      :items="items"
      :row="1"
      :column="1"
      :infinite="true"
      :autoplay="autoplay"
      :step="1"
      :buffer="2"
      :breakpoints="breakpoints"
      :half-mode="true"
      arrow-top-center="item"
    >
      <template v-slot="{ item, position }">
        <!-- <link-wrapper -->
        <div
          :key="position"
          :link="item | linkNormalisation"
          :tabindex="position"
          class="banner-item"
          draggable="false"
          ondragstart="return false;"
          @click="bannerClick(item.b_id || item.id, item)"
        >
          <responsive-image
            v-resize-element="changeAspectRatio"
            img-class="banner-item-image"
            :src="getBannertype(item)"
            resolution-type="image"
            :force-resolution-type="['SD', 'FHD', 'HD']"
            :aspect-ratio="aspectRatio"
            size="cover"
            :title="item | contentLanguage('title')"
            style="cursor: pointer"
          >
            <div
              class="banner-overlay p-4 d-flex flex-column align-items-end justify-content-end"
            >
              <h5
                class="d-inline-block mw-100 default-title text-truncate"
                :title="item | contentLanguage('title')"
              >
                {{ item | contentLanguage('title') }}
              </h5>
              <p
                class="d-inline-block mw-100 my-0 text-truncate"
                :title="item | contentLanguage('subtitle')"
              >
                {{ item | contentLanguage('subtitle') }}
              </p>
              <!-- <trim-el
                class="default-title"
                :text="item | contentLanguage('title')"
                tag="h5"
              />
              <trim-el
                class="my-0"
                :text="item | contentLanguage('subtitle')"
                tag="p"
              /> -->
            </div>
          </responsive-image>
        </div>
        <!-- </link-wrapper> -->
      </template>
    </carousel-slider>

    <pagination
      v-if="isAviablePagination"
      class="banner-pagination"
      :items="items"
      :position.sync="currentPosition"
      :event-identifier="carouselId"
    />
  </div>
</template>

<script>
import CarouselSlider from 'src/components/carousel/carousel-slider';
import TrimEl from 'src/components/common/trim-el.vue';
import pagination from 'src/components/player/main/pagination.vue';
import linkWrapper from 'src/components/link-wrapper.vue';
import responsiveImage from 'src/components/reponsive-image.vue';
import device from 'src/service/device-service.js';
import Config from 'src/service/config';
import { linkNormalisation } from 'src/filters';
import { amsClient } from '../service/ams';
import generateUrl from '../utils/generateUrl';

export default {
  name: 'Banner',
  components: {
    CarouselSlider,
    TrimEl,
    pagination,
    linkWrapper,
    responsiveImage,
  },
  filters: {
    normaliseBanner(item) {
      return item.banner;
    },
  },
  props: {
    /** Area with all data as one object */
    area: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      position: 0,
      carouselId: null,
      breakpoints: [
        {
          width: 992,
          halfMode: false,
        },
      ],
      aspectRatio: '12x5',
      autoplay: true,
    };
  },
  computed: {
    items() {
      return this.area.items || [];
    },
    isAviablePagination() {
      return this.items.length > 1;
    },
    currentPosition: {
      set(position) {
        let diff = position - this.position;
        let direction = diff > 0 ? 'next' : 'prev';
        this.position = position;

        this.$bus.$emit(this.carouselId + '_click', {
          pos: position,
          direction: direction,
        });
      },
      get() {
        return this.position;
      },
    },
  },

  mounted() {
    this.getCarouselId();
    this.position = 0;
    this.$bus.$on(
      this.carouselId + '_isPrevClicked',
      this.setCurrentAsPosition,
    );
    this.$bus.$on(
      this.carouselId + '_isNextClicked',
      this.setCurrentAsPosition,
    );
  },
  beforeDestroy() {
    this.$bus.$off(
      this.carouselId + '_isPrevClicked',
      this.setCurrentAsPosition,
    );
    this.$bus.$off(
      this.carouselId + '_isNextClicked',
      this.setCurrentAsPosition,
    );
  },
  methods: {
    getCarouselId() {
      let carousel = this.$el.querySelector('[component-id]');
      if (carousel) {
        this.carouselId = carousel.getAttribute('component-id');
      }
    },
    getBannertype(item) {
      // TODO: should be used after AMS in Prod.
      if (device.isMobileScreen()) {
        return item.banner;
      } else {
        if (item.banner_stb) {
          return item.banner_stb;
        }
        return item.banner;
      }
    },

    changeAspectRatio() {
      // TODO: should be used after AMS Prod.
      this.aspectRatio = device.isMobileScreen() ? '1x1' : '12x5';
    },

    setCurrentAsPosition(options) {
      let pos = options.position;
      let item = pos + 1 < this.items.length ? pos + 1 : 0;
      this.position = item;
    },

    bannerClick(id, item) {
      if (item.id === null) {
        window.open(item.link, '_blank');
        return;
      }
      const url = `/locate/item/${item.id}`;
      const link = linkNormalisation(item);

      let eventData = { banner_id: id };
      Config.set('temp.context', 'banner');
      this.$stats.send(
        'banner_click',
        Object.assign(
          {
            item_id: id,
            banner_target: linkNormalisation(item),
          },
          eventData,
        ),
      );
      /** assume that it is link to external site*/
      if (item.link && item.link.indexOf('http') !== -1) {
        this.$stats.send('external_application_opened', {
          ext_app_name: item.link,
        });
      }
      if (this.$cm) {
        this.$cm.trackAppEvent(this.$cm.APP_EVENTS.BANNER_CLICK, eventData);
      }

      // redirect
      item.alt_lang = { tha: { title: item.title } };

      amsClient
        .callAms(item.link, { cache: false }) //`/get_item/${item.id}/`
        .then((data) => {
          if (data.head.exclusive) {
            amsClient.callAms(url).then((data) => {
              if (!data?.locations?.screen) {
                this.$router.push({
                  path: link,
                });
              } else {
                const exclusiveUrl = `/exclusive/${
                  data.locations.screen.slug
                }/${generateUrl(item)}`;
                this.$router.push({
                  path: exclusiveUrl,
                });
              }
            });
          } else {
            this.$router.push({
              path: link,
            });
          }
        })
        .catch((error) => {
          //
        });
    },
  },
};
</script>

<style lang="scss">
.banner-component {
  /* background-color: rgba(188, 208, 67, 1); */
  width: 100%;
  position: relative;

  .btn-arrow {
    &:hover {
      svg {
        path {
          stroke: $base-arrow-hover;
        }
      }
    }

    svg {
      transform: scale(0.7);

      path {
        stroke: white;
      }
    }
  }
}

.banner-overlay {
  position: absolute;
  bottom: -2px;
  width: 100%;
  // background-color: rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1)
  );
  color: white;

  /* text-shadow: -2px 2px #000; */
  // padding: 16px 36px 36px;
  height: 50%;
  border-bottom-left-radius: $base-border-radius;
  border-bottom-right-radius: $base-border-radius;
}

.banner-item-image {
  border-radius: $base-border-radius;
  // width: 100%;
  // max-height: 600px;
  // object-position: 0;
  // object-fit: cover;
}

.banner-item {
  // width: 100%;
  /* max-width: 600px; */
  // display: inline-block;
}

.banner-pagination {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
}
</style>

