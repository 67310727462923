<template>
  <div class="container-fluid">
    <div class="bg-block py-3 px-3 px-md-5 row mx-0">
      <!-- NOTE:  Visible on small devices -->
      <div class="col-12 d-md-none">
        <h2 class="default-title responsive-title mb-0">
          {{ title }}
        </h2>
        <small
          v-if="isSeries"
          class="text-muted d-block mb-3"
        >
          <span v-t="{path: 'BLOCK.SEASONS'}"/>
          {{ seasons }}
        </small>
        <!-- <div class="no-wrap mt-2 d-inline-block user-action-block">
            <button
              v-if="isShowFavoriteBtn && mediaId && isSeries"
              class="btn btn-circle-icon btn-favorite"
              :class="{'active': isFavorite}"
              @click="toggleFavorite"
            >
              <i
                class="fa-heart"
                :class="{'fas': isFavorite, 'far': !isFavorite}"
              />
            </button>
            <share-btn :item="area"/>
        </div>-->
      </div>
      <div class="col-12 col-md-4">
        <responsive-image
          :src="poster"
          :aspect-ratio="aspectRatio"
        />
      </div>
      <div class="col-12 col-md-8">
        <!-- NOTE:  Visible on large devices -->
        <div class="d-none d-md-inline-block w-100">
          <h2 class="default-title responsive-title w-100 mb-0">
            {{ title }}
          </h2>
          <small
            v-if="isSeries"
            class="text-muted d-block w-100"
          >
            <span v-t="{path: 'BLOCK.SEASONS'}"/>
            {{ seasons }}
          </small>

          <!-- <small
            class="text-muted d-block my-3"
            v-if="views > 0"
          >
            <span v-t="{path: 'BLOCK.VIEWS'}"/>
            {{views | viewsNormalise}}
          </small>-->
        </div>
        <div class="no-wrap my-2 d-inline-block user-action-block">
          <button
            v-if="isShowFavoriteBtn"
            class="btn btn-circle-icon btn-favorite"
            :class="{'active': isFavorite}"
            @click="toggleFavorite"
          >
            <i
              class="fa-heart"
              :class="{'fas': isFavorite, 'far': !isFavorite}"
            />
          </button>
          <share-btn
            v-if="isShowShare"
            :item="area"
          />
        </div>
        <p
          class="d-block text-muted"
        >
          {{ description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import Config from 'src/service/config';
  import ResponsiveImage from 'src/components/reponsive-image';
  import { FavoriteMixin, AuthVariableMixin } from 'src/plugins/mixin';
  import ShareBtn from 'src/components/share-btn/share-btn';
  import contentLanguage from 'src/filters/contentLanguage';

  export default {
    name: 'CategotyTitle',
    components: {
      ResponsiveImage,
      ShareBtn
    },
    mixins: [FavoriteMixin, AuthVariableMixin],
    props: {
      area: {
        type: Object,
        required: true
      }
    },
    computed: {
      title() {
        return contentLanguage(this.area, 'title');
      },
      poster() {
        return this.area.backdrop;
      },
      aspectRatio() {
        return this.aspect_ratio;
      },
      description() {
        return (
          contentLanguage(this.area, 'description') ||
          contentLanguage(this.area, 'synopsis')
        );
      },
      views() {
        return this.area.views;
      },
      seasons() {
        return this.area.seasons;
      },
      isSeries() {
        return this.area.itype === 'category_series_head';
      },
      mediaId() {
        return this.area.id || null;
      },
      itype() {
        return this.area.itype || null;
      },

      isShowShare() {
        return this.isActiveFeature('share-btn-category');
      },

      isShowFavoriteBtn() {
        // NOTE: The same as in mixin  + local checks
        return (
          this.isActiveFeature('favorite-btn') &&
          this.isActiveFeature('favorite-btn-series') &&
          this.mediaId &&
          this.isSeries
        );
      }
    },
    created() {}
  };
</script>

<style lang="scss">
  .responsive-title {
    font-size: 1.4em;
  }

  @include media-breakpoint-up('lg') {
    .responsive-title {
      font-size: 2vw;
    }
  }
</style>
