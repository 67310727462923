<template>
  <form class="form-inline form-search" @submit.prevent="clickBySearh()">
    <div
      class="form-search-block my-3"
      :class="{ 'container-fluid ': isVisible }"
    >
      <div
        class="col-12 col-md-4 ml-auto pl-0"
        :class="{ 'pr-3': !isVisible, 'pr-0': isVisible }"
      >
        <div class="form-search-input-group input-group mr-5">
          <transition
            name="search-slide"
            enter-active-class="animated slideInRight"
            leave-active-class="animated slideOutRight"
            @after-enter="setFocus"
            @after-leave="clearFocus"
          >
            <input
              v-if="isVisible"
              v-outside-click="close"
              type="text"
              class="form-control form-search-input-form"
              :placeholder="placeHolderSearchTranslate"
              maxlength="255"
              :value="query"
              @input="dynamicSearch"
              @keyup.enter.stop
            />
          </transition>
        </div>
        <div
          v-if="isVisible && items.length"
          class="w-100 position-absolute rounded mt-1 search-query-list"
        >
          <!-- <router-link
            v-for="(item, key) in items"
            :key="key"
            class="p-2 d-block w-100 search-query-item"
            :to="item | linkNormalisation"
            @click.native="onClick(item)"
          >
            iteitemitemitemm: {{ item | contentLanguage('title') }}
          </router-link> -->
          <div
            style="cursor: pointer"
            v-for="(item, key) in items"
            :key="key"
            class="p-2 d-block w-100 search-query-item"
            @click="onClick(item)"
          >
            {{ item | contentLanguage('title') }}
          </div>
        </div>
      </div>
    </div>

    <button
      ref="afterClick"
      type="button"
      class="btn form-search-btnsearch not-focusable"
      :class="{ 'rounded-left': !isVisible, active: isVisible }"
      @click.stop="clickBySearh()"
    >
      <i class="fas fa-search" aria-hidden="true" />
    </button>
  </form>
</template>

<script>
  import Config from 'src/service/config';
  import debounce from 'lodash/debounce';
  import linkNormalisation from 'src/filters/linkNormalisation';
  // import { CancelToken, isCancel } from 'axios';
  import { amsClient } from 'src/service/ams';
  import generateUrl from '@/utils/generateUrl';

  export default {
    name: 'SearchBlock',
    data() {
      return {
        isVisible: false,
        query: '',
        items: null,
        cancel: null,
        timeout: 1 * 1000,
        minLen: 2,
      };
    },
    computed: {
      placeHolderSearchTranslate() {
        return this.$t('HEADER.SEARCH') + '...';
      },
    },
    created() {
      this.dynamicSearchDebounce = debounce(
        this.dynamicSearchRequests,
        this.timeout,
      );
    },
    mounted() {
      window.amsClient = amsClient;
    },
    updated() {

    },
    methods: {
      dynamicSearch(evt) {
        this.query = evt.target && evt.target.value ? evt.target.value : null;
        this.dynamicSearchDebounce();
      },
      close() {
        this.isVisible = false;
      },
      toggleVisibility() {
        this.isVisible = !this.isVisible;

        this.items = [];
        this.query = null;
      },

      dynamicSearchRequests() {
        let queryBase = this.query ? this.query.trim().substr(0, 256) : null;
        if (queryBase && queryBase.length > this.minLen) {
          let query = encodeURIComponent(
            queryBase.replace(/[\@]/g, '&#x40;').replace(/[\|]/g, '&#x7c;'),
          ); // encodeURI(
          const searchUrl = `/dynamic_search/?term=${query}`;

          if (this.cancel) {
            this.cancel('Operation canceled by the user.');
            this.cancel = null;
          }
          amsClient
            .callAms(searchUrl, {
              cache: false,
              // config: {
              // cancelToken: new CancelToken(c => {
              //   // An executor function receives a cancel function as a parameter
              //   this.cancel = c;
              // })
              // }
            })
            .then((data) => {
              this.formatItems(data.items);
              // this.items = response.data.items;
              // console.log(response.data.items);
            })
            .catch((err) => {
              if (isCancel(err)) {
                console.error(err);
                return;
              }
              this.items = [];
            });
        } else {
          this.items = [];
        }
      },

      formatItems(items) {
        this.items = Object.assign([], items);
        // items.forEach(el => {
        //   // if (el.items.length > 0) {
        //   this.items.push(el);
        //   // }
        // });
      },

      search() {
        this.query = this.query.trim().substr(0, 256);
        this.$router.push({
          path: '/search',
          query: {
            q: this.query,
          },
        });

        this.toggleVisibility();
      },

      onClick(item) {
        const url = `/locate/item/${item.id}`;
        const link = linkNormalisation(item);

        // ---
        Config.set('temp.context', 'search');
        this.$stats.send('search', {
          search_keyword: this.query,
          result_of_click: 'dynamic_search',
        });
        amsClient
          .callAms(`/get_item/${item.id}/`, { cache: false })
          .then((data) => {
            if (data.head.exclusive) {
              amsClient.callAms(url).then((data) => {
                if (!data?.locations?.screen) {
                  this.$router.push({
                    path: link,
                  });
                }
                const exclusiveUrl = `/exclusive/${
                  data.locations.screen.slug
                }/${generateUrl(item)}`;
                this.$router.push({
                  path: exclusiveUrl,
                });
              });
            } else {
              this.$router.push({
                path: link,
              });
            }
          });
      },

      setFocus(el) {
        if (el) {
          el.focus();
        }
      },
      clearFocus() {
        this.$refs.afterClick.blur();
      },

      clickBySearh() {
        if (this.isVisible && this.query && this.query.trim().length > 0) {
          this.search();
        } else {
          this.$bus.$emit('toggleAuthPopup', {
            login: true,
            center: true,
            trigger: false,
            formId: 'fromPlayer',
          });
          this.toggleVisibility();
        }
      },
    },
  };
</script>

<style lang="scss">
  @import './_search';
</style>

