<template>
  <div id="app" class="app-container scrollstop" :class="{'distro-error':retryPopup, 'distro-wait': retryWaiting}">
    <div v-if="retryPopup" class="distro-retry">
      <div class="distro-content">
        <img
          :src="require('src/assets/ais-logo/ais_play.png')"
          alt="AIS PLAY"
          height="100%"
          width="100%"
        >
        <div class="distro-content__title">
          <div class="distro-content__title-firts" v-t="{ path: 'ERROR.DISTRO_MSG_1' }"></div>
          <div v-t="{ path: 'ERROR.DISTRO_MSG_2' }"></div>
        </div>
        <div v-if="!retryFromDistro" class="distro-content__btn" v-t="{ path: 'ERROR.DISTRO_RETRY' }"
             @click="getAMSLocation()"></div>
      </div>
    </div>
    <loader v-if="!loaded && !retryPopup" />
    <vue-headful :head="head" />
    <app-header v-if="loaded" />
    <main class="content" :class="extraClass">
      <router-view />
    </main>
    <!-- <app-footer/> -->
  </div>
</template>

<script>
  import AppHeader from 'src/components/header/AppHeader';

  import Config from './service/config';
  import debounce from 'lodash/debounce';
  import JsonLD from 'src/service/jsonld';
  import Loader from 'src/components/loader/loader';
  import { thirdLocation } from 'vimmi-web-utils/cjs/thirdLocation';
  import { fetchAMS } from 'vimmi-web-utils/cjs/ams/fetchAMS';
  import { localStore } from 'vimmi-web-utils/esm/localStore';
  // import Vue from 'vue';
  // import CatchMediaPlugin from 'src/plugins/catch_media';

  export default {
    name: 'App',
    components: {
      Loader,
      AppHeader,
      // AppFooter
    },
    data() {
      return {
        loaded: false,
        retryPopup: false,
        retryFromPlayer: false,
        retryFromDistro: false,
        retryWaiting: false,
        resizeId: 0,
        RESIZE_DELAY: 300,
        prevSize: 0,
        extraClass: '',
        head: {},
      };
    },
    computed: {
      handleResize() {
        return debounce(this.emitEvent, this.RESIZE_DELAY, {
          leading: false,
          trailing: true,
        });
      },
    },
    created() {
      Config.set('temp.context', 'deeplink');
      this.prevSize = window.innerWidth;

      this.$stats.send('app_open');
      this.$bus.$on('handleSpecificClass', (classList) => {
        this.extraClass = classList;
      });
      Config.loaded(() => {
        this.head = {
          'meta[name="google-site-verification"]': {
            content: Config.get('google-site-verification_parameter'),
          },
          'meta[property="fb:app_id"]': {
            content: Config.get('auth.facebook.app_id'),
          },
          'meta[name="prerender-status-code"]': { content: '200' },
        };
        this.loaded = true;

        // if (!_isPreviewMode()) {
        // Vue.use(CatchMediaPlugin, this.$router, {
        //   resursLink: Config.get('analytics.catchMedia.link'), // 'http://1115282512.rsc.cdn77.org/plinks/jssdk/CMSDK-atl-latest.min.js',
        //   partnerId: Config.get('analytics.catchMedia.partner_id'),
        //   appCode: Config.get('analytics.catchMedia.app_code'),
        //   appVersion: Config.get('version'),
        //   allowGeoLocation: Config.get('analytics.catchMedia.allowGeoLocation'),
        //   enableInbox: Config.get('analytics.catchMedia.enableInbox')
        // });
        // }
      });

      this.$bus.$on('openRetryPopup', (data) => {
        this.retryPopup = true;
        this.retryFromPlayer = data.retryFromPlayer;
      });

      window.addEventListener('resize', this.handleResize);
      JsonLD.add();
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
      localStore.delete('firstRetry');
      JsonLD.pop();
      JsonLD.remove();
    },
    mounted() {
      thirdLocation.events.on('thirdLocationRetry', (data) => {
        this.retryFromDistro = data?.retryFromDistro;
        this.retryPopup = true;
      });

      this.$bus.$on('hide-retry-text', () => {
        this.retryWaiting = false;
      });
      // thirdLocation.events.on('updatedDomain', () => {
      //   this.retryWaiting = false;
      // });
      this.addSchemaOrg();
    },
    methods: {
      emitEvent(event) {
        let curr = window.innerWidth;
        if (curr !== this.prevSize) {
          this.$logger.log(
            `Emit event resize from ${this.prevSize}px to ${curr}px `,
            event,
          );
          this.prevSize = curr;
          this.$bus.$emit('resizeWindow', event);
        }
      },
      addSchemaOrg() {
        // TODO: add ais info
        let data = {
          '@context': 'http://schema.org',
          '@type': 'Organization',
          // url: 'https://protvplus.ro/portal/',
          logo: location.origin + '/portal/static/favicon/favicon.png',
          contactPoint: [
            {
              // '@type': 'ContactPoint',
              // telephone: '+4021-9454',
              // contactType: 'customer service'
            },
          ],
        };
        JsonLD.push(data);
      },
      getAMSLocation() {
        this.retryPopup = false;
        this.retryWaiting = true;
        let delay = 0;
        if (localStore.get('firstRetry')) {
          delay = Math.random() * (10 - 5) + 5;
        } else {
          localStore.set('firstRetry', true);
          delay = Math.random() * (4 - 1) + 1;
        }

        setTimeout(() => {
          if (this.retryFromPlayer) {
            this.retryFromPlayer = false;
            this.$bus.$emit('thirdLocationRetry');
          } else {
            fetchAMS.retry();
          }
        }, delay);
      },
    },
  };
</script>

<style lang="scss">
  // NOTE: ORDER IS IMPORTANT
  @import '~smart-app-banner/dist/smart-app-banner.css';
  @import 'src/style/custom_bootstrap';
  @import './style/animate';

  $roboto-font-path: '~roboto-fontface/fonts';
  @import '~roboto-fontface/css/roboto/sass/roboto-fontface-regular.scss';
  @import '~roboto-fontface/css/roboto/sass/roboto-fontface-medium.scss';
  @import './style/icons/fontawesome-all.css';
  @import 'src/style/scroll';
  @import 'src/style/_shared';
  @import 'src/style/app';
  @import 'src/style/_fix';
  // to redefine some styles
  @import 'src/style/ais-style';
  .distro-error {
    .loading-container {
      display: none !important;
    }
  }

  .distro-wait-msg {
    display: none;
  }

  .distro-wait {
    .distro-wait-msg {
      font-size: 1.2rem;
      display: block;
    }
  }

  .distro {
    &-retry {
      position: fixed;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      background: rgba(21, 21, 22, 0.25);;
      z-index: 10000;
      padding: 10px;
    }

    &-content {
      position: relative;
      text-align: center;
      background-color: #151516;
      align-items: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      border-radius: 15px;
      height: 40%;
      width: 40%;
      //padding: 30px 20px;

      @media (max-width: 1090px) {
        width: 50%;
      }
      @media (max-width: 850px) {
        width: 60%;
      }
      @media (max-width: 650px) {
        width: 80%;
      }
      @media (max-width: 400px) {
        width: 90%;
      }
      @media (max-width: 350px) {
        width: 95%;
      }
      @media (max-height: 490px) {
        height: 50%;
      }

      img {
        width: 250px;
        height: 45px;
        margin-bottom: 25px;

        @media (max-width: 1150px) {
          width: 180px;
          height: 35px;
        }
        @include media-breakpoint-down('md') {
          margin-bottom: 20px;
        }
        @include media-breakpoint-down('sm') {
          margin-top: 15px;
          margin-bottom: 10px;
        }
      }

      &__title {
        font-size: 22px;
        color: white;
        font-weight: 500;
        @media (max-width: 1400px) {
          font-size: 18px;
        }
        @media (max-width: 1150px) {
          font-size: 16px;
        }
        @media (max-width: 700px) {
          font-size: 14px;
        }
        @media (max-width: 470px) {
          font-size: 12px;
        }
        @media (max-width: 360px) {
          font-size: 11px;
        }

        &-firts {
          margin-bottom: 25px;
          @include media-breakpoint-down('md') {
            margin-bottom: 20px;
          }
          @include media-breakpoint-down('sm') {
            margin-bottom: 10px;
          }
        }
      }

      &__btn {
        transition: all 200ms;
        text-decoration: none;
        opacity: 1;
        cursor: pointer;
        height: auto;
        width: 350px;
        font-size: 22px;
        line-height: 30px;
        color: #343434;
        background-color: #8BB31D;;
        border-radius: 5px;
        padding: 10px 0;
        font-weight: 500;
        margin-bottom: 15px;
        margin-top: 25px;
        @media (max-width: 1350px) {
          width: 250px;
          font-size: 18px;
        }

        @media (max-width: 1150px) {
          width: 250px;
          padding: 8px 0;
          font-size: 16px;
          margin-top: 30px;
        }
        @media (max-width: 700px) {
          font-size: 14px;
          margin-top: 20px;
        }
        @include media-breakpoint-down('md') {
          margin-bottom: 30px;
          width: 200px;
          padding: 6px 0;
          font-size: 16px;
        }
        @include media-breakpoint-down('sm') {
          font-size: 14px;
          width: 150px;
        }

        &:hover {
          opacity: .7;
        }
      }
    }
  }

</style>
