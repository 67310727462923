<template>
  <section
    v-if="isAvailable"
    :id="getId"
    v-bg-image-js="{name: background, type: 'image'}"
    class="pro-carousel-component container-fluid"
    :class="{'pro-carousel-component-background': !!background}"
  >
    <div class="container-fluid bg-block py-3 px-3 px-md-5 rounded-bottom-0">
      <h2 class="default-title pro-carousel-component-title">
        {{ title }}
      </h2>
    </div>
    <carousel-slider
      class="px-3 px-md-5 pb-3 bg-block rounded-top-0"
      :items="items"
      :row="rows"
      :column="columns"
      :infinite="true"
      :autoplay="autoplay"
      :step="step"
      :arrow-top-center="'item'"
      :breakpoints="brackpoints"
    >
      <template v-slot="{item, position}">
        <carousel-component
          :key="position"
          :item="item"
          :buh-context="buhContext"
          :aspect-ratio="area.aspect_ratio"
        />
      </template>
    </carousel-slider>
  </section>
</template>

<script>
  import CarouselSlider from 'src/components/carousel/carousel-slider';
  import CarouselComponent from 'src/components/carousel/carousel-component';
  import contentLanguage from 'src/filters/contentLanguage.js';

  export default {
    name: 'ProCarousel',
    components: {
      CarouselSlider,
      CarouselComponent,
    },
    props: {
      area: {
        type: Object,
        required: true,
      },
      itemsProp: {
        type: Array,
      },
      buhContext: null,
    },
    computed: {
      items() {
        return this.itemsProp && this.itemsProp.length > 0
          ? this.itemsProp
          : this.area.items && this.area.items.length > 0
            ? this.area.items
            : [];
      },
      title() {
        return contentLanguage(this.area, 'title');
      },
      // mainColor() {
      //   return this.area.main_color;
      // },
      // titleIcon() {
      //   return this.area.icon;
      // },
      grid() {
        return this.area.grid;
      },
      background() {
        return this.area.background;
      },
      // ad() {
      //   return this.area.ad;
      // },
      // buttons() {
      //   return this.area.area.buttons;
      // },
      // buttonColor() {
      //   return area.play_bt_color;
      // },
      // playButtonColor() {
      //   return this.area.play_bt_color;
      // },
      // isFullEpisodes() {
      //   return this.items ? this.items[0].itype === 'item_mov_episode' : false;
      // },

      isAvailable() {
        return this.items && this.items.length > 0;
      },
      gridConfig() {
        return this.grid
          ? this.grid.split('_')
          : this.area.itype === 'section_season'
            ? [1, 5, 6, 'manual']
            : [1, 8, 8, 'manual'];
      },
      brackpoints() {
        return this.area.itype === 'section_season'
          ? [
            {
              width: 1024,
              column: 5,
            },
            {
              width: 960,
              column: 4,
            },
            {
              width: 640,
              column: 3,
            },
            {
              width: 380,
              column: 2,
            },
          ]
          : [
            {
              width: 1920,
              column: 8,
            },
            {
              width: 1280,
              column: 7,
            },
            {
              width: 1024,
              column: 6,
            },
            {
              width: 960,
              column: 5,
            },
            {
              width: 640,
              column: 4,
            },
            {
              width: 385,
              column: 4,
              step: 3,
            },
          ];
      },
      autoplay() {
        return this.gridConfig[3] === 'auto';
      },
      rows() {
        return parseInt(this.gridConfig[0]); //
      },
      columns() {
        let col = parseInt(this.gridConfig[1]);
        return col;
      },
      step() {
        return +this.gridConfig[2];
      },
      getId() {
        return this.area.title.toLowerCase()
          .replace(/\s/g, '');
      },
    },
  };
</script>

<style lang="scss" src="./_pro-carousel.scss"></style>
