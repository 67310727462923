import { Device } from 'vimmi-web-utils/esm/device';

class DeviceService extends Device {
  constructor() {
    super();
  }
}

const device = new DeviceService();
export default device;
