import i18n from 'src/lang/index.js';

export default function(item = null, field = null) {
  if (!item || !field) {
    return '-';
  }
  let original = item[field];
  let lang = i18n.locale;
  let lang_field = 'alt_lang';
  if (
    item[lang_field] &&
    item[lang_field][lang] &&
    item[lang_field][lang][field]
  ) {
    return item[lang_field][lang][field];
  }
  return original;
}
