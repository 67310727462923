import { amsClient } from 'src/service/ams';

export default (function() {
  let context = {};
  let requests = {};
  return {
    get: async (app, page, options) => { // async function how to work?
      page = page || '/conf/';
      options = options || {};

      let url = page;
      if (options.sync) {
        return context[url];
      }
      if (requests[url]) {
        return requests[url];
      }
      if (_isPreviewMode()) {
        url += (url.indexOf('?') === -1 ? '?' : '&') + 'preview=1';
      }
      requests[url] = amsClient.callAms(url, { cache: false })
        .then((data) => {
          delete requests[url];
          context[url] = data;
          return context[url];
        });
      return await requests[url];
    },
  };
})();
