<template>
  <div class="container-fluid">
    <div class="row mx-0  bg-block ">
      <h2 class="col-12 default-title pt-3">
        {{ areaTitle }}
      </h2>
      <responsive-image
        class="col-12  py-3"
        :aspect-ratio="aspectRatio"
        :src="areaImage"
      />
    </div>
    <!-- <div
        class="col-12 px-0 media-player-container"
        :class="{'media-player-extended':extended, 'col-lg-8' : !isOne}"
      >
        <media-video
          class="media-player-video"
          :event-identifier="eventIdentifier"
          :autoplay="autoplay"
        />
        <h3
          class="mx-3 ml-lg-0 mr-lg-0 media-player-title"
        >{{ currentdata.title | stripHTML }}</h3>
        <div
          v-if="currentdata.description"
          class="mx-3 ml-lg-0 mr-lg-3 media-player-lead"
        >{{ currentdata.description || currentdata.synopsis | stripHTML }}
        </div>
        <div v-if="currentdata" class="mx-3 ml-lg-0 mr-lg-3">
          <div v-if="currentdata.year" class="video-year">Year: {{ currentdata.year}}</div>
          <div v-if="currentdata.duration" class="video-duration">Length: {{ currentdata.duration | duration}}</div>
        </div>
    </div>-->
  </div>
</template>

<script>
  import MediaVideo from 'src/components/player/video/video';
  import Responsive from 'src/components/responsive.vue';
  import ResponsiveImage from 'src/components/reponsive-image.vue';
  import Auth from 'src/service/authService';
  import contentLanguage from 'src/filters/contentLanguage.js';
  import { amsClient } from 'src/service/ams';

  export default {
    name: 'MediaPlayer',
    components: {
      MediaVideo,
      ResponsiveImage,
      Responsive,
    },
    props: {
      area: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        // isLoading: true,
        eventIdentifier: Math.ceil(Math.random() * 10000000),
        video: {},
        // currentItem: {},
        // currentdata: {},
        // items: Object.assign([], [this.area]),
        // allTagsForVideo: [],
        // watchLaterTagName: 'watch_later',
        // ratingFromCM: 0,
        autoplay: false,
      };
    },
    computed: {
      areaImage() {
        return this.area.backdrop;
      },
      areaTitle() {
        return contentLanguage(this.area, 'title');
      },
      aspectRatio() {
        return '16x9';
      },

      // mediaId() {
      //   return this.currentItem.iid || +this.currentItem.id;
      // },
      // isWatchLater() {
      //   return this.allTagsForVideo.indexOf(this.watchLaterTagName) !== -1;
      // }
    },
    created() {
      // if (!this.isOne) {
      //   this.getRelatedList();
      // } else {
      //   this.getCurrentItem();
      // }
    },

    mounted() {

      window.addEventListener('popstate', this.eventPopstateChange);

      this.$bus.$on(
        this.eventIdentifier + '_playerReady',
        this.event_playerReady,
      );

         this.$bus.$on(
        this.eventIdentifier + '_changeCurrentVideo',
        this.event_changeCurrentVideo,
      );

      //      this.$bus.$off(this.eventIdentifier + '_playerNextPlaying', this.event_playerClickPrevNext);
      this.$bus.$on(
        this.eventIdentifier + '_playerNextPlaying',
        this.event_playerClickPrevNext,
      );

      //      this.$bus.$off(this.eventIdentifier + '_playerPrevPlaying', this.event_playerClickPrevNext);
      this.$bus.$on(
        this.eventIdentifier + '_playerPrevPlaying',
        this.event_playerClickPrevNext,
      );

      //      this.$bus.$off(this.eventIdentifier + '_loadedMetadata', this.event_loadedMetadata);
      this.$bus.$on(
        this.eventIdentifier + '_loadedMetadata',
        this.event_loadedMetadata,
      );

      //      this.$bus.$off(this.eventIdentifier + '_playerEnded', this.event_playerEnded);
      this.$bus.$on(
        this.eventIdentifier + '_playerEnded',
        this.event_playerEnded,
      );
      this.$bus.$on(
        this.eventIdentifier + '_showNextAfterError',
        this.setNextAsCurrent,
      );
    },
    destroyed() {
      window.removeEventListener('popstate', this.eventPopstateChange);

      this.$bus.$off(
        this.eventIdentifier + '_playerEnded',
        this.event_playerEnded,
      );
      this.$bus.$off(
        this.eventIdentifier + '_loadedMetadata',
        this.event_loadedMetadata,
      );
      this.$bus.$off(
        this.eventIdentifier + '_playerNextPlaying',
        this.event_playerClickPrevNext,
      );
      this.$bus.$off(
        this.eventIdentifier + '_playerPrevPlaying',
        this.event_playerClickPrevNext,
      );
      this.$bus.$off(
        this.eventIdentifier + '_changeCurrentVideo',
        this.event_changeCurrentVideo,
      );
      this.$bus.$off(
        this.eventIdentifier + '_playerReady',
        this.event_playerReady,
      );
      this.$bus.$off(
        this.eventIdentifier + '_showNextAfterError',
        this.setNextAsCurrent,
      );
    },
    methods: {
      event_playerReady() {
        this.$logger.log('MP', 'event_playerReady');
        this.$bus.$emit(this.eventIdentifier + '_playNowVideo', {
          item: this.currentItem,
          autoplay: this.autoplay,
          bigButton: true,
          clickTime: Date.now(),
        });
      },

      event_changeCurrentVideo(options) {
        // options = options || {};
        // if (options.item.id === this.currentItem.id) {
        //   return;
        // }
        // this.$logger.log('MP', 'event_changeCurrentVideo', options.item.id);
        // this.isLoading = true;
        // // if (options.isEndedVideo) {
        // let pos = this.items.findIndex(el => {
        //   return el.id === options.item.id;
        // });
        // this.changeItemsList(pos);
        // this.getCurrentItem();
        // this.$bus.$emit(this.eventIdentifier + '_playNowVideo', {
        //   item: this.currentItem,
        //   autoplay: true,
        //   bigButton: true
        // });
        // // } else {
        // // this.getRelatedList(options.item.id);
        // // }
        // if (options.scrollToView) {
        //   this.$bus.$emit('scrollVideoToView', true);
        // }
      },
      event_playerEnded() {
        // let nextItem = this.getNextItem();
        // // this.$bus.$emit('setCurrentPage', nextItem.page);
        // this.event_changeCurrentVideo({
        //   item: nextItem,
        //   isPlayedItem: true,
        //   isEndedVideo: true
        // });
      },
      event_loadedMetadata() {
        this.loadNextPrevItems();
      },
      event_playerClickPrevNext(options) {
        let pos = this.items.findIndex(el => {
          return el.id === options.id;
        });

        this.isLoading = true;

        this.$logger.info('MP', 'event_playerClickPrevNext', options, pos);
        this.changeItemsList(pos);
        this.getCurrentItem();
      },

      getCurrentItem() {
        if (this.items.length && !this.isOne) {
          this.currentItem = Object.assign({}, this.items[0]);
          this.$set(this.currentItem, 'id', this.items[0].id);
          this.currentdata = Object.assign({}, this.currentItem);
          this.$set(this.currentdata, 'id', this.currentItem.id);
          // this.getAllTagsForVideo();
          this.isLoading = false;

          this.$bus.$emit('setCurrentPage', this.currentItem);
          // this.$cache({
          //   url: this.currentItem.link
          // }).then(response => {
          //   this.currentdata = Object.assign({}, response.data.head) ;
          //   this.$set(this.currentdata, 'id', response.data.head.id);
          //   this.getAllTagsForVideo();
          //   this.isLoading = false;
          //   this.$bus.$emit('setCurrentPage', this.currentItem.page);
          // });
        } else {
          this.currentItem = Object.assign({}, this.area);
          this.$set(this.currentItem, 'id', this.area.id);
          this.currentdata = Object.assign({}, this.currentItem);
          this.$set(this.currentdata, 'id', this.currentItem.id);
          // this.getAllTagsForVideo();
          this.isLoading = false;

          this.$bus.$emit('setCurrentPage', this.currentItem);
        }
      },

      loadNextPrevItems() {
        let nextItem = this.getNextItem();
        let prevItem = this.getPrevItem();
        this.$bus.$emit(this.eventIdentifier + '_loadPrevNextVideo', {
          item: nextItem,
          isNext: true,
          bigButton: true,
        });
        this.$bus.$emit(this.eventIdentifier + '_loadPrevNextVideo', {
          item: prevItem,
          isNext: false,
          bigButton: true,
        });
      },

      getNextItem() {
        let item = this.items[1];
        return item;
      },

      getPrevItem() {
        return this.items[this.items.length - 1];
      },

      changeItemsList(posCurrent) {
        let previousItems = this.items.splice(0, posCurrent);
        this.items = this.items.concat(previousItems);
      },
      setNextAsCurrent() {
        this.event_playerEnded();
      },

      getRelatedList(id) {
        if (!id && this.link) {
          amsClient.callAms(this.link, { cache: false })
            .then(data => {
              this.items = Object.assign([], [...data.items]);
              this.getCurrentItem();
            });
        } else {
          amsClient.callAms(`/related/${id}/`, { cache: false }).then(data => {
            this.items = Object.assign([], data.items);
            this.getCurrentItem();
            // Play Now
            this.$bus.$emit(this.eventIdentifier + '_playNowVideo', {
              item: this.currentItem,
              autoplay: true,
              bigButton: true,
              clickTime: Date.now(),
            });
          });
        }
      },
      // getAllTagsForVideo() {
      //   if (false && this.$cm) {
      //     this.$cm.readAllMediaTags(this.mediaId, result => {
      //       if (result.length > 0) {
      //         let res = [];
      //         let objProp = result[0].tags;
      //         for (let key in objProp) {
      //           if (+objProp[key].value > 0) {
      //             res.push(key);
      //           }
      //         }
      //         this.allTagsForVideo = res;
      //       } else {
      //         this.allTagsForVideo = [];
      //       }
      //
      //       let rating = 0;
      //       if (
      //         result.length > 0 &&
      //         result[0].hasOwnProperty('tags') &&
      //         result[0].tags.hasOwnProperty('rating')
      //       ) {
      //         let val = result[0].tags.rating.value;
      //         let totals = result[0].tags.rating.totals;
      //         if (val > 0) {
      //           rating = val;
      //         } else if (totals.length > 0) {
      //           let totalRating = totals.reduce((calculateRating, el) => {
      //             return (calculateRating += +el.count * +el.value);
      //           }, 0);
      //           let totalLen = totals.reduce((calculateLen, el) => {
      //             return calculateLen + +el.count;
      //           }, 0);
      //           rating = Math.round(totalRating / totalLen);
      //         }
      //       }
      //       this.ratingFromCM = rating;
      //     });
      //   } else {
      //     this.allTagsForVideo = [];
      //     this.ratingFromCM = 0;
      //   }
      // },
      toggleWatchLater() {
        if (!Auth.isAuth()) {
          this.$bus.$emit('toggleAuthPopup', {
            login: true,
          });
          return;
        }
        if (this.$cm) {
          if (this.isWatchLater) {
            this.$cm.deleteMediaTag(this.mediaId, this.watchLaterTagName);
            let ind = this.allTagsForVideo.indexOf(this.watchLaterTagName);
            this.allTagsForVideo.splice(ind, 1);
          } else {
            this.$cm.addMediaTag(this.mediaId, this.watchLaterTagName, 1);
            this.allTagsForVideo.push(this.watchLaterTagName);
          }
        }
      },

      eventPopstateChange() {
        this.$nextTick(() => {
          // console.log('Router', this.$router.currentRoute);
          let query = this.$router.currentRoute.query.video_id;
          if (query) {
            let item = this.items.find(el => {
              return el.link === query;
            });
            if (item) {
              this.event_changeCurrentVideo({
                item: item,
                isPlayedItem: true,
                isEndedVideo: true,
              });
            }
          }
        });
      },
    },
  };
</script>

