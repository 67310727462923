/**
 *  function to normalisize lang key as need. 
 * If `type` === iso-2 return the 2 sumbols language code
 * If `type` === iso-3 return the 3 sumbols language code
 * @param {String} key The language key 
 * @param {('iso-2'|'iso-3')} type type of normalisation
 * @return {String} The language key based on `type`
 */
export default function (key, type ){
  // NOTE: order is important 0 - always 3 symbol, 1 - always 2 symbol code
  let normalisation = {
    eng: ['eng', 'en'],
    tha: ['tha', 'th'],
    ro: [ 'rom', 'ro'],
  };

  let code = null ;
  Object.keys(normalisation).some(el => {
    if (normalisation[el].indexOf(key) > -1) {
      code = el;
      return true;
    }
    return false;
  });

  if (type === 'iso-2') {
    return normalisation[code][1];
  } else if (type === 'iso-3') {
    return normalisation[code][0];
  }
  return key;
}