<template>
  <div
    v-click-outside="close"
    class="auth-block animated fadeIn"
  >
    <h5
      v-for="(mess, index) in messages"
      :key="index"
      class="auth-message"
      :class="'message-' + mess.status"
    >
      {{ mess.text }}
    </h5>
    <h3 class="auth-title">
      <span v-t="{path: 'FORGOT_PASSWORD.FORGOT_PASSWORD_TITLE'}"/>
      <button
        type="button"
        class="auth-title-close"
        aria-label="Close"
        @click="close()"
      >
        <span
          aria-hidden="true"
          class="fas fa-times"
        />
      </button>
    </h3>
    <form class="container-fluid auth-form">
      <div class="row form-group auth-form-group auth-email">
        <label for="email">
          <span v-t="{path: 'REGISTER_FORM.EMAIL'}"/>
        </label>
        <input
          id="email"
          v-model="userData.email"
          required
          type="email"
          :placeholder="$t('REGISTER_FORM.EMAIL')"
          class="form-control"
          :class="{'is-invalid' : validator.email }"
        >
        <span
          v-if="validator.email"
          class="invalid-feedback"
        >
          {{ validator.email[0] }}
        </span>
      </div>

      <div class="row flex-nowrap">
        <button
          type="button"
          class="col-6 btn auth-login rounded"
          :disabled="wait"
          @click="submitForm()"
        >
          <span class="d-inline-block text-truncate align-middle w-100">
            <span v-t="{path: 'FORGOT_PASSWORD.RESTORE'}"/>
          </span>
        </button>
        <button
          type="button"
          class="col-6 btn auth-register rounded"
          @click.stop="close()"
        >
          <span class="d-inline-block text-truncate align-middle w-100">
            <span v-t="{path: 'FORGOT_PASSWORD.BACK_TO_LOGIN'}"/>
          </span>
        </button>
      </div>
    </form>
  </div>
</template>
<script>
  import Auth from 'src/service/authService';
  import vClickOutside from 'v-click-outside';

  export default {
    name: 'ForgotBlock',
    components: {
    },
    directives: {
      clickOutside: vClickOutside.directive,
    },
    props: [],
    data() {
      return {
        wait:false,
        timeToShowMessage: 4 * 1000,
        userData: {
          email: '',
        },
        messages: [],
        fix18n: 0,
        validator: {},

      }
    },
    computed: {},
    mounted() {
      this.fix18n = this.$i18n;
      this.validator = {};
    },
    methods: {
      translateVal(val) {
        return this.fix18n.t(val);
      },
      close() {
        // this.$body.closeModal();
        this.$emit('update:isActive', false);
      },
      submitForm: function () {
        if (!this.userData.email) {
          this.showMessage({
            status: 'warning',
            text: this.translateVal("REGISTER_FORM.CORRECT_MAIL_ADDRESS"),
          });
          return;
        }
        this.wait = true;

        Auth.forgotPass(this.userData.email)
          .then((response) => {
            this.validator = {};
            if(response.error){
              this.wait = false;
              this.showMessage({
                status: 'error',
                text: response.message,
              });
            } else {
              this.showMessage({
                status: 'success',
                text: this.translateVal("FORGOT_PASSWORD.TO_YOUR_MAIL_WAS_SENT_INSTRUCTIONS"),
              });
              setTimeout(() => {
                this.close();
              }, 3000);
            }
          })
          .catch((error) => {
            console.error(error);

            this.wait = false;
            this.validator = (error.hasOwnProperty('response') && error.response.hasOwnProperty('data') && error.response.data.hasOwnProperty('validator'))? error.response.data.validator : {};

            this.showMessage({
              status: 'error',
              text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
            });
          });
      },
      showMessage(messageData, time){
        this.messages.push(messageData);
        setTimeout(() => {
          let pos = this.messages.indexOf(messageData);
          this.messages.splice(pos, 1);
        }, time || this.timeToShowMessage)
      },
    },
  }
</script>
