<template>
  <link-wrapper
    :link="item | linkNormalisation"
    class="row my-2 related-item"
    @click="onClick()"
  >
    <card-thumb-image
      class="col-4 px-0 related-item-image"
      :item="item"
      :hide-duration="true"
      aspect-ratio="27x40"
    />
    <div class="col-8 pr-0 d-flex flex-column justify-content-between py-2">
      <div class="related-item-text">
        <trim-el
          class="d-block h-100 text-break"
          :text="title"
          tag="span"
        />
      </div>
      <div class="related-item-additional text-muted text-nowrap">
        <div v-if="year">
          <span v-t="{path: 'BLOCK.YEAR'}"/>
          {{ year }}
        </div>
        <div>
          <span v-t="{path: 'BLOCK.LENGTH'}"/>
          {{ duration | duration }}
        </div>
      </div>
    </div>
  </link-wrapper>
</template>

<script>

  import Config from 'src/service/config';
  import LinkWrapper from 'src/components/link-wrapper.vue';
  import TrimEl from 'src/components/common/trim-el.vue';
  import contentLanguage from 'src/filters/contentLanguage.js';

  import CardThumbImage from 'src/components/cards/card-thumb-image.vue';

  export default {
    name: 'RelatedItem',
    components: {
      TrimEl,
      LinkWrapper,
      CardThumbImage,
    },
    props: {
      item: {
        type: Object,
        required: true,
      },
      index: {
        type: Number,
        default: 0,
      },
      relatedMethod: {
        type: String,
        required: true,
      },
    },
    computed: {
      year() {
        return this.item.year;
      },
      duration() {
        return this.item.duration;
      },
      title() {
        return contentLanguage(this.item, 'title');
      },
    },
    methods: {
      onClick() {
        Config.set('temp.context', 'related');
        this.$stats.send('item_related', {
          related_position: `pos_${this.index + 1}`,
          related_item_id: this.item.id,
          item_id: this.item.id,
          ams_url: `${location.origin}${this.item.link || ''}`,
          related_method: this.relatedMethod,
        });
      },
    },
  };
</script>

<style lang="scss">
  .related-item {
    color: $base-link-color;

    &:hover {
      color: $base-link-hover;
    }

    &-text {
      flex: 1;
      max-height: calc(100% - 42px);
      flex-basis: calc(100% - 42px);
    }

    &-additional {
      font-size: 0.8em;
      color: rgba(125, 125, 125, 1);
    }
  }

  @include media-breakpoint-up('lg') {
    .related-item .card-thumb-add {
      font-size: 0.6em;
    }
  }
</style>

