import ErrorPlugin from './error';

/**
 * Installation procedure
 *
 * @param Vue
 * @param conf
 */
const install = function (Vue, conf = {}) {
      let plugin = new ErrorPlugin(conf);
      Vue.prototype.$plError = plugin;
}
// Export module
export default {install}
