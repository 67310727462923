import Auth from 'src/service/authService';
import StockStore from 'src/store/stock';

export const ScreenReadyMixin = {
  methods: {
    setCurrentScreen(screen) {
      if (!this.$stats.get('current.screen')) {
        this.$stats.set('current.screen', screen);
        this.$stats.send('app_ready');
      }
      this.$stats.set('current.screen', screen);
      return true;
    },
    setScreenRequest(head) {
      if (!head.id && head.link) {
        let screenId = head.link.replace(/(^\/|\/$)/g, '')
          .split('/');
        head.id = screenId.length >= 2 ? screenId[1] : 'unknown';
      }

      this.$stats.send('screen_request', {}, {
          ams_url: `${location.origin}${head.link}`,
        },
      );
      return Date.now();
    },
    setScreenResponse(head) {
      let link = head.link && Array.isArray(head.link) ? head.link[0] : head.link;
      if (!head.id && head.link) {
        let screenId = link.replace(/(^\/|\/$)/g, '')
          .split('/');
        head.id = screenId.length >= 2 ? screenId[1] : 'unknown';
      }

//      this.$stats.set('current.screen', head);
      this.setCurrentScreen(head);
      this.$stats.send('screen_response', Object.assign({ ams_url: `${location.origin}${head.link}` }, head.cached ? { cached: true } : {}));
    },
    setScreenReady(head, requestTime = null) {
      let eventData = {
        screen_id: head.id,
        screen_type: this.$stats.utils.normaliseScreentype(head.link),
        itype: head.itype,
        search_keyword: head.search_keyword,
      };
      if (head.id && head.id !== 'error') { // do not send for screen error (ErrorPage)
//        this.$stats.set('current.screen', head);

        this.setCurrentScreen(head);
        let extraData = {
          screen_ready_time: !requestTime ? 0 : (Date.now() - requestTime) / 1000,
          ams_url: `${location.origin}${head.link}`,
        };
        if (head.cached) {
          extraData.cached = true;
        }
        this.$stats.send('screen_ready', extraData);
      }

      if (this.$cm) {
        // console.log(`target cm`, head);

        this.$cm.trackAppEvent(this.$cm.APP_EVENTS.SCREEN_READY, eventData);
      }
    },
    normaliseScreentype(link) {

      if (!link) {
        return 'unknow';
      }
      let [empty, type, id] = link.split('/');
      if (type === 'screen') {
        return type + '/' + id;
      }
      return type;
    },
  },
};

export const AuthVariableMixin = {
  data: () => ({
    isAuth: false,
    isGuest: false,
    isFullAuth: false,
  }),

  created() {
    this.$bus.$on('changedUserState', this.changeAuth);
    this.$bus.$on('sync', this.amsSync);
    this.isAuth = Auth.isAuth();
    this.isGuest = Auth.isGuest();
    this.isFullAuth = Auth.isAuth() && !Auth.isGuest();
  },
  mounted() {
    if (Auth.isAuth()) {
      this.changeAuth({ type: 'logged' });
    }
  },
  beforeDestroy() {
    this.$bus.$off('changedUserState', this.changeAuth);
    this.$bus.$off('sync', this.amsSync);
  },
  methods: {
    amsSync(data) {
      if (this.onSync) {
        if (Object.keys(data.addedProducts).length || Object.keys(data.removedProducts).length) {
          this.onSync(data);
        }
      }
    },
    changeAuth(data) {
      const prevIsAuth = this.isAuth;
      const prevIsGuest = this.isGuest;
      const prevIsFullAuth = this.isFullAuth;
      this.$nextTick(() => {

        this.isAuth = Auth.isAuth();
        this.isGuest = Auth.isGuest();
        this.isFullAuth = Auth.isAuth() && !Auth.isGuest();
        if (this.onChangedUserState && (prevIsAuth !== this.isAuth || prevIsGuest !== this.isGuest || prevIsFullAuth !== this.isFullAuth)) {
          this.onChangedUserState(data);
        }
      });


      // if (this.onChangedUserState) {
      //   this.onChangedUserState(data);
      // }
    },
  },
};

export const FavoriteMixin = {

  computed: {
    isFavorite() {
      return StockStore.isFavoriteItem({
        item_id: this.mediaId, itype: this.itype,
      });
    },
    isShowFavoriteBtn() {
      return this.isActiveFeature('favorite-btn') && (Auth.isAuth() || this.isGuest);
    },
  },

  methods: {
    toggleFavorite() {
      if (!Auth.isAuth() || this.isGuest) {
        this.$bus.$emit('toggleAuthPopup', {
          guestLogin: true,
          trigger: true,
        });
        return;
      }
      let operation = this.isFavorite ? 'stop' : 'start';
      StockStore.toggleFavorite({
        operation,
        item_id: this.mediaId,
        itype: this.itype,
      });
    },
  },
};
