import { Logger } from 'logger-js';
import VimmiAI from 'vimmi-analytics-integration/dist/vimmi-analytics-integration.esm';
import Config from 'src/service/config';
import AnalyticsUtils from './utils';
import { amsClient } from '@/service/ams';
import getLanguageKey from '../../utils/getLanguageKey';
import { localStore } from 'vimmi-web-utils/esm/localStore';
import device from '../../service/device-service';
import CryptoJS from 'crypto-js';
import Auth from '../../service/authService';
import Configurable from '../../service/configurable';
import { isActiveFeature } from '@/plugins/feature-toggle';

export default class Analytics extends Configurable {
  static instances = {};
  static _instance;

  constructor() {
    super();
    this.logger = new Logger('Analytics');
    this.utils = AnalyticsUtils;


    if (isActiveFeature('buh-analytics') || isActiveFeature('fbq-analytics')) {
      let drivers = [];
      if (isActiveFeature('buh-analytics')) {
        drivers.push(VimmiAI.DRIVERS.BUH);
      }
      if (isActiveFeature('fbq-analytics')) {
        drivers.push(VimmiAI.DRIVERS.FBQ);
      }
      this.analyticModule = amsClient.vimmiAIInit(VimmiAI, { drivers: drivers });
      amsClient.attachAnalytics(this);
    }


    if (isActiveFeature('buh-analytics')) {
      this.analyticModule.conf({
        buhDeviceId: this.buhDeviceIdPart,
      });
    }
  }

  set(key, value) {
    return super.set(key, value);
  }

  get deviceType() {
    return device.isAnyMobile() ? 'mob' : 'web';
  }

  _getFormatedError(error) {
    error = error || {};
    return {
      error_name: error.type || error.name || 'unknown error',
      error_code: error.status || error.statusCode || error.code,
      error_description: error.message || error.status || 'unknown error',
      error_source: error.source || ((error.status || error.statusCode || (error.code <= 2 && error.code >= 1) || error.code === 4) ?
        'HTTP' : 'Player'),
    };
  }

  get buhDeviceIdPart() {
    if (!localStore.get('buh_device_id_part')) {
      let randomString = CryptoJS.lib.WordArray.random(16).toString();
      localStore.set('buh_device_id_part', randomString);
    }
    return localStore.get('buh_device_id_part');
  }

  _getEventData(evn, options) {
    let data = {
      app_info: Config.get('app_info'),
      app_version: Config.get('version'),
      customer: process.env.VUE_APP_CUSTOMER,
      user_id: Auth.get('user.id'),
      session_id: Auth.get('user.sid'),
      classification: (amsClient.get('user.classes') || []).join(',') || 'non-ais',//Auth.get('user.classes'),
      server: location.host,
    };
    if (!Auth.get('user.id')) {
      data.temp_user_id = Auth.get('extraData.temp_id');
    }

    let screen = this.get('current.screen') || {};
    switch (evn) {

      case 'auth_sent':
      case 'auth_ok':
      case 'sync_sent':
      case 'sync_ok':
        data = Object.assign(data, {
          screen_id: screen.id,
        });
        break;
      case 'auth_error':
      case 'sync_error':
        data = Object.assign(data, this._getFormatedError(options.error));
        break;
      case 'search':
      case 'share_item':
      case 'banner_click':
      case 'try_play_premium':
        data = Object.assign(data, {
          screen_id: screen.id,
          screen_type: AnalyticsUtils.normaliseScreentype(screen.link),
        });
        break;
      case 'ams_request':
      case 'screen_request':
      case 'screen_ready':
      // if (screen.cached) {
      //   data.cached = !!screen.cached;
      // }
      case 'products_updated':
      case 'item_related':
      case 'favorite_item':
      case 'unfavorite_item':
      case 'ams_response':
      case 'screen_response':
        if (evn === 'screen_ready') {
          data.itype = screen.itype;
          data.item_id = screen.id;
        }
        if (evn === 'screen_request' || evn === 'screen_response') {
          data.login_type = Auth.get('user.login_type');
        }
        data = Object.assign(data, {
          screen_id: screen.id,
          screen_type: AnalyticsUtils.normaliseScreentype(screen.link),
        });
        break;
      case 'app_error':
        data = Object.assign(data, this._getFormatedError(options.error));
      case 'app_ready':
        data.screen_id = screen.id || null;
      case 'app_open':
        data.login_type = Auth.get('user.login_type');
        // case 'app_closed':
        break;
      case 'fungus_login_failure':
      case 'fungus_logout_failure':
        data = Object.assign(data, this._getFormatedError(options.error));
        break;
    }

    return data;
  }

  static instance() {
    if (!Analytics._instance) {
      Analytics._instance = new Analytics();
    }
    return Analytics._instance;
  }

  destroy(analyticName) {
    this.logger.info('Destroying:', analyticName);
    if (Analytics.instances[analyticName] && Analytics.instances[analyticName].destroy) {
      Analytics.instances[analyticName].destroy();
    }
    delete Analytics.instances[analyticName];
  }

  updateConfig(confData = {}) {
    for (let key in confData) {
      let val = confData[key];
      if (key === 'language') {
        val = getLanguageKey(localStore.get('lang') || 'tha', 'iso-2');
      }
      this.analyticModule.conf(key, val);
    }
  }

  send(event, event_extra_data = {}, options = {}) {
    let computedExtraData = Object.assign({}, this._getEventData(event, options), event_extra_data);
    this.analyticModule.send(event, computedExtraData, {
      beacon: !!options.beacon,
      only: options.only || null,
      buffer_timestamp: options.buffer_timestamp || null,
    });
  }
}