<template>
  <b-navbar
    v-cloak
    fixed="top"
    type="dark"
    class="navbar-component"
  >
    <!-- <nav class="navbar navbar-toggleable-md navbar-light fixed-top"> -->
    <side-bar
      v-if="!$isPreviewMode"
      :active.sync="isActiveSidebar"
    />
    <auth-block v-if="!$isPreviewMode || isConsent"/>

    <div class="container-fluid p-3 header-container">
      <div
        class="nav-heading"
        style="z-index: 0"
      >
        <!-- <a class="navbar-toggler nav-heading-toogle" @click="toggleSideBar()">
          <transition
            name="HamburgerOpen"
            :enter-active-class="isActiveSidebar? 'rotateIn' : 'fadeIn'"
            :leave-active-class="isActiveSidebar? 'fadeOut' : 'rotateOut'"
            mode="out-in"
            :duration="{enter:isActiveSidebar? 300 : 100 , leave: isActiveSidebar? 0 : 300 }"
          >

          </transition>
        </a>-->
        <button
          class="navbar-toggler nav-heading-toogle"
          @click="toggleSideBar()"
        >
          <img
            key="open"
            :src="require('../../assets/icons/hamburger.svg')"
            width="100%"
            height="100%"
          >
        </button>
        <router-link
          to="/"
          class="nav-heading-brand"
          @click.native="closeAuthPopup()"
        >
          <img
            :src="require('src/assets/ais-logo/ais_play.png')"
            alt="AIS PLAY"
            height="100%"
            width="100%"
          >
        </router-link>
      </div>

      <div class="header-navs">
        <b-navbar-nav
          class="header-navs-login justify-content-end"
          toggleable="false"
        >
          <div
            v-if="!isFullAuth"
            class="nav-item"
          >
            <a
              v-if="isConfLoaded"
              class="nav-link link-login"
              @click.stop="showAuthPopup()"
            >
              <!-- // FIXME: after update don`t work directives -->
              <!-- <span v-t="{path: 'HEADER.LOGIN'}"/> -->
              {{ $t('HEADER.LOGIN') }}
            </a>
            <!--            <auth-block v-if="!$isPreviewMode"/>-->
          </div>
          <div
            v-else
            class="nav-item"
          >
            <a
              v-if="isConfLoaded"
              class="nav-link link-login"
              @click="logout()"
            >
              <!-- // FIXME: after update don`t work directives -->
              {{ $t('HEADER.LOGOUT') }}
              <!-- <span v-t="{path: 'HEADER.LOGOUT'}"/> -->
            </a>
          </div>
          <!-- <b-nav-item-dropdown
            v-if="isAuth"
            class="nav-item"
            type="link"
            no-caret
            no-flip
          >
            <template slot="button-content">
              <i
                v-show="isNewInboxMessages"
                class="fas fa-exclamation animated bounce"
              />
              <span> {{ profileTitle }}
              </span>
            </template>
            <b-dropdown-item @click="logout()">
              <span v-t="{path: 'HEADER.LOGOUT'}"/>
            </b-dropdown-item>
          </b-nav-item-dropdown>-->
        </b-navbar-nav>
      </div>
      <header-search-block/>
    </div>
  </b-navbar>
</template>

<script>
  import SideBar from 'src/components/sidebar/sidebar.vue';
  // import SideBar from 'src/components/header/SideBar.vue';
  import HeaderSearchBlock from 'src/components/search-block/SearchComponent.vue';
  import Auth from 'src/service/authService';
  import Config from 'src/service/config';
  import AuthBlock from 'src/components/auth-block/auth-block';
  import device from 'src/service/device-service';
  import { formatDate } from 'src/filters';
  import { AuthVariableMixin } from 'src/plugins/mixin';
  import AcceptPopup from '../auth-block/accept-popup/accept-popup';
  import { amsClient } from '../../service/ams';
  import { localStore } from 'vimmi-web-utils/esm/localStore';

  export default {
    name: 'AppHeader',
    components: {
      SideBar,
      AuthBlock,
      HeaderSearchBlock,
      AcceptPopup,
    },
    mixins: [AuthVariableMixin],
    data() {
      return {
        isActiveSidebar: false,
        isConfLoaded: false,
        isDesktop: true,
        isNewInboxMessages: false,
        isConsent: false,
      };
    },
    computed: {
      // NOTE: fix https://redmine.vidnt.com/issues/5638 Changing profile by changing name.
      // Maybe problem with templetes or so?
      //      isAuth() {
      //        return Auth.isAuth() && !Auth.isSilent();
      //      },
      profileTitle() {
        return this.$i18n.t('HEADER.PROFILE');
      },
    },
    //    watch: {
    //      $route(to, from) {
    //        this.onUserLogoutTimeout();
    //        this.checkInboxMessages();
    //      }
    //    },
    created() {
      this.isConsent = true;
      Config.loaded(() => {
        this.isConfLoaded = true;
        this.isDesktop = !device.isAnyMobile();
      });
    },
    mounted() {
      this.$nextTick(function() {
        if (device.isHuaweiDevice()) {
          let aisHeader = document.getElementById('ais_header');
          aisHeader.style.display = 'none';
          let appContainer = document.querySelector('.app-container');
          let navbarComponent = document.querySelector('.navbar-component');
          let sideBar = document.querySelector('.side-bar');
          appContainer.style.top = '0';
          navbarComponent.style.top = '0';
          sideBar.style.top = '0';
        }
      });
      // this.$bus.$on('toggleAuthPopup', this.toggleAuthPopup);
      //      this.$bus.$on('userSuccessAuth', this.onUserSuccessLogin);
      //      this.$bus.$on('userSuccessLogOut', this.onUserSuccessLogout);
    },
    destroyed() {
      // this.$bus.$off('toggleAuthPopup', this.toggleAuthPopup);

      //      this.$bus.$off('userSuccessAuth', this.onUserSuccessLogin);
      //      this.$bus.$off('userSuccessLogOut', this.onUserSuccessLogout);
    },
    methods: {
      formatDate,
      toggleSideBar() {
        this.isActiveSidebar = !this.isActiveSidebar;
        if (this.isActiveSidebar) {
          this.$body.openSideBar();
        } else {
          this.$body.closeModal();
        }
      },

      //      onUserLogoutTimeout() {
      //        let res = Auth.logoutByTimeOut();
      //        let forsed = Auth.logoutForsed();
      //      },

      //      onUserSuccessLogin() {
      //        this.$logger.log('AH', 'onUserSuccessLogin');
      //        //        setTimeout(() => {
      //        //          this.isAuth = true;
      //        //        }, 1000);
      //      },

      //      onUserSuccessLogout(event) {
      //        this.$logger.log('AH', 'onUserSuccessLogout');
      //        //        this.isAuth = false;
      //      },

      logout() {
        Auth.logOut();
      },

      showAuthPopup() {
        if (!Config.get('guest_mode')) {
          Auth.openFungusLoginPage();
          return;
        }
        if (!this.$consent) {
          this.$bus.$emit('toggleAuthPopup', {
            login_type: true,
            trigger: true,
          });
          return;
        }
        if (Config.get('mode') === 'dev') {
          this.$bus.$emit('toggleAuthPopup', {
            login: true,
          });
        } else {
          Auth.openFungusLoginPage();
        }
      },
      closeAuthPopup() {
        this.$bus.$emit('toggleAuthPopup', {
          login: true,
          trigger: false,
        });
      },
      //      checkInboxMessages() {
      //        if (this.$cm) {
      //          this.isNewInboxMessages = this.$cm.hasInboxMessage();
      //        } else {
      //          this.isNewInboxMessages = false;
      //        }
      //      }
    },
  };
</script>

<style lang="scss">
  @import './_AppHeader';
</style>
