<template>
  <transition
    name="loader-anima"
    enter-active-class="fadeIn"
    leave-active-class="fadeOut"
  >
    <div
      class="loading-container animated "
      :style="{'color': colorLoader}"
    >
      <div>
        <i class="fas fa-5x fa-spinner fa-pulse" />
      </div>
      <div class="distro-wait-msg text-white mt-2 text-center" v-html="$t('ERROR.DISTRO_WAIT_MSG')"></div>
    </div>
  </transition>
</template>

<script>

  export default {
    name: 'Loader',
    props: {
      color: {
        type: String,
        default: '#8bb31d',
      },
    },
    data() {
      return {
        colorLoader: '#8bb31d',
      };
    },
    created() { // this is faster

    },
    beforeDestroy() {
      this.$bus.$emit('hide-retry-text');
    },
  };
</script>

<style lang="scss">
  @import './_loader';
</style>
