<template>
  <div :class="{'sub-label text-nowrap text-uppercase' : !isBtn, 'd-inline-block': isBtn }">
    <button
      v-if="isBtn"
      class=" btn btn-subscribe-big"
    >
      <span v-t="{path: 'SUBSRIPTION.LABEL'}"/>
    </button>
    <span
      v-else
      v-t="{path: 'SUBSRIPTION.LABEL'}"
      class="d-block text-truncate"
    />
  </div>
</template>


<script>
  export default {
    name: 'SubscriptionLabel',
    props: {
      isBtn: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss">
// @import '~/bootstrap/scss/_utilities.scss';
// @import '~/bootstrap/scss/_buttons.scss';
// @import 'src/style/_shared.scss';
.sub-label {
  background-color: $subscribe-bg;// 
  color: $subscribe-color;
  padding: $subscribe-padding;
  min-width: 0;
  border-radius: $subscribe-border-radius;
  font-weight: 500;
}

// TODO: rewrite code without btn extend
.btn.btn-subscribe-big {
  // @extend .btn;
  margin-left: 0;
  margin-right: 8px;
  margin-bottom: 8px;
  margin-top: 8px;

  background-color: $main-color;
  color: $background-color;
  // border-radius: 16px;
  border-radius: 32px;
  padding: 6px 16px;
}

// @include media-breakpoint-up('md') {
//   .sub-label {
//     font-size: 0.5em;
//   }
// }
// @include media-breakpoint-up('xl') {
//   .sub-label {
//     font-size: 0.8em;
//   }
// }
</style>