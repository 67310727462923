<template>
  <div class="container-fluid bg-block list-wrapper px-0">
    <h2
      ref="title"
      class="list-wrapper-title py-3 px-3 my-0"
    >
      <slot name="title">
        <span v-t="{path: `LIST.TITLE`}"/>
      </slot>
    </h2>

    <template v-if="isAviable">
      <transition-group
        name="slide"
        tag="div"
        class="row mx-0 list-wrapper-items align-items-start"
      >
        <template
          v-for="(item, index) in filteredItems"
          class="animated"
        >
          <!-- :key="item.id" -->
          <slot
            :item="item"
            :index="index"
          />
        </template>
      </transition-group>
    </template>
    <div
      v-else
      class="d-flex justify-content-center align-items-center list-wrapper-items h-100"
    >
      <slot
        name="no-items"
        class="d-flex"
      >
        <span v-t="{path: `LIST.NO_ITEMS`}"/>
      </slot>
    </div>
  </div>
</template>

<script>
  import Device from 'src/service/device-service.js';

  export default {
    name: 'WrapperList',
    props: {
      items: {
        type: Array,
        required: true,
      },
      limit: {
        type: Number,
        default: -1,
      },
    },

    data() {
      return {
        isLoaded: false,
        ht: 600,
        maxHeight: '600px',
      };
    },
    computed: {
      isAviable() {
        return this.items && this.items.length;
      },
      filteredItems() {
        return this.limit <= 0 ? this.items : this.items.slice(0, this.limit);
      },
    },
  };
</script>

<style lang="scss">
.list-wrapper {
  height: auto;
  will-change: transform;
  overflow-y: auto;
  overflow-x: hidden;
}

.list-wrapper-items {
  will-change: transform;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
}

  .list-wrapper-title {
    font-size: 1.4em;
  }

@include media-breakpoint-up('lg') {
  .list-wrapper {
    height: 90vh;
  }

  .list-wrapper-items {
    max-height: calc(100% - 70px);
  }
}
</style>
