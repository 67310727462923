<template>
  <div
    v-click-outside="close"
    class="auth-block animated fadeIn"
  >
    <h5
      v-for="(mess, index) in messages"
      :key="index"
      class="auth-message"
      :class="'message-' + mess.status"
    >
      {{ mess.text }}
    </h5>
    <h3 class="auth-title">
      <span v-t="{path: 'REGISTER_FORM.REGISTER_TITLE'}"/> 
      <button
        type="button"
        class="auth-title-close"
        aria-label="Close"
        @click="close()"
      >
        <span
          aria-hidden="true"
          class="fas fa-times"
        />
      </button>
    </h3>
    <form
      class="container-fluid auth-form"
      autocomplete="on"
      @submit.prevent.stop="submitForm()"
    >
      <div class="row form-group auth-form-group auth-name">
        <label for="name">
          <span v-t="{path: 'REGISTER_FORM.USERNAME'}"/>
        </label>
        <input
          id="name"
          v-model="userData.name"
          type="text"
          name="name"
          class="form-control"
          autocomplete="name"
          :class="{'is-invalid' : validator.name }"
          :placeholder="$t('REGISTER_FORM.USERNAME')"
          minlength="1"
          required="required"
        >
        <span
          v-if="validator.name"
          class="invalid-feedback"
        >
          {{ validator.name[0] }}
        </span>
      </div>

      <div class="row form-group auth-form-group auth-email">
        <label for="email">
          <span v-t="{path: 'REGISTER_FORM.EMAIL'}"/>
        </label>
        <input
          id="email"
          v-model="userData.email"
          type="email"
          name="email"
          class="form-control"
          autocomplete="username"
          :class="{'is-invalid' : validator.email }"
          :placeholder="$t('REGISTER_FORM.EMAIL')"
          required="required"
        >
        <span
          v-if="validator.email"
          class="invalid-feedback"
        >                
          {{ validator.email[0] }}
        </span>
      </div>
      <div class="row form-group auth-form-group auth-pass">
        <label for="password">
          <span v-t="{path: 'REGISTER_FORM.PASSWORD'}"/>
        </label>
        <input
          id="password" 
          v-model="userData.password"
          type="password"
          name="password"
          autocomplete="new-password"
          class="form-control"
          :class="{'is-invalid' : validator.password }"
          :placeholder="$t('REGISTER_FORM.PASSWORD')"
          required="required"
          minlength="6" 
        >
        <span
          v-if="validator.password"
          class="invalid-feedback"
        >                
          {{ validator.password[0] }}
        </span>
      </div>

      <div class="custom-control custom-checkbox auth-form-remember">
        <input
          id="agree_rule"
          v-model="userData.rule"
          type="checkbox"
          class="custom-control-input"
        >
        <label
          is="i18n"
          path="REGISTER_FORM.AGREE_WITH_TERMS"
          tag="label"
          for="agree_rule"
          class="custom-control-label"
        >
          <a
            class="auth-form-forgot-link"
            href="http://www.protv.ro/confidentialitate"
            target="_blank"
            rel="nofollow"
            place="terms"
          >
            <span v-t="{path: 'REGISTER_FORM.TERMS_AND_CONDITIONS'}"/> 
          </a>
        </label>

        <!-- <label class="custom-control-label" for="agree_rule">  -->
              
        <!-- {{ $t("REGISTER_FORM.AGREE_WITH") }} <a class="auth-form-forgot-link" href="#">{{ $t("REGISTER_FORM.TERMS_AND_CONDITIONS")}} </a> {{ $t("REGISTER_FORM.AND")}}
               <a class="auth-form-forgot-link" href="#"> {{ $t("REGISTER_FORM.PRIVACY_POLICY")}} </a> </label> -->
      </div>

      <div class="custom-control custom-checkbox auth-form-remember">
        <input
          id="agree_rule_politica"
          v-model="userData.rule_politica"
          type="checkbox"
          class="custom-control-input"
        >
        <label
          is="i18n"
          path="REGISTER_FORM.AGREE_WITH_POLICY"
          tag="label"
          for="agree_rule_politica"
          class="custom-control-label"
        >
          <a
            class="auth-form-forgot-link"
            href="http://www.protv.ro/about-cookies"
            target="_blank"
            rel="nofollow"
            place="policy"
          >
            <span v-t="{path: 'REGISTER_FORM.PRIVACY_POLICY'}"/>
          </a>
        </label>
      </div>

      <div class="custom-control custom-checkbox auth-form-remember">
        <input
          id="subscribe"
          v-model="userData.subscribe"
          type="checkbox"
          class="custom-control-input"
        >
        <label
          class="custom-control-label"
          for="subscribe"
        >
          <span v-t="{path: 'REGISTER_FORM.SUBSCRIBE_TO_NEWSLETTER'}"/>
        </label>
      </div>

      <div class="row flex-nowrap">
        <button
          type="submit"
          class="col-6 btn auth-login rounded"
        >
          <span class="d-inline-block text-truncate align-middle w-100">
            <span v-t="{path: 'REGISTER_FORM.REGISTER'}"/>
          </span>
        </button>
        <button
          type="button"
          class="col-6 btn auth-register rounded"
          @click.stop="close()"
        > 
          <span class="d-inline-block text-truncate align-middle w-100">
            <span v-t="{path: 'REGISTER_FORM.BACK_TO_LOGIN'}"/>
          </span>
        </button>
      </div>
    </form>
  </div>
</template>
<script>
  import Auth from 'src/service/authService';
  import vClickOutside from 'v-click-outside';

  export default {
    name: 'RegisterBlock',
    components: {
    },
    directives: {
      clickOutside: vClickOutside.directive,
    },
    props: [],
    data() {
      return {
        timeToShowMessage: 4 * 1000,
        userData: {
          name: '',
          email: '',
          password: '',
          rule: false,
          rule_politica: false,
          subscribe: false,
        },
        messages: [

        ],
        fix18n : {},
        validator: {},
      }
    },
    computed: {},
    mounted() {
      this.fix18n = this.$i18n;
      this.validator = {};
    },
    methods: {
      translateVal (val) {
        return this.fix18n.t(val);
      },
      close() {
        // this.$body.closeModal();
        this.$emit('update:isActive', false);
      },
      submitForm: function () {
        if (!this.userData.email) {
          this.showMessage({
            status: 'warning',
            text: this.$i18n.t("REGISTER_FORM.CORRECT_MAIL_ADDRESS"),
          });
          return;
        } else if (!this.userData.password || this.userData.password.length < 6) {
          this.showMessage({
            status: 'warning',
            text: this.$i18n.t("REGISTER_FORM.PASSWORD_CAN_NOT_BE_LESS_THAN_6_CHARACTERS"),
          });
          return;
        } else if (this.userData.name === null || this.userData.name === undefined || this.userData.name === '') {
          this.showMessage({
            status: 'warning',
            text: this.$i18n.t("REGISTER_FORM.ENTER_NAME"),
          });
          return;
        } else if (!this.userData.rule || !this.userData.rule_politica) {
          this.showMessage({
            status: 'warning',
            text: this.$i18n.t("REGISTER_FORM.YOU_DID_NOT_ACCEPT"),
          });
          return;
        }

        Auth.register(this.userData.name, this.userData.email, this.userData.password, this.userData.subscribe)
          .then((response) => {
            this.validator = {};
            //            console.log(response);
            if(response.error){
              this.showMessage({
                status: 'error',
                text: response.message,
              });
            } else {
              this.showMessage({
                status: 'success',
                text: this.$i18n.t("REGISTER_FORM.SUCCESSFULLY_REGISTERED"),
              });
              this.$nextTick(() => {
                this.$router.push({
                  path: '/dashboard',
                });
              });
              // setTimeout(() => {

              // }, 100);
            }
          })
          .catch((error) => {
            this.validator = (error.hasOwnProperty('response') && error.response.hasOwnProperty('data') && error.response.data.hasOwnProperty('validator'))? error.response.data.validator : {};

            this.showMessage({
              status: 'error',
              text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
            });
          });
      },
      showMessage(messageData, time){
        this.messages.push(messageData);
        setTimeout(() => {
          let pos = this.messages.indexOf(messageData);
          this.messages.splice(pos, 1);
        }, time || this.timeToShowMessage)
      },
    },
  }
</script>
