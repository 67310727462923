import scriptManager from 'src/service/scriptManager';
import { features } from 'src/plugins/feature-toggle'

export default {
  install(Vue) {
    if (features.active('ais-header-footer')) {
      let script = scriptManager.addScript(
        'ais-templates-sdk',
         './ais/ais-script.js',
      );
      console.log(`Ais Templetes should be loaded`);
    } 


{/* <script src="<%= BASE_URL %>ais/ais-script.js" async></script> */}
  },
};

