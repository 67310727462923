<template>
  <div
    class="auth-container"
    :class="{'centered' : (isCentered && !isShowGuestLogin),
    'blur-background': isShowAuth || isShowPdpaPopup|| isShowAcceptPopup || isShowLoginType}"
  >
    <login-popup
      v-show="!isShowRegister && !isShowForgot"
      :is-active.sync="isShowAuth"
      :is-force="isForce"
      :is-show-register.sync="isShowRegister"
      :is-show-forgot.sync="isShowForgot"
      :default-messages="messages"
    />
    <login-type-popup
      v-if="isShowLoginType"
    />
    <accept-popup
      v-if="isShowAcceptPopup"
    />
    <pdpa-popup
      v-if="isShowPdpaPopup"
      :pdpa-type="pdpaType"
      :is-accept-pdpa="isAcceptPdpa"
    />
    <guest-login-popup
      v-if="isShowGuestLogin"
      :is-active.sync="isShowGuestLogin"
    />
    <register-popup
      v-if="isShowRegister"
      :is-active.sync="isShowRegister"
    />
    <forgot-popup
      v-if="isShowForgot"
      :is-active.sync="isShowForgot"
    />
  </div>
</template>

<script>
  import LoginPopup from 'src/components/auth-block/login/login';
  import GuestLoginPopup from 'src/components/auth-block/guest-login/guest-login';
  import RegisterPopup from 'src/components/auth-block/register/register.vue';
  import ForgotPopup from 'src/components/auth-block/forgot-pass/forgot';
  import LoginTypePopup from 'src/components/auth-block/login-type/login-type';
  import PdpaPopup from 'src/components/auth-block/pdpa-popup/pdpa-popup';
  import AcceptPopup from 'src/components/auth-block/accept-popup/accept-popup';

  export default {
    name: 'AuthBlock',
    components: {
      LoginPopup,
      LoginTypePopup,
      GuestLoginPopup,
      RegisterPopup,
      ForgotPopup,
      PdpaPopup,
      AcceptPopup,

    },
    props: {
      isShowPdpa: {
        type: Boolean,
      },
      // eventLine: {
      //   type: [String, Number]
      // },
      // isGeneral: {
      //   type: Boolean
      // }
    },
    data() {
      return {
        isShowAuth: false,
        isShowLoginType: false,
        isShowPdpaPopup: false,
        isShowAcceptPopup: false,
        isShowGuestLogin: false,
        isShowRegister: false,
        isShowForgot: false,
        isCentered: false,
        isForce: false,
        messages: null,
        idTimeout: 0,
        pdpaType: '',
        isAcceptPdpa: false,
      };
    },
    mounted() {
      // this.$bus.$on(this.eventLine + "toggleAuthPopup", this.toggleAuthPopup);
      // if (this.isGeneral) {
      this.$bus.$on('toggleAuthPopup', this.toggleAuthPopup);
      // }
    },
    destroyed() {
      // this.$bus.$off(this.eventLine + "toggleAuthPopup", this.toggleAuthPopup);
      // if (this.isGeneral) {
      this.$body.closeModal();

      this.$bus.$off('toggleAuthPopup', this.toggleAuthPopup);
      // }
    },
    methods: {
      toggleAuthPopup(event = {}) {
        this.$nextTick(() => {
          // console.log('toggleAuthPopup', event, this.isShowAuth, this.isShowForgot, this.isShowRegister);
          // NOTE: blocked closing popup by click on player
          // if(this.isShowAuth || this.isShowForgot || this.isShowRegister) {
          //   return;
          // }

          let trigger = event.trigger !== undefined ? event.trigger : null;

          this.isCentered = event.center ? event.center : false;
          this.isForce = event.force ? event.force : false;
          this.messages = null;

          if (event.message) {
            this.messages = event.message;
          }
          if (event.login) {
            this.isShowGuestLogin = false;
            this.isShowLoginType = false;
            this.isShowAuth = trigger !== null ? trigger : !this.isShowAuth;
          } else if (event.login_type) {
            this.isCentered = true;
            this.isShowAuth = false;
            this.isShowLoginType = trigger && !event.accept;
            this.isShowAcceptPopup = event.accept;
            document.body.style.overflowY = this.isShowLoginType || this.isShowAcceptPopup ? 'hidden' : 'auto';
            document.body.style.position = this.isShowLoginType || this.isShowAcceptPopup ? 'fixed' : 'static';
          } else if (event.pdpa_type) {
            this.isShowPdpaPopup = trigger;
            this.pdpaType = event.pdpa_type;
            this.isAcceptPdpa = event.isAcceptPdpa;
            this.isShowLoginType = false;
            this.isShowAcceptPopup = event.accept;
          } else if (event.guestLogin) {
            this.isShowAuth = false;
            this.isShowGuestLogin = trigger !== null ? trigger : !this.isShowGuestLogin;
          } else if (event.register) {
            this.isShowRegister = trigger !== null ? trigger : !this.isShowRegister;
          } else if (event.forgot) {
            this.isShowForgot = trigger !== null ? trigger : !this.isShowForgot;
          }
        });
      },
    },

  };
</script>


<style lang="scss">
  @import './_auth-block';
</style>
