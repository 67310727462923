<template>
  <link-wrapper
    :link="item | linkNormalisation(currentlink)"
    class="carousel-card"
    draggable="false"
    ondragstart="return false;"
    @click="onClick()"
  >
    <card-thumb-image :item="item" :aspect-ratio="aspectRatio" />
    <div class="carousel-card-text-block">
      <trim-el class="carousel-card-title" :text="title" tag="h3" />
    </div>
  </link-wrapper>
</template>

<script>
  import Config from 'src/service/config';
  import TrimEl from '../../components/common/trim-el';
  import contentLanguage from 'src/filters/contentLanguage.js';
  import CardThumbImage from 'src/components/cards/card-thumb-image.vue';
  import LinkWrapper from 'src/components/link-wrapper';
  import { amsClient } from '@/service/ams';
  import generateUrl from '@/utils/generateUrl';

  export default {
    name: 'CardCarusel',
    components: {
      // ViewsComponent
      // ProPlayBtn,
      // Duration,
      // Views,
      TrimEl,
      // responsiveImage,
      // SubscriptionLabel,
      CardThumbImage,
      LinkWrapper,
    },
    props: {
      item: {
        type: Object,
        required: true,
      },
      aspectRatio: {},
      buhContext: null,
    },
    computed: {
      currentlink() {
        return this.$route.path;
      },

      title() {
        return contentLanguage(this.item, 'title');
      },
    },
    created() {
      this._origHref = location.href;
    },
    methods: {
      onClick() {
        Config.set(
          'temp.context',
          this.buhContext
            ? this.buhContext
            : this._origHref.indexOf('search') === -1
              ? 'user_click'
              : 'search',
        );
        if (this.item.exclusive) {
          const url = `/locate/item/${this.item.id}`;
          this.$bus.$emit('loader');

          amsClient
            .callAms(url)
            .then((data) => {
              this.$bus.$emit('loader', false);
              if (!data?.locations?.screen)
                throw new Error(
                  'The channel doesn\'t belong to an exclusive screen',
                );

              const url = `/exclusive/${data.locations.screen.slug}/${generateUrl(
                this.item,
              )}`;
              this.$router.push({
                path: url,
              });
            })
            .catch((error) => {
              this.$bus.$emit('loader', false);
              console.error(error);
            });
        }
      },
    },
  };
</script>

<style lang="scss" src="./_carousel-component.scss">
</style>
