<template>
  <!-- <transition name="sidebar" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut"> -->
  <div
    class="side-bar"
    :class="{ 'side-bar-active': active }"
    @click="closeSideBar()"
    @touchstart.stop="closeSideBar()"
  >
    <div class="side-bar-wrapper">
      <div class="side-bar-container">
        <transition
          name="sidebarSlide"
          enter-active-class="slideInLeft"
          leave-active-class="slideOutLeft"
        >
          <div
            v-if="active"
            class="animated side-bar-content"
            @click.stop
            @touchstart.stop
          >
            <div class="side-bar-title-fixed">
              <router-link to="/" class="side-bar-title-fixed-logo">
                <img
                  :src="require('src/assets/ais-logo/ais_play.png')"
                  alt="PRO TV PLUS"
                  height="100%"
                  width="100%"
                />
              </router-link>
              <button
                key="close"
                class="side-bar-title-fixed-close"
                type="button"
                @click="closeSideBar()"
              >
                <span class="far fa-times" />
              </button>

              <!-- <a
                class="navbar-toggler"
                @click="closeSideBar()"
              >
                <transition
                  name="HamburgerOpen"
                  :enter-active-class="isActiveSidebar? 'rotateIn' : 'fadeIn'"
                  :leave-active-class="isActiveSidebar? 'fadeOut' : 'rotateOut'"
                  mode="out-in"
                  :duration="{enter:isActiveSidebar? 300 : 100 , leave: isActiveSidebar? 0 : 300 }"
                >
              <!-- :class="isActiveSidebar? 'rotateIn' : 'rotateOut'"-->
              <!--<img-->
              <!--v-if="!isActiveSidebar"-->
              <!--key="open"-->
              <!--class="animated"-->
              <!--:src="require('../../assets/icons/hamburger.svg')"-->
              <!--width="100%"-->
              <!--height="100%"-->
              <!--&gt;-->
              <!-- </transition> -->
              <!-- <transition name="HamburgerClose"  enter-active-class="fadeIn">
              </transition>-->
              <!-- </a> -->
              <!--<div class="line-hr align-bottom-left side-bar-separator" />-->
            </div>
            <aside
              :class="
                firefoxSideBarStyle
                  ? 'side-bar-aside side-bar-aside-firefox'
                  : 'side-bar-aside'
              "
              @touchstart.stop
            >
              <transition-group
                tag="div"
                appear-active-class="animated fadeIn"
                enter-active-class="animated fadeIn"
              >
                <div v-for="section in blocks" :key="section.title">
                  <ul class="side-bar-list">
                    <li
                      v-for="link in section.links"
                      :key="link.page"
                      class="side-bar-list-item"
                      @click="closeSideBar()"
                    >
                      <link-wrapper
                        class="side-bar-list-link is-icon"
                        :link="link | linkNormalisation"
                      >
                        <img
                          class="side-bar-list-link-icon"
                          :src="link.icon | normalizeImg('thumb')"
                          :alt="link.title"
                          width="32px"
                          height="32px"
                          :style="{
                            visibility: link.icon ? 'visible' : 'hidden',
                          }"
                        />
                        <span class="side-bar-list-link-text">{{
                            link | contentLanguage('title')
                          }}</span>
                      </link-wrapper>
                    </li>
                  </ul>
                  <!--<div class="line-hr  side-bar-separator" />-->
                </div>
              </transition-group>
              <!--<social-icons-->
              <!--class="side-bar-social d-block d-lg-none"-->
              <!--:only-icons="true"-->
              <!--/>-->
            </aside>
          </div>
        </transition>
      </div>
    </div>
  </div>
  <!-- </transition> -->
</template>

<script>
  import Config from 'src/service/config';
  import linkWrapper from 'src/components/link-wrapper.vue';
  import { isExternalLink } from 'src/utils';
  import { amsClient } from 'src/service/ams';

  export default {
    name: 'SidebarNav',
    components: {
      // SocialIcons,
      linkWrapper,
    },
    props: {
      active: {
        type: [Boolean, String],
        default: false,
      },
    },
    data() {
      return {
        context: {},
        // noScrollContent: false,
        loading: true,
        // innerHeight: window.innerHeight,
        isActive: false,
        blocks: [],
      };
    },
    computed: {
      firefoxSideBarStyle() {
        return navigator.userAgent.search(/Firefox/) !== -1 ? true : false;
      },
    },
    created() {
      let vm = this;
      Config.loaded(() => {
        this.loadData();
      });
      this.$bus.$on('resizeWindow', this.closeSideBar);
      this.$bus.$on('re-load-ams-entry', this.loadData);
    },
    destroyed() {
      this.$bus.$off('resizeWindow', this.closeSideBar);
      this.$bus.$off('re-load-ams-entry', this.loadData);
    },
    methods: {
      loadData(/*isClearCache = false*/) {
        this.loading = true;
        this.blocks = [];
        amsClient.callAms(Config.get('amsEntryPoint')).then((data) => {
          this.blocks = [
            {
              links: data.items,
              title: 'Shows',
            },
          ];
          this.loading = false;
          //            setTimeout(() => {
          //              this.$stats.send('app_ready', { extra_data: response.cached ? { cached: true } : {} });
          //            }, 100);
        });
      },
      closeSideBar() {
        if (this.active) {
          this.$body.closeModal();
          this.$emit('update:active', false);
        }
      },
      isExternalLink,
    },
  };
</script>

<style lang="scss" src="./_sidebar.scss"></style>
