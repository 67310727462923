<template>
  <div class="container-fluid row mx-0">
    <vue-headful
      :title="metadata.title"
      :description="metadata.description"
      :image="metadata.image"
      :url="metadata.url"
      :keywords="metadata.keyword"
      :head="metadata.head"
    />
    <loader v-if="loading" />
    <template v-else>
      <div class="col-12 col-lg-8 mb-3 mb-lg-0 px-0">
        <media-video
          class="video-live-player"
          :event-identifier="eventIdentifier"
          :autoplay="false"
        />
        <live-text-block
          v-if="activeChannel"
          class="mt-3"
          :event="currentEvent"
          :channel="activeChannel"
        />
      </div>
      <channels-list
        v-if="channels.length"
        :event-identifier="eventIdentifier"
        :active-channel="activeChannelId"
        class="col-12 col-lg-4 px-0 pl-lg-4 pr-lg-0 mb-3 mb-lg-0 live-channellist"
        :items="channels"
      />
    </template>
  </div>
</template>

<script>
  // import EpgService from 'src/service/epg-service';
  import EpgService from 'src/store/epg/index.js';
  import MediaVideo from 'src/components/player/video/video';
  import ChannelsList from 'src/components/channels-list/channels-list';
  import contentLanguage from 'src/filters/contentLanguage.js';
  import normaliseMetaTags from 'src/utils/normaliseMetaTags';
  import LiveTextBlock from 'src/components/live/live-text-block.vue';
  import Loader from 'src/components/loader/loader.vue';
  import { ScreenReadyMixin } from 'src/plugins/mixin';
  import { amsClient } from 'src/service/ams';
  import Config from 'src/service/config.js';
  import { memoryStore } from 'vimmi-web-utils/cjs/memoryStore';
  import { localStore } from 'vimmi-web-utils/esm/localStore';

  export default {
    name: 'LivePlayer',
    components: {
      ChannelsList,
      MediaVideo,
      LiveTextBlock,
      Loader,
    },
    mixins: [ScreenReadyMixin],

    data() {
      return {
        eventIdentifier: Math.ceil(Math.random() * 10000000),
        // channels: [],
        head: {},
        activeChannelId: null,
        // activeChannel: null,
        epgData: {},
        metadata: {},
        // displayVideo: true,
        playerReady: false,
        playmode: '',
        // loading: true
      };
    },
    computed: {
      // currentEvent() {
      //   return this.activeChannelId &&
      //     this.epgData[this.activeChannelId] &&
      //     Array.isArray(this.epgData[this.activeChannelId]) &&
      //     this.epgData[this.activeChannelId].length
      //     ? this.epgData[this.activeChannelId][0]
      //     : null;
      // },
      currentEvent() {
        let it = this.activeChannelId
          ? EpgService.getCurrentEpg(this.activeChannelId)
          : null;
        return it;
      },
      eventTitle() {
        return contentLanguage(this.currentEvent, 'title');
      },
      eventDescription() {
        return (
          contentLanguage(this.currentEvent, 'synopsis') ||
          contentLanguage(this.currentEvent, 'description')
        );
      },

      loading() {
        return EpgService.getLoading();
      },

      channels() {
        let list = EpgService.getChannels();
        return list;
      },

      activeChannel() {
        let channel = EpgService.getActiveChannel();
        if (channel) {
          this.event_setActiveChannel(channel, false);
        }
        return channel;
      },
    },
    watch: {
      'head.id': function(val) {
        this.setScreenReady(this.head, this.screenRequestTime);
      },
    },
    beforeRouteUpdate(to, from, next) {
      let vid = to?.query?.vid || localStore.get('last_play_channel') || 0;
      if (this.channels && this.channels.length) {
        EpgService.setActiveChannel(vid);
      }
      next();
    },
    mounted() {
      this.$bus.$on(
        this.eventIdentifier + '_playerReady',
        this.event_playerReady,
      );

      this.$bus.$on('changedUserState', this.onChangedUserState);
      Config.loaded(async () => {
        try {
          EpgService.setLoading(true);
          const screenData = await amsClient.callAms(`/screen/tv/`);
          this.head = Object.assign({ cached: screenData.cached }, screenData.head);
          this.playmode = this.head?.playmode;
          this.setCurrentScreen(this.head);
          this.setScreenResponse(this.head);
          const data = await EpgService.getRemoteChannels();
          let err = data.error;
          if (err) {
            this.$nextTick(() => {
              if (typeof err === 'string') {
                let prs = err.split(':');
                if (prs[0] === '301') {
                  let url = prs[1] || '/';
                  let err = prs[2] || 'Content not found';
                  // if (this.$global) {
                  memoryStore.set('redirect', {
                    code: prs[0],
                    url: url,
                    err: err,
                  });
                  // }
                  this.$router.push({ path: url });
                }
              }
            });
            return;
          }
          memoryStore.set('autoplay', true);

          let vid = this.$route.query.vid || (this.playmode !== 'enforce' && localStore.get('last_play_channel')) || screenData.head.media[0] || 0;
          EpgService.setActiveChannel(vid);

          this.screenRequestTime = this.setScreenRequest({
            link: `/screen/tv/`,
          });
          localStore.set('last_play_channel', vid);
        } catch (e) {
          console.error(err);
          this.head = Object.assign(
            {},
            {
              itype: `screen_tv`,
              id: 'error',
              link: `/screen/tv/`,
            },
          );
        }

      });
    },
    destroyed() {
      this.$bus.$off(
        this.eventIdentifier + '_playerReady',
        this.event_playerReady,
      );
      this.$bus.$off('changedUserState', this.onChangedUserState);
      EpgService.setActiveChannel(null);
      EpgService.disableAutoRefreshEpg();
    },
    methods: {
      onChangedUserState() {
        EpgService.refreshChannels();
      },
      event_playerReady() {
        this.$nextTick(() => {
          this.playerReady = true;
          this.event_setActiveChannel(this.activeChannel, true);
        });
      },

      event_setActiveChannel(channel, forcePlay) {
        this.$nextTick(() => {
          if (
            this.playerReady &&
            (forcePlay || this.activeChannelId !== channel.id)
          ) {
            this.$bus.$emit(this.eventIdentifier + '_playNowVideo', {
              item: channel,
              autoplay: true,
              bigButton: true,
              clickTime: Date.now(),
            });
          }

          if (this.activeChannelId === channel.id) {
            return;
          }

          this.activeChannelId = channel.id;
          let dt =
            channel && channel.seo_metadata ? channel.seo_metadata : channel;
          let meta = Object.assign({}, dt, {
            title: `${channel.chid}. ${channel.title}`,
          });
          this.metadata = normaliseMetaTags(meta);

          this.reloadComponent = false;

          if (localStore.get('last_play_channel') !== channel.id && this.playmode !== 'enforce') {
            localStore.set('last_play_channel', channel.id);
          }

          if (
            !this.$route.query ||
            !this.$route.query.vid ||
            this.$route.query.vid !== channel.id
          ) {
            this.$router.push({
              path: '/live',
              query: { vid: channel.id },
            });
          }
        });
      },
    },
  };
</script>

<style lang="scss">
  .live-channellist {
    .list-wrapper {
      height: 30vh;
      min-height: 300px;

      &-items {
        /* height: calc(100% - 70px);*/
      }
    }
  }

  @include media-breakpoint-up('lg') {
    .live-channellist {
      .list-wrapper {
        height: 90vh;
      }
    }
  }
</style>
