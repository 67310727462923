//import Config from './config'
import {formatDfpId} from '../utils'
import device from 'src/service/device-service'

const DfpService = (function () {
  let banners = {};
  let dfpContext = '';
  let prefix = '';
  let mPrefix ='';
  return {
    getBanner: (position, area) => {
      return area && area.uniqId && banners[area.uniqId] && banners[area.uniqId][position] ? banners[area.uniqId][position] : null;
    },
    hasBanner: (position, area) => {
      return area && area.uniqId && banners[area.uniqId] && banners[area.uniqId][position];
    },
    loadBanners: (area) => {
      if (area && !area.uniqId) {
        area.uniqId = Math.round(Math.random() * 10000);
        if (area.ads && Array.isArray(area.ads)) {
          banners[area.uniqId] = {};
          area.ads.map((_item) => {
            let _pData = DfpService.getPlacement(_item);
            if (_item.id) banners[area.uniqId][_pData.placement] = _item;
          });
        } else if (area.ad) {
          let _pData = DfpService.getPlacement(area.ad);
          banners[area.uniqId] = {
            [_pData.placement]: area.ad,
          };
        }
      }
      return true;
    },
    getAndFormatDfpPath(id = null) {
      
      let pid = formatDfpId(DfpService.getCurrentPageDfpPrefix());
      let context = formatDfpId(DfpService.getCurrentPageDfpContext());
      if(!id) {
        id = context;
      }
      return `/${pid}/${context}/${id}`
    },
    getPlacement: (_item) => {
      let placement = _item.placement || 'bottom';
      if (placement.indexOf('|') !== -1) {
        let _arrayP = placement.split('|');
        placement = _arrayP[0];
        if (_arrayP[1] && !isNaN(+_arrayP[1])) {
          _item.position = +_arrayP[1];
        }
      }
      return {placement: placement};
    },
    setCurrentPageDfpContext: (_context) => {
      dfpContext = _context;
    },
    getCurrentPageDfpContext: () => {
      return dfpContext;
    },
    
    setCurrentPageDfpPrefix: (_prefix, _mprefix) => {
      mPrefix = _mprefix;
      prefix = _prefix;

    },
    getCurrentPageDfpPrefix: () => {
      return (device.isAnyMobile() || device.isMobileSize())? mPrefix : prefix;
    },
  };
})();

export default DfpService;
