import Vue from 'vue';
import router from 'src/router';
import { localStore } from "vimmi-web-utils/esm/localStore";
import { doFetch } from "vimmi-web-utils/esm/doFetch";

export default (function () {
  let list = {};
  let currentUser = null;
  return {
    getListSubscribed: (user = null) => {
      let op = 'list';

      if (currentUser !== user || user === null) {
        currentUser = user;
        let url = `/subscription/?op=${op}&c_dm=${Date.now()}`;

        return doFetch(url, { method: 'get' })
          .then(response => {
            if (response && response.data && response.data.info) {
              list = Object.assign({}, response.data.info);
              return response.data.info;
            }
          });
      } else {
        return new Promise((resolve) => {
          resolve(list);
        });
      }
    },
    getSubcriptionStatus(item, restriction = null) {
      return !item.not_free ? 'free' :
        restriction === 'RestrictedForNotFree' ? 'not_paid' : 'paid';
    },
    subscribe: program => {
      let op = 'start';
      let url = `/subscription/?op=${op}&program=${program}`;
      return doFetch(url, {method:'get'})
        .then(response => {
          if (response && response.data && response.data.info) {
            list = Object.assign({}, response.data.info);
            return response.data.info;
          }
        });
    },

    unsubscribe: program => {
      let op = 'stop';
      let url = `/subscription/?op=${op}&program=${program}`;
      return doFetch(url, {method:'get'})
        .then(response => {
          if (response && response.data && response.data.info) {
            list = Object.assign({}, response.data.info);
            return response.data.info;
          }
        });
    },
    /**
     * @method subscribeVDO
     * @description subscribe by video package through VDO (AIS video store)
     * @param {Object} itemId, id for item
     * @param {Object} options, object with query params for request
     * @param {string} options. (optional) cpcid, Product id of CPC
     * @return {Promise}
     * */
    subscribeVDO(itemId, options = {}) {
      let defaultOpt = {
        pf: 'WEB',
        pvid: 'jtwqR1zcVWMaDQhJ0/FP2i0gzWtGGRWLCKhEJ0FRlD9bydVB9LfJv8Ef3JJaEJbzBGQTLYxWNqIVhd1qqkDc+2og==',//stg prid only for debug//authStore.get('user.privateId'),
        //        refid: md5((Date.now()).toString())
        //          .toString(),
        lang: localStore.get('lang') || 'tha',
        url: this.formatRedirectUrl('any'),
      };
      let stgUrl = 'https://stg-m.ais.co.th/stg-vdostore/srfp-fe/vdostore/web';
      options = Object.assign({}, defaultOpt, options);

      doFetch(`/get_products/${itemId}/`, {method:'get'})
        .then((productsResponse) => {
          options.vmid = [];
          productsResponse.data.products.map((dat) => {
            for (let i in dat) {
              options.vmid.push(dat[i].remote_id);
            }
          });
          options.vmid = options.vmid.join(',');

          let queryOpt = new URLSearchParams(options);
          stgUrl += '?' + queryOpt.toString();
          // console.log('subscribeVDO', options, stgUrl);

          location.href = stgUrl;

        });
    },

    formatRedirectUrl(type) {
      let props = router.resolve({
        name: 'Callbacks',
        params: {
          type: 'vdo',
          status: type,
        },
      });
      return (
        location.origin +
        (location.href.indexOf('/portal') === -1
          ? props.href.replace('/portal', '')
          : props.href)
      );
    },
  };
})();
