<template>
  <!-- <div class="play-btn-style" :style="{backgroundColor: background, 'opacity': opacity, 'borderColor': (border? border : color)}" >
          <img class="play-btn-style-img" src="require('src/assets/icons/icon-play.svg')" width="50" height="50" />
        </div> -->
  <div
    class="play-btn-style"
    :style="{'borderColor': (border? border : null), background: (type==='carousel')? background : 'none', opacity: (type==='carousel')? opacity: 1}"
  >
    <img
      v-if="type==='carousel'"
      class="play-btn-style-img"
      :src="require('src/assets/icons/icon-play.svg')"
      width="50"
      height="50"
    >
    <!-- <v-lazy-image v-if="type==='carousel'" class="play-btn-style-img" :src="require('src/assets/icons/icon-play.svg')" width="50" height="50" /> -->

    <svg
      v-else
      class="play-btn-style-svg"
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      :fill="background"
      :fill-opacity="opacity"
    >
      <g>
        <circle
          cx="50"
          cy="50"
          r="50"
        />
        <path
          v-if="type==='player'"
          style="fill:#ffffff;fill-opacity:1;"
          d="M 40,33 40,67 67,50 z"
        />
        <path
          v-else
          style="fill:#ffffff;fill-opacity:1;"
          d="M 40,30 40,70 65,50 z"
        />
      </g>
    </svg>
    <span class="play-btn-style-text">
      {{ text }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'ProPlayBtn',
    props: {
      color: {
        type: String,
        default: 'rgba(202, 202, 202, 0.3)',
      },
      border: {
        type: String,
        default: null,
      },
      opacity: {
        type: Number,
        default: 1,
      },
      text: {
        type: String,
        default: null,
      },
      type: {
        type: String,
        default: 'player',
        validator: function(value) {
          let vals = ['player', 'carousel', 'duration'];
          return vals.indexOf(value) !== -1;
        },
      },
    },
    data() {
      return {
        background: '#cccccc',
      };
    },
    mounted() {
      this.background = this.color;
      // console.log(this.background);
      if (this.background.indexOf('#') !== -1 && this.background.length === 9) {
        this.background = this.rgbToString(this.hexToRgb(this.background));
      }
    },
    methods: {
      hexToRgb(hex) {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function(m, r, g, b) {
          return r + r + g + g + b + b;
        });

        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        if (!result) {
          result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
            hex,
          );
        }
        return result
          ? [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16),
            result.length >= 5 ? +(parseInt(result[4], 16) / 255).toFixed(2) : 1,
          ]
          : [];
      },
      rgbToString(rgb) {
        let rgbString = rgb.map(val => val).join(',');
        if (rgb.length === 3) {
          rgbString = 'rgb(' + rgbString + ')';
        } else if (rgb.length === 4) {
          rgbString = 'rgba(' + rgbString + ')';
        }
        return rgbString;
      },
    },
  };
</script>

<style lang="scss">
@import './_playbtn';
</style>
