<template>
  <div class="channels-list">
    <list-wrapper
      :items="items"
      :limit="-1"
    >
      <template v-slot:title>
        <span v-t="{path: 'EPG.TV_SCHEDULE'}" />
      </template>
      <template v-slot:no-items>
        <span v-t="{path: 'EPG.NO_ITEMS'}" />
      </template>
      <template v-slot:default="{item : item, index: index}">
        <channel-item
          :key="item.id"
          :active-channel="activeChannel"
          :event-identifier="eventIdentifier"
          :item="item"
          :index="index"
          :exclusive-screen="exclusiveScreen"
          class="col-12 mx-0 px-0"
        />
      </template>
    </list-wrapper>
    <!--
      <h2
        ref="title"
        class="default-title container-fluid channels-list-title py-3 my-0"
      >
        <span v-t="{path: 'EPG.TV_SCHEDULE'}"/>
      </h2>
      <div class="col-12 channels-list-items">
        <channel-item
          v-for="(item, index) in items"
          :key="item.id"
          :active-channel="activeChannelId"
          :event-identifier="eventIdentifier"
          :item="item"
          :index="index"
          class
        />
    </div>-->
    <!-- </div> -->
  </div>
</template>

<script>
  import ListWrapper from 'src/components/list-wrapper.vue';
  import ChannelItem from './channel-item.vue';

  export default {
    name: 'ChannelsList',
    components: {
      ChannelItem,
      ListWrapper,
    },
    props: {
      eventIdentifier: {
        type: Number,
      },
      activeChannel: {
        type: String,
      },
      items: {
        type: Array,
        required: true,
      },
      exclusiveScreen: {
        type: String,
      },
    },

    data() {
      return {};
    },
    computed: {},

    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    methods: {
    },
  };
</script>


<style lang="scss">
  // .channels-list {
  //   &-title {
  //     font-size: 1.4em;
  //     // background-color: #fff;
  //   }
  //   &-items {
  //     // background-color: #fff;
  //     overflow-x: hidden;
  //     overflow-y: auto;
  //     height: 100%;
  //     max-height: 600px;
  //   }
  // }
</style>


