<template>
  <div
    v-if="isActive"
    v-click-outside="close"
    class="auth-block animated fadeIn p-4"
    @click.stop
  >
    <h3 class="auth-title text-center">
      <span v-t="{ path: 'GUEST_LOGIN.TITLE' }" />
    </h3>
    <br>
    <div class="text-center">
      <button
        type="button"
        class="btn auth-cancel rounded"
        @click="login"
      >
        <span
          class="d-inline-block text-truncate align-middle w-100 text-uppercase"
        >
          <span v-t="{ path: 'GUEST_LOGIN.YES' }" />
        </span>
      </button>
      <button
        type="button"
        class="btn auth-cancel rounded"
        @click="close"
      >
        <span
          class="d-inline-block text-truncate align-middle w-100 text-uppercase"
        >
          <span v-t="{ path: 'GUEST_LOGIN.NO' }" />
        </span>
      </button>
    </div>
  </div>
</template>

<script>
  import Auth from 'src/service/authService';
  import vClickOutside from 'v-click-outside';

  export default {
    name: 'GuestLoginBlock',
    directives: {
      clickOutside: vClickOutside.directive,
    },
    props: {
      isActive: {
        type: [Boolean, String],
        default: false,
      },
      defaultMessages: {
        type: String,
      },
      isShowRegister: {
        type: Boolean,
      },

      isShowForgot: {
        type: Boolean,
      },
      isForce: {
        type: Boolean,
      },
    },
    methods: {
      login() {
        Auth.openFungusLoginPage();
      },
      close() {
        if (this.waiting) {
          return;
        }
        if (!this.isShowRegister && !this.isShowForgot && this.isActive) {
          this.$emit('update:isActive', false);
          this.$body.closeModal();
        }
      },
    },
  }
</script>