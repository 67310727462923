<template>
  <div class="channel-profile-component">
    <div class="container-fluid">
      <div
        v-if="isVideo"
        class="channel-profile-video"
      >
        <video-live :item="mediaItem"/>
      </div>
      <img
        v-else
        class="channel-profile-poster"
        :src="poster | normalizeImg('image')"
      >
    </div>
  </div>
</template>

<script>
  import VideoLive from 'src/components/live/live-player';

  export default {
    name: 'ChannelProfile',
    components: {
      VideoLive,
    },
    props: {
      area: {
        type: Object,
        required: true,
      },
    },
    computed: {
      poster() {
        if (!this.mediaItem) {
          return null;
        }
        return this.mediaItem.itype === 'item_mov_vod' ? this.mediaItem.poster : this.mediaItem
      },
      mediaItem() {
        return this.area.media && Array.isArray(this.area.media) && this.area.media.length ? this.area.media[0] : this.area.media;
      },
      isVideo() {
        return this.mediaItem && (this.mediaItem.itype === 'item_mov_vod' || ['item_live_virtual', 'item_live'].indexOf(this.mediaItem.itype) !== -1);
      },
    },
    mounted(){
      //      console.log(this.area,22222222222);
    },

  }
</script>

<style lang="scss">
  @import './_channel-profile';
</style>
