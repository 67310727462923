<template>
  <div class="container-fluid text-white">
    <div
      v-for="(block, index) in staticLinks"
      :key="index"
      class="row border border-dark m-2 justify-content-center"
    >
      <div
        v-for="(value, key, index) in block"
        :key="key"
        class="row w-100"
      >
        <div class="col-md-5 p-2 pl-4">
          {{ key }}
        </div>

        <div class="col-md-7 p-2 pl-4 text-custom-wrap">
          <a
            class="btn btn-link text-left w-100 p-0"
            :href="value"
            target="_blank"
          >
            {{ value }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HyperlinksPage',
    data() {
      return {
        staticLinks: [
          {
            'Free VOD item': 'https://app.ais-vidnt.com/sharepoint/play/item/5b0f5725aae73172d8ab17fb/?gwtw=1',
            'Freemium VOD item (Freemium TIGA offer)': 'https://app.ais-vidnt.com/sharepoint/play/item/5f8d392191f3cded0bdc7924/?gwtw=1',
            'Freemium VOD item(Freemium_Sahamongkol offer)': 'https://app.ais-vidnt.com/sharepoint/play/item/5e953816f46828db22b2d6c7/?gwtw=1',
          },
          {
            'Free Seasons': 'https://app.ais-vidnt.com/sharepoint/play/series/5b569d32aae73116236102e5/?sub=5cb5f0f9aae73140c7d049d9&gwtw=1',
            'Freemium Seasons(Freemium For Non-AIS Packages offer)': 'https://app.ais-vidnt.com/sharepoint/play/series/5a9f4af8aae73154d24dfd53/?sub=5a9f4af8aae73154d24dfd54&gwtw=1',
            'Freemium Seasons': 'https://app.ais-vidnt.com/sharepoint/play/series/5a9f4af8aae73154d24dfd53/?sub=5a9f4af8aae73154d24dfd54&gwtw=1',
          },
          {
            'Free Series': 'https://app.ais-vidnt.com/sharepoint/play/series/5d244823aae73146a21dbc2a/?gwtw=1',
            'Premium Series(PLAY PREMIUM)': 'https://app.ais-vidnt.com/sharepoint/play/series/5e93cb8ffce7c0088f13f3e9/?gwtw=1',
            'Freemium Series("Freemium_TIGA")': 'https://app.ais-vidnt.com/sharepoint/play/series/5a9f4af8aae73154d24dfd53/?gwtw=1',
          },
          {
            'Free Episode': 'https://app.ais-vidnt.com/sharepoint/play/item/59ad1656aae731517f1a506d/?gwtw=1',
            'Premium Episode(PLAY PREMIUM, VIP PLAY Premium)': 'https://app.ais-vidnt.com/sharepoint/play/item/5fc4e29071f5858d9eac6284/?gwtw=1',
            'Freemium Episode': 'https://app.ais-vidnt.com/sharepoint/play/item/5d284ea5aae7316d5b9d7d9a/?gwtw=1',
          },
        ],
      }
    },
  };
</script>

<style scoped>
.text-custom-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  white-space: normal;
}
</style>