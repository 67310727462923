<template>
  <responsive-image
    :src="itemImage"
    :aspect-ratio="aspectRatio"
    :force-resolution-type="['SD', 'SD', 'SD']"
    :title="itemTitle"
    size="contain"
    :content-class="'card-thumb-content ' + (isNotPromotedLive? ' is-live ': '') + (showLiveBorder? ' bordered ': '') "
    :img-class="'card-thumb-image is-live' + (isNotPromotedLive? ' is-live ': '') + (showLiveBorder? ' bordered ': '') "
    :content-style="styleResponsive"
  >
    <div
      class="card-thumb-overlay d-flex flex-row align-items-end justify-content-between"
      :class="{'card-thumb-overlay-color': !isLive}"
    >
      <duration
        v-if="isVisibleDuration"
        :duration="item.duration"
        icon-play="none"
        class="pl-1 card-thumb-add"
      />
      <subscription-label
        v-if="isVisibleSubscription"
        class="card-thumb-add"
      />
      <views
        v-if="isVisibleViews"
        v-observe-visibility="viewsVisibilityConfig"
        class="ml-auto pr-1 card-thumb-add"
        :views="itemViews"
      />
    </div>
  </responsive-image>
</template>

<script>
  import responsiveImage from 'src/components/reponsive-image.vue';
  import Duration from 'src/components/player/duration/duration';
  import Views from 'src/components/player/views/views.vue';

  import contentLanguage from 'src/filters/contentLanguage.js';
  import SubscriptionLabel from 'src/components/subscription-label.vue';
  import { amsClient } from '../../service/ams';
  import Auth from '../../service/authService';

  export default {
    name: 'CardThumbnailImage',
    components: {
      Duration,
      Views,
      responsiveImage,
      SubscriptionLabel,
    },
    props: {
      item: {
        type: Object,
        required: true,
      },
      aspectRatio: {
        type: String,
      },
      hideDuration: {
        type: Boolean,
        default: false,
      },
      hideSubscription: {
        type: Boolean,
        default: false,
      },
      hideViews: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      isVideo() {
        return /mov/i.test(this.item.itype);
      },
      isLive() {
        return ['item_live_virtual', 'item_live'].indexOf(this.item.itype) !== -1;//this.item.itype === 'item_live'; // /live/i.test(this.item.itype);
      },
      isPromotedLive() {
        return /*this.item.itype === 'item_live'*/ this.isLive && this.item.promoted;
      },
      isVisibleDuration() {
        return this.canPlay && this.isVideo && !this.isLive && !this.hideDuration;
      },
      isVisibleViews() {
        return !this.isLive && this.itemViews > -1 && !this.hideViews;
      },
      isVisibleSubscription() {
        return !this.canPlay && !this.hideSubscription;
      },
      viewsVisibilityConfig() {
        return {
          callback: this.addTransparetion,
          intersection: {
            threshold: 0.9,
            // rootMargin: '0px',
          },
        };
      },
      srcImageType() {
        return !this.aspectRatio
          ? 'poster'
          : this.aspectRatio === '16x9'
            ? 'backdrop'
            : 'poster';
      },
      itemImage() {
        return this.item[this.srcImageType];
      },
      itemTitle() {
        return contentLanguage(this.item, 'title');
      },
      itemDuration() {
        return this.item.duration;
      },
      itemViews() {
        return this.item.views || this.item.views === 0 ? this.item.views : -1;
      },
      canPlay() {
        if (this.item) {
          if (amsClient.get('user.in_sync') === false && amsClient.get('user.classes')[0] === 'ais' && amsClient.isContentPremium(this.item)) {
            return false;
          }
          if(!Auth.isAuth() && (amsClient.isContentPremium(this.item) || amsClient.isContentFreemium(this.item))) {
            return false;
          }
          return amsClient.canPlay(this.item);
        }
        return true;
      },

      styleResponsive() {
        let color =
          this.isNotPromotedLive &&
          this.isActiveFeature('live-item-background')
            ? this.item.background_color
            : null;
        return {
          'background-color': color,
        };
      },
      isNotPromotedLive() {
        return this.isLive &&
          !this.isPromotedLive && this.isActiveFeature('live-item-class');
      },
      showLiveBorder() {
        return (
          this.isActiveFeature('live-item-circle') &&
          this.isNotPromotedLive
        );
      },
    },

    created() {
      // this.checkItemBuyedStatus();
      // this.$bus.$on('changedUserState', this.checkItemBuyedStatus);
    },

    beforeDestroy() {
      // this.$bus.$off('changedUserState', this.checkItemBuyedStatus);
    },

    methods: {
      addTransparetion(isVisible, el) {
        if (el && el.target && el.target.style) {
          el.target.style.opacity = isVisible ? 1 : 0.1;
        }
      },

    },
  };
</script>

<style lang="scss">
  .card-thumb-overlay {
    position: absolute;
    height: 50%;
    width: auto;
    min-width: 100%;
    bottom: -1px;
    color: white;
    border-bottom-right-radius: $subscribe-border-radius;
    border-bottom-left-radius: $subscribe-border-radius;
    overflow: hidden;

    &-color {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.5)
      );
    }
  }

  .card-thumb-content {
    border-radius: $base-border-radius;
    height: auto;
    max-height: 100%;

    // margin: auto;
    top: 50%;
    transform: translateY(-50%);

    &.is-live {
      border-radius: 50%;
      padding: 10px;

      &.bordered {
        border: 1px solid white;
        border-bottom-width: 2px;
      }
    }
  }

  .card-thumb-image {
    border-radius: $base-border-radius;
  }

  .card-thumb-add {
    padding: 5px;
    line-height: 1;
    font-size: calc(0.5em + 0.5vw);

    // &.sub-label {
    //   font-size: 0.6em;
    // }
  }

  @include media-breakpoint-up('sm') {
    .card-thumb-content {
      &.is-live {
        padding: 15px;
      }
    }
  }

  @include media-breakpoint-up('md') {
    .card-thumb-add {
      font-size: 0.8em; // 12px
    }
    .card-thumb-content {
      &.is-live {
        padding: 20px;
      }
    }
  }

  @include media-breakpoint-up('lg') {
    .card-thumb-content {
      &.is-live {
        padding: 35px;
      }
    }
  }

  @include media-breakpoint-up('xl') {
    .card-thumb-content {
      &.is-live {
        padding: 50px;
      }
    }
  }

  @media screen and (min-width: 3000px) {
    .card-thumb-content {
      &.is-live {
        padding: 100px;
      }
    }
  }
</style>
