<template>
  <pro-carousel
    v-if="!loading"
    class="internal-section-component"
    :area="area"
    :buh-context="stockType"
    :items-prop="items"
  />
</template>

<script>
import ProCarousel from './pro-carousel';
import Auth from 'src/service/authService';
import { amsClient } from 'src/service/ams';
import { AuthVariableMixin } from '../../plugins/mixin';

export default {
  name: 'InternalSection',
  components: {
    ProCarousel,
  },
  mixins: [AuthVariableMixin],
  props: {
    area: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      items: [],
    };
  },
  computed: {
    stockType() {
      return this.tag === 'watchlist'
        ? 'recent'
        : this.tag === 'favorite'
        ? 'favorites'
        : this.tag || null;
    },
    tag() {
      return this.area && this.area.directives && this.area.directives.tag
        ? this.area.directives.tag
        : null;
    },
    // link() {
    //   return this.area &&
    //   this.area.directives &&
    //   this.area.directives.filter_link
    //     ? this.area.directives.filter_link
    //     : null;
    // }
  },
  mounted() {
    // this.$bus.$on('changedUserState', this.userChange);
    //      this.$bus.$on('userSuccessAuth', this.userChange);
    //      this.$bus.$on('userSuccessLogOut', this.userChange);
    this.getData();
  },
  beforeDestroy() {
    // this.$bus.$off('changedUserState', this.userChange);
    //      this.$bus.$off('userSuccessAuth', this.userChange);
    //      this.$bus.$off('userSuccessLogOut', this.userChange);
  },
  methods: {
    getData() {
      if (
        Auth.isAuth() && !Auth.isGuest() &&
        ['recent', 'favorites'].indexOf(this.stockType) !== -1
      ) {
        let call;
        if (this.stockType === 'favorites') {
          call = amsClient.stock.getFavorites(false, 0, 50);
        } else if (this.stockType === 'recent') {
          call = amsClient.stock.getRecent(false, 0, 50);
        }
        call
          .then((items) => {
            this.items = items;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.items = [];
            this.loading = false;
          });
        // this.$cm.getMediaByTagName(this.tag, res => {
        //   let tempList = {};
        //   if (!res) {
        //     return;
        //   }
        //   // TODO: need count from link
        //   let tmp = this.link.match(/count=([^&\s]*)/);
        //   let count = tmp.length > 0 && tmp[1] ? tmp[1] : 100;
        //   let iids = res.slice(0, count)
        //     .map(el => el.mediaId);
        //   if (iids.length) {
        //     let url = this.link.replace(`{{iid}}`, iids.join(','));
        //     amsClient.callAms(url)
        //       .then(data => {
        //         let items = data.items;
        //         items.forEach((item, index) => {
        //           let pos = iids.indexOf(item.iid || item.id);
        //           if (pos !== -1) {
        //             item.poster = item.poster || item.backdrop;
        //             item.synopsis = item.synopsis || item.description;
        //             tempList[pos] = item;
        //           }
        //         });
        //         // NOTE: don`t change to assign : the order will be correct, but can be not set items
        //         this.items = Object.values(tempList);
        //         this.loading = false;
        //       });
        //   }
        // });
      } else {
        this.items = [];
        this.loading = false;
      }
    },

    onSync() {
      // setTimeout(() => {
      this.getData();
      // }, 300);
    },
  },
};
</script>

<style lang="scss">
</style>
